import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";


/**
 * 유저 휴가 정보
 * @param no : int
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const userAnnualInfo =(no)=>{
    return customAxios.post(myIpAddress+'/vacation/userAnnualInfo',
        {mem_no:no});
}
export default userAnnualInfo;
