/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";
import { AmountFormat } from "utils/AmountFormat";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';



const CustomWidthTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
		))({
		[`& .${tooltipClasses.tooltip}`]: {
			maxWidth: 500,
		},
	});



function MiniStatisticsCard({ title, count, percentage,  direction, min, init, addCount }) {

	const helpText = "복지포인트는 \n 매달 1일이 아닌 11일부터 적립되어 사용 가능해집니다."


	if (!min) {
		return (
			<SoftBox variant="gradient" borderRadius="lg" >
				<SoftBox pt={2} px={2}>
					<Grid container alignItems="center">
					<Grid item >
						<SoftBox ml={direction === "left" ? 2 : 0} lineHeight={1}>
							<SoftTypography
								variant="button"
								textTransform="capitalize"
								//fontWeight={title.fontWeight}
								color="wp_main_text"
								>
								<Icon sx={{ fontSize: 30 }}>credit_score</Icon> 
								{" "}{title.text}
							{
								title.text === "사용 가능한 복지포인트" ? (
									<CustomWidthTooltip title={helpText}
										sx={{ whiteSpace: "pre-wrap" }}
										>
										<Icon sx={{ fontSize: 30 , color : "#000" }}
											>error_outline
										</Icon> 
									</CustomWidthTooltip>
								) : ( null )
							}
							</SoftTypography>
							<SoftBox pt={1} pb={1}>
								{title.text === "앞으로 적립될 복지포인트" ? (
									<SoftTypography variant="h5" fontWeight="bold" color="wp_main_count">
										{count}
										<SoftTypography variant="button" fontWeight="bold" color="secondary">
											{" "}({AmountFormat(addCount)})
										</SoftTypography>
										<SoftTypography variant="button" fontWeight="bold" color="wp_main_unit">
											{" "}원
										</SoftTypography>
									</SoftTypography>
								) : (
									<SoftTypography variant="h5" fontWeight="bold" color="wp_main_count">
										{count}{" "}
										<SoftTypography variant="button" fontWeight="bold" color="wp_main_unit">
											원
										</SoftTypography>
									</SoftTypography>
									)
								}
							</SoftBox>
						</SoftBox>
					</Grid>
					</Grid>
				</SoftBox>
			</SoftBox>
		);

	} else {
		return (
			<SoftBox variant="gradient" borderRadius="lg">
				<SoftBox pt={2} px={2}>
					<Grid container alignItems="center">
					<Grid item >
						<SoftBox lineHeight={1}>
						<SoftTypography
							variant="body2"
							textTransform="capitalize"
							//fontWeight={title.fontWeight}
							color="secondary"
							>
							{" "}{title.text}
						</SoftTypography>
						<SoftBox pt={1} pb={1}>
							<SoftTypography
							variant="h5"
							fontWeight="bold"
							color="wp_main_count"
							>
							{count}{" "}
								<SoftTypography variant="button" fontWeight="medium" color="secondary">
								{init}
								</SoftTypography>
							</SoftTypography>
						</SoftBox>
						</SoftBox>
					</Grid>
					</Grid>
				</SoftBox>
			</SoftBox>
		);
	}
}

// 기본값 세팅
MiniStatisticsCard.defaultProps = {
  bgColor: "white",
  title: {
    fontWeight: "medium",
    text: "",
  },
  percentage: {
    color: "success",
    text: "",
  },
  direction: "right",
};

// 타입 체크
MiniStatisticsCard.propTypes = {
  bgColor: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.PropTypes.shape({
    fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
    text: PropTypes.string,
  }),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "wp_main_text",
      "wp_main_unit",
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  // icon: PropTypes.shape({
  //   color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  //   component: PropTypes.node.isRequired,
  // }).isRequired,
  direction: PropTypes.oneOf(["right", "left"]),
};

export default MiniStatisticsCard;
