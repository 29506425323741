import React, {useEffect} from "react";
import {BrowserRouter} from "react-router-dom";
import App from "App";
import {SoftUIControllerProvider} from "context";
import {QueryClient, QueryClientProvider} from "react-query";
import {RecoilRoot} from "recoil";
import {ReactQueryDevtools} from "react-query/devtools";
import {createRoot} from "react-dom/client";

// 기본 언어 ko
document.documentElement.setAttribute('lang', 'ko');
document.documentElement.setAttribute('translage', 'no');

// 구글 번역기 미사용 meta tag 추가
const metaTag = document.createElement('meta');
metaTag.setAttribute('name', 'google');
metaTag.setAttribute('content', 'notranslate');
document.head.appendChild(metaTag);

const queryClient = new QueryClient();
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <RecoilRoot>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <SoftUIControllerProvider>
                    <App/>
                    {/*<ReactQueryDevtools/>*/}
                </SoftUIControllerProvider>
            </BrowserRouter>
        </QueryClientProvider>
    </RecoilRoot>
    ,
)
