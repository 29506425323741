import React ,  { useEffect, useState  }from "react";
import { useNavigate } from "react-router-dom";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import { FormHelperText, Grid, Input, Table as MuiTable, TableCell, TextField } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import { AmountFormat } from "utils/AmountFormat";
import { useRecoilState, useRecoilValue } from "recoil";
import { loginUser } from "atom/member/loginUser";
import { useQuery } from "react-query";
import { DateFormat } from "utils/DateFormat";
import AdminWPListTableUser from "./AdminWPListTableUser";
import { wpAdminListSearch } from "../../../../atom/wp/wpAdminListSearch";
import allCommonCodeMap from "api/commonCode/allCommonCodeMap";
import SoftBadge from "components/SoftBadge";
import wpAdminAllDocList from "../../../../api/wp/wpAdminAllDocList";
import Pagination from "../../../../components/pagination/Pagination";
import { myIpAddress } from "config";
import { customAxios } from "../../../../axios/CustomAxios";
import SelectInput from "@mui/material/Select/SelectInput";



function AdminWpAllDocList() {
    const user = useRecoilValue(loginUser);

	const { light } = colors;
	const { size } = typography;
	const { borderWidth } = borders;

	const navigate = useNavigate();


	/** 오늘 일 */
	const todayDate = new Date();
	const year = todayDate.getFullYear();
	
	const startYear = year - 5; // 5년 전
    const endYear = year + 5; // 5년 후
    const selectYearList = [];
    for (let year = startYear; year <= endYear; year++) {
        selectYearList.push(year);
    }

	const [ body , setBody ] = useRecoilState(wpAdminListSearch);
	const [ start , setStart ] = useState(false);
	
	/** 년 select box */
	const [ selectedYear , setSelectedYear ] = useState(body.year);
	/** 월 select box */
	const [ selectedMonths, setSelectedMonths ] = useState(body.monthList);

	/** 페이징 처리 */
    const [ totalCount , setTotalCount ] = useState(0);
    const [ pageSize , setPageSize ] = useState(10); // 페이지당 아이템 개수
    const [ page , setPage ] = useState(body.page === 1 ? 1 : (body.page / 10) + 1);

	/** 전체, 상신 문서 일때  */
	const columns = [
		{ key: "wp_no" , name: "번호", align: "center" },
		{ key: "wp_date" , name: "작성일", align: "center" },
		{ key: "mem_name" , name: "이름", align: "left" }, 
		{ key: "wp_totalpoint" , name: "사용한 금액", align: "center" },
		{ key: "year_point" , name: "이번 연도 금액", align: "center" },
		{ key: "wp_approved" , name: "결재상태", align: "center" },
	]
	
	useEffect(() =>{
		const bodyData = {
			...body,
			mem_no : user?.id
			, role : (user?.role || "GUEST")
			, page : page === 1 ? 0 : body.page
			, pageSize : pageSize
			, stype :'all'
			, monthList : body.monthList		
			, year : body.year
		};
		setBody(bodyData)
		setStart(true)
	}, [])


	useEffect(() =>{
		if(start){
			const bodyData = {
				...body,
				mem_no : user.id
				, role : (user.role || "GUEST")
				, page : page === 1 ? 0 : ((page-1) * pageSize)
				, pageSize : pageSize
				, stype :'all'
				, monthList : body.monthList		
				, year : body.year
			};
			setBody(bodyData)
		}
	}, [page,])


	const { data } = useQuery(['wpAdminAllDocList', body], () => wpAdminAllDocList(body) , {
		enabled : start,
		select : (data) => {
			return data.data;
		}, 
	});

	const { data:info } = useQuery('allCommonCodeMap', allCommonCodeMap, {
		select : (data) => {
			return data.data;
		}
	});

	const wpAdminDocTotalCount = (body) => {
		return customAxios.post(myIpAddress+'/wp/wpAdminDocTotalCount', body);
	}
	const { data:count } = useQuery(['wpAdminDocTotalCount', body], () => wpAdminDocTotalCount(body) , {
		onSuccess : (count) => {
			setTotalCount(count.data);
		},
	});


	const handleMonthChange = (event) => {
		const selectedValues = event.target.value;
		const sortedMonths = [...selectedValues].sort((a, b) => a - b); // 낮은 숫자부터 정렬
		const formattedMonths = sortedMonths.map((month) => parseInt(month));
		setSelectedMonths(formattedMonths);
		const bodyData = {
			...body,
			mem_no : user.id
			, role : (user.role || "GUEST")
			, page : 0 
			, pageSize : 10
			, stype :'all'
			, monthList : formattedMonths		
			, year : selectedYear
		};
		setBody(bodyData)
		setPage(1)
	};
	
	const handleYearChange = (event) => {
		const selectedValues = event.target.value;
        setSelectedYear(selectedValues);
		const bodyData = {
			...body,
			mem_no : user.id
			, role : (user.role || "GUEST")
			, page : 0 
			, pageSize : 10
			, stype :'all'
			, monthList : selectedMonths		
			, year : selectedValues
		};
		setBody(bodyData)
		setPage(1)
    };



	/** 문서 row 데이터 */
	const rows = []


    if( data && info && data.length > 0 ) {
        data.map((list ,index) =>{
            let row ={}
				row["wp_no"] = list["wp_no"]
				row["wp_date"] = DateFormat(list["wp_date"])
				row["mem_no"] = list["mem_no"]
				row["imageUrl"]	= list["imageUrl"]
				row["mem_name"] = list["mem_name"]
				row["mem_team"]	= info[list["mem_team"]].info 
				row["mem_rank"]	= info[list["mem_rank"]].info 
                row["wp_totalpoint"] = AmountFormat(list["wp_totalpoint"]) + " 원"
				row["remaining_point"] = AmountFormat(parseInt(list.year_point) - parseInt(list.usedTotalPoint)) + " 원"
				row["used_totalPoint"] = AmountFormat(parseInt(list.usedTotalPoint)) + " 원"
				row["year_point"] = AmountFormat(parseInt(list["year_point"])) + " 원"
				row["wp_approved"]	= info[list["wp_approved"]].info 
            rows.push(row)
        })
    }


	const renderColumns = columns.map(({ name, align, width }, key) => {
		let pl;
		let pr;
		if (key === 0) {pl = 3;pr = 3;
		} else if (key === columns.length - 1) {pl = 3;pr = 3;
		} else {pl = 1;pr = 1;
		}

		return (
			<SoftBox
				key={name} component="th" width={width || "auto"} pt={1.5} pb={1.25} pl={align === "left" ? pl : 3} pr={align === "right" ? pr : 3}
				textAlign="center" opacity={0.7} borderBottom={`${borderWidth[1]} solid ${light.main}`}
			>
				<SoftTypography
					// variant="button" fontWeight="medium" color="dark"
					fontSize={size.xs}
					fontWeight="bold"
					color="secondary"
					sx={{ display: "inline-block", width: "max-content" , }}
					>
					{name.toUpperCase()}
				</SoftTypography>
			</SoftBox>
		);
	});

  	const renderRows = rows.map((row, key) => {
		const rowKey = `row-${key}`;
		const wp_no = row.wp_no
		let no = key+1

		const tableRow = columns.map(({ name, align, key }) => {
			let data = row[key]

			if(key === "wp_no"){ 
				data = no 
			}
			
			if(key === "mem_name")	{
				return (
					<SoftBox component="td" width="180px" pt={0.5}
						borderBottom={`${borderWidth[1]} solid ${light.main}`}
						>
						<AdminWPListTableUser 
							name={row["mem_name"]} 
							rank={row["mem_rank"]} 
							team={row["mem_team"]} 
							imageUrl={row["imageUrl"]} 
						/>
					</SoftBox>
				)
			} else if(key === "wp_approved")	{
				return (
					<SoftBox
						key={uuidv4()} component="td" p={2} textAlign={"center"}
						borderBottom={`${borderWidth[1]} solid ${light.main}`}
						>
						<SoftBadge 
							variant="contained" 
							badgeContent={row["wp_approved"]} 
							color={ row["wp_approved"] === "결재완료" ? "success" : row["wp_approved"] === "상신문서" ? "info" : "error"} size="lm" container 
							sx={{ display: "inline-block", width: "max-content" }}
						/>
					</SoftBox>
				)
			} else {
				return (
					<SoftBox
						key={uuidv4()} component="td" p={2} textAlign={"center"}
						borderBottom={`${borderWidth[1]} solid ${light.main}`}
						>
						<SoftTypography
							variant="button" fontWeight={ key === "wp_totalpoint" ? "bold" : "regular" }
							color={ name === "wp_totalpoint" ? "dark" : "text"}
							sx={{ display: "inline-block", width: "max-content" }}
							>
							{data}
						</SoftTypography>
					</SoftBox>
				)
			}
		});

        return (
			<>
				<TableRow key={rowKey}
					onClick={()=> navigate('/welfarePoint/detail',{state : wp_no})} 
					sx={{ "&:hover": { backgroundColor: "#eceff1" , cursor: "pointer" , } }} 
					>
					{tableRow}
				</TableRow>
			</>
		);
    });

    return (
		<>
			{	data &&
				<> 
					<Grid container flexWrap="nowrap">
						<Grid item width="max-content" mr={2} sx={{ minWidth : 150}}>
							<FormControl  sx={{ m: 1, width: "max-content", minWidth : 150 ,border : "0.0625rem solid #d2d6da" , borderRadius: "0.5rem", height : "38px" ,}}>
								{ selectedYear === "" && <InputLabel id="demo-year-select-label" sx={{ mt: -1 }}>년도 선택</InputLabel>}
								<SelectInput
									labelId="demo-year-select-label"
									id="demo-year-select"
									value={selectedYear}
									onChange={handleYearChange}
									// input={<OutlinedInput label="Year" />}
									sx={{
										cursor: "pointer"
										, width : 300
										, "&:focus": {
											backgroundColor: "#FFF",
										},
									}}
									>
									{selectYearList.map((year) => (
										<MenuItem key={year} value={year}>
											{year}
										</MenuItem>
									))}
								</SelectInput>
							</FormControl>
						</Grid>
						<Grid item>
							<FormControl key="monthChange" sx={{ m: 1, width: "max-content", minWidth : 150 ,border : "0.0625rem solid #d2d6da" , borderRadius: "0.5rem", height : "38px" ,}}>
								{ selectedMonths.length <= 0 && <InputLabel id="demo-year-select-label" sx={{ mt: -1 }}>월 선택</InputLabel>}
								<SelectInput
									labelId="demo-multiple-checkbox-label"
									id="demo-multiple-checkbox"
									multiple
									sx={{
										cursor: "pointer"
										, width : 300
										, "&:focus": {
											backgroundColor: "#FFF",
										},
									}}
									value={selectedMonths}
									onChange={handleMonthChange}
									//renderValue={(selected) => selected.join(', ')}
									renderValue={(selected) => selected.map((month) => `${month}월`).join(', ')}
									>
									{[...Array(12)].map((_, index) => {
										const month = index + 1;
										return (
										<MenuItem key={month} value={month}>
											<Checkbox checked={selectedMonths.includes(month)} />
											<ListItemText primary={`${month}월`} />
										</MenuItem>
										);
									})}
								</SelectInput>
							</FormControl>
						</Grid>
					</Grid>

					<TableContainer>
						<MuiTable>
						<SoftBox component="thead">
							<TableRow>{renderColumns}</TableRow>
						</SoftBox>
						<TableBody>{renderRows}</TableBody>
						</MuiTable>
					</TableContainer>
					<SoftBox pt={2} pb={1}
						display="flex"
						justifyContent="center">
						<Pagination 
							totalCount={totalCount} 
							pageSize={pageSize}
							page={page}
							setPage={setPage}
							size="small"
							/>
					</SoftBox>
				</>
			}
		</>
        )
    }

    // Setting default values for the props of Table
    AdminWpAllDocList.defaultProps = {
    columns: [],
    rows: [{}],
    };

    // Typechecking props for the Table
    AdminWpAllDocList.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    rows: PropTypes.arrayOf(PropTypes.object),
    };



export default AdminWpAllDocList