import { Grid, Table as MuiTable, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import AmountFormatInput from 'components/textField/AmountFormatInput';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { v4 as uuidv4 } from "uuid";
import { CostSave, PublicTransportationCostDetail, PublicTransportationCostDetailList, TotalAmount } from '../../../atom/cost/CostWriteDetail';
import PublicTransportationCostList from './PublicTransportationCostList';
import TableColumns from './TableColumns';


const PublicTransportaionCost = () => {

    /** 대중교통비 row 값 (행 갯수)- */
    const [costWriteDetailRowNum, setCostWriteDetailRowNum] = useRecoilState(PublicTransportationCostDetail);
    // 대중교통비 상세내용 리스트
    const [publicTransportationCostDetailList, setPublicTransportationCostDetailList] = useRecoilState(PublicTransportationCostDetailList);
    // 대중교통비 상세내용 Map
    const [publicTransportationCostDetailMap, setPublicTransportationCostDetailMap] = useState(new Map);
    // 금액 합계
    // const sumAmount = useRecoilValue(TotalAmount);
    const [totalAmount, setTotalAmount] = useState(0);
    // 저장
    const costSave = useRecoilValue(CostSave);
    

    let [delay, setdelay] = useState(false);
    useEffect(() => {
        let timer = setTimeout(() => { setdelay(true) }, 50);
    })


    useEffect(() => {
        const newMap = new Map(publicTransportationCostDetailList.map(item => [item.traffic_detail, { ...item }]));
        setPublicTransportationCostDetailMap(newMap);
    }, []); 



    // 저장 
    useEffect(()=>{
        if(costSave) {
            const updatedList = Array.from(publicTransportationCostDetailMap.values());
            setPublicTransportationCostDetailList(updatedList);
            // console.log("EmployeeBenefit costSave :: ", updatedList)  
        }
    },[costSave])


    const AddCostWriteDetailRow = () => {
        const newMap = new Map(publicTransportationCostDetailMap);
        // map의 맨 마지막 key 값 이후에 생성되어야 해서 
        const maxKey = Math.max(...publicTransportationCostDetailMap.keys()) + 1;
        // 새로운 항목 추가
        newMap.set(maxKey, {
            traffic_detail: maxKey,
            traffic_date: "",
            traffic_purpose: "",
            traffic_method: "",
            traffic_location: "",
            traffic_way: "",
            traffic_amount: ""
        });
        setCostWriteDetailRowNum(newMap.size);
        setPublicTransportationCostDetailMap(newMap);
        // console.log("바꾼 맵 값 : ", newMap);
    }
    

    useEffect(()=> {
        sumAmount()
    },[publicTransportationCostDetailMap])

    const sumAmount = () => {
        let total = 0;
        publicTransportationCostDetailMap.forEach((value, key) => {
            const trafficAmount = value.traffic_amount;
            if (trafficAmount === "" || trafficAmount === null) {
                total += 0;
            } else {
                total += parseInt(trafficAmount);
            }
        });
        setTotalAmount(total)
    }



    // row 삭제 
    const removeDetailRow = (rowindex) => {
        // const newRestailRow = publicTransportationCostDetailMap.filter((item) => item.traffic_detail !== rowindex);
        // setPublicTransportationCostDetailMap(newRestailRow)
        const newPublicTransportationCostDetailMap = new Map(publicTransportationCostDetailMap);
        newPublicTransportationCostDetailMap.forEach((value, key) => {
            if (value.traffic_detail === rowindex) {
                newPublicTransportationCostDetailMap.delete(key);
            }
        });
        setPublicTransportationCostDetailMap(newPublicTransportationCostDetailMap);
    }



    const publiccolumns = [
        // { key: "grab", name: '', align: "center",  width: "30px" },
        { key: "public_date", name: '지출일', align: "center", color: "warning", icon: "event_available", width: "150px" },
        { key: "public_category", name: '방문목적', align: "center", color: "success", icon: "fact_check", min_width: "200px" },
        { key: "public_content", name: '교통수단', align: "center", color: "info", icon: "directions_bus", width: "150px" },
        { key: "public_person", name: '방문장소', align: "center", color: "secondary", icon: "location_on", width: "160px", min_width: "160px" },
        { key: "public_amount", name: '편도/왕복', align: "center", color: "error", icon: "route", width: "150px", min_width: "150px" },
        { key: "public_payway", name: '금액', align: "center", color: "primary", icon: "paid", width: "180px", min_width: "180px" },
        { key: "btn", name: "추가", align: "center", color: "dark", icon: "payment", width: "30px" }
    ]

    const { light } = colors;
    const { borderWidth } = borders;


    const renderColumns = <TableColumns column={publiccolumns} add={AddCostWriteDetailRow} />


    //드래그를할 애를 잡았을때 인덱스값 저장용
    const [grab, setGrab] = useState(null)

    //드래그 할때
    const _onDragOver = e => {
        e.preventDefault();
    }

    //드래그 시작
    const _onDragStart = (e, item) => {
        const index = Array.from(publicTransportationCostDetailMap.values())
                        .findIndex(detail => detail.traffic_detail === item.traffic_detail);
        if (index !== -1) {
            setGrab(index);
        }
    }

    //드래그한거를 놨을때
    // 2023-06-08 드래그앤드롭 되는 위치 수정
    const _onDrop = (e, item) => {
        // let targetPosition = 0;
        // const list = [...Array.from(publicTransportationCostDetailMap.values())];
        // targetPosition = list.findIndex(detail => detail.traffic_detail === item.traffic_detail);
        // -1 아닐 때만 Map 재배치
        // if(targetPosition !== -1){
        //     let _list = [...Array.from(publicTransportationCostDetailMap.values())];
        //     // 드롭 대상 위치가 더 뒤에 있는 경우에만 요소 위치를 변경
        //     if (grabPosition < targetPosition) {
        //         _list.splice(targetPosition + 1, 0, _list[grabPosition]);
        //         _list.splice(grabPosition, 1);
        //     } else {
        //         _list.splice(targetPosition, 0, _list[grabPosition]);
        //         _list.splice(grabPosition + 1, 1);
        //     }
        //     const newMap = new Map();
        //     _list.forEach((value, index) => {
        //         newMap.set(index, value);
        //     });
        //     setPublicTransportationCostDetailMap(newMap);
        // }
        e.preventDefault();
        const grabPosition = Number(grab);
        const list = Array.from(publicTransportationCostDetailMap.values());
        const targetPosition = list.findIndex(detail => detail.traffic_detail === item.traffic_detail);

        // -1 아닐 때만 Map 재배치
        if (targetPosition !== -1) {
            const newList = [...list];
            const movedItem = newList.splice(grabPosition, 1)[0];
            newList.splice(targetPosition, 0, movedItem);

            publicTransportationCostDetailMap.clear();
            newList.forEach((value, index) => {
                publicTransportationCostDetailMap.set(index, value);
            });
            setPublicTransportationCostDetailMap(new Map(publicTransportationCostDetailMap));
        }
        
    }
    const _onDragLeave = (e, item) => {
        if (grab === publicTransportationCostDetailList.indexOf(item)) {
            e.target.style.visibility = "hidden";
        }
    }




    return (
        <>
            <TableContainer>
                <MuiTable>
                    <SoftBox component="thead">
                        <TableRow>{renderColumns}</TableRow>
                    </SoftBox>
                    <TableBody>
                        {delay === true ?
                            Array.from(publicTransportationCostDetailMap.values()).map((item) => (
                                <PublicTransportationCostList
                                    key={item.traffic_detail}
                                    rowindex={item.traffic_detail}
                                    removeDetailRow={removeDetailRow}
                                    item={item} 
                                    publicTransportationCostDetailMap={publicTransportationCostDetailMap}
                                    setPublicTransportationCostDetailMap={setPublicTransportationCostDetailMap}
                                    _onDragOver={_onDragOver}
                                    _onDragStart={_onDragStart}
                                    _onDrop={_onDrop} 
                                    sumAmount={sumAmount}
                                />
                            )) : null
                        }
                        <TableRow key={uuidv4()} bgColor="#b2dfdb" >
                            <TableCell colSpan={8} key={uuidv4()} style={{ borderTop: `${borderWidth[1]} solid ${light.main}` }}>
                                <Grid container justifyContent="flex-end" alignItems="center">
                                    <SoftBox pr={1}  >
                                        <SoftTypography
                                            variant="h4"
                                            color="white"
                                            fontWeight="bold"
                                            sx={{ display: "inline-block", width: "max-content", }}>
                                            합계
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox>
                                        <AmountFormatInput
                                            label="합계"
                                            theme="sum"
                                            size="Normal"
                                            value={totalAmount}
                                            unit="원"
                                        />
                                    </SoftBox>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </MuiTable>
            </TableContainer>
        </>
    )
}

export default PublicTransportaionCost