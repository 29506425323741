import {atom} from "recoil";

/**
 * 휴가 결재 수신함 문서 상태
 * @type {RecoilState<number>}
 */
export const vacationReceiveState = atom({
    key: "vacationReceiveState"
    , default: {
        nowPage: 1,
        docsNum: 10,
        ascending: 'DESC',
        searchEndStart: '',
        searchEndEnd: '',
        searchStartStart: '',
        searchStartEnd: '',
        searchName: '',
        searchTeam: '',
        searchCategory: '',
    }
})