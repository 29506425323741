import {CircularProgress} from "@mui/material";
import SoftTypography from "../SoftTypography";
import {styled} from "@mui/material/styles";
import SoftBox from "../SoftBox";
import Footer from "../../examples/Footer";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";

const CenteredSoftBox = styled(SoftBox)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

/**
 * 로딩페이지
 * @returns {JSX.Element}
 * @constructor
 */
const Loading = () => {
    return (
        <>
            <DashboardLayout>
                <DashboardNavbar/>
                <CenteredSoftBox>
                    <CircularProgress/>
                    <SoftTypography variant="h4" fontWeight="medium">
                        Loading.....
                    </SoftTypography>
                </CenteredSoftBox>
                <Footer/>
            </DashboardLayout>
        </>
    );
}

export default Loading;