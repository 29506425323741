// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import CostTable from "../../examples/Tables/CostTable";

// Data
import { useQuery } from "react-query";
import CostMainLists from "api/cost/CostMainLists";
import { useLocation, useNavigate } from "react-router-dom";
import SoftButton from "components/SoftButton";
import { useRecoilState, useRecoilValue } from "recoil";
import { loginUser } from "atom/member/loginUser";
import DocumentStatus from "./components/DocumentStatus";
import { useEffect, useState } from "react";
import { FaExclamation } from "react-icons/fa";
import { CostMaintitle } from "atom/cost/CostWriteDetail";
import CostTableComponent from "./components/CostTableComponent";


function Dashboard() {

  const navigate = useNavigate();
  //로그인정보
  const user = useRecoilValue(loginUser);

  const location = useLocation();

  const [documentStateSort, setDocumentStateSort] = useState("")
  // const columns = [
  //   { key: "cost_no", name: "번호", align: "center", width: "1" },
  //   { key: "category_info", name: "분류", align: "center", width: "2" },
  //   { key: "team_info", name: "소속", align: "center", width: "2" },
  //   { key: "mem_name", key2: "rank_info", name: "이름", align: "center", width: "2" },
  //   { key: "dateformat", name: "작성일", align: "center", width: "3" },
  //   { key: "approved_info", name: "결재상태", align: "center", width: "2" },
  // ]
  //문서정보를 가져올 배열
  // const rows = []
  // let reExamination = false;
  //상신올린 문서 수
  let upperCount = 0;
  //결재중인 문서 수
  let approvalingCount = 0;
  //반려된 문서 수
  let rejectCount = 0;
  //임시저장 된 문서 수
  let tempCount = 0;
  useEffect(() => { }, [documentStateSort])

  const [size, setSize] = useRecoilState(CostMaintitle);
  //비용처리 문서 내역 가져오기
  const { data, isSuccess, refetch } = useQuery(['CostMainLists', user.id, size], () => CostMainLists(user.id, size), {
    select: (data) => {
      return data.data;
    }
  });
  // //데이터 불러오기 성공시 데이터 리스트와 문서 상태에따라 저장
  // if (isSuccess) {
  //   data.map((list) => {
  //     let row = {};
  //     if (documentStateSort === "임시보관" && list.cost_approved === 'AP005') {
  //       columns.map((col) => {
  //         row[col.name] = list[col.key] + (list[col.key2] ? " " + list[col.key2] : "")
  //       })
  //       rows.push(row)
  //     } else if (documentStateSort === "") {
  //       columns.map((col) => {
  //         row[col.name] = list[col.key] + (list[col.key2] ? " " + list[col.key2] : "")
  //       })
  //       rows.push(row)
  //     }
  //   })
  //   if(data[0].count>0){
  //     reExamination = true;
  //   }
  // }

  //데이터 불러오기 성공시 데이터 리스트와 문서 상태에따라 저장
  if (isSuccess) {
    data.map((list) => {
      let row = {};
      if (list.cost_approved === 'AP001') {
        upperCount += 1
      } else if (list.cost_approved === 'AP002') {
        approvalingCount += 1;
      } else if (list.cost_approved === 'AP004') {
        rejectCount += 1;
      } else if (list.cost_approved === 'AP005') {
        tempCount += 1;
      }
    })
  }
  
  // 작성하기 버튼을 누를경우 작성페이지로 이동
  const writeBtn = () => {
    navigate('/cost/insert', { state: { oldPath: location.pathname } })
  }

  let title;
  if(size===10){
    title = "최근 10건 문서"
  }else if(size === 9){
    title = "임시저장 문서"
  }else if(size === 7){
    title = "결재보류 문서"
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <SoftBox p={1} mt={0.5}>
                <SoftTypography variant="h5">{title}</SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                {/*<span className="iconAni" style={{ color: "red", marginTop: '10px' }}>*/}
                {/*  <FaExclamation onClick={() => {*/}
                {/*    setSize(7)*/}
                {/*    refetch()*/}
                {/*  }} size={35} />*/}
                {/*</span>*/}
                <SoftButton color="secondary" onClick={() => {
                  setSize(9)
                  refetch()
                }} sx={{ ml: 1 }}>
                  임시저장문서
                </SoftButton>
                <SoftButton color="secondary" onClick={() => {
                  setSize(10)
                  refetch()
                }} sx={{ ml: 1 }}>
                  최근문서
                </SoftButton>
                <SoftButton color="primary" onClick={writeBtn} sx={{ ml: 1 }}>
                  작성하기
                </SoftButton>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox>
          <CostTableComponent/>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
