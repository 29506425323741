/**
 * colorConfig
 * @type {{vacation: string[], datasets: [{color: string, data: number[], label: string},{color: string, data: number[], label: string}]}}
 */
const colorConfig = {
    approvalType: {
        AP000 : "#2E3840"
        , AP001 : "#95BDFF"
        , AP002 : "#F99417"
        , AP003 : "#C9F4AA"
        , AP004 : "#EB455F"
        , AP005 : "#FFA3FD"
    },
    vacationType: {
        VT000 : "#AAC4FF",
        VT001 : "#AA96DA",
        VT002 : "#FCBAD3",
        VT003 : "#FFFFD2",
        VT004 : "#AAC4FF",
        VT005 : "#AAC4FF",
        PULL : "#F38181"
    }
};


// #F0F1F3
export default colorConfig;
