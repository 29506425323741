import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 복지포인트 기본 금액 밎 몰아쓰기 월 조회 
 * @param {*} body 
 * @returns 
 */

const wpAdminBasicSetting =()=>{
    return customAxios.post(myIpAddress+'/wp/adminBasicList');
    
}
export default wpAdminBasicSetting;
