import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 전체 수신함 문서
 * @returns {Promise<axios.AxiosResponse<any>>}
 * @constructor
 */
const allReceiveDocList =({
                            no,
                            nowPage,
                            docsNum,
                            startStart,
                            startEnd,
                            endStart,
                            endEnd,
                            })=>{
    return customAxios.post(myIpAddress+'/approval/allReceiveDocList',
        {
            mem_no: no
            , now_page: nowPage
            , docs_num: docsNum
            , search_start_num: startStart
            , vac_start_end: startEnd
            , vac_end_start: endStart
            , vac_end_end: endEnd
        }
        );
}
export default allReceiveDocList;