import SoftBox from "../SoftBox";
import SoftAvatar from "../SoftAvatar";
import SoftTypography from "../SoftTypography";

/**
 * 네임 카드
 * @param name:String-이름
 * @param rank:String-랭크
 * @param team:String-팀
 * @param img:String-구글이미지URL
 * @returns {JSX.Element}
 */
const NameCard = (name, rank, team, img) => {
    return (
        <SoftBox display="flex">
            <SoftBox>
                <SoftAvatar
                    src={img}
                    alt="profile-image"
                    variant="rounded"
                    size="md"
                    shadow="sm"
                />
            </SoftBox>
            <SoftBox ml={1}>
                <SoftBox mt={0.5} lineHeight={1}>
                    <SoftTypography variant="h6" fontWeight="bold">
                        {name}
                    </SoftTypography>
                    <SoftTypography variant="button" color="text" fontWeight="medium">
                        {team} / {rank}
                    </SoftTypography>
                </SoftBox>
            </SoftBox>
        </SoftBox>
    );
}

export default NameCard;