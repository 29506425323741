import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {nameCompleteInputValueAtomFamily} from "../../../../../atom/approval/approver_referrer/nameCompleteInputValue";
import {
    nameCompleteInputStatusAtomFamily
} from "../../../../../atom/approval/approver_referrer/nameCompleteInputStatusAtomFamily";

import cloneDeep from "lodash.clonedeep";
import {toMap} from "../../../../../utils/ToMap";
import Grid from "@mui/material/Grid";
import SoftButton from "../../../../../components/SoftButton";
import Icon from "@mui/material/Icon";
import {referrerInsertAtomFamily} from "../../../../../atom/approval/approver_referrer/referrerInsertAtomFamily";
import Referrer from "../Referrer";
import Tooltip from "@mui/material/Tooltip";
import {referrerCountAtomFamily} from "../../../../../atom/approval/approver_referrer/referrerCountAtomFamily";
import {useQueries} from "react-query";
import allCommonCodeMap from "../../../../../api/commonCode/allCommonCodeMap";

/**
 * 참조자 카드
 * @param props.keyName : String (식별용 키)
 * @param gridXs : int (입력값: 0~12) (해상도:0px ~ 599px)
 * @param gridMd : int (입력값: 0~12) (해상도:960px ~ 1279px)
 * @param gridLg : int (입력값: 0~12) (해상도:1280px ~ )
 * @returns {JSX.Element}
 * @constructor
 */
function ReferrerInsertCard({keyName,gridXs,gridMd,gridLg}) {

    // 참조자수 recoil
    const [referrerNum, setReferrerNum] = useRecoilState(referrerCountAtomFamily(keyName));

    // 제일 마지막에 추가된 recoil 값 초기화
    const lastInputValueReset = useResetRecoilState(nameCompleteInputValueAtomFamily(keyName+"_"+referrerNum.count))

    // 제일 마지막에 추가된 recoil 상태
    const lastInputStatus = useRecoilValue(nameCompleteInputStatusAtomFamily(keyName+"_"+referrerNum.count));

    // referrer set 값
    const [referrerInfo, setReferrerInfo] = useRecoilState(referrerInsertAtomFamily(keyName));

    // react-query
    const queries = useQueries([
        // queries[0] : 공용코드 리스트
        {
            queryKey: ["allCommonCodeMap"],
            queryFn: () => allCommonCodeMap(),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            },
        }
    ]);

    // 참조라인 추가
    const referrerAdd=()=>{
        const count = referrerNum.count+1;
        if (lastInputStatus !== true) {
            setReferrerNum({
                count: count
            });
        }
    }

    // 참조라인 삭제
    const referrerRemove=()=> {
        if (referrerNum.count > 0) {
            const count = referrerNum.count - 1;
            setReferrerNum({
                count: count
            });
            // 제일 마지막 참조자 리코일 값 초기화
            if(lastInputStatus !== true){
                lastInputValueReset();
                const referrerList = [cloneDeep(referrerInfo).referrerList];
                const insert = toMap({...referrerInfo});
                referrerList[0].pop() // 마지막 요소 제거
                insert.set("referrerList", referrerList[0]);
                setReferrerInfo(Object.fromEntries(insert)); // 저장
            }
        }
    }

    /**
     * 참조자 렌더링
     * @returns {[Referrer]}
     */
    const referrerRender = () => {
        const reder = [];
        for (let i = 0; i < referrerNum?.count; i++) {
            if (cloneDeep(referrerInfo)?.referrerList !== null) {
                const referrer = cloneDeep(referrerInfo)?.referrerList[i];
                if (referrer !== undefined && queries[0].isSuccess) {
                    const codeMap = queries[0].data.data;
                    const referrerTeam = referrer?.referrer_team;
                    const referrerRank = referrer?.ref_rank;
                    const defaultValue = "[" + (codeMap[referrerTeam]?.info) + "]"
                        + referrer?.referrer_name + " " + (codeMap[referrerRank]?.info)
                    reder.push(
                        <Grid item xs={12} lg={6}>
                            <SoftBox mt={2}>
                                <SoftTypography variant="h5" gutterBottom fontWeight="bold">
                                    {i + 1}번 참조자
                                </SoftTypography>
                                <Referrer key={i + 1} num={i + 1} keyName={keyName} defaultValue={defaultValue}/>
                            </SoftBox>
                        </Grid>
                    );
                } else {
                    reder.push(
                        <Grid item xs={12} lg={6}>
                            <SoftBox mt={2}>
                                <SoftTypography variant="h5" gutterBottom fontWeight="bold">
                                    {i + 1}번 참조자
                                </SoftTypography>
                                <Referrer key={i + 1} num={i + 1} keyName={keyName}/>
                            </SoftBox>
                        </Grid>
                    );
                }
            } else {
                reder.push(
                    <Grid item xs={12} lg={6}>
                        <SoftBox mt={2}>
                            <SoftTypography variant="h5" gutterBottom fontWeight="bold">
                                {i + 1}번 참조자
                            </SoftTypography>
                            <Referrer key={i + 1} num={i + 1} keyName={keyName}/>
                        </SoftBox>
                    </Grid>
                );
            }
        }
        return reder;
    }

    return (
        <>
            <Grid item xs={gridXs} md={gridMd} lg={gridLg}>
                <Card>
                    <Grid
                        container
                        spacing={0.5}
                        p={3}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item xs={3} md={3} lg={3}>
                            <SoftTypography variant="h5" gutterBottom fontWeight="bold">
                                참조자
                            </SoftTypography>
                        </Grid>
                        <Grid item xs={9} md={9} lg={9}>
                            <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                                {lastInputStatus ?
                                    <Tooltip title="이전 결재자가 정확히 선택되지 않으면 결재 추가되지 않습니다." placement="top">
                                        <SoftButton variant="gradient" color="warning" onClick={() => {
                                            referrerAdd()
                                        }}>
                                            <Icon sx={{fontWeight: "bold"}}>person_add</Icon>
                                            &nbsp;참조추가
                                        </SoftButton>
                                    </Tooltip>
                                    : <>
                                        <SoftButton variant="gradient" color="success" onClick={() => {
                                            referrerAdd()
                                        }}>
                                            <Icon sx={{fontWeight: "bold"}}>person_add</Icon>
                                            &nbsp;참조추가
                                        </SoftButton>
                                    </>
                                }
                                &nbsp;
                                <SoftButton variant="gradient" color="error" onClick={() => {
                                    referrerRemove()
                                }}>
                                    <Icon sx={{fontWeight: "bold"}}>person_remove</Icon>
                                    &nbsp;참조제거
                                </SoftButton>
                            </SoftBox>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <SoftBox p={3} mt={-5}>
                            <Grid
                                item
                                xs={12}
                                lg={12}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                flexWrap="wrap"
                            >
                                {referrerRender()}
                            </Grid>
                        </SoftBox>
                    </Grid>
                </Card>
            </Grid>
        </>
    );
}

export default ReferrerInsertCard;
