import AssetList from "./components/AssetList"
import { useCallback, useRef, useState } from "react";
import { Grid } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import AssetCountAndInsert from "./components/AssetCountAndInsert";
import SoftTypography from "components/SoftTypography";
import Footer from "examples/Footer";



function AssetManagement() {


    const [category, setCategory] = useState('all');
    const onSelect = useCallback(category => setCategory(category), []);

    const modalChange = useRef();
    const overlayRef = useRef();

    const closeModal = () => {
      modalChange.current.style = "display:none;";
      document.querySelector('.modal-overlay').style.zIndex = "-1";
    };
    
    const openModal = (e) => {
      modalChange.current.style = "display:block;";
      document.querySelector('.modal-overlay').style.zIndex = "10";
      modalChange.current.style.zIndex = "20";
    };



    return(
        <DashboardLayout>
          <DashboardNavbar/>           
          <Grid mt={3}>
                <AssetCountAndInsert />              
            <Grid mt={3}>
              <AssetList
                category={category}
                modalChange={modalChange}
                closeModal={closeModal}
                openModal={openModal}
                onSelect={onSelect}
                overlayRef={overlayRef}
              />
            </Grid>
          </Grid>
          <Footer />
         </DashboardLayout>         
        

    )
}

export default AssetManagement;