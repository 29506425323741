import { Card, Grid, Icon, Typography } from '@mui/material'
import { getAllMemberReservation } from "api/workPlace/WorkPlace";
import SoftTypography from "components/SoftTypography";
import { addMonths, format, subMonths } from "date-fns";
import { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { useQuery } from "react-query";
import '../workPlace.css';
import { isEmpty } from '../../../utils/ReferrerCheck'
import Box from '@mui/material/Box'
import { isMobile } from 'react-device-detect';



const RenderHeader = (props) => {

    const {
        currentMonth, prevMonth, nextMonth
    } = props;


    return (
        <>  
            <Grid container flexWrap="nowrap" alignItems="center" sx={{pb:3}}>
                <Grid>
                    <Icon fontSize="large" onClick={prevMonth}>keyboard_arrow_left</Icon>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid>
                        <SoftTypography fontWeight="bold" variant="h5">
                            {format(currentMonth, 'yyyy')} 년&nbsp;
                        </SoftTypography>
                    </Grid>
                    <Grid>
                        <SoftTypography  fontWeight="bold" variant="h5">
                            &nbsp;{format(currentMonth, 'M')} 월
                        </SoftTypography>
                    </Grid>
                </Grid>
                <Grid>
                    <Icon fontSize="large" onClick={nextMonth}>keyboard_arrow_right</Icon>
                </Grid>
            </Grid>
        </>
    );
};




const AllMemberWorkPlace = (props) => { 

    const {
        selectDate
    } = props;

    const [currentMonth, setCurrentMonth] = useState(new Date());
    
    const {isError, isLoading, data} = useQuery(['reservationAllMember',currentMonth], () => getAllMemberReservation(currentMonth),{
        refetchOnWindowFocus:false
    });

    if(!isLoading){
        console.log(data)
    }
    const prevMonth = () => {
        setCurrentMonth(subMonths(currentMonth, 1));
    };
    const nextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
    };

    
    const transformData = (rawData) => {
        const data = [[
            { type: "string", id: "name" },
            { type: "string", id: "place" },
            { type: "string", role: "tooltip" },
            { type: "string", role: "style" },
            { type: "date", id: "Start" },
            { type: "date", id: "End" }
        ]];

        const reservationInfoMap = new Map(Object.entries(rawData.reservationsListsByRegion))
        reservationInfoMap.forEach(regionReservations => {
            regionReservations.forEach(reservation => {
            const tooltip = `
                <div class="tooltip">
                    <div class="tooltip-name">
                        <span class="tooltip-name-text">[ ${reservation.user.name} ]</span>
                    </div>
                    <div>
                        <span class="tooltip-text">위치 : </span>
                        <span class="tooltip-place">${reservation.region.regName}</span>
                    </div>
                    <div>
                        <span class="tooltip-text">기간 : </span>
                        <span class="tooltip-date">${reservation.reservationDate} ~ ${reservation.reservationDate}</span>
                    </div>
                </div>
            `;

                // 시간 변경 안하면 그래프에서 작게 나옴
                const startDate = new Date(reservation.reservationDate);
                startDate.setHours(0, 0, 0, 0); 

                const endDate = new Date(reservation.reservationDate);
                endDate.setDate(endDate.getDate() + 1); // 다음 날로 설정
                endDate.setHours(0, 0, 0, 0); // 0시 0분 0초로 설정
                endDate.setMilliseconds(endDate.getMilliseconds() - 1);

                const newData = [
                    reservation.user.name,
                    reservation.region.regName,
                    tooltip,
                    reservation.region.regName === '뚝섬' ? 'color: #67b108;' :'color: #fbc400;',
                    startDate, 
                    endDate
                ];

                data.push(newData);
            });
        });

        return data;
    };



    // 차트 옵션
    const options = {
        timeline: {
            groupByRowLabel: true,
          },
        hAxis: {
            title: 'Date',
            format: 'YYYY-MM-dd', // 날짜 형식 설정
            minValue: new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1),      // 해당 월의 첫째날
            maxValue: new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0)   // 해당 월의 마지막날
        },
        allowHtml: true, // 툴팁
    };

    const getUserSeatChart = () => {
        if(!isEmpty(data.reservationsListsByRegion)){
            return(
                <Chart
                    chartType="Timeline"
                    data={transformData(data)}
                    width="100%"
                    height="100%"
                    options={options}
                    rootProps={{ 'data-testid': '1' }}/>
            )
        }else{
            return (
                    <Grid container justifyContent="center" alignContent={"center"} sx={{
                        height:'30vh',
                        paddingBottom:'50px'
                    }}>
                        <Grid item>
                            <Typography>해당 월에 등록한 사용자가 없습니다.</Typography>
                        </Grid>
                    </Grid>
            )
        }

    }



    return (
        <>
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12} >
                <Card sx={{ mt: 2, pt: 2, px: 2,  flexDirection: "column", justifyContent:"space-between", alignItems:"center"}}>
                <RenderHeader 
                    currentMonth={currentMonth} 
                    prevMonth={prevMonth}
                    nextMonth={nextMonth}
                />   
                {
                    isLoading? <></>
                        :getUserSeatChart(data)
                } 
                </Card>
            </Grid>
        </Grid>
        </>
    );
};

export default AllMemberWorkPlace;