import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 복지포인트 임시 저장 문서 저장
 * @param {*} body 
 * @returns 
 */

const wpTemporaryDocInsert = (body) =>{
    return customAxios.post(myIpAddress+'/wp/update', body);
}
export default wpTemporaryDocInsert;