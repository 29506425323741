import VacationTable from "../../../../../../examples/Tables/VacationTable";
import data from "./data";
import SoftBox from "../../../../../../components/SoftBox";
import Pagination from "@mui/material/Pagination";
import {useRecoilState, useRecoilValue} from "recoil";
import {toMap} from '../../../../../../utils/ToMap';
import {storeDocTotalCount} from "../../../../../../atom/approval/store/storeDocTotalCount";
import {vacationStoreState} from "../../../../../../atom/approval/store/vacationStoreState";

/**
 * 휴가 결재 보관 문서들
 * @returns {JSX.Element}
 * @constructor
 */
function VacationStoreDocs () {
    const {columns, rows} = data();

    const [storeState, setStoreState ] = useRecoilState(vacationStoreState);

    const totalCount = useRecoilValue(storeDocTotalCount);
    const itemsPerPage = storeState.docsNum;
    const totalPages =  Math.ceil(totalCount / itemsPerPage);

    // page 변경
    function handlePageChange(event, value) {
        const thisSentMap = toMap({...storeState});
        thisSentMap.set("nowPage",value);
        setStoreState(Object.fromEntries(thisSentMap));
    }

    return (
        <>
            <VacationTable columns={columns} rows={rows}/>
            <SoftBox display='flex' justifyContent='center'>
                <Pagination
                    count={totalPages}
                    page={storeState.nowPage}
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton
                />
            </SoftBox>
        </>
    );
}

export default VacationStoreDocs;