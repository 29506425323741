import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 휴가 수정
 * @param body
 * @returns {Promise<axios.AxiosResponse<any>>}
 * @constructor
 */
const EditVacation =(body)=>{
    return customAxios.post(myIpAddress+'/vacation/vacationEdit', body);
}
export default EditVacation;