// react 
import { Icon } from "@mui/material";
import SoftPagination from "../../components/SoftPagination";
import React ,  { forwardRef, useEffect, useState }from "react";
import { useLocation } from "react-router-dom";


// api

// util


// wp components

// @mui material components

// Soft UI Dashboard React examples


/**
 * 페이지
 * @param {*} totalCount
 * @returns 
 */
const Pagination = ({ totalCount, page , pageSize , size , setPage}) => {

    const totalPages = Math.ceil(totalCount / pageSize); // 전체 페이지 수
    const currentPageRange = Math.ceil(page / pageSize); // 현재 페이지 범위
    const startPage = currentPageRange === 1 ? 1 : (currentPageRange - 1) * pageSize + 1; // 시작 페이지
    const endPage = Math.min(currentPageRange * pageSize, totalPages); // 끝 페이지

    const generateItems = () => {
        const items = [];
        
        for (let i = startPage; i <= endPage; i++) {
            const active = i === page;
            items.push(
            <SoftPagination
                item
                key={i}
                size={size}
                active={active}
                onClick={() => {
                if (!active) {
                    setPage(i);
                }
                }}
            >
                {i}
            </SoftPagination>
            );
        }
        
        return items;
    };

  
    const handlePrev = () => {
        if (page > pageSize) {
            setPage(currentPageRange * pageSize - pageSize)
        } else {
            setPage(1)
        } 
      };
      
    const handleNext = () => {
        const nextPageRange = currentPageRange + 1;
        if (nextPageRange <= Math.ceil(totalCount / pageSize)) {
            const nextPageStart = nextPageRange * pageSize - pageSize + 1;
            const nextPageEnd = Math.min(nextPageRange * pageSize, totalPages);
            setPage(nextPageStart <= nextPageEnd ? nextPageStart : nextPageEnd);
        }
    };
    // const handleNext = () => {
    //     if (page < totalPages) {
    //         setPage(currentPageRange * pageSize + 1)
    //     }
    //     console.log(startPage , endPage , page +"????????????")
    // };

    const handleFirst = () => {
        setPage(1);
      };
    
    const handleLast = () => {
        setPage(totalPages);
    };

      
    return (
      <>
        <SoftPagination variant="outlined">
            <SoftPagination item onClick={handleFirst}>
                <Icon>keyboard_double_arrow_left</Icon>
            </SoftPagination>
            <SoftPagination item onClick={handlePrev}>
                <Icon>keyboard_arrow_left</Icon>
            </SoftPagination>
    
            {generateItems()}
    
            <SoftPagination item onClick={handleNext}>
                <Icon>keyboard_arrow_right</Icon>
            </SoftPagination>
            <SoftPagination item onClick={handleLast}>
                <Icon>keyboard_double_arrow_right</Icon>
            </SoftPagination>
        </SoftPagination>
      </>
    );
  };
  
  export default Pagination;
  