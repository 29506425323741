import {QueryClient, useQuery} from "react-query";
import userAnnualInfo from "../../../api/vacation/userAnnualInfo";
import {isRecoilValue, useRecoilValue} from "recoil";
import {loginUser} from "../../../atom/member/loginUser";
import {useEffect} from "react";
import Loading from "../../../components/loading/Loading";

/**
 * 휴가 일 수 정보 json
 * @returns {{dayData: {items: [{icon: {component: string, color: string}, progress: {percentage: number, content: string}, label: string},{icon: {component: string, color: string}, progress: {percentage: number, content: string}, label: string},{icon: {component: string, color: string}, progress: {percentage: number, content: string}, label: string},{icon: {component: string, color: string}, progress: {percentage: number, content}, label: string}]}}}
 * @constructor
 */

function VacationDaysData() {
  // react query
  const queryClient = new QueryClient();

  // login 정보
  const recoilState = isRecoilValue(loginUser);
  const user = useRecoilValue(loginUser);

  // recoil 정보가 가져와 지면 refetch
  useEffect(() => {
    if (recoilState) {
      // Only execute the query if myState is not null
      queryClient.refetchQueries('userAnnualInfo');
    }
  }, [recoilState]);

  // 유저 휴가 쿼리 저장용 변수
  let annualInfo;

  // 휴가 값
  let totalAnnual; // 전체 연차
  let pullAnnual; // 직전년도 당겨쓴 연차
  let useAnnual; // 당해년도 소진 연차
  let sickLeave; // 병가 일수
  let ccLeave; // 경조사 일수
  let etcLeave; // 기타 일수

  // 퍼센트
  let annualPercentage;
  let sickPercentage;
  let ccAndEtckPercentage;
  let pullPercentage;

  // 유저 휴가 정보 가져오기
  const {
    data,
    isSuccess
  } = useQuery(['userAnnualInfo', user?.id], () => userAnnualInfo(parseInt(user?.id)), {
    // 데이터 불러오지(fetch) 않는 시간 지정 20분
    staleTime: 120000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    select: (data) => {
      annualInfo = data.data
      return annualInfo
    }
  })

  // 성공시 휴가값 저장
  if (isSuccess) {
    // 휴가값 저장
    totalAnnual = annualInfo.total_annual
    pullAnnual = annualInfo.pull_annual
    useAnnual = annualInfo.total_annual - (annualInfo.left_annual)
    sickLeave = annualInfo.sick_leave
    ccLeave = annualInfo.cc_leave
    etcLeave = annualInfo.etc_leave

    // 퍼센테이지 저장
    annualPercentage = useAnnual/totalAnnual*100

    if (sickLeave > 0){
      sickPercentage = 100
    } else {
      sickPercentage = 0
    }

    if (ccLeave > 0 && etcLeave > 0){
      ccAndEtckPercentage = ccLeave/(ccLeave+etcLeave)*100;
    } else if(ccLeave > 0){
      ccAndEtckPercentage = ccLeave/(ccLeave+etcLeave)*100;
    } else {
      ccAndEtckPercentage = 0
    }

    if (pullAnnual > 0){
      pullPercentage = 100
    } else {
      pullPercentage = 0
    }
  }

  const dayData = {
    items: [
      {
        load: useAnnual !== undefined,
        icon: { color: "primary", component: "flight_takeoff" },
        label: "연차(사용/총)",
        progress: { content: useAnnual+'일 / '+totalAnnual+'일' , percentage: annualPercentage > 100? 100: annualPercentage},
      },
      {
        load: useAnnual !== undefined,
        icon: { color: "info", component: "vaccines" },
        label: "병가",
        progress: { content: sickLeave+'일', percentage: sickPercentage > 100 ? 100: sickPercentage},
      },
      {
        load: useAnnual !== undefined,
        icon: { color: "warning", component: "portrait_lighting" },
        label: "경조사/기타",
        progress: { content: ccLeave+'일 / '+etcLeave+'일', percentage: ccAndEtckPercentage > 100? 100: ccAndEtckPercentage },
      },
      {
        load: useAnnual !== undefined,
        icon: { color: "error", component: "keyboard_backspace" },
        label: "작년 당겨쓴 휴가",
        progress: { content: pullAnnual+'일', percentage: pullPercentage > 100 ? 100: pullPercentage},
      },
    ],
  };

  return(
      {dayData}
  )
}

export default VacationDaysData;
