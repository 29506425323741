// Soft UI Dashboard React layouts
import Dashboard from "layouts/dashboard";
import Profile from "layouts/profile";

// 복지포인트
import WPMainPage from "layouts/wp";
import WPInsertPage from "layouts/wpInsert";
import WPDetailPage from "layouts/wpDetail";
import WPListPage from "layouts/wpList/wpList";
import AdminWPpage from "layouts/admin/wp";
import WPTempListPage from "layouts/wpList/tempList";
import WPEditPage from "layouts/wpEdit";

// Soft UI Dashboard React icons
import Shop from "examples/Icons/Shop";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";
import Vacation from "./layouts/vacation";
import VacationInsert from "./layouts/vacationInsert";
import LoginSuccess from "layouts/authentication/sign-in/LoginSuccess";
import Cost from "./layouts/cost"
import CostDetail from "layouts/costDetail";
import CostInsert from "layouts/costInsert";
import VacationView from "./layouts/vacationView";
import Tables2 from "layouts/assetManagement/tables";

import MemberTable from "layouts/admin/member";
import VacationCalendar from "./layouts/vacationCalendar";
import ApprovalSent from "./layouts/docs/components/ApprovalSent";
import ApprovalReceive from "./layouts/docs/components/ApprovalReceive";
import ApprovalStore from "./layouts/docs/components/ApprovalStore";
import {BugReport, Build, Computer, Deck, Description, Settings, WorkspacesRounded} from "@mui/icons-material";
import VacationEdit from "./layouts/vacationEdit";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import NewCostInsert from "layouts/costInsert/NewCostInsert";
import Docs from "./layouts/docs";
import MoneyIcon from '@mui/icons-material/Money';
import AdminCostPage from "layouts/admin/cost/AdminCostPage";
import AssetManagement from "./layouts/assetManagement";
import BugReportPage from "./layouts/bugReportPage";
import VacationViewRedirect from "./layouts/vacationView/components/vacationViewRedirect";
import ErrorPage from "./layouts/authentication/errorPage";
import ReferenceDocs from "layouts/docs/components/ReferenceDocs";
import AdminVacation from "./layouts/admin/vacation";
import MemberAnnualInfoView from "./layouts/admin/vacation/component/memberAnnualInfoView";
import WpDetailRedirect from "./layouts/wpDetail/components/WpDetailRedirect";
import WorkPlacePage from "layouts/workPlace";
import WorkPlaceInsertDialog from './layouts/workPlace/components/WorkPlaceInsertDialog'
import WorkPlaceUpdateDialog from "./layouts/workPlace/components/WorkPlaceUpdateDialog";
import CommonCodeView from "./layouts/commonCode";


// 2023-06-05 name 변경 및 변경에 따른 자산관리, 회원 목록 route 변경
// collapse 을 추가 하면 하위 목록 생기지만 상위 클릭 시 이동 불가
const routes = [
  {
    type: "collapse",
    name: "대쉬보드",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,      
    component: <Dashboard />,   
    noCollapse: true,
  },
  {
    type: "noCollapse",
    name: "내 문서함",
    key: "docs",
    route: "/docs",
    icon: <Description size="12px" />,
    component: <Docs />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "휴가",
    key: "vacation",
    route: "/vacation",
    icon: <Deck size="12px" />,
    component: <Vacation />,
    noCollapse: true,
  },
  {
    type: "noCollapse",
    name: "휴가 등록",
    key: "vacation-insert",
    route: "/vacation/insert",
    component: <VacationInsert />,
    noCollapse: true,
  },
  {
    type: "noCollapse",
    name: "휴가 상세",
    key: "vacation-view",
    route: "/vacation/view",
    component: <VacationView />,
    noCollapse: true,
  },
  {
    type: "noCollapse",
    name: "휴가 수정",
    key: "vacation-edit",
    route: "/vacation/edit",
    component: <VacationEdit />,
    noCollapse: true,
  },
  {
    type: "noCollapse",
    name: "휴가 달력",
    key: "vacation-calendar",
    route: "/vacation/calendar",
    component: <VacationCalendar />,
    noCollapse: true,
  },
  {
    type: "noCollapse",
    name: "상신함",
    key: "docs-sent",
    route: "/docs/sent",
    component: <ApprovalSent />,
    noCollapse: true,
  },
  {
    type: "noCollapse",
    name: "수신함",
    key: "docs-receive",
    route: "/docs/receive",
    component: <ApprovalReceive />,
    noCollapse: true,
  },
  {
    type: "noCollapse",
    name: "보관함",
    key: "docs-store",
    route: "/docs/store",
    component: <ApprovalStore />,
    noCollapse: true,
  },
  {
    type: "noCollapse",
    name: "참조함",
    key: "docs-store",
    route: "/docs/reference",
    component: <ReferenceDocs />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "비용처리",
    key: "cost",
    route: "/cost",
    icon: <AttachMoneyIcon size="12px" />,
    component: <Cost />,
    noCollapse: true,
  },
  {
    type: "noCollapse",
    name: "비용처리 상세",
    key: "cost-detail",
    route: "/cost/:id",
    component: <CostDetail />,
    noCollapse: true,
  },
  {
    type: "noCollapse",
    name: "비용처리 등록",
    key: "cost-insert",
    route: "/cost/insert",
    component: <CostInsert />,
    noCollapse: true,
  },
  {
    type: "noCollapse",
    name: "NewCostInsert",
    key: "new-cost-insert",
    route: "/cost/newInsert",
    component: <NewCostInsert />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "복지포인트",
    key: "welfarePoint",
    route: "/welfarePoint",
    icon: <CreditCard size="12px" />,
    component: <WPMainPage/>,
    // collapse: [
    //   {
    //     name: "복지포인트",
    //     key: "main",
    //     route: "/wp/main",
    //     icon: <Shop size="12px" />,
    //     component: <WPMainPage/>,
    //   },
    //   {
    //     name: "복지포인트-관리자",
    //     key: "admin",
    //     route: "/wp/admin",
    //     component: <AdminWPpage />,
    //     noCollapse: true,
    //   },
    // ],
  },

  {
    type: "noCollapse",
    name: "복지포인트 등록",
    key: "welfarePoint-insert",
    route: "/welfarePoint/insert",
    component: <WPInsertPage />,
    noCollapse: true,
  },
  {
    type: "noCollapse",
    name: "전체 목록",
    key: "welfarePoint-list",
    route: "/welfarePoint/list",
    component: <WPListPage />,
    noCollapse: true,
  },
  {
    type: "noCollapse",
    name: "복지포인트 상세",
    key: "welfarePoint-detail",
    route: "/welfarePoint/detail",
    component: <WPDetailPage />,
    noCollapse: true,
  },
  {
    type: "noCollapse",
    name: "복지포인트 수정",
    key: "welfarePoint-edit",
    route: "/welfarePoint/edit",
    component: <WPEditPage />,
    noCollapse: true,
  },
  {
    type: "noCollapse",
    name: "임시 저장 목록",
    key: "welfarePoint-temp-list",
    route: "/welfarePoint/tempList",
    component: <WPTempListPage />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "자산 관리",
    key: "assetManagement",
    route: "/assetManagement",
    icon: <Computer size="12px" />,
    component: <AssetManagement />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "근무지",
    key: "workPlace",
    route: "/workPlace",
    icon: <WorkspacesRounded size="12px" />,
    component: <WorkPlacePage />,
  },
  { type: "title", title: "community", key: "community-pages" },
  {
    type: "collapse",
    name: "버그 리포트",
    key: "bugReport",
    route: "/bugReport",
    icon: <BugReport size="12px" />,
    component: <BugReportPage />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "건의 사항",
  //   key: "improvements",
  //   route: "/improvements",
  //   icon: <Build size="12px" />,
  //   component: <ImprovementsPage />,
  //   noCollapse: true,
  // },
  { type: "title", title: "Account Pages", key: "account-pages" },
  {
    type: "collapse",
    name: "내 정보",
    key: "profile",
    route: "/profile",
    icon: <CustomerSupport size="12px" />,
    component: <Profile />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "회원 목록",
    key: "memberAll",
    route: "/memberAll",
    icon: <CustomerSupport size="12px" />,
    component: <MemberTable />,
    noCollapse: true,
  },

  {
    type: "Collapse",
    name: "LoginSuccess",
    key: "LoginSuccess",
    route: "/login/success",
    component: <LoginSuccess />,
    noCollapse: true,
  },
  {
    type: "noCollapse",
    name: "물품 관리 대장",
    key: "asset-member",
    route: "/assetManagement/member",
    component: <Tables2 />,
    noCollapse: true,
  },    
  {
    type: "collapse",
    name: "복지포인트-관리자",
    key: "welfarePointAdmin",
    route: "/welfarePointAdmin",
    component: <AdminWPpage />,
    icon: <CustomerSupport size="12px" />,
    noCollapse: true, 
    showOnlyForAdmin: true
  },
  {
    type: "noCollapse",
    name: "vacationViewRedirect",
    key: "vacationViewRedirect",
    route: "/vacationViewRedirect/:viewNo",
    component: <VacationViewRedirect />,
    noCollapse: true,
  },
  {
    type: "noCollapse",
    name: "wpDetailRedirect",
    key: "wpDetailRedirect",
    route: "/wpDetailRedirect/:viewNo",
    component: <WpDetailRedirect />,
    noCollapse: true,
  },
  {
    type: "noCollapse",
    name: "errorPage",
    key: "errorPage",
    route: "/errorPage/:errorType",
    component: <ErrorPage/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "비용처리-관리자",
    key: "costAdmin",
    route: "/costAdmin",
    component: <AdminCostPage />,
    icon: <MoneyIcon size="12px" />,
    noCollapse: true,
    showOnlyForAdmin: true
  },
  {
    type: "collapse",
    name: "휴가-관리자",
    key: "adminVacation",
    route: "/adminVacation",
    component: <AdminVacation />,
    icon: <Deck size="12px" />,
    noCollapse: true,
    showOnlyForAdmin: true
  },
  {
    type: "noCollapse",
    name: "사원 휴가 정보",
    key: "memberAnnualInfoView",
    route: "/adminVacation/memberAnnualInfoView",
    component: <MemberAnnualInfoView/>,
    icon: <Deck size="12px" />,
    noCollapse: true,
    showOnlyForAdmin: true
  },
  {
    type: "noCollapse",
    name: "근무지 입력",
    key: "workPlaceInsertView",
    route: "/workPlace/insert",
    component: <WorkPlaceInsertDialog open={true} isMobile={true}/>
  },
  {
    type: "noCollapse",
    name: "근무지 수정",
    key: "workPlaceInsertView",
    route: "/workPlace/update",
    component: <WorkPlaceUpdateDialog open={true} isMobile={true}/>
  },
  {
    type: "collapse",
    name: "공용코드 관리",
    key: "commonCodeView",
    route: "/commonCodeView",
    component: <CommonCodeView/>,
    icon: <Settings size="12px" />,
    noCollapse: true,
  }
];

export default routes;
