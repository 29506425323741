import {useState} from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../../examples/Footer";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import {BottomNavigation, BottomNavigationAction} from "@mui/material";
import iconConfig from "../../../../iconConfig";
import {useQueries} from "react-query";
import allCommonCodeMap from "../../../../api/commonCode/allCommonCodeMap";
import {useLocation} from "react-router-dom";
import Grid from "@mui/material/Grid";
import colors from "../../../../assets/theme/base/colors";
import AllReferenceDocs from "./components/AllReferenceDocs";

/**
 * 참조함
 * @returns {JSX.Element}
 * @constructor
 */
function ReferenceDocs() {
    const location = useLocation();

    const {approvalType} = iconConfig;
    const {gradients} = colors;

    let approvalTypeList = [];
    //const approvalTypeCodeList = ['APT000', 'APT001', 'APT002', 'APT003']
    const approvalTypeCodeList = ['APT000', 'APT001',]
    const approvalTypeNo = {
        APT000: 0
        , APT001: 1
        , APT002: 2
        , APT003: 3
    };

    const queries = useQueries([
        // queries[0] : 공용코드 맵
        {
            queryKey: ["allCommonCodeMap"],
            queryFn: () => allCommonCodeMap(),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            }
        },
    ]);

    if (queries[0].isSuccess) {
        // 공용코드 맵
        const codeMap = queries[0].data.data;

        for (let i = 0; i < approvalTypeCodeList.length; i++) {
            approvalTypeList.push(codeMap[approvalTypeCodeList[i]].info)
        }
    }

    const [select, setSelect] = useState(approvalTypeNo[location.state]);

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Card>
                <Grid container p={1}>
                    <Grid item mt={1} ml={3} mr={3} xs={12} xl={12} lg={12}>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
                            <SoftBox>
                                <SoftTypography variant="h5" gutterBottom fontWeight="bold">
                                    참조 문서함
                                </SoftTypography>
                            </SoftBox>
                        </SoftBox>
                    </Grid>
                    <Grid item mb={2} ml={3} mr={3} xs={12} xl={12} lg={12}>
                        <SoftBox
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({borders: {borderWidth, borderColor}}) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                },
                            }}
                        >
                            {
                                <AllReferenceDocs/>
                            }
                        </SoftBox>
                    </Grid>
                </Grid>
            </Card>
            <Footer/>
        </DashboardLayout>
    );
}

export default ReferenceDocs;
