import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";


/**
 * 유저 문서 갯수 정보
 * @param no : int-멤버번호
 * @param type : String-문서타입공용코드
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const myVacationDocCount =(no,type)=>{
    return customAxios.post(myIpAddress+'/approval/myVacationDocCount',
        {
            mem_no:no,
            doc_type:type
        });
}
export default myVacationDocCount;