import Tooltip from "@mui/material/Tooltip";
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftProgress from "components/SoftProgress";
import {QueryClient, useQueries} from "react-query";
import {isRecoilValue, useRecoilState, useRecoilValue} from "recoil";
import {loginUser} from "../../../../../../atom/member/loginUser";
import {useEffect} from "react";
import allCommonCodeMap from "../../../../../../api/commonCode/allCommonCodeMap";
import vacationApprovalDocList from "../../../../../../api/vacation/vacationApprovalDocList";
import {receiveDocTotalCount} from "../../../../../../atom/approval/receive/receiveDocTotalCount";
import {receiveApprovalCheckCount} from "../../../../../../atom/approval/receive/receiveApprovalCheckCount";
import {vacationReceiveState} from "../../../../../../atom/approval/receive/vacationReceiveState";
import approveCancel from "../../../../../../assets/images/x_image.png";
import SoftTypography from "../../../../../../components/SoftTypography";
import allReceiveDocList from "../../../../../../api/docs/allReceiveDocList";
import checkDocCount from "api/docs/checkDocCount";
import moment from "moment";

/**
 * 모든 수신함 문서 테이블 데이터
 * @returns {{columns: [{name: string, align: string},{name: string, align: string},{name: string, align: string}], rows: *[]}}
 */
export default function data() {

    // 타이틀
    let title = "";
    let progress;
    let cancelReason = '';

    // react query
    const queryClient = new QueryClient();

    // login 정보
    const recoilState = isRecoilValue(loginUser);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const user = useRecoilValue(loginUser);

    // recoil 정보가 가져와 지면 refetch
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (recoilState) {
            // Only execute the query if myState is not null
            queryClient.refetchQueries('allReceiveDocList');
        }
    }, [recoilState]);

    const tableDataList= [];

    // 카테고리 state
    // eslint-disable-next-line react-hooks/rules-of-hooks,no-use-before-define
    const vacationDocState = useRecoilValue(vacationReceiveState);
    // 전체 페이지 수
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [totalCount, setTotalCount] = useRecoilState(receiveDocTotalCount);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const queries = useQueries([
        // queries[0] :
        {
            queryKey: ["allReceiveDocList", {
                no: parseInt(user?.id)
                , startStart: vacationDocState.searchStartStart
                , startEnd: vacationDocState.searchStartEnd
                , endStart: vacationDocState.searchEndStart
                , endEnd: vacationDocState.searchEndEnd
                , ascending: vacationDocState.ascending
                , nowPage: vacationDocState.nowPage
                , docsNum: vacationDocState.docsNum
            }],
            queryFn: () => allReceiveDocList({
                no: parseInt(user?.id)
                , startStart: vacationDocState.searchStartStart
                , startEnd: vacationDocState.searchStartEnd
                , endStart: vacationDocState.searchEndStart
                , endEnd: vacationDocState.searchEndEnd
                , ascending: vacationDocState.ascending
                , nowPage: vacationDocState.nowPage
                , docsNum: vacationDocState.docsNum
            }),
            // onSuccess: (data) => {
            // },
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            }
        },
        // queries[1] : 공용코드 리스트
        {
            queryKey: ["allCommonCodeMap"],
            queryFn: () => allCommonCodeMap(),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
                initialData: [],
            }
        },{
            queryKey: ["checkDocCount", {
                mem_no: parseInt(user?.id)
            }],
            queryFn: () => checkDocCount({
                mem_no: parseInt(user?.id)
            }),
            onSuccess: (data) => {
                const dataList = data.data;
                // 상신문서, 결재중 문서 갯수
                let checkCount = dataList[0].checkCount;
                // 그외 문서 갯수
                let otherDocCount = dataList[0].otherDocCount
                setTotalCount(parseInt(checkCount + otherDocCount));
            },
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            }
        }
    ]);

    if(queries[0].isSuccess && queries[1].isSuccess){
        // 휴가 정보
        const recList = queries[0].data.data;
        // 공용코드 리스트
        const codeMap = queries[1].data.data;

        for(let i=0; i<recList.length; i++){
            let cancelBool = false;
            // 결재여부(x차 결재)
            const approvalDo = recList[i]["approval_do"];
            // 결재자 수
            const approvalApprovers = recList[i]["approval_approvers"];
            // 문서 번호
            const docNo = recList[i]["doc_no"];
            // 문서 타입
            const docType = recList[i]["doc_type"];
            // 문서 결재 상태
            const approved = codeMap[recList[i]["doc_approved"]]?.info;
            // 제목
            title = "[ "+codeMap[recList[i]["doc_type"]]?.info+" ] 문서";
            // 작성자 이름 
            const recMemName = recList[i]["recMemName"];
            // 작성자info
            let member;
            // 작성일
            let regdate;
            if(docType === "APT000") {
                member = "["+codeMap[recList[i].vacList[0]["vac_team"]]?.info+"] "+recMemName+" "+codeMap[recList[i].vacList[0]["vac_rank"]]?.info+"";
                regdate = moment(recList[i].vacList[0]["vac_regdate"]).format('YYYY-MM-DD  HH:mm')
            } else if(docType === "APT001") {
                member = "["+codeMap[recList[i].costList[0]["cost_team"]]?.info+"] "+recMemName+" "+codeMap[recList[i].costList[0]["cost_rank"]]?.info+"";
                regdate = moment(recList[i].costList[0]["cost_regdate"]).format('YYYY-MM-DD  HH:mm')
            } else if(docType === "APT003"){
                member = "["+codeMap[recList[i].wpList[0]["mem_team"]]?.info+"] "+recMemName+" "+codeMap[recList[i].wpList[0]["mem_rank"]]?.info+"";
                regdate = moment(recList[i].wpList[0]["wp_date"]).format('YYYY-MM-DD  HH:mm')
            }
            // 결재 번호
            const approval_no = recList[i]["approval_no"];

            progress = (approvalDo / approvalApprovers) * 100;

            const saveMap ={
                viewNo: approval_no,
                docNo:docNo,
                docType:docType,
                title: title,
                member:member,
                progress: progress,
                cancelBool: cancelBool,
                approved:approved,
                regdate:regdate,
                myApprovalOrder: recList[i]?.approver_order
            }

            // 전체 리스트에 저장
            tableDataList.push(saveMap);
        }
    }

   
    /**
     * row에 데이터 렌더링
     * @returns {*[]}
     */
    const tabelDataRender = () => {
        const dataArr = [];

        for (let i = 0; i < tableDataList.length; i++) {
            const approved = tableDataList[i]["approved"];
            dataArr.push(
                {
                    no: tableDataList[i]["viewNo"],
                    docNo: tableDataList[i]["docNo"],
                    docType: tableDataList[i]["docType"],
                    myApprovalOrder: tableDataList[i]?.myApprovalOrder,
                    vacationinfo: [tableDataList[i]["title"]],
                    member: tableDataList[i]["member"],
                    approvalState : (
                        <SoftTypography ml={1} fontWeight="bold" variant="button"
                            color ={approved === "상신문서" || approved === "결재중" ? "info" : approved === "결재완료" ? "success" : approved === "반려" ? "error" : "" }
                            >
                            {tableDataList[i]["approved"]}
                        </SoftTypography>
                    ),
                    approvalprogress: (
                        <SoftBox width="8rem" textAlign="left">
                            {tableDataList[i]?.cancelBool ?
                                <>
                                    <SoftBox display="flex" justifyContent="flex-start" alignItems="center">
                                        <Tooltip key={i} title={"반려사유: "+tableDataList[i]?.cancelReason} placeholder="bottom">
                                            <SoftAvatar
                                                src={approveCancel}
                                                alt="profile-image"
                                                variant="rounded"
                                                size="xs"
                                                shadow="sm"
                                            />
                                        </Tooltip>
                                        <SoftTypography ml={1} variant="h6" fontWeight="medium">
                                            결재 문서 반려
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftProgress value={tableDataList[i]["progress"]} color="info" variant="gradient"
                                                  label={true}/>
                                </>
                                :
                                <>
                                <SoftProgress value={tableDataList[i]["progress"]} color="info" variant="gradient"
                                              label={true}/>
                                </>
                            }
                        </SoftBox>
                ),
                regdate:tableDataList[i]["regdate"],
            }
            );
        }
        return dataArr;
    };

    return {
        columns: [
            // 2023-06-02 내문서함 칼럼 통일
            {name: "no", align: "center" , value : "번호" , width : "10%"},
            {name: "vacationinfo", align: "left" , value : "문서정보" , width : "22%"},
            {name: "member", align: "left" , value : "작성자" , width : "20%"},
            {name: "approvalState", align: "center" , value : "결재상태" , width : "13%"},
            {name: "approvalprogress", align: "center" , value : "결재진행률" , width : "20%"},
            {name: "regdate", align: "center" , value : "작성일" , width : "10%"},
        ],

        rows: tabelDataRender(),
    };
}
