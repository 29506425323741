import allCommonCodeList from "api/commonCode/allCommonCodeList";
import { useState } from "react";
import { useQuery } from "react-query";

export default function CommonCodeData() {
    
    const [codeMap, setCodeMap] = useState([]);

    const {data:commonCodeMap} = useQuery(['commonCodeAdmin'], () => allCommonCodeList() , {
		select : (data) => {
			return data.data;
		},
        onSuccess: (data) => {
            setCodeMap(data)
        }
	});

    return {
        columns: [
            {name: "cc_info", align: "center", value: "공용 코드", width: "88%"},
            // {name: "update", align: "center", value: "수정", width: "12%"},
            {name: "delete", align: "center", value: "삭제", width: "12%"},
        ],
        rows: codeMap,
    }
}