import DashboardNavbar from "../../../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../../../examples/Footer";
import DashboardLayout from "../../../../../examples/LayoutContainers/DashboardLayout";
import SoftBox from "../../../../../components/SoftBox";
import {useLocation, useNavigate} from "react-router-dom";
import VacationDaysData from "../../data/VacationDaysData";
import VacationBarChartData from "../../data/VacationBarChartData";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import VacationBarChart from "../../../../vacation/components/VacationBarChart";
import {useQueries, useQuery} from "react-query";
import userAnnualInfo from "../../../../../api/vacation/userAnnualInfo";
import userWorkInfo from "../../../../../api/user/userWorkInfo";
import {useState} from "react";
import allCommonCodeMap from "../../../../../api/commonCode/allCommonCodeMap";
import SoftAvatar from "../../../../../components/SoftAvatar";
import SoftTypography from "../../../../../components/SoftTypography";
import myVacationDocCount from "../../../../../api/docs/myVacationDocCount";

const MemberAnnualInfoView = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const memNo = location.state;

    // 문서 공용코드
    const docType = "APT000";

    // 휴가 일수 정보
    const {items} = VacationDaysData(memNo).dayData;

    // 당해년도 월당 휴가 사용 정보
    const {chart} = VacationBarChartData(memNo).chartData;

    // 유저 정보
    const [thisUserInfo, setThisUserInfo] = useState(new Map());

    // 공요코드 맵
    const [codeMap, setCodeMap] = useState(new Map());

    const [noApprovalCount, setNoApprovalCount] = useState(0); // 무결재
    const [approvalCount, setApprovalCount] = useState(0); // 상신문서
    const [underApprovalCount, setUnderApprovalCount] = useState(0); // 결재진행중인문서
    const [completeApprovalCount, setCompleteApprovalCount] = useState(0); // 결재완료 문서
    const [rejectCount, setRejectCount] = useState(0); // 반려문서
    const [storeDocCount, setStoreDocCount] = useState(0); // 임시보관문서
    const [cancelCount, setCancelCount] = useState(0); // 상신취소 문서
    const [reexamination, setReexamination] = useState(0); // 재검토

    const queries = useQueries([
            // queries[0] : 공용코드 리스트
            {
                queryKey: ["allCommonCodeMap"],
                queryFn: () => allCommonCodeMap(),
                options: {
                    staleTime: 120000,
                    refetchOnWindowFocus: false,
                    refetchOnMount: false,
                },
                onSuccess: (result) => {
                    setCodeMap(result.data);
                }
            },
            // queries[1] : 유저 리스트
            {
                queryKey: ["userWorkInfo"],
                queryFn: () => userWorkInfo(),
                options: {
                    staleTime: 120000,
                    refetchOnWindowFocus: false,
                    refetchOnMount: false,
                },
                onSuccess: (result) => {
                    const userList = result.data;
                    userList.forEach((e) => {
                        if (e.memNo === memNo) {
                            setThisUserInfo(e);
                        }
                    });
                }

            },
            // queries[2] : 문서 카운트
            {
                queryKey: ["myVacationDocCount", memNo, docType],
                queryFn: () => myVacationDocCount(memNo,docType),
                options: {
                    staleTime: 120000,
                    refetchOnWindowFocus: false,
                    refetchOnMount: false,
                },
                onSuccess: (data) => {
                    const docCount = data.data;
                    setNoApprovalCount(UndefinedClassifier(docCount?.AP000));
                    setApprovalCount(UndefinedClassifier(docCount?.AP001));
                    setUnderApprovalCount(UndefinedClassifier(docCount?.AP002));
                    setCompleteApprovalCount(UndefinedClassifier(docCount?.AP003));
                    setRejectCount(UndefinedClassifier(docCount?.AP004));
                    setStoreDocCount(UndefinedClassifier(docCount?.AP005));
                    setCancelCount(UndefinedClassifier(docCount?.AP006));
                    setReexamination(UndefinedClassifier(docCount?.AP007));
                }
            }
        ]
    )

    /**
     * 들어온 변수가 undifined면 0 리턴 아니면 값 리턴하는 함수
     * @param keyValue - docCount
     * @returns {number|*}
     * @constructor
     */
    function UndefinedClassifier(keyValue) {
        if (keyValue !== undefined) {
            return keyValue;
        } else {
            return 0;
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <SoftBox pt={1} pb={3}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Card>
                            <Grid container px={2} pt={2} display="flex" justifyContent="space-between" alignItems="center">
                                <Grid item xs={12} md={3} lg={3} display="flex">
                                    <SoftAvatar
                                        src={thisUserInfo?.imageUrl}
                                        alt="profile-image"
                                        variant="rounded"
                                        size="lg"
                                        shadow="sm"
                                    />
                                    <SoftBox ml={1}>
                                        <SoftTypography variant="h6" fontWeight="bold">
                                            {thisUserInfo?.memName}
                                        </SoftTypography>
                                        <SoftTypography variant="button" color="text" fontWeight="medium">
                                            {codeMap[thisUserInfo?.memTeam]?.info} / {codeMap[thisUserInfo?.memRank]?.info}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} md={9} lg={9} display="flex" justifyContent="space-between">
                                    <SoftBox>
                                        <SoftTypography variant="h6" fontWeight="bold" textAlign="center">
                                            무결재 문서
                                        </SoftTypography>
                                        <SoftTypography variant="h6" fontWeight="medium" textAlign="right">
                                            {noApprovalCount} 건
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox>
                                        <SoftTypography variant="h6" fontWeight="bold" textAlign="center">
                                            상신 문서
                                        </SoftTypography>
                                        <SoftTypography variant="h6" fontWeight="medium" textAlign="right">
                                            {approvalCount} 건
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox>
                                        <SoftTypography variant="h6" fontWeight="bold" textAlign="center">
                                            결재중인 문서
                                        </SoftTypography>
                                        <SoftTypography variant="h6" fontWeight="medium" textAlign="right">
                                            {underApprovalCount} 건
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox>
                                        <SoftTypography variant="h6" fontWeight="bold" textAlign="center">
                                            결재 완료 문서
                                        </SoftTypography>
                                        <SoftTypography variant="h6" fontWeight="medium" textAlign="right">
                                            {completeApprovalCount} 건
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox>
                                        <SoftTypography variant="h6" fontWeight="bold" textAlign="center">
                                            반려 문서
                                        </SoftTypography>
                                        <SoftTypography variant="h6" fontWeight="medium" textAlign="right">
                                            {rejectCount} 건
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox>
                                        <SoftTypography variant="h6" fontWeight="bold" textAlign="center">
                                            재검토 문서
                                        </SoftTypography>
                                        <SoftTypography variant="h6" fontWeight="medium" textAlign="right">
                                            {reexamination} 건
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                            </Grid>
                            <Grid container p={1}>
                                <Grid item xs={12}>
                                    <VacationBarChart
                                        title="휴가 정보"
                                        chart={chart}
                                        items={items}
                                        chartOn={true}
                                    />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
            <Footer/>
        </DashboardLayout>
    );

}

export default MemberAnnualInfoView;