/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useEffect, useState} from "react";

// react-router-dom components
import {Link, useLocation, useNavigate} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";

// Images
import curved6 from "assets/images/curved-images/curved14.jpg";
import {useQuery} from "react-query";
import getCookie from "../../../api/auth/getCookie";
import loginPass from "../../../api/auth/loginPass";
import {loginState} from "../../../atom/member/loginState";

function SignUp() {
  const [passFlag, setPassFlag] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // state값을 가지고 접속된 접근만 login 여부 파악하여 쿼리 실행
  useEffect(()=>{
    const locState = location.state;
    if(locState === null){
      setPassFlag(true);
    }
  },[]);

  const {data: pass} = useQuery("loginPass", async () => await loginPass(), {
    enabled : passFlag,
    onSuccess: (data) => {
      navigate("/dashboard")
      setPassFlag(false);
    },
    onError: (err)=>{
      console.log(err);
      setPassFlag(true);

    }
  })

  return (
    <BasicLayout
      title="Welcome!"
      description="NASDOC from NineoneSoft"
      image={curved6}
    >
      <Card>
        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Login with
          </SoftTypography>
        </SoftBox>
        <SoftBox mb={2}>
          <Socials />
        </SoftBox>
        <Separator />
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form">
            <SoftBox mb={2}>
              <SoftInput type="email" placeholder="Email" />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput type="password" placeholder="Password" />
            </SoftBox>
            <SoftBox display="flex" alignItems="center">
             
            </SoftBox>
            <SoftBox mt={4} mb={1}>
              <SoftButton variant="gradient" color="dark" fullWidth>
                sign in
              </SoftButton>
            </SoftBox>
            <SoftBox mt={3} textAlign="center">
             
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>
    </BasicLayout>
  );
}

export default SignUp;
