import {atomFamily} from "recoil";

/**
 * 결재자 입력용 아톰 패밀리
 * @type {RecoilState<{}>}
 */
export const approverInsertAtomFamily = atomFamily({
    key: 'approverInsertAtomFamily',
    default:(key) => ({
        approverList:null,
        key: key
    })
});
