import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 휴가 상세보기
 * @param DocNo : int
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const vacationView =(DocNo)=>{
    return customAxios.post(myIpAddress+'/vacation/vacationView',
        {vac_no:DocNo});
}
export default vacationView;
