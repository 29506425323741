/**
 =========================================================
 * Soft UI Dashboard React - v4.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, {useMemo, useState} from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import {v4 as uuidv4} from "uuid";

// @mui material components
import {Table as MuiTable, Tooltip} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import {useNavigate} from "react-router-dom";
import SoftProgress from "components/SoftProgress";
import approveCancel from "../../../assets/images/x_image.png";
import {FaBan} from "react-icons/fa";
import {TruncateString} from "../../../utils/StringUtil";
import SoftBadge from "../../../components/SoftBadge";


function WpTable({columns, rows, tableDataList, type, totalCount, pageSize, page}) {

    const {light} = colors;
    const {size, fontWeightBold} = typography;
    const {borderWidth} = borders;
    const navigate = useNavigate();

    const tableRowData = rows.rows;

    const [isHovered, setIsHovered] = useState(false);


    /**
     * 이름과 이미지로 호버시 팀,이름,직책 나옴
     * @param members
     * @returns {*}
     */
    const avatars = (members) =>
        members.map(([image, name], index) => (
            <Tooltip key={name + index} title={name} placeholder="bottom">
                <SoftAvatar
                    src={image}
                    alt="name"
                    size="xs"
                    sx={{
                        border: ({borders: {borderWidth}, palette: {white}}) =>
                            `${borderWidth[2]} solid ${white.main}`,
                        cursor: "pointer",
                        position: "relative",

                        "&:not(:first-of-type)": {
                            ml: -1.25,
                        },

                        "&:hover, &:focus": {
                            zIndex: "10",
                        },
                    }}
                />
            </Tooltip>
        ));

    /**
     * row에 데이터 렌더링
     * @returns {*[]}
     */
    const tabelDataRender = () => {
        const dataArr = [];
        if (tableDataList.length > 0) {
            for (let i = 0; i < tableDataList.length; i++) {
                dataArr.push({
                    no: tableDataList[i]["viewNo"],
                    docNo: tableDataList[i]["docNo"],
                    wpinfo: tableDataList[i]["title"],
                    member: tableDataList[i]["member"],
                    approver: (
                        <SoftBox display="flex" py={1}>
                            {avatars(
                                tableDataList[i]["approvers"]
                            )
                            }
                        </SoftBox>
                    ),
                    wpAmount: tableDataList[i]?.wpAmount,
                    approved: (
                        <SoftBadge
                            variant="contained"
                            badgeContent={tableDataList[i]?.approvedInfo}
                            color={
                                tableDataList[i]?.approvedInfo === "결재완료"
                                    ? "success" : tableDataList[i]?.approvedInfo === "반려"
                                        ? "error" : tableDataList[i]?.approvedInfo === "상신취소"
                                            ? "warning" : "info"}
                            size="lm" container
                            sx={{display: "inline-block", width: "max-content"}}
                        />
                    ),
                    approvalprogress: (
                        type === "store" ?
                            tableDataList[i]["storeDate"]
                            :
                            tableDataList[i]?.cancelBool ?
                                <>
                                    <SoftBox width="8rem" textAlign="left">
                                        <SoftBox display="flex" justifyContent="flex-start" alignItems="center">
                                            <SoftTypography variant="h6" fontWeight="medium">
                                                <FaBan color="#d10404" size={18}
                                                       style={{verticalAlign: "middle", marginTop: -3}}/>
                                                결재 반려
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftTypography variant="button" fontWeight="medium">
                                            {TruncateString('사유: ' + tableDataList[i]?.cancelReason, 10)}
                                        </SoftTypography>
                                    </SoftBox>
                                </>
                                :
                                <SoftBox width="8rem" textAlign="left">
                                    <SoftProgress value={tableDataList[i]["progress"]} color="info" variant="gradient"
                                                  label={true}/>
                                </SoftBox>

                    ),
                });
            }
        } else {
            <></>
        }
        return dataArr;
    };


    const renderColumns = columns.map(({name, align, width, title}, key) => {

        let pl;
        let pr;

        if (key === 0) {
            pl = 3;
            pr = 3;
        } else if (key === columns.length - 1) {
            pl = 3;
            pr = 3;
        } else {
            pl = 1;
            pr = 1;
        }

        return (
            <SoftBox
                key={name}
                component="th"
                width={width || "auto"}
                pt={1.5}
                pb={1.25}
                pl={align === "left" ? pl : 3}
                pr={align === "right" ? pr : 3}
                textAlign={align}
                fontSize={size.xs}
                fontWeight={fontWeightBold}
                color="secondary"
                opacity={0.7}
                borderBottom={`${borderWidth[1]} solid ${light.main}`}
            >
                {name}
            </SoftBox>
        );
    });

    const data = tabelDataRender();

    const renderRows = data.map((row, key) => {
        const rowKey = `row-${key}`;
        const wp_no = data[key]["docNo"];

        let reDocNo = totalCount - (page - 1) * pageSize - key;


        const tableRow = columns.map(({name, align, title}) => {
            if (Array.isArray(row[title])) {
                return (
                    <SoftBox
                        key={title + "_" + rowKey}
                        component="td"
                        p={1}
                        borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
                    >
                        <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
                            <SoftTypography variant="button" fontWeight="medium" sx={{width: "max-content"}}>
                                {row[title][0]}
                            </SoftTypography>
                        </SoftBox>
                    </SoftBox>)

            } else {
                return (
                    <SoftBox
                        key={title + "_" + rowKey}
                        component="td"
                        p={1}
                        textAlign={align}
                        borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
                    >
                        <SoftTypography
                            key={title + "_" + rowKey}
                            variant="button"
                            fontWeight="regular"
                            color="secondary"
                            sx={{display: "inline-block", width: "max-content"}}
                        >
                            {title === "no" ? reDocNo : row[title]}
                        </SoftTypography>
                    </SoftBox>
                );
            }
            // console.log(template)
            // return template;
        });
        return (<TableRow
            key={rowKey}
            onClick={() => {
                navigate("/welfarePoint/detail", {state: wp_no});
            }}
            onMouseEnter={() => {
                setIsHovered(true)
            }}
            onMouseLeave={() => {
                setIsHovered(false)
            }}
            sx={{"&:hover": {backgroundColor: "#f5f5f5", cursor: "pointer",}}}
        >
            {tableRow}
        </TableRow>)
    });

    return (
        <TableContainer>
            <MuiTable>
                <SoftBox component="thead">
                    <TableRow>{renderColumns}</TableRow>
                </SoftBox>
                <TableBody>{renderRows}</TableBody>
            </MuiTable>
        </TableContainer>
    )
}

// Setting default values for the props of Table
WpTable.defaultProps = {
    columns: [],
    rows: [{}],
};

// Typechecking props for the Table
WpTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    rows: PropTypes.arrayOf(PropTypes.object),
};

export default WpTable;
