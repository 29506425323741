/**
 =========================================================
 * Soft UI Dashboard React - v4.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";


// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";



import VacationDaysData from "../vacation/data/VacationDaysData";
import VacationBarChartData from "../vacation/data/VacationBarChartData";
import {useRecoilValue} from "recoil";
import {loginUser} from "../../atom/member/loginUser";

import VacationBarChart from "layouts/vacation/components/VacationBarChart";
import CalendarData from "layouts/vacationCalendar/CalendarData";
import WpPointCard from "../wp/components/WpPointCard";
import Weather from "./components/Weather";
import DocsCountView from "../docs/components/DocsCountView";


function Dashboard() {
    // 로그인 리코일
    const user = useRecoilValue(loginUser);


    // 휴가 일수 정보
    const {items} = VacationDaysData().dayData;
    // 당해년도 월당 휴가 사용 정보
    const {chart} = VacationBarChartData().chartData;

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <SoftBox pb={3}>
                <Grid container spacing={2} mb={1}>
                    <Grid item xs={12} md={12} lg={12}>
                        <DocsCountView/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <WpPointCard/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <VacationBarChart chart={chart} items={items} chartOn={false}/>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <CalendarData height='60' minWidth='40' fullView={true}/>
                    </Grid>
                </Grid>
            </SoftBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default Dashboard;
