import Tooltip from "@mui/material/Tooltip";
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftProgress from "components/SoftProgress";
import {QueryClient, useQueries} from "react-query";
import {isRecoilValue, useRecoilState, useRecoilValue} from "recoil";
import {loginUser} from "../../../../../../../atom/member/loginUser";
import {useEffect} from "react";
import allCommonCodeMap from "../../../../../../../api/commonCode/allCommonCodeMap";
import {receiveDocTotalCount} from "../../../../../../../atom/approval/receive/receiveDocTotalCount";
import {vacationReceiveState} from "../../../../../../../atom/approval/receive/vacationReceiveState";
import approveCancel from "../../../../../../../assets/images/x_image.png";
import SoftTypography from "../../../../../../../components/SoftTypography";
import refAllDocList from "../../../../../../../api/refer/refAllDocList";
import SoftBadge from "../../../../../../../components/SoftBadge";
import { docReferenceState } from "../../../../../../../atom/approval/reference/docReferenceState";
import { referenceDocTotalCount } from "../../../../../../../atom/approval/reference/referenceDocTotalCount";

/**
 * 휴가 참조함 문서 테이블 데이터
 * @returns {{columns: [{name: string, align: string},{name: string, align: string},{name: string, align: string}], rows: *[]}}
 */
export default function data() {

    // 타이틀
    let title = "";
    let progress;
    let cancelReason = '';

    // react query
    const queryClient = new QueryClient();

    // login 정보
    const recoilState = isRecoilValue(loginUser);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const user = useRecoilValue(loginUser);

    // recoil 정보가 가져와 지면 refetch
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (recoilState) {
            // Only execute the query if myState is not null
            queryClient.refetchQueries('vacationApprovalDocList');
        }
    }, [recoilState]);

    const tableDataList= [];

    // 카테고리 state
    // eslint-disable-next-line react-hooks/rules-of-hooks,no-use-before-define
    const vacationDocState = useRecoilValue(docReferenceState);

    // 전체 페이지 수
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [totalCount, setTotalCount] = useRecoilState(referenceDocTotalCount);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const queries = useQueries([
        // queries[0] : 휴가 정보
        {
            queryKey: ["refAllDocList", {
                no: parseInt(user?.id)
                , nowPage: vacationDocState.nowPage
                , docsNum: vacationDocState.docsNum
            }],
            queryFn: () => refAllDocList({
                no: parseInt(user?.id)
                , nowPage: vacationDocState.nowPage
                , docsNum: vacationDocState.docsNum
            }),
            onSuccess: (data) => {
                setTotalCount(data.data[0].total_count);
            },
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            }
        },
        // queries[1] : 공용코드 리스트
        {
            queryKey: ["allCommonCodeMap"],
            queryFn: () => allCommonCodeMap(),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
                initialData: [],
            }
        },
    ]);

    if(queries[0].isSuccess && queries[1].isSuccess){
        // 휴가 정보
        const refList = queries[0].data.data;
        // 공용코드 리스트
        const codeMap = queries[1].data.data;

        for(let i=0; i<refList.length; i++){

            let cancelBool = false;
            // 결재여부(x차 결재)
            const approvalDo = refList[i]["approval_do"];
            // 결재자 수
            const approvalApprovers = refList[i]["approval_approvers"];
            // 문서 번호
            const docNo = refList[i]["doc_no"];
            // 문서 타입
            const docType = codeMap[refList[i]["doc_type"]]?.info;
            // 문서 타입
            const orgDocType = refList[i]["doc_type"];
            // 문서 결재 상태
            const approved = refList[i]["doc_approved"];
            // 멤버이름
            const member = "["+codeMap[refList[i]["mem_team"]]?.info+"] "+refList[i]["mem_name"]+" "+codeMap[refList[i]["mem_rank"]]?.info+"";
            // 문서 번호
            const viewNo = refList[i]["view_no"];
            // 반려 이유
            const cancelReason = refList[i]["approval_cancel_reason"];

            // 참조 확인 여부
            const refCheck = refList[i]["ref_regdate"] !== null;

            // // 결재 진행 상황
            // progress = (approvalDo/approvalApprovers)*100;

            const saveMap ={
                viewNo: viewNo,
                docNo:docNo,
                docType: docType,
                orgDocType: orgDocType,
                member:member,
                refCheck : refCheck,
                // progress: progress,
                cancelBool: cancelBool,
                cancelReason: cancelReason
            }

            // 전체 리스트에 저장
            tableDataList.push(saveMap);
        }
    }


    /**
     * row에 데이터 렌더링
     * @returns {*[]}
     */
    const tabelDataRender = () => {
        const dataArr = [];
        for (let i = 0; i < tableDataList.length; i++) {
            dataArr.push(
                {
                    no: tableDataList[i]["viewNo"],
                    docType: tableDataList[i]["docType"],
                    orgDocType: tableDataList[i]["orgDocType"],
                    member: tableDataList[i]["member"],
                    docNo:tableDataList[i]["docNo"],
                    refCheck: (
                        <SoftBox textAlign="left">
                            {tableDataList[i]?.refCheck ?
                                <>
                                    <SoftBadge variant="contained" badgeContent="참조 확인" color="success" size="ml" container />
                                </>
                                :
                                <>
                                    <SoftBadge variant="contained" badgeContent="미확인" color="error" size="ml" container />
                                </>
                            }
                        </SoftBox>
                ),
                    // approvalprogress: (
                    //     <SoftBox width="8rem" textAlign="left">
                    //         {tableDataList[i]?.cancelBool ?
                    //             <>
                    //                 <SoftBox display="flex" justifyContent="flex-start" alignItems="center">
                    //                     <Tooltip key={i} title={"반려사유: "+tableDataList[i]?.cancelReason} placeholder="bottom">
                    //                         <SoftAvatar
                    //                             src={approveCancel}
                    //                             alt="profile-image"
                    //                             variant="rounded"
                    //                             size="xs"
                    //                             shadow="sm"
                    //                         />
                    //                     </Tooltip>
                    //                     <SoftTypography ml={1} variant="h6" fontWeight="medium">
                    //                         결재 문서 반려
                    //                     </SoftTypography>
                    //                 </SoftBox>
                    //                 <SoftProgress value={tableDataList[i]["progress"]} color="info" variant="gradient"
                    //                               label={true}/>
                    //             </>
                    //             :
                    //             <SoftProgress value={tableDataList[i]["progress"]} color="info" variant="gradient"
                    //                           label={true}/>
                    //         }
                    //     </SoftBox>
                    // ),
            }
            );
        }
        return dataArr;
    };

    return {
        columns: [
            // 2023-06-02 내문서함 칼럼 통일
            {name: "no", align: "center" , value : "번호" },
            {name: "docType", align: "left" , value : "문서 타입"},
            {name: "member", align: "left" , value : "작성자" },
            // {name: "approvalprogress", align: "center" , value : "결재상태" },
            {name: "refCheck", align: "center" , value : "참조확인" },
        ],

        rows: tabelDataRender(),
    };
}
