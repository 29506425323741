import { customAxios } from "../../axios/CustomAxios";
import {myIpAddress} from "../../config";

/**
 * 유저 메모 최신 10개 조회
 * @param memNo : int 유저 번호
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const userWorkMemoList =(memNo)=>{
    return customAxios.post(myIpAddress+'/user/workMemo',{memNo : memNo});
}
export default userWorkMemoList;