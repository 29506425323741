import React ,  {  useEffect, useState }from "react";
import { useQuery, useMutation } from "react-query";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import moment from "moment";
import PropTypes from "prop-types";


// api
import wpDetail from "../../../api/wp/wpDetail";
import wpTemporaryDocInsert from "../../../api/wp/wpTemporaryDocInsert";
import wpPointList from "../../../api/wp/wpPointList";

// atom
import { loginUser } from "../../../atom/member/loginUser";
import { wpTemporaryDocListAtom } from "../../../atom/wp/wpTemporaryDocListAtom";
import { wpTemporalListRowAtom } from "../../../atom/wp/wpTemporalListRowAtom";
import { wpThisRowNumAtom } from "../../../atom/wp/wpThisRowNumAtom";
import { wpRowCountAtom } from "../../../atom/wp/wpRowCountAtom";
import { wpSumPointAtom } from "../../../atom/wp/wpSumPointAtom";

// utils
import { AmountFormat } from "utils/AmountFormat";

import WpEditTableRow from "./WpEditTableRow";
import AmountFormatInput from "components/textField/AmountFormatInput";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

import { Icon, Table as MuiTable, TableBody, TableCell, TableContainer, TableRow, Tooltip } from "@mui/material";
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import { approverInsertAtomFamily } from "atom/approval/approver_referrer/approverInsertAtomFamily";
import { toMap } from "utils/ToMap";
import { approverCountAtomFamily } from "atom/approval/approver_referrer/approverCountAtomFamily";
import { getFormattedDateTitle } from "layouts/wp/components/getFormattedDateTitle";



const WpEditTable = ({ isResetting , keyName}) => {
    const user = useRecoilValue(loginUser);
    const navigate = useNavigate();

	const location = useLocation();
    const wp_no = location.state; 
	
	const { light } = colors;
	const { size, fontWeightBold } = typography;
	const { borderWidth } = borders;


	/** 오늘 */
	const currentDate = new Date();
	//const testDay = new Date(2023,0,10);
	const month = currentDate.getMonth() + 1; // 1월은 0으로 시작하므로 +1
	const day = currentDate.getDate();

	/** 이번 연도 - 이번 연도 쓴 복지포인트 계산을 불러오기 위해서 */
	const thisYear = moment(currentDate).format('YYYY');

	/** 작년 */
	const lastYearData = currentDate.getFullYear() - 1;
	const lastYear = lastYearData.toString();
	
	/** 1월1일~10일 경우 true */
	const isJanuary1To10 = (month === 1 && day <= 10);

	//console.log("lastYear : :::::::::::: " , lastYear)
	//console.log("isJanuaryFirstTen:", isJanuaryFirstTen , DateFormat(currentDate));

	/** 제목 */
	const title = getFormattedDateTitle(currentDate, "복지포인트");
	//const day = 10;

		
	/** WpEditTableRow 렌더링 전 데이터 set 완료 했는지 체크 하기 위해서 */
	const [ isTempData , setIsTempData ] = useState(false);

    /** 임시저장 리스트 */
	const [ tempList , setTempList] = useRecoilState(wpTemporaryDocListAtom);
    /** 임시저장 상세내역 - row  */
	const [ tempListRow , setTempListRow] = useRecoilState(wpTemporalListRowAtom);
	/** row 행 갯수 */
    const [ tempRowCount , setTempRowCount] = useRecoilState(wpRowCountAtom);
	/** 삭제버튼 클릭 한 해당 row의 key 값 */
    const tempThisRowNum = useRecoilValue(wpThisRowNumAtom);
	/** 사용한 금액 합계 리코일 - row 삭제 시 실행 되게 하기 위해서 */
	const [ totalpoint , setTotalpoint ] = useRecoilState(wpSumPointAtom);
	/** 예외 처리 */
	const [ wp_exception, setWp_exception ] = useState("EH000");


	/** 결재자 리코일  */
	const [approverInfo, setApproverInfo] = useRecoilState(approverInsertAtomFamily(keyName));
    const setApproverNum = useSetRecoilState(approverCountAtomFamily(keyName));



	/** wpPointList 카드 및 몰아쓰기 버튼 */
	const writeInfoArray = []
	/** 사용 가능한 금액 */
	let usablePoint; 
	/** 이번 연도 금액 */
	let yearTotalWPoint; 
	/** 사용한 금액 */
	let usedYearTotalPoint; 


	/** wpPointList 불러오기 위한 body  */
	const wpPointbody = {
		mem_no : user.id
		, wp_usedate : isJanuary1To10 ? lastYear : thisYear
		, mem_join_date : user.joinDate
		, mem_rank : user.rank
		, pageName : "edit"
	}


	/** 임시저장 상세 내용 불러오기 */
	const { data  } = useQuery(['wpDetail', { wp_no : parseInt(wp_no) }] , () => wpDetail({ wp_no : parseInt(wp_no) }), {
		select : (data) => {
			return data.data;
		},
		onSuccess: (data) => {
			const approverList = data.approvalList;

			for(let i=0; i<approverList.length; i++) {
				// 결재자가 있을때
				if(approverList[i].approver_name && approverList[i].approver_name !== null){
					const newApproverList = approverList.map((approver) => {
					return {
							...approver,
							approver_key: `${keyName}_${approver.approver_level + 1}`
						}
					});
					const approverInsert = toMap({...approverInfo});
					approverInsert.set("approverList", newApproverList);
					//console.log(" approverInsert ::::::::::::::::::::::::::: ", approverInsert)
					setApproverInfo(Object.fromEntries(approverInsert));
					setApproverNum({
							count: newApproverList.length
					});
				}
			}
			//console.log("approverList : " + JSON.stringify(approverList))
			// const newApproverList = approverList.map((approver) => {
			// 	return {
			// 		...approver,
			// 		approver_key: `${keyName}_${approver.approver_level + 1}`
			// 	}
			// });
		}
	})
	

	
	/** 사용 가능 한 금액 및 이번 연도 총 금액 배열에 push */
    const {data:pointData } = useQuery(['wpPointList', wpPointbody] , () => wpPointList(wpPointbody), {
        select : (data) => {
            return data.data;
        }
    });

	if(pointData){
		writeInfoArray.push(
			{ title : "복지포인트", content : title },
			{ title : "사용 가능한 금액", content : AmountFormat(pointData.usablePoint) , init : "원"},
			{ title : "사용한 금액", content : AmountFormat(pointData.usedYearTotalPoint) , init : "원"},
			{ title : "이번 연도 총 금액", content : AmountFormat(pointData.year_point), init : "원"},
		)
		usablePoint = pointData.usablePoint;
		yearTotalWPoint = pointData.year_point;
		usedYearTotalPoint = pointData.usedYearTotalPoint;
	}


    /** 
	 * 수정 페이지 row 
	 * - useEffect 를 안쓰면 계속 데이터를  무한으로 set,
	 *  isResetting 을 안넣어주면 상위 컴포넌트에서 리코일 리셋하고 데이터를 다시 set X  */ 
	useEffect(() => {
		if (data) {
			const array = data;
			const arraydata = array.wpdDto;
			const rowdata = arraydata.map((data, index) => ({
				...data, 
				wp_rowIndex: index + 1,   
			})); 
			//approval_no = data.approval_no;
			setTempRowCount(arraydata.length);  
			setTempListRow(rowdata);
			setTempList(array);
			setTotalpoint(array.wp_totalpoint);
			setIsTempData(true);
		}
	}, [data, isResetting]);


	/** 복지포인트 상세 내역 row 추가 */
	function AddwpTempRow(){
        const array  = [...tempListRow];
        const count = tempRowCount+1;

        array.push({
			wp_rowIndex : count
			, wp_dno : 0
			, wp_no : wp_no
			, wp_fno : 0
			, wp_detail_date : ""
			, wp_detail_update : ""
			, wp_usedate : ""
			, wp_category : ""
			, wp_content : ""
			, wp_filename : ""
			, wp_file : ""
			, wp_file_date : ""
			, wp_usedpoint : 0
			, wp_paytype : ""
		})
		//console.log("작성화면 : " + count);
		setTempRowCount(count)
        setTempListRow(array)
    }

	/** 복지포인트 row 삭제 버튼 클릭 시 wpWriteThisRowNum 변경 되어 실행됨 */
	useEffect (() =>{
        if(tempRowCount > 1) {
            const filteredList = tempListRow.filter(list => {
                return list.wp_rowIndex !== tempThisRowNum;
            });
            setTempListRow(filteredList);
            
			const totalusedpoint = filteredList.reduce((acc, cur) => acc + parseInt(cur.wp_usedpoint || 0), 0);
			setTotalpoint(totalusedpoint)
			//console.log(" 삭제 후 합계 : " + totalusedpoint);
        }
    },[tempThisRowNum]);


    /** 저장 */
	function insert(){
		usablePointCheck();
        isEmptyCheck();
		approverInfoCheck();

		const formdata = insertFormData("AP001");

		if( usablePointCheck() === true && isEmptyCheck() === true && approverInfo.approverList ) {
			wpSave(formdata)
		} 
	}

	/** 복지 포인트 작성 insert -> 전체목록 리스트로 */
	const { mutate:wpSave } = useMutation(wpTemporaryDocInsert, {
		onSuccess: () => {
			Swal.fire({
				title: '정상처리 되었습니다.',
				icon: 'success',
				//showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: '작성 목록으로 이동',
				backdrop: false
			}).then((result) => {
					if (result.isConfirmed) {
						navigate('/welfarePoint/list', {state: "update"})
					}
				}
			)
		},
		onError: () => {
			Swal.fire({
				title: '저장 중 에러가 발생했습니다. 잠시후 다시 실행해주세요',
				icon: 'error',
				backdrop: false
			})
		}
	});

	/** 임시저장 */
	function tempSave() {
		usablePointCheck();
		const formdata = insertFormData();

		if( usablePointCheck() === true) {
			const array = {
				mem_team : user.team
				, mem_rank : user.rank
				, mem_no : user.id
                , wp_no : wp_no
				, wp_totalpoint : totalpoint
				, wp_approved : "AP005"
				//, wp_checker : "관리팀"
				, wp_exception : wp_exception
				, wpdDto : tempListRow
				//, usablePoint : usablePoint
				, year_point : yearTotalWPoint
				, approvalList : approverInfo.approverList
		        , doc_type:"APT003"
				, approval_no : data.approval_no
			}
			//console.log(array)
			wpTemp(formdata)
		}
	}

	/** 복지 포인트 임시저장 -> 임시저장 리스트로  */
	const { mutate:wpTemp } = useMutation(wpTemporaryDocInsert, {
		onSuccess: () => {
			Swal.fire({
				title: '임시 저장 처리 되었습니다.',
				icon: 'success',
				//showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: '임시저장 목록으로 이동',
				backdrop: false
			}).then((result) => {
					if (result.isConfirmed) {
						navigate('/welfarePoint/tempList' , {state: "update"})
					}
				}
			)
		},
		onError: () => {
			Swal.fire({
				title: '업데이트 중 에러가 발생했습니다. 잠시후 다시 실행해주세요',
				icon: 'error',
				backdrop: false,
			})
		}
	});

	/**  */
	function insertFormData(save){
		/**
		 * formData 에 append 되는 key의 종류 
		 * 'dto' , 'wpdDto' , 'approvalList' , 'fileData'
		 */
		const formData = new FormData();

		/** 복지포인트 작성자,합계,결재,몰아쓰기,이번연도포인트,문서타입,결재번호 */
		const dto = {
			wp_no : wp_no
			, mem_no : user.id
			, mem_id : user.email
			, mem_team : user.team
			, mem_rank : user.rank
			, wp_totalpoint : totalpoint
			, wp_approved : save? "AP001" : "AP005" 
			, wp_exception : wp_exception
			, year_point : yearTotalWPoint
			, doc_type:"APT003"
			, approval_no : data.approval_no
		}

		const tempListRowArray = [] 

		// wp_file 영수증 사진이 없을 경우 및 이미지 데이터라서 제외
		for (const [index, temp] of tempListRow.entries()) {
			const filteredTemp = Object.entries(temp)
				.filter(([key, value]) => key !== 'wp_file')
				.reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
			
				tempListRowArray.push(filteredTemp)
		
			// 이미지 데이터 있을 경우만 formData에 append
			if (temp.wp_file && temp.wp_file !== null && temp.wp_file.file) {
				formData.append('fileData', temp.wp_file.file , temp.wp_rowIndex+"_"+temp.wp_file.name);
			}
		}

		formData.append('dto', JSON.stringify(dto));
		/** 결재자  */      
		formData.append('approvalList', JSON.stringify(approverInfo.approverList));
		/** row 데이터 */
		formData.append('wpdDto', JSON.stringify(tempListRowArray));


		return formData
	}

    /** 사용 가능한 금액인지 체크 */
	function usablePointCheck () {
		let check = true;

		// 예외처리 아닐때
		if (wp_exception === "EH000"){
			if(usablePoint < totalpoint) {
				Swal.fire({
					icon: 'error',
					title: '사용 가능 금액이 초과 하였습니다.',                
                    backdrop: false,
				}); 
				check = false;
				return check
			} else{
				return check
			}
   		// 예외 일때
		} else {
			if(parseInt(yearTotalWPoint) < parseInt(usedYearTotalPoint)+parseInt(totalpoint)) {
				Swal.fire({
					icon: 'error',
					text: '이번 연도 총 금액을 초과 하였습니다.',
                    backdrop: false,            
				}); 
				check = false;
				return check
			} else{
				return check
			}
		}
	}
	/** 빈 값 찾기 */
    function isEmptyCheck() {
		let hasEmptyValue = false;
		const arrayRow = [...tempListRow];
		
		//const array = {...tempList};
		// const isEmptyCheckData = {
		// 	mem_team : user.team
		// 	, mem_rank : user.rank
		// 	, mem_no : user.id
		// 	, wp_no : wp_no
		// 	, wp_totalpoint : totalpoint  
		// 	, wp_approved : "AP005"
		// 	, wp_exception : wp_exception
		// 	, year_point : yearTotalWPoint
		// 	, doc_type:"APT003"
		// }

		// for (const key in array) {
		// 	let obj = arrayRow[key];
		// 	for (const prop in isEmptyCheckData) {
		// 		console.log(" wp 테이블 내용 확인 " , obj[prop])
		// 		if (!obj[prop]) {
		// 			Swal.fire({
		// 				icon: "error",
		// 				title: "저장 중에 문제 발생",
		// 				text: "임시 저장 후 다시 작성해 주십시오.",
		// 				backdrop: false,
		// 			});
		// 			hasEmptyValue = true;
		// 			break;
		// 		}
		// 	}
		// 	if (hasEmptyValue) {
		// 		break;
		// 	}
		// }

		const isEmptyCheckRowData = {
			 wp_usedate : "사용일을 선택해주세요"
			, wp_category : "사용처를 선택해주세요"
			, wp_content : "상세 내역을 입력해주세요"
			, wp_usedpoint : "금액을 입력해주세요"
			, wp_paytype : "결제수단을 선택해주세요"
		}
		for (const key in arrayRow) {
			let obj = arrayRow[key];
			for (const prop in isEmptyCheckRowData) {
				if (!obj[prop]) {
					Swal.fire({
						icon: "error",
						title: isEmptyCheckRowData[prop],
						backdrop: false,
					});
					hasEmptyValue = true;
					break;
				} else if(prop === "wp_usedpoint" && obj[prop] < 1 ){
					Swal.fire({
						icon: "error",
						title: '금액은 0원 이상이어야 합니다.',
						backdrop: false,
					});
					hasEmptyValue = true;
					break;
				} 
			}
			if (hasEmptyValue) {
				break;
			}
		}
		return !hasEmptyValue;
	}
    
	/** 결재자 체크 */
	function approverInfoCheck(){
		if(!approverInfo.approverList){
			Swal.fire({
				icon: "error",
				title: "결재자를 선택해주세요",
				backdrop: false,
			});
		}
	}


    /** 임시저장 , 수정  칼럼 : 수정중 */
    const columns = [
		{ key: "wp_usedate" , name: "사용일", align: "left" , color : "warning" , icon : "event_available" ,width : "7%"},
		{ key: "wp_category" , name: "사용처", align: "center" , color : "success" , icon : "fact_check",width : "7%"},
		{ key: "wp_content" , name: "상세내역", align: "center" , color : "info" , icon : "notes",width : "60%"},
		{ key: "wp_filename" , name: "영수증", align: "left" , color : "secondary" , icon : "insert_photo",width : "7%"},
		{ key: "wp_usedpoint" , name: "금액", align: "center" , color : "primary" , icon : "paid",width : "7%"},
		{ key: "wp_paytype" , name: "결제수단", align: "center" , color : "dark" , icon : "payment",width : "7%"},
		{ key: "btn" , name: "추가", align: "center" , color : "dark" , icon : "payment",width : "7%"},
	]
   	/** 칼럼 map */
    const renderColumns = columns.map(({ name, align, width, color, icon }, key) => {
		
		if( name ==="추가" ) {
			return (
				<SoftBox
					key={name} component="th" width={width || "auto"} pt={1} pb={1.25} 
					textAlign="center" alignItems="center"
					opacity={0.7} borderBottom={`${borderWidth[1]} solid ${light.main}`} 
				>   
					<IconButton aria-label="add" color= "dark" >
						<AddCircleIcon onClick={AddwpTempRow}/>
					</IconButton>
				</SoftBox>
			);
		} else {
			return (
				<SoftBox
					key={name} component="th" width={width || "auto"} pt={1} pb={1.25} 
					textAlign="center" fontSize={size.lg} fontWeight={fontWeightBold} color= "secondary" alignItems="center"
					opacity={0.7} borderBottom={`${borderWidth[1]} solid ${light.main}`} 
				>   
					<SoftBox display="flex" alignItems="center" justifyContent="center">
						<SoftBox
							bgColor={color}
							width="1.5rem"
							height="1.5rem"
							borderRadius="sm"
							color="white"
							fontSize={size.md}
							display="flex"
							justifyContent="center"
							alignItems="center"
							shadow="md"
							mr={1}
							variant="gradient"
							>
							<Icon>{icon}</Icon>
						</SoftBox>
						<SoftTypography
							variant="h6"
							textTransform="capitalize"
							fontWeight="medium"
							color="text"
							sx={{  width: "max-content" ,   }}
						>
							{name.toUpperCase()}
						</SoftTypography>
					</SoftBox>
				</SoftBox>
			);
		}
	});

    return (
        <>
        <Grid container spacing={3}>
            { writeInfoArray.length > 0 && writeInfoArray.map(({ title , content, init},index) => 
                <Grid item xs={12} sm={6} xl={3} key={index}>
                    <SoftBox bgColor="white" shadow="lg" pb={1} borderRadius="lg">
                        <SoftBox>
                            <MiniStatisticsCard
                                title={{ text: title }}	
                                count= {content}
                                min = "min"
                                init={init}
                                />
                        </SoftBox>
                    </SoftBox>
                </Grid>
            )}    
            
        </Grid>

    
        <SoftBox mt={2}>
            <TableContainer>
                <MuiTable>
                <SoftBox component="thead">
                    <TableRow>{renderColumns}</TableRow>
                </SoftBox>
                <TableBody>
                    {
                        isTempData && tempListRow.length > 0 && tempListRow.map((list) => (
                            <WpEditTableRow
                            key={`tempListRow${list.wp_rowIndex}`}
                            rowindex={list.wp_rowIndex}
                            list={list}
                            />
                        ))
                    }
                    <TableRow key={uuidv4()} bgColor="#b2dfdb" >
                        <TableCell colSpan={7} key={uuidv4()} style={{ borderTop: `${borderWidth[1]} solid ${light.main}` }}>
                            <Grid container justifyContent="flex-end" alignItems="center">
                                <SoftBox pr={1}  >
                                    <SoftTypography
                                        variant="h4"
                                        color="white"
                                        fontWeight="bold"  
                                        sx={{ display: "inline-block", width: "max-content" ,   }}>
                                        합계 
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox>
                                    <AmountFormatInput
                                        label ="합계"
                                        theme = "sum"
                                        size="Normal"
                                        value = {totalpoint} 
                                        unit = "원"
                                    />  
                                </SoftBox>
                            </Grid>
                        </TableCell>
                    </TableRow>    
                </TableBody>
                </MuiTable>
            </TableContainer>
        </SoftBox>
        <SoftBox mb={3}>
			<Grid container spacing={3} mt={0.5} mb={0.5}>
				<Grid item xs={12} md={12} lg={12} display="flex" justifyContent="center" alignItems="center">
					<SoftBox>
						<SoftButton variant="gradient" color="info" onClick={tempSave}>
							<Icon sx={{fontWeight: "bold"}}>person_add</Icon>
							&nbsp;임시저장
						</SoftButton>
					</SoftBox>
					&nbsp;&nbsp;&nbsp;
					<SoftBox>
						<SoftButton variant="gradient" color="success" onClick={insert}>
							<Icon sx={{fontWeight: "bold"}}>person_add</Icon>
							&nbsp;문서상신
						</SoftButton>
					</SoftBox>
				</Grid>
			</Grid>
        </SoftBox>
        </>
    )
}

	// Setting default values for the props of Table
	WpEditTable.defaultProps = {
    columns: [],
    rows: [{}],
    };

    // Typechecking props for the Table
    WpEditTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    rows: PropTypes.arrayOf(PropTypes.object),
    };


export default WpEditTable
