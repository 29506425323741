import { atom } from 'recoil'


export const myReservationAtom = atom({
    key:'myReservation',
    default:{}
})

export const SelectReservationAtom = atom({
    key:"selectReservation",
    default:undefined
})