import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {useEffect, useMemo, useState} from "react";
import {nameCompleteInputValueAtomFamily} from "../../../../../atom/approval/approver_referrer/nameCompleteInputValue";

import {approverInsertAtomFamily} from "../../../../../atom/approval/approver_referrer/approverInsertAtomFamily";
import cloneDeep from "lodash.clonedeep";
import {toMap} from "../../../../../utils/ToMap";
import {useQueries} from "react-query";
import userWorkInfo from "../../../../../api/user/userWorkInfo";
import {loginUser} from "../../../../../atom/member/loginUser";
import {
    nameCompleteInputStatusAtomFamily
} from "../../../../../atom/approval/approver_referrer/nameCompleteInputStatusAtomFamily";
import {Autocomplete, TextField} from "@mui/material";
import allCommonCodeMap from "../../../../../api/commonCode/allCommonCodeMap";

/**
 * 결재라인 순서와 팀코드, 키이름을 받아서 해당하는 결재라인에 해당하는 결재자 정보 전달 받음
 * @param num : int 결재라인 순서
 * @param keyName : String 컴포넌트 식별을 위한 키 이름
 * @param defaultValue : String 기본값
 * @param type : String 결재자 타입
 * @param defaultTeamCode : String 기본 팀코드
 * @returns {JSX.Element}
 * @constructor
 */
function Approver({num, keyName, defaultValue, type, defaultTeamCode}) {
    // 로그인 리코일
    const user = useRecoilValue(loginUser);
    // 팀코드
    const [parentsTeamCode, setParentsTeamCode] = useState();
    // 리코일 식별 키값
    const key = keyName + "_" + num
    // userInfo
    const [userInfo, setUserInfo] = useState();
    // common code map
    const [codeMap, setCodeMap] = useState({});
    // query 실행여부
    const [queryStart, setQueryStart] = useState(false);
    // option list
    const options = [];
    // 팀장급이상만 호출 여부
    const rankCutBool = true;
    // 옵션과 키값 매칭 map
    const inputMap = new Map();
    // 이름과 유저번호 map
    const nameUserMap = new Map();
    // 저장되는 입력된 input값
    const [inputValue, setInputValue] = useState('');

    // recoil insert값 set
    const [approverInfo, setApproverInfo] = useRecoilState(approverInsertAtomFamily(keyName));

    // recoil family - approver 정보 호출
    const [approverValue, setApproverValue] = useRecoilState(nameCompleteInputValueAtomFamily(key));

    // approver 상태
    const [status, setStatus] = useRecoilState(nameCompleteInputStatusAtomFamily(key));

    // 추가된 결재자 저장하는 리코일 리셋
    const approverReset = useResetRecoilState(nameCompleteInputValueAtomFamily(key));

    // 카테고리
    const category = approverValue.category;

    // 기본 값 세팅
    useEffect(() => {
        if (defaultValue !== undefined) {
            setInputValue(defaultValue);
            setStatus(false);
        }
        if (type === "wp") {
            setParentsTeamCode(defaultTeamCode);
        } else {
            setParentsTeamCode(user.team);
        }
    }, []);

    // 쿼리 실행 후
    useEffect(() => {
        if (queryStart) {
            if (defaultValue !== undefined) {
                setInputValue(defaultValue);
                setStatus(false);
            } else {
                if (type === "wp") {
                    setParentsTeamCode(defaultTeamCode);
                } else {
                    setParentsTeamCode(user.team);
                }
                setInputValue("[" + codeMap[parentsTeamCode]?.info + "]");
            }
        }
    }, [queryStart]);

    // 결재자 set
    useEffect(() => {
        // 임시저장 리스트
        let saveList = [];
        // 합산 리스트
        let concat;
        // 결재자 번호
        if (approverValue?.NO !== '' && defaultValue === undefined) {
            // 저장된 결재자 리스트
            const approverList = cloneDeep(approverInfo)?.approverList;
            // 변경 가능한 결재자 리스트
            const insert = toMap({...approverInfo});
            // 저장용 맵
            const approverInfoMap = new Map();
            for (let i = 0; i < userInfo?.length; i++) {
                // 결재자 번호와 유저 번호와 맞는 정보 가져오기
                if (userInfo[i].memNo === approverValue.NO) {
                    approverInfoMap.set("approver_key", key);
                    approverInfoMap.set("approver_mem_no", userInfo[i]?.memNo);
                    approverInfoMap.set("approver_name", userInfo[i]?.memName);
                    approverInfoMap.set("approver_rank", userInfo[i]?.memRank);
                    approverInfoMap.set("approver_level", num - 1);
                    if (num - 1 === 0) {
                        approverInfoMap.set("approver_order", 1);
                    }
                    saveList.push(Object.fromEntries(approverInfoMap));
                    break;
                }
            }
            // 1번 이상 실행시 이전데이터와 합산
            if (approverList !== null) {
                // 중복된 인덱스 리스트
                let duplicateIndexList = [];
                // 중복된 인덱스 push
                for (let i = 0; i < approverList.length; i++) {
                    if (approverList[i].approver_key === key) {
                        duplicateIndexList.push(i);
                    }
                }
                // 중복된 인덱스 자르기
                for (let i in duplicateIndexList) {
                    approverList.splice(i, 1);
                }
                // 합산
                concat = approverList.concat(saveList);
                insert.set("approverList", concat);
            }
            // 1번째 실행시
            if (approverList === null) {
                insert.set("approverList", saveList);
            }
            // 저장
            setApproverInfo(Object.fromEntries(insert));
            approverReset();
        } else if (approverValue?.NO !== '') {
            // 저장된 결재자 리스트
            const approverList = cloneDeep(approverInfo)?.approverList;
            // 현재 선택된 결재자와 이전 결재자와 다를때
            if (String(approverList[num - 1].approver_mem_no) !== approverValue.NO) {
                // 변경 가능한 결재자 리스트
                const insert = toMap({...approverInfo});
                // 저장용 맵
                const approverInfoMap = new Map();
                for (let i = 0; i < userInfo?.length; i++) {
                    // 결재자 번호와 유저 번호와 맞는 정보 가져오기
                    if (userInfo[i].memNo === approverValue.NO) {
                        approverInfoMap.set("approver_key", key);
                        approverInfoMap.set("approver_mem_no", userInfo[i]?.memNo);
                        approverInfoMap.set("approver_name", userInfo[i]?.memName);
                        approverInfoMap.set("approver_rank", userInfo[i]?.memRank);
                        approverInfoMap.set("approver_level", num - 1);
                        if (num - 1 === 0) {
                            approverInfoMap.set("approver_order", 1);
                        }
                        saveList.push(Object.fromEntries(approverInfoMap));
                        break;
                    }
                }
                approverList[num - 1] = saveList[0];
                insert.set("approverList", approverList);
                setApproverInfo(Object.fromEntries(insert));
                approverReset();
            }
        }
    }, [approverValue])

    // react-query
    const queries = useQueries([
        // queries[0] : 유저 리스트
        {
            queryKey: ["userWorkInfo"],
            queryFn: () => userWorkInfo(),
            onSuccess: (data) => {
                setUserInfo(data.data);
            }
        },
        // queries[1] : 공용코드 리스트
        {
            queryKey: ["allCommonCodeMap"],
            queryFn: () => allCommonCodeMap(),
            onSuccess: (data) => {
                setCodeMap(data.data);
                setQueryStart(true);
            }
        }

    ]);

    // option 값 세팅
    useMemo(() => {
        for (let i = 0; i < userInfo?.length; i++) {
            const thisUserName = userInfo[i]?.memName; /* 이름 */
            const thisUserRank = userInfo[i]?.memRank; /* 직급 */
            const thisUserTeam = userInfo[i]?.memTeam; /* 부서 */

            // // 팀장 이상급만 리턴하는 정규식
            // const rankCut = /^R00[4-9]/;
            // if (rankCut.test(thisUserRank) && rankCutBool || thisUserTeam === "T002") {
            //     try {
            //         const optionVal = "[" + (codeMap[thisUserTeam]?.info) + "]"
            //             + thisUserName + " " + (codeMap[thisUserRank]?.info)
            //         // optionList set
            //         options.push(optionVal);
            //
            //         // 추후 옵션값 선택시 가져올 이름 값 및 번호
            //         inputMap.set(optionVal, true);
            //         nameUserMap.set(optionVal, userInfo[i]?.memNo);
            //     } catch (err) {
            //         console.log(err);
            //     }
            // }

            // 모든 사원 호출
            // if (rankCutBool === false || rankCutBool) {
                try {
                    if (codeMap[thisUserTeam]?.info !== undefined && codeMap[thisUserRank]?.info !== undefined && userInfo[i]?.role !== "GUEST") {
                        const optionVal = "[" + (codeMap[thisUserTeam]?.info) + "]" + thisUserName + " " + (codeMap[thisUserRank]?.info)
                        // optionList set
                        options.push(optionVal);

                        // 추후 옵션값 선택시 가져올 이름 값 및 번호
                        inputMap.set(optionVal, true);
                        nameUserMap.set(optionVal, userInfo[i].memNo);
                    }
                } catch (err) {
                    console.log(err);
                }
            // }
        }
    }, [queries])


    // input값이 변경될때 실행되는 Effect -> 부모로 파라메터 전달
    useEffect(() => {
        // 선택한 인풋값의 공용코드
        if (inputValue !== "" || inputValue !== null) {
            if (inputMap.get(inputValue)) {
                // 입력한 값과 옵션값이 맞는지 체크
                setStatus(false);
                // 공용코드 recoil set
                const saveInputVal = toMap(approverValue);
                saveInputVal.set(category, nameUserMap.get(inputValue));
                setApproverValue(Object.fromEntries(saveInputVal));
            } else {
                setStatus(true);
            }
        }
    }, [inputValue]);


    return (
        <Autocomplete
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={status ? '옳바른값을 선택해주세요' : ''}
                    InputProps={{
                        ...params.InputProps,
                        style: { marginTop: '10px' }
                    }}
                />
            )}
            // query에서 가져온 해당하는 카테고리의 리스트 옵션값으로 넣어주기
            options={options}
            value={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
        >
        </Autocomplete>
    );
}

export default Approver;
