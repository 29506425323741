import {useEffect, useState} from "react";
import SoftBox from "../../../../../../components/SoftBox";
import {useRecoilState, useRecoilValue} from "recoil";
import WpTable from "../../../../../../examples/Tables/WpTable";
import Pagination from "../../../../../../components/pagination/Pagination";
import { loginUser } from "atom/member/loginUser";
import { useQuery } from "react-query";
import allCommonCodeMap from "api/commonCode/allCommonCodeMap";
import moment from "moment";
import { Tooltip } from "chart.js";
import SoftAvatar from "components/SoftAvatar";
import SoftProgress from "components/SoftProgress";
import wpDocList from "../../../../../../api/wp/wpDocList";
import noApprove from "../../../../../../assets/images/-_image.png";
import check from "../../../../../../assets/images/check.png";
import { customAxios } from "../../../../../../axios/CustomAxios";
import { myIpAddress } from "config";
import SoftTypography from "components/SoftTypography";
import { getFormattedDateTitle } from "layouts/wp/components/getFormattedDateTitle";
import NameCard from "../../../../../../components/nameCard/NameCard";
import SoftBadge from "../../../../../../components/SoftBadge";


/**
 * 복지포인트 문서 상신함
 * @returns 
 */
function WpSentDocs () {

    const user = useRecoilValue(loginUser);
    // 타이틀
    let title = "";
    let progress;
    let cancelReason = '';

    // 현재 페이지 
    const [ page , setPage ] = useState(1);
    // 전체 페이지 수
    const [ totalCount , setTotalCount ] = useState(0);
    // 페이지당 아이템 개수
    const [ pageSize , setPageSize] = useState(10); 


    /**  */
    const body = { 
        mem_no : user.id
        , page : page === 1 ? 0 : ((page - 1) * pageSize)
        , pageSize : pageSize
        , stype : "sent"
        , wp_approved : "AP005"  // 임시 보관 문서만 제외 하기때문에
    };

    const { data:list } = useQuery(['wpDocList', body ] , () => wpDocList(body) , {
        select : (data) => {
            return data.data;
        },
    })


    const { data:info} = useQuery('allCommonCodeMap', allCommonCodeMap, {
        select : (data) => {
            return data.data;
        }
    });

    const countBody = {
        mem_no : user.id
        , stype : "sent"
        , wp_approved : "AP005"  // 임시 보관 문서 제외 
    }

    const wpDocTotalCount = (body) => {
        return customAxios.post(myIpAddress+'/wp/wpDocTotalCount', body);
    }
    const { data:count } = useQuery(['wpDocTotalCount', body], () => wpDocTotalCount(countBody) , {
        onSuccess : (count) => {
            setTotalCount(count.data);
        },
    });



    const tableDataList= [];

    if(list && info) {
        const wpList = list;

        for(let i=0; i<wpList.length; i++){

            let cancelBool = false;
             // 결재여부(x차 결재)
            const approvalDo = wpList[i]["approval_do"];
            // 결재자 수
            const approvalApprovers = wpList[i]["approval_approvers"];
            // 문서 번호
            const docNo = wpList[i]["wp_no"];
            // 작성일
            const wp_date = wpList[i]["wp_date"]
            // 복지 포인트 제목
            title = getFormattedDateTitle(wp_date, "복지포인트").slice(9);
            // 작성자 이름
            const member = "["+info[wpList[i]["mem_team"]]?.info+"] "+wpList[i]["mem_name"]+" "+info[wpList[i]["mem_rank"]]?.info+"";
            // 문서 번호
            const viewNo = i+1;
            // 결재자 정보
            const approverDataList = wpList[i]["approvalList"];
            // 결재자 정보 저장용 리스트
            const approverList = [];
            // 문서 결재상태
            const approved = wpList[i]["wp_approved"];

            // 결재 상태에 따라 결재자 마크 변경
            // 무결재
            if(approved === "AP000"){
                const tinyInfoSetList = [];
                // 저장용 리스트에 저장
                tinyInfoSetList.push(check);
                tinyInfoSetList.push("무결재 문서");

                // 저장용 리스트에 저장
                approverList.push(tinyInfoSetList);
                progress = (1/1)*100;
            
            //상신 취소
            } else if (approved === "AP006"){
                const tinyInfoSetList = [];
                // 저장용 리스트에 저장
                tinyInfoSetList.push(noApprove);
                tinyInfoSetList.push("상신취소 문서");

                // 저장용 리스트에 저장
                approverList.push(tinyInfoSetList);
                progress = (0/2)*100;

            } else if (approved === "AP004") {
                for (let j = 0; j < approverDataList.length; j++) {
                    const tinyInfoSetList = [];
                    const approverRank = info[approverDataList[j]["approver_rank"]]?.info; // 결재자 랭크
                    const approverName = approverDataList[j]["approver_name"]; // 결재자 이름
                    const approverLevel = approverDataList[j]["approver_level"];
                    const approverImg = approverDataList[j]["approver_img"]; // 결재자 프로필사진
                    // 저장용 리스트에 저장
                    tinyInfoSetList.push(approverImg);
                    tinyInfoSetList.push("[" + (approverLevel + 1) + "번 결재자] " + approverName + " " + approverRank);

                    // 저장용 리스트에 저장
                    approverList.push(tinyInfoSetList);
                }
                cancelBool = true;
                cancelReason = wpList[i]["approval_cancel_reason"];
                progress = (approvalDo / approvalApprovers) * 100;

            } else{
                for(let j=0; j<approverDataList.length; j++){
                    const tinyInfoSetList = [];
                    const approverRank = info[approverDataList[j]["approver_rank"]]?.info; // 결재자 랭크
                    const approverName = approverDataList[j]["approver_name"]; // 결재자 이름
                    const approverLevel = approverDataList[j]["approver_level"];
                    const approverImg = approverDataList[j]["approver_img"]; // 결재자 프로필사진
    
                    // 저장용 리스트에 저장
                    tinyInfoSetList.push(approverImg);
                    tinyInfoSetList.push("["+(approverLevel+1)+"번 결재자] "+approverName+" "+approverRank);
    
                    // 저장용 리스트에 저장
                    approverList.push(tinyInfoSetList);
                }
                // 결재 진행 상황
                progress = (approvalDo/approvalApprovers)*100;
            }

            const saveMap ={
                viewNo: viewNo,
                docNo:docNo,
                title: (
                    <SoftBadge
                        variant="contained"
                        badgeContent={title}
                        color="secondary" size="md" container
                        sx={{display: "inline-block", width: "max-content"}}
                    />
                ),
                wpAmount: (
                    <SoftBadge
                        variant="contained"
                        badgeContent={wpList[i]?.wp_totalpoint+'원'}
                        color="secondary" size="md" container
                        sx={{display: "inline-block", width: "max-content"}}
                    />
                ),
                member:NameCard(wpList[i]["mem_name"], info[wpList[i]["mem_rank"]]?.info, info[wpList[i]["mem_team"]]?.info, wpList[i]?.imageUrl),
                approvers: approverList,
                progress: progress,
                approvedInfo: info[approved]?.info,
                cancelBool: cancelBool,
                cancelReason: cancelReason
            }

            // 전체 리스트에 저장
            tableDataList.push(saveMap);            
        }
    }
  
    const columns = [
        { title: "no", name : "번호" , align: "center" , width : "7%"},
        { title: "member", name: "작성자",  align: "center"  , width : "23%"},
        { title: "wpinfo", name: "문서정보", align: "center" , width : "20%" },
        { title: "wpAmount", name: "금액", align: "center" , width : "15%" },
        { title: "approver", name: "결재자", align: "center" , width : "13%" },
        { title: "approved", name : "결재상태", align: "center" ,  width: "12%"},
        { title: "approvalprogress", name: "결재완료율", align: "center" , width : "20%"},
    ]

    return (
        tableDataList && 
        tableDataList.length > 0 ? (
            <>
                <WpTable 
                    columns={columns} 
                    tableDataList={tableDataList} 
                    totalCount={totalCount} 
                    pageSize={pageSize}
                    page={page}
                    />
                <SoftBox pt={2}
                    display="flex"
                    justifyContent="center"
                    >
                    <Pagination 
                        totalCount={totalCount} 
                        pageSize={pageSize}
                        page={page}
                        setPage={setPage}
                        size="small"
                    />
                </SoftBox>
            </>
         ) : (
            <>
                <SoftBox>
                    <SoftTypography
                        variant="button"
                        fontWeight="lg"
                        color= "secondary"
                        >
                        상신 문서 내역이 없습니다.
                    </SoftTypography>
                </SoftBox>
            </>
        )
        
    );
}

export default WpSentDocs;