import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 쿠키 삭제
 * @returns {Promise<axios.AxiosResponse<any>>}
 * @constructor
 */
const DeleteCookie =()=>{
    return customAxios.post(myIpAddress+'/auth/deleteCookie');
}
export default DeleteCookie;