import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 *
 * @param months:List<String>-달 리스트
 * @param page:int-현재 페이지
 * @param size:int-페이지당 갯수
 * @param year:int-선택연도
 * @param approvedCodeList:List<String>-문서 결재상태 리스트
 * @param memNo:int-멤버번호
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const costAdminLists =(months,page,size,year,approvedCodeList,memNo)=>{
    return customAxios.post(myIpAddress+'/cost/adminList',
        {
            months:months,
            page:page,
            size:size,
            year:year,
            approvedCodeList:approvedCodeList,
            memNo:memNo
        }
    );
}
export default costAdminLists;