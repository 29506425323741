const allMemberTableData = {
    columns: [
      { name: "사원", align: "left" },
      { name: "소속", align: "center" },      
      { name: "입사일", align: "center"},
      { name: "수정", align: "center"},
      { name: "비고", align: "center"},
      { name: "", align: "center"},
    ],
  };
  
  export default allMemberTableData;
  