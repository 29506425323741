import { forwardRef } from "react"
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Grid } from "@mui/material";

function menuImage(theme, ownerState) {
    const { functions, palette, borders } = theme;
    const { color } = ownerState;

    return {
      display: "grid",
      placeItems: "center",
      background: palette[color].main
    };
  }


const getImageText = (value) => {
  switch (value) {
    case "1":
      return "뚝섬";
    case "2":
      return "과천";
    default:
      return "X";
  }
}


const getColor = (value) => {
  switch (value) {
    case "1":
      return "success";
    case "2":
      return "warning";
    default:
      return "dark";
  }
};

const MyWorkPlace = forwardRef(({ color, image, title, date, ...rest }, ref) => (
  <Grid>
    <Grid sx={{mb:1}}>
      <SoftTypography fontWeight="bold" variant="h4">
        {title[0]} {title[1]}
      </SoftTypography>
    </Grid>
    <Grid container alignItems="center">
        <SoftBox
            width="3rem"
            height="2.55rem"
            mt={0.25}
            mr={1}
            mb={0.25}
            borderRadius="lg"
            sx={(theme) => menuImage(theme, { color: getColor(color) })}
        >
            <Icon sx={{width:"2.5rem",height:"1.5rem"}} fontSize="small" fontWeight="550" className="myWorkPlace-text">{getImageText(image)}</Icon>
        </SoftBox>
        <SoftBox>
        <SoftTypography
            variant="body2"
            color="secondary"
            sx={{
            display: "flex",
            alignItems: "center",
            mt: 0.5,
            }}
        >
            <SoftTypography variant="subtitle1" color="secondary">
                <Icon
                    sx={{
                    lineHeight: 1.2,
                    mr: 0.5,
                    }}
                >
                    watch_later
                </Icon>
            </SoftTypography>
            <SoftTypography variant="subtitle1"> 
                {date}
            </SoftTypography>
        </SoftTypography>
        </SoftBox>
    </Grid>
  </Grid>
));

// Setting default values for the props of NotificationItem
MyWorkPlace.defaultProps = {
  color: "dark",
};

// Typechecking props for the NotificationItem
MyWorkPlace.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  image: PropTypes.node.isRequired,
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
  date: PropTypes.string.isRequired,
};

export default MyWorkPlace;
