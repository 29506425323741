/**
 * 문자열과 절삭문자열수를 받아 ... 으로 생략해주는 함수
 * @param str:String-문자열
 * @param maxLength:Number-최대절삭문자열 수
 * @returns {*|string}
 */
export function TruncateString(str, maxLength) {
    if (str.length > maxLength) {
        return str.slice(0, maxLength) + '...';
    } else {
        return str;
    }
}