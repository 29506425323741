import Tooltip from "@mui/material/Tooltip";
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftProgress from "components/SoftProgress";
import {QueryClient, useQueries} from "react-query";
import {isRecoilValue, useRecoilState, useRecoilValue} from "recoil";
import {loginUser} from "../../../../../../../atom/member/loginUser";
import {useEffect} from "react";
import allCommonCodeMap from "../../../../../../../api/commonCode/allCommonCodeMap";
import vacationDocList from "../../../../../../../api/vacation/vacationDocList";
import {vacationSentState} from "../../../../../../../atom/approval/sent/vacationSentState";
import {sentDocTotalCount} from "../../../../../../../atom/approval/sent/sentDocTotalCount";
import approveCancel from "../../../../../../../assets/images/x_image.png";
import noApprove from "../../../../../../../assets/images/-_image.png";
import check from "../../../../../../../assets/images/check.png";
import SoftTypography from "../../../../../../../components/SoftTypography";
import NameCard from "../../../../../../../components/nameCard/NameCard";
import SoftBadge from "../../../../../../../components/SoftBadge";
import {FaBan} from "react-icons/fa";

/**
 * 휴가 상신 테이블 데이터
 * @returns {{columns: [{name: string, align: string},{name: string, align: string},{name: string, align: string}], rows: *[]}}
 */
export default function data() {

    // 타이틀
    let progress;
    let cancelReason = '';

    // react query
    const queryClient = new QueryClient();

    // login 정보
    const recoilState = isRecoilValue(loginUser);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const user = useRecoilValue(loginUser);

    // recoil 정보가 가져와 지면 refetch
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (recoilState) {
            // Only execute the query if myState is not null
            queryClient.refetchQueries('vacationDocList');
        }
    }, [recoilState]);

    const tableDataList = [];

    // 카테고리 state
    // eslint-disable-next-line react-hooks/rules-of-hooks,no-use-before-define
    const vacationDocState = useRecoilValue(vacationSentState);
    
    // 전체 페이지 수
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [totalCount, setTotalCount] = useRecoilState(sentDocTotalCount);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const queries = useQueries([
        // queries[0] : 휴가 정보
        {
            queryKey: ["vacationDocList", {
                no: parseInt(user?.id)
                , approvedList: vacationDocState.approvedList
                , overuse: vacationDocState.searchOveruse
                , category: vacationDocState.searchCategory
                , team: vacationDocState.searchTeam
                , name: vacationDocState.searchName
                , startStart: vacationDocState.searchStartStart
                , startEnd: vacationDocState.searchStartEnd
                , endStart: vacationDocState.searchEndStart
                , endEnd: vacationDocState.searchEndEnd
                , ascending: vacationDocState.ascending
                , nowPage: vacationDocState.nowPage
                , docsNum: vacationDocState.docsNum
            }],
            queryFn: () => vacationDocList({
                no: parseInt(user?.id)
                , approvedList: vacationDocState.approvedList
                , overuse: vacationDocState.searchOveruse
                , category: vacationDocState.searchCategory
                , team: vacationDocState.searchTeam
                , name: vacationDocState.searchName
                , startStart: vacationDocState.searchStartStart
                , startEnd: vacationDocState.searchStartEnd
                , endStart: vacationDocState.searchEndStart
                , endEnd: vacationDocState.searchEndEnd
                , ascending: vacationDocState.ascending
                , nowPage: vacationDocState.nowPage
                , docsNum: vacationDocState.docsNum
            }),
            onSuccess: (data) => {
                setTotalCount(data.data[0].total_count);
            },
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            }
        },
        // queries[1] : 공용코드 리스트
        {
            queryKey: ["allCommonCodeMap"],
            queryFn: () => allCommonCodeMap(),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
                initialData: [],
            }
        },
    ]);
    
    if (queries[0].isSuccess && queries[1].isSuccess) {
        // 휴가 정보
        const vacationList = queries[0].data.data;
        // 공용코드 리스트
        const codeMap = queries[1].data.data;

        for (let i = 0; i < vacationList.length; i++) {

            let cancelBool = false;
            // 결재여부(x차 결재)
            const approvalDo = vacationList[i]["approval_do"];
            // 결재자 수
            const approvalApprovers = vacationList[i]["approval_approvers"];
            // 휴가타입
            const category = codeMap[vacationList[i]["vac_category"]].info;
            // 휴가 시작일
            const start = vacationList[i]["vac_start"];
            // 휴가 종료일
            const end = vacationList[i]["vac_end"];
            // 총 휴가 일 수
            const days = vacationList[i]["vac_days"];
            // 문서 번호
            const docNo = vacationList[i]["vac_no"];
            // 문서 번호
            const viewNo = vacationList[i]["view_no"];
            // 문서 결재상태
            const approved = vacationList[i]?.vac_approved;
            // 결재자 정보
            const approverDataList = vacationList[i]["approverList"];
            // 결재자 정보 저장용 리스트
            const approverList = [];

            // 결재 상태에 따라 결재자 마크 변경
            // 무결재
            if (approved === "AP000") {
                const tinyInfoSetList = [];
                // 저장용 리스트에 저장
                tinyInfoSetList.push(check);
                tinyInfoSetList.push("무결재 문서");

                // 저장용 리스트에 저장
                approverList.push(tinyInfoSetList);
                progress = (1 / 1) * 100;
                // 상신취소
            } else if (approved === "AP006") {
                const tinyInfoSetList = [];
                // 저장용 리스트에 저장
                tinyInfoSetList.push(noApprove);
                tinyInfoSetList.push("상신취소 문서");

                // 저장용 리스트에 저장
                approverList.push(tinyInfoSetList);
                progress = (0 / 2) * 100;
            } else if (approved === "AP004") {
                for (let j = 0; j < approverDataList.length; j++) {
                    const tinyInfoSetList = [];
                    const approverRank = codeMap[approverDataList[j]["approver_rank"]]?.info; // 결재자 랭크
                    const approverName = approverDataList[j]["approver_name"]; // 결재자 이름
                    const approverLevel = approverDataList[j]["approver_level"];
                    const approverImg = approverDataList[j]["approver_img"]; // 결재자 프로필사진
                    // 저장용 리스트에 저장
                    tinyInfoSetList.push(approverImg);
                    tinyInfoSetList.push("[" + (approverLevel + 1) + "번 결재자] " + approverName + " " + approverRank);

                    // 저장용 리스트에 저장
                    approverList.push(tinyInfoSetList);
                }
                cancelBool = true;
                cancelReason = truncateString(`사유: ${vacationList[i]["approval_cancel_reason"]}`,10);
                progress = (approvalDo / approvalApprovers) * 100;
            } else {
                for (let j = 0; j < approverDataList.length; j++) {
                    const tinyInfoSetList = [];
                    const approverRank = codeMap[approverDataList[j]["approver_rank"]]?.info; // 결재자 랭크
                    const approverName = approverDataList[j]["approver_name"]; // 결재자 이름
                    const approverLevel = approverDataList[j]["approver_level"];
                    const approverImg = approverDataList[j]["approver_img"]; // 결재자 프로필사진
                    // 저장용 리스트에 저장
                    tinyInfoSetList.push(approverImg);
                    tinyInfoSetList.push("[" + (approverLevel + 1) + "번 결재자] " + approverName + " " + approverRank);

                    // 저장용 리스트에 저장
                    approverList.push(tinyInfoSetList);
                }
                progress = (approvalDo / approvalApprovers) * 100;
            }

            const saveMap = {
                viewNo: viewNo,
                docNo: docNo,
                category: category,
                start: start,
                end: end,
                day: days,
                team: codeMap[vacationList[i]["vac_team"]]?.info,
                name: vacationList[i]["mem_name"],
                rank: codeMap[vacationList[i]["vac_rank"]]?.info,
                img: vacationList[i]?.imageUrl,
                approvers: approverList,
                progress: progress,
                approvedInfo: codeMap[approved]?.info,
                cancelBool: cancelBool,
                cancelReason: cancelReason
            }

            // 전체 리스트에 저장
            tableDataList.push(saveMap);
        }
    }

    /**
     * 이름과 이미지로 호버시 팀,이름,직책 나옴
     * @param members
     * @returns {*}
     */
    const avatars = (members) =>
        members.map(([image, name], index) => (
            <Tooltip key={name + index} title={name} placeholder="bottom">
                <SoftAvatar
                    src={image}
                    alt="name"
                    size="xs"
                    sx={{
                        border: ({borders: {borderWidth}, palette: {white}}) =>
                            `${borderWidth[2]} solid ${white.main}`,
                        cursor: "pointer",
                        position: "relative",

                        "&:not(:first-of-type)": {
                            ml: -1.25,
                        },

                        "&:hover, &:focus": {
                            zIndex: "10",
                        },
                    }}
                />
            </Tooltip>
        ));

    /**
     * 문자열과 절삭문자열수를 받아 ... 으로 생략해주는 함수
     * @param str:String-문자열
     * @param maxLength:Number-최대절삭문자열 수
     * @returns {*|string}
     */
    function truncateString(str, maxLength) {
        if (str.length > maxLength) {
            return str.slice(0, maxLength) + '...';
        } else {
            return str;
        }
    }

    /**
     * row에 데이터 렌더링
     * @returns {*[]}
     */
    const tabelDataRender = () => {
        const dataArr = [];
        for (let i = 0; i < tableDataList.length; i++) {
            dataArr.push(
                {
                    no: tableDataList[i]["viewNo"],
                    docNo: tableDataList[i]["docNo"],
                    member: NameCard(tableDataList[i]?.name, tableDataList[i]?.rank, tableDataList[i]?.team, tableDataList[i]?.img),
                    vacationInfo: (
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                            <SoftBadge
                                variant="contained"
                                badgeContent={tableDataList[i]?.start}
                                color="secondary" size="md" container
                                sx={{display: "inline-block", width: "max-content"}}
                            />
                            <SoftTypography variant="button" fontWeight="bold">
                                &nbsp;-&nbsp;
                            </SoftTypography>
                            <SoftBadge
                                variant="contained"
                                badgeContent={tableDataList[i]?.end}
                                color="secondary" size="md" container
                                sx={{display: "inline-block", width: "max-content"}}
                            />
                        </SoftBox>
                    ),
                    vacationDate: (
                        <>
                            <SoftBadge
                                variant="contained"
                                badgeContent={tableDataList[i]?.category}
                                color="secondary" size="md" container
                                sx={{display: "inline-block", width: "max-content"}}
                            />
                            <SoftTypography variant="button" fontWeight="bold">
                                &nbsp;{tableDataList[i]?.day} 일
                            </SoftTypography>
                        </>
                    ),
                    approver: (
                        <SoftBox display="flex" py={1}>
                            {avatars(
                                tableDataList[i]["approvers"]
                            )
                            }
                        </SoftBox>
                    ),
                    approved: (
                        <SoftBadge
                            variant="contained"
                            badgeContent={tableDataList[i]?.approvedInfo}
                            color={
                                tableDataList[i]?.approvedInfo === "결재완료"
                                    ? "success" : tableDataList[i]?.approvedInfo === "반려"
                                        ? "error" : tableDataList[i]?.approvedInfo === "상신취소"
                                            ? "warning" : "info"}
                            size="lm" container
                            sx={{display: "inline-block", width: "max-content"}}
                        />
                    ),
                    approvalProgress: (
                        <SoftBox width="8rem" textAlign="left">
                            {tableDataList[i]?.cancelBool ?
                                <>
                                    <SoftBox display="flex" justifyContent="flex-start" alignItems="center">
                                        <SoftTypography variant="h6" fontWeight="medium">
                                            <FaBan color="#d10404" size={18} style={{ verticalAlign: "middle", marginTop: -3 }} />
                                            결재 반려
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftTypography variant="button" fontWeight="medium">
                                        {tableDataList[i]?.cancelReason}
                                    </SoftTypography>
                                </>
                                :
                                <SoftProgress value={tableDataList[i]["progress"]} color="info" variant="gradient"
                                              label={true}/>
                            }
                        </SoftBox>
                    ),
                }
            );
        }
        return dataArr;
    };

    return {
        columns: [
            {name: "no", align: "center" , value : "번호" , width : "7%"},
            {name: "member", align: "center" , value : "작성자" , width : "20%"},
            {name: "vacationInfo", align: "center", value : "휴가일정",  width: "25%"},
            {name: "vacationDate", align: "center", value : "휴가정보",  width: "18%"},
            {name: "approver", align: "center" , value : "결재자" , width : "10%"},
            {name: "approved", align: "center" , value : "결재상태",  width: "10%"},
            {name: "approvalProgress", align: "center" , value : "결재완료율" , width : "10%"},

        ],

        rows: tabelDataRender(),
    };
}
