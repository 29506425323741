import {useRecoilState, useRecoilValue} from "recoil";
import {CostMaintitle} from "../../../../atom/cost/CostWriteDetail";
import {useQuery} from "react-query";
import CostMainLists from "../../../../api/cost/CostMainLists";
import CostTable from "../../../../examples/Tables/CostTable";
import {loginUser} from "../../../../atom/member/loginUser";
import {useEffect, useState} from "react";

/**
 * 비용처리 컴포넌트
 * @param storeBool : Boolean-임시저장인지
 * @returns {JSX.Element}
 * @constructor
 */
const CostTableComponent = ({storeBool}) => {
    // 유저
    const user = useRecoilValue(loginUser);

    // 선택 사이즈
    const [size, setSize] = useRecoilState(CostMaintitle);

    //
    const [documentStateSort, setDocumentStateSort] = useState("");

    // 임시저장 일때
    useEffect(()=>{
        if(storeBool){
            setSize(9);
        }
    },[]);

    // 컬럼
    const columns = [
        { key: "cost_no", name: "번호", align: "center", width: "1" },
        { key: "category_info", name: "분류", align: "center", width: "2" },
        { key: "team_info", name: "소속", align: "center", width: "2" },
        { key: "mem_name", key2: "rank_info", name: "이름", align: "center", width: "2" },
        { key: "dateformat", name: "작성일", align: "center", width: "3" },
        { key: "approved_info", name: "결재상태", align: "center", width: "2" },
    ];

    // 문서정보를 가져올 배열
    const rows = [];
    let reExamination = false;

    const { data, isSuccess } = useQuery(['CostMainLists', user.id, size], () => CostMainLists(user.id, size), {
        select: (data) => {
            return data.data;
        }
    });

    if (isSuccess) {
        data.map((list) => {
            let row = {};
            if (documentStateSort === "임시보관" && list.cost_approved === 'AP005') {
                columns.map((col) => {
                    row[col.name] = list[col.key] + (list[col.key2] ? " " + list[col.key2] : "")
                })
                rows.push(row)
            } else if (documentStateSort === "") {
                columns.map((col) => {
                    row[col.name] = list[col.key] + (list[col.key2] ? " " + list[col.key2] : "")
                })
                rows.push(row)
            }
        })
        if(data.length > 0){
            if(data[0].count>0){
                reExamination = true;
            }
        }
    }

    return(
        <>
            <CostTable columns={columns} rows={rows}/>
        </>
    );

}

export default CostTableComponent;