import {atom} from "recoil";

/**
 * 복지포인트 임시 저장 페이지 총 리스트
 * @type {  RecoilState<[{  key : value , wpdDto : [{}]  }] > }
 */

export const wpTemporaryDocListAtom = atom({
    key: "wpTemporaryDocListAtom",
    default: {
        mem_name : ""
        , mem_no : 0
        , mem_rank : ""
        , mem_team : ""
        , memname : ""
        , usablePoint : 0
        , usedTotalPoint : 0
        , wp_date : ""
        , wp_exception : ""
        , wp_no : 0
        , wp_approved : ""
        , wp_usedtotalpoint : 0
        , wp_update : ""
        , wpdDto : [{
            wp_rowIndex : 0
            , wp_no : 0
            , wp_dno : 0
            , wp_fno : 0
            , wp_detail_date : ""
            , wp_detail_update : ""
            , wp_category : ""
            , wp_content : ""
            , wp_filename : ""
            , wp_file : ""
            , wp_file_date : ""
            , wp_usedpoint : 0
            , wp_paytype : ""
            , wp_usedate : ""
        }]
    }
});
