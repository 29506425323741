import React, { useMemo, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import { Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../styles/Cost/CostSt.css"

function CostReceptionTable({ columns, rows , type}) {
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const navigate = useNavigate();
  const location = useLocation();

  const renderColumns = columns.map(({ name, align, width, value }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {value}
      </SoftBox>
    );
  });


  const renderRows = rows.map((row, key) => {
    const rowKey = `row-${key}`;

    // 비용처리 게시물 번호
    // let listNo =  totalCount - (page - 1) * 10 - key;
		
		// if (listNo > totalCount ) {
		// 	listNo = totalCount
		// }

    const detailClick = () => {
      navigate(`/cost/${row?.cost_no || row?.번호}`, {state:location.pathname})
    }
    const tableRow = columns.map(({ name, align }) => {
      let template;

      if (Array.isArray(row[name])) {
        template = (
          <SoftBox
            key={uuidv4()}
            component="td"
            p={1}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
          >
            <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
              <SoftTypography variant="button" fontWeight="medium" sx={{ width: "max-content" }}>
                {row[name][0]}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        );
      } else {
        template = (
          <SoftBox
            key={uuidv4()}
            component="td"
            p={1}
            textAlign={align}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
            onClick={detailClick}
          >
            <SoftTypography
              variant="button"
              fontWeight={"regular"}
              // 2023-06-02 문서정보 일때 색 찐하게
              color={name ==="category_info" || ( type && name==="mem_name") ? "back" : "secondary"}
              sx={{ display: "inline-block", width: "max-content" }}
            >
              {row[name]}
            </SoftTypography>
          </SoftBox>
        );
      }

      return template;
    });

    return <TableRow
      key={rowKey}
      className="costTableRowColor"
      sx={
        { backgroundColor: key % 2 === 0 ? "#f5f5f5" : "inherit",
          "&:hover": { backgroundColor: "#DBE2EF" , cursor: "pointer" , } }
      }

    >
      {tableRow}
    </TableRow>;
  });

  return (
    <TableContainer>
      <MuiTable>
        <SoftBox component="thead">
          <TableRow>{renderColumns}</TableRow>
        </SoftBox>
        <TableBody>{renderRows}</TableBody>
      </MuiTable>
    </TableContainer>
  )
}

// Setting default values for the props of Table
CostReceptionTable.defaultProps = {
  columns: [],
  rows: [{}],
};

// Typechecking props for the Table
CostReceptionTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default CostReceptionTable;
