import { Card, Grid, MenuItem, Select } from "@mui/material";
import SoftBox from "components/SoftBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import SoftTypography from "../../components/SoftTypography";
import CommonCodeInsert from "./components/CommonCodeInsert";
import CommonCodeTable from "./components/CommonCodeTable";
import CommonCodeData from "./data/CommonCodeData";
import { useMutation, useQueryClient } from "react-query";
import { deleteCommonCode } from "api/commonCode/commonCode";
import Swal from "sweetalert2";



const CommonCodeView = () => {

    const [codeMap, setCodeMap] = useState(new Map());
    // const [codeList, setCodeList] = useState([]);


    const [selectValue, setSelectValue] = useState("");
    
    const {rows, columns} = CommonCodeData();

    const [rowDataMap, setRowDataList] = useState(new Map());

    useEffect(() => {
        if (rows) {
            const filterMap = new Map();
            const filterCategoryMap = new Map();
            rows.forEach((item, index )=> {
                if (!filterMap.has(item.cc_category_info)) {
                    filterMap.set(item.cc_category_info, {
                        cc_category: item.cc_category,
                        cc_category_info: item.cc_category_info
                    });
                }
                if(selectValue === "") {
                    filterCategoryMap.set(index,item);
                    setRowDataList(filterCategoryMap)
                } else {
                    if(selectValue === item.cc_category){
                        filterCategoryMap.set(index,item);
                    }
                    setRowDataList(filterCategoryMap)
                }
            });
            setCodeMap(filterMap);
        }
    },[rows, selectValue])

    
    const handleCategoryChange = (e) => {
        setSelectValue(e.target.value)
    }

    const queryClient = useQueryClient();
    
    const { mutate:commonCodeDelete } = useMutation(deleteCommonCode, {
		onSuccess: () => {
            Swal.fire({
                title: '삭제 완료',
                icon: 'success',
                backdrop: false
            })
            queryClient.invalidateQueries('commonCodeAdmin');
		},
        onError: () =>{
            Swal.fire({
				title: '삭제 중 에러가 발생했습니다. 잠시후 다시 실행해주세요',
				icon: 'error',
                backdrop: false,
			})
        }
	});

    const deleteCode = (no,name) => {
        Swal.fire({
            title: '삭제 하시겠습니까?',
            text: "삭제 코드 : " + name,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '삭제',
            cancelButtonText: '취소',
            backdrop: false
        }).then((result) => {
            if (result.isConfirmed) {
                commonCodeDelete({cc_no:no})
                Swal.fire({
                    title: '삭제 성공',
                    icon: 'success',
                    backdrop: false
                })
            }
        })
    }



    return (
        <>
        <DashboardLayout>
            <DashboardNavbar/>
                <SoftBox pt={1} pb={3}>
                    <Grid container md={12} lg={12} sx={{flexDirection: isMobile ? 'column' : 'row'}}>
                        {/* 공용 코드 등록 */}
                        <CommonCodeInsert codeMap={codeMap} rows={rows} />
                        <Grid item md={6} lg={6}>
                            <Card sx={{ mt: 2, mx: 0, py: 2, px: 2, }}>
                                <Grid>
                                    <SoftBox sx={{my: 1}}>
                                        <SoftTypography variant="h5" fontWeight="bold">
                                            공용 코드 목록 
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid>
                                    <Select
                                        className="commonCode"
                                        displayEmpty
                                        size="large"
                                        onChange={handleCategoryChange}
                                        value={selectValue}
                                        sx={{
                                            "& .MuiOutlinedInput-input": {
                                                width: '-webkit-fill-available !important'
                                            }
                                        }}
                                    >
                                        <MenuItem value="" key={"all"}>-선택-</MenuItem>
                                        {   
                                            codeMap.size > 0 && 
                                            Array.from(codeMap).map(([key, value]) => (
                                                <MenuItem value={value.cc_category} key={value.cc_category}>
                                                    {value.cc_category_info}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Grid>
                                <Grid>
                                    {
                                        rowDataMap.size > 0 &&  
                                            <CommonCodeTable rows={rowDataMap} columns={columns} deleteCode={deleteCode} />
                                    }
                                </Grid>
                            </Card>             
                        </Grid>
                    </Grid>
                </SoftBox>
            <Footer/>
        </DashboardLayout>
        </>
    )
}


export default CommonCodeView