/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import { Grid, Icon, Table as MuiTable, TableCell } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import AmountFormatInput from "components/textField/AmountFormatInput";
import SoftButton from "components/SoftButton";

function CostDetailTable({ columns, rows, sum }) {
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const renderColumns = columns.map(({ name, align, icon, color }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width="auto"
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.lg}
        fontWeight={fontWeightBold}
        color="white"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        <SoftBox sx={{ display: "inline-flex", whiteSpace: "nowrap" }}>
          {icon &&
            <SoftBox
              bgColor={color}
              width="1.5rem"
              height="1.5rem"
              borderRadius="sm"
              color="white"
              display="inline-flex"
              justifyContent="center"
              alignItems="center"
              shadow="md"
              mr={1}
              variant="gradient"
            >
              <Icon>{icon}</Icon>
            </SoftBox>
          }

          <SoftTypography
            variant="button"
            fontWeight="regular"
            color={icon ? "black" : "white"}
            sx={{ flex: "1 1 50%", verticalAlign: "middle", mb: 0.75 }}
          >
            {name.toUpperCase()}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    );
  });

  const handleClick = (img) => {
    // setImgSrc(img);
    // setModalOpen(true);
    window.open(window.location.origin + img, '_blank');
  };

  const renderRows = rows.map((row, key) => {
    const rowKey = `row-${key}`;
    const tableRow = columns.map(({ name, align, icon, key }, colIndex) => {
      let template;

      // 영수증 이미지 처리
      if(key === "cost_filename"){

        template = (
            <SoftBox
                key={`${key}_${colIndex}`}
                component="td" pt={1.5} pb={1.6} textAlign={"center"}
                >
                {  row.cost_filename_real ? (
                    <div>
                        <SoftButton color="info" size="small" onClick={() => handleClick(row["cost_filename_real"])} >
                            영수증 보기
                        </SoftButton>
                        {/* {modalOpen && (
                            <WpImgModal
                                open={modalOpen}
                                header="영수증"
                                setModalOpen={setModalOpen}
                                imgSrc={imgSrc}
                                >
                            </WpImgModal>
                        )} */}
                    </div>) 
                    : 
                    <SoftButton key={`${key}_${colIndex}`} variant="outlined" color="info" size="small" disabled>
                        영수증 없음
                    </SoftButton>     
                }
            </SoftBox>
        )
      } else {

        template = (
          <SoftBox
            key={uuidv4()}
            component="td"
            p={1}
            textAlign={align}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
          >
            <SoftTypography
              variant="button"
              fontWeight="regular"
              color="secondary"
              sx={{
                display: "inline-block",
                width: "max-content",
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
                maxWidth: null
              }}
            >
              {key === "other_amount" || key === "traffic_amount" || key === "fuel_amount" ? row[key]?.toLocaleString() + "원" : row[key]}
            </SoftTypography>
          </SoftBox>
        );
      }
      
      return template;
    });

    return <TableRow key={rowKey} className="costTableRowColor" style={{cursor:"default"}}>{tableRow}</TableRow>;
  });

  return useMemo(
    () => (
      <TableContainer>
        <MuiTable>
          <SoftBox component="thead">
            <TableRow>{renderColumns}</TableRow>
          </SoftBox>
          <TableBody>
            {renderRows}
            <TableRow key={uuidv4()} bgColor="#b2dfdb" >
              <TableCell colSpan={columns[0]?.key === "fuel_date" ? 9 : 7} key={uuidv4()} style={{ borderTop: `${borderWidth[1]} solid ${light.main}` }}>
                <Grid container justifyContent="flex-end" alignItems="center">
                  <SoftBox pr={1} mt={-1} mb={-1}>
                    <SoftTypography
                      variant="h5"
                      color="white"
                      fontWeight="bold"
                      sx={{ display: "inline-block", width: "max-content", }}>
                      합계
                    </SoftTypography>
                  </SoftBox>
                  {sum >= 0 &&
                    <SoftBox mt={-1} mb={-1}>
                      <AmountFormatInput
                        label="합계"
                        theme="sum"
                        size="small"
                        value={sum}
                        unit="원"
                      />
                    </SoftBox>
                  }
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </MuiTable>
      </TableContainer>
    ),
    [columns, rows]
  );
}

// Setting default values for the props of Table
CostDetailTable.defaultProps = {
  columns: [],
  rows: [{}],
};

// Typechecking props for the Table
CostDetailTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default CostDetailTable;
