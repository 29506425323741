import React ,  { useEffect, useRef, useState  }from "react";
import { useNavigate } from "react-router-dom";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import { Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import { AmountFormat } from "utils/AmountFormat";
import { useRecoilState, useRecoilValue } from "recoil";
import { loginUser } from "atom/member/loginUser";
import { useQuery } from "react-query";
import { DateFormat } from "../../../../utils/DateFormat";
import AdminWPListTableUser from "./AdminWPListTableUser";
import { wpAdminListSearch } from "../../../../atom/wp/wpAdminListSearch";
import allCommonCodeMap from "api/commonCode/allCommonCodeMap";
import { wpCsvDownloadRow } from "atom/wp/wpCsvDownloadRow";
import SoftBadge from "components/SoftBadge";
import wpAdminAllDocList from "../../../../api/wp/wpAdminAllDocList";
import Pagination from "../../../../components/pagination/Pagination";
import { myIpAddress } from "config";
import { customAxios } from "../../../../axios/CustomAxios";
import { CSVLink } from "react-csv";
import SoftButton from "components/SoftButton";



function AdminWPCompleteList() {
    const user = useRecoilValue(loginUser);

	const { light } = colors;
	const { size } = typography;
	const { borderWidth } = borders;

	const navigate = useNavigate();


	const todayDate = new Date();
	const today = todayDate;
	/** 오늘 일 */
	const day = todayDate.getDate();
	let month = todayDate.getMonth() + 1; 
	let year = todayDate.getFullYear();

	
	const [ body , setBody ] = useRecoilState(wpAdminListSearch);
	const [ start , setStart ] = useState(false);
	
    const [ totalCount , setTotalCount ] = useState(0);
    const [ page , setPage ] = useState(1);
    const [ pageSize , setPageSize] = useState(10); // 페이지당 아이템 개수
	
	/** 사용금액 합산 시 쿼리문에 사용 - 연초 */
	let startDate = new Date(year, 0, 1);
	/** 사용금액 합산 시 쿼리문에 사용 - 현재 날짜 */
	let endDate = todayDate;


	// 오늘이 1월이면서 10일 이내
	if (today.getMonth() === 0 && day <= 10) {
	//if (today.getMonth() === 4 && day > 10) {
		const lastYear = today.getFullYear() - 1;
		startDate = new Date(lastYear, 0, 1);	// lastYear-01-01
		endDate = new Date(year, 0, 0);		// lastYear-12-31
		month = 12;
		year = lastYear;					
	} else if (day <= 10) {
		// 전달 11일 부터 2023-05-10 이라했을때 2023-04-11~2023-05-10
		month = month - 1;
	}
	//console.log( " month :::: ", month , " year :::: " , year , " startDate :::: " , DateFormat(startDate) ," endDate :::: " , DateFormat(endDate))


	useEffect(() =>{
		const bodyData = {
			...body,
			mem_no : user.id
			, page : page === 1 ? 0 : ((page - 1) * pageSize)
			, pageSize : pageSize
			, stype :'complete'
			, monthList : [month]		
			, year : parseInt(year)
			, startDate : DateFormat(startDate)
			, endDate : DateFormat(endDate)
		};
		setBody(bodyData)
		setStart(true)
		//console.log(" body   ::::: " , body)
	}, [page])


	const columns = [
		{ key: "wp_no" , name: "번호", align: "center" },
		{ key: "wp_date" , name: "작성일", align: "center" },
		{ key: "mem_name" , name: "이름", align: "left" }, 
		{ key: "wp_totalpoint" , name: "결재금액", align: "center" },
		{ key: "usedTotalPoint" , name: "사용한 금액 합계", align: "center" },
		{ key: "remaining_point" , name: "남은 금액", align: "center" },
		{ key: "year_point" , name: "이번 연도 금액", align: "center" },
		{ key: "wp_approved" , name: "결재상태", align: "center" },
	]

	const excelColumns = [
		{ key: "wp_date" , name: "작성일", align: "center" },
		{ key: "mem_name" , name: "이름", align: "left" }, 
		{ key: "mem_rank" , name: "직급", align: "left" }, 
		{ key: "mem_team" , name: "소속", align: "left" }, 
		{ key: "wp_totalpoint" , name: "결재금액", align: "center" },
		{ key: "usedTotalPoint" , name: "사용한 금액 합계", align: "center" },
		{ key: "remaining_point" , name: "남은 복지포인트", align: "center" },
		{ key: "year_point" , name: "이번 연도 복지포인트", align: "center" },
	]


	const { data } = useQuery(['wpAdminAllDocList', body], () => wpAdminAllDocList(body) , {
		enabled : start,
		select : (data) => {
			return data.data;
		}, 
	});

	const { data:info } = useQuery('allCommonCodeMap', allCommonCodeMap, {
		select : (data) => {
			return data.data;
		}
	});


	const wpAdminDocTotalCount = (body) => {
		return customAxios.post(myIpAddress+'/wp/wpAdminDocTotalCount', body);
	}
	const { data:count } = useQuery(['wpAdminDocTotalCount', body], () => wpAdminDocTotalCount(body) , {
		onSuccess : (count) => {
			setTotalCount(count.data);
		},
	});
	
	// CSV 파일로 저장할 데이터 생성하기
	const [csvData, setCsvData ] = useState([]);
	const [headers, setHeaders ] = useState([]);
	const [filename , setFilename] = useState(`${month}월_복지포인트.csv`);


	useEffect(()=>{
		const updatedFilename  = `${month}월_복지포인트.csv`;
		
		const header = excelColumns.map((column) => ({
			label: column.name.toUpperCase(),
			key: column.name,
		}));

		const excelBody = {
			mem_no : user.id
			, page : 0
			, pageSize : 1000
			, stype :'complete'
			, monthList : [month]		
			, year : parseInt(year)
			, startDate : DateFormat(startDate)
			, endDate : DateFormat(endDate)
		}

		customAxios.post(myIpAddress+'/wp/wpAdminAllDocList', excelBody)
		.then((response)=>{
			let data = response.data;
			const csvRow  = []
				data.map((list ,index) =>{
					let row ={}
						row["wp_no"] = list["wp_no"]
						row["wp_date"] = DateFormat(list["wp_date"])
						row["mem_no"] = list["mem_no"]
						row["imageUrl"]	= list["imageUrl"]
						row["mem_name"] = list["mem_name"]
						row["mem_team"]	= info[list["mem_team"]].info 
						row["mem_rank"]	= info[list["mem_rank"]].info 
						row["wp_totalpoint"] = AmountFormat(list["wp_totalpoint"])
						row["remaining_point"] = AmountFormat(parseInt(list.year_point) - parseInt(list.usedTotalPoint))
						row["usedTotalPoint"] = AmountFormat(parseInt(list.usedTotalPoint)) 
						row["year_point"] = AmountFormat(parseInt(list["year_point"]))
						row["wp_approved"]	= info[list["wp_approved"]].info 
					csvRow.push(row)
				})
				
			// 데이터(excelRows) 작성하기
			const excelRows = csvRow.map((row) => {
				const rowData = {};
				excelColumns.forEach((column) => {
					const value = row[column.key] || '';
						rowData[column.name] = value;
				});
				return rowData;
			});
			//console.log(excelRows)
			setFilename(updatedFilename)
			setCsvData(excelRows)
			setHeaders(header)
		})
		.catch((error) => {
			// 오류 처리
			console.error(error);
		});
	}, [])
	
	const handleDownloadCsv = () => {

	};


	/** */
	const rows = []

    if( data && info && data.length > 0 ) {
        data.map((list ,index) =>{
            let row ={}
				row["wp_no"] = list["wp_no"]
				row["wp_date"] = DateFormat(list["wp_date"])
				row["mem_no"] = list["mem_no"]
				row["imageUrl"]	= list["imageUrl"]
				row["mem_name"] = list["mem_name"]
				row["mem_team"]	= info[list["mem_team"]].info 
				row["mem_rank"]	= info[list["mem_rank"]].info 
                row["wp_totalpoint"] = AmountFormat(list["wp_totalpoint"]) + " 원"
				row["remaining_point"] = AmountFormat(parseInt(list.year_point) - parseInt(list.usedTotalPoint)) + " 원"
				row["usedTotalPoint"] = AmountFormat(parseInt(list.usedTotalPoint)) + " 원"
				row["year_point"] = AmountFormat(parseInt(list["year_point"])) + " 원"
				row["wp_approved"]	= info[list["wp_approved"]].info 
            rows.push(row)
        })
    }


	const renderColumns = columns.map(({ name, align, width }, key) => {
		let pl;
		let pr;
		if (key === 0) {pl = 3;pr = 3;
		} else if (key === columns.length - 1) {pl = 3;pr = 3;
		} else {pl = 1;pr = 1;
		}

		return (
			<SoftBox
				key={name} component="th" width={width || "auto"} pt={1.5} pb={1.25} pl={align === "left" ? pl : 3} pr={align === "right" ? pr : 3}
				textAlign="center" opacity={0.7} borderBottom={`${borderWidth[1]} solid ${light.main}`}
			>
				<SoftTypography
					// variant="button" fontWeight="medium" color="dark"
					fontSize={size.xs}
					fontWeight="bold"
					color="secondary"
					sx={{ display: "inline-block", width: "max-content" , }}
					>
					{name.toUpperCase()}
				</SoftTypography>
			</SoftBox>
		);
	});

  	const renderRows = rows.map((row, key) => {
		const rowKey = `row-${key}`;
		const wp_no = row.wp_no
		let no = key+1

		const tableRow = columns.map(({ name, align, key }) => {
			let data = row[key]

			if(key === "wp_no"){ 
				data = no 
			}
			
			if(key === "mem_name")	{
				return (
					<SoftBox component="td" width="180px" pt={0.5}
						borderBottom={`${borderWidth[1]} solid ${light.main}`}
						>
						<AdminWPListTableUser 
							name={row["mem_name"]} 
							rank={row["mem_rank"]} 
							team={row["mem_team"]} 
							imageUrl={row["imageUrl"]} 
						/>
					</SoftBox>
				)
			} else if(key === "wp_approved")	{
				return (
					<SoftBox
						key={uuidv4()} component="td" p={2} textAlign={"center"}
						borderBottom={`${borderWidth[1]} solid ${light.main}`}
						>
						<SoftBadge 
							variant="contained" 
							badgeContent={row["wp_approved"]} 
							color={ row["wp_approved"] === "결재완료" ? "success" : "info"} size="lm" container 
							sx={{ display: "inline-block", width: "max-content" }}
						/>
					</SoftBox>
				)
			} else {
				return (
					<SoftBox
						key={uuidv4()} component="td" p={2} textAlign={"center"}
						borderBottom={`${borderWidth[1]} solid ${light.main}`}
						>
						<SoftTypography
							variant="button" fontWeight={ key === "wp_totalpoint" ? "bold" : "regular" }
							color={ name === "wp_totalpoint" ? "dark" : "text"}
							sx={{ display: "inline-block", width: "max-content" }}
							>
							{data}
						</SoftTypography>
					</SoftBox>
				)
			}
		});

        return (
			<>
				<TableRow key={rowKey}
					onClick={()=> navigate('/welfarePoint/detail',{state : wp_no})} 
					sx={{ "&:hover": { backgroundColor: "#eceff1" , cursor: "pointer" , } }} 
					>
					{tableRow}
				</TableRow>
			</>
		);
    });

    return (
		<>
			
			{	data &&
				<> 
					<SoftBox p={1} display="flex" alignItems="center">
						<SoftBox>
							<SoftTypography variant="body2" fontWeight="bold" pl={1} pr={1}>
								{`${month}월_복지포인트`}
							</SoftTypography>
						</SoftBox>
						<SoftBox>
							<CSVLink onClick={handleDownloadCsv} data={csvData} headers={headers} filename={filename}>
								<SoftButton
									variant="outlined" 
									color="info"
									>
									엑셀 다운로드 
								</SoftButton>
							</CSVLink>
						</SoftBox>
					</SoftBox>

					<TableContainer>
						<MuiTable>
						<SoftBox component="thead">
							<TableRow>{renderColumns}</TableRow>
						</SoftBox>
						<TableBody>{renderRows}</TableBody>
						</MuiTable>
					</TableContainer>
					<SoftBox pt={2} pb={1}
						display="flex"
						justifyContent="center">
						<Pagination 
							totalCount={totalCount} 
							pageSize={pageSize}
							page={page}
							setPage={setPage}
							size="small"
							/>
					</SoftBox>
				</>
			}
		</>
        )
    }

    // Setting default values for the props of Table
    AdminWPCompleteList.defaultProps = {
    columns: [],
    rows: [{}],
    };

    // Typechecking props for the Table
    AdminWPCompleteList.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    rows: PropTypes.arrayOf(PropTypes.object),
    };


export default AdminWPCompleteList