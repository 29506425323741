import {atom} from "recoil";

/**
 * 복지포인트 작성하기 - row 의 list 
 * @type { RecoilState<[{key : value}]> }
 */


export const wpDetailRowAtom = atom({
    key: "wpDetailRowAtom",
    default: [{
        wp_rowIndex : 1
        , wp_detail_date : ""
        , wp_usedate : ""
        , wp_category : ""
        , wp_content : ""
        , wp_filename : ""
        , wp_file : ""
        , wp_usedpoint : 0
        , wp_paytype : ""
    }]
});
