import {useEffect, useState} from "react";
import {useRecoilState} from "recoil";
import SoftInput from "../../../../components/SoftInput";
import {vacationPhoneValueAtom} from "../../../../atom/vacation/vacationPhoneValueAtom";
import {PhoneNumCheck} from "../../../../utils/PhoneNumCheck";

/**
 * 전화번호 값을 받아서 정규식 처리해서 보내는 함수
 * @param category : String 라벨이름
 * @param keyName : String 키이름
 * @param defaultValue : String 휴가 연락처
 * @returns {JSX.Element}
 * @constructor
 */
const VacationPhoneNum = ({category,keyName,defaultValue}) => {

    // 입력값
    const [inputValue, setInputValue] = useState("");
    // 연락처 리코일
    const [recoilValue, setRecoilValue] = useRecoilState(vacationPhoneValueAtom);
    // 연락처 미입력 에러
    const [telError, setTelError] = useState(true);

    // 기본값 저장
    useEffect(()=>{
        if(defaultValue !== '' && defaultValue !== undefined ) {
            setInputValue(defaultValue);
            setTelError(false);
            // 값 저장
            setRecoilValue(defaultValue);
        }
    },[defaultValue]);

    // 전화번호 정규식 처리
    const inputChangeHandle = (e) => {
        const regex = /^[0-9\b -]{0,13}$/;
        const changeValue = e.target.value
        // 휴가 목적 input value 핸들링
        if(changeValue !== ''){
            // 숫자 또는 하이픈일때
            if (regex.test(changeValue)) {
                setInputValue(changeValue);
                // 함수 설명 참조
                if(PhoneNumCheck(changeValue)){
                    setTelError(false);
                    setRecoilValue(changeValue);
                } else {
                    setTelError(true);
                    setRecoilValue('');
                }
            }
        // ''이 아닐때
        } else {
            setInputValue(changeValue);
            setTelError(true);
        }
    }

    // 전화번호 하이픈 넣기 및 리코일에 저장
    useEffect(() => {
        // 10글자일 떄
        if (inputValue.length === 10) {
            setInputValue(inputValue.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
        }
        // 13글자일때
        if (inputValue.length === 13) {
            setInputValue(inputValue.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
            // 전화번호 패턴과 맞으면 저장
            if(PhoneNumCheck([...inputValue].join(''))) {
                // 값 저장
                setTelError(false);
                setRecoilValue([...inputValue].join(''));
            }
        }
        // 13글자가 넘어갈때
        if (inputValue.length > 13){
            const inputLength = inputValue.length - 13;
            setInputValue(inputValue.slice(0, -inputLength));
        }
    }, [inputValue]);

    return (
        <SoftInput
            size="small"
            value={inputValue}
            onChange={inputChangeHandle}
            error={telError}
        />
    );
}
export default VacationPhoneNum;