import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 반려 문서 열람 여부 업데이트
 * @returns {Promise<axios.AxiosResponse<any>>}
 * @constructor
 */
const rejectViewCheckUpdate =(body)=>{
    return customAxios.post(myIpAddress+'/approval/rejectViewCheck',body);
}
export default rejectViewCheckUpdate;