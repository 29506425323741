import { Chip, Grid } from '@mui/material'
import { loginUser } from 'atom/member/loginUser'
import { blue } from '@mui/material/colors';
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'
import CommonCodeInfoReturn from 'components/commonCode/CommonCodeInfoReturn'
import SoftAvatar from 'components/SoftAvatar'
// props.url  이미지주소
// props.name 이름
// props.team 부서
// props.rank 직급
/**
 * props.url  이미지주소
 * props.name 이름
 * props.team 부서
 * props.rank 직급 
 * propt.writer 작성자chip칩
 * @param {*} props 
 * @returns 
 */
const CostWriterInput = (props) => {
  const user = useRecoilValue(loginUser);
  let asdf = (props.url===undefined ? "ㅇㅇㅇㅇ" : "ddd");
  let team = (props.team===undefined ? user?.team : props.team);
  let rank = (props.rank===undefined ? user?.rank : props.rank);
  let url = (props.url===undefined ? user?.imageUrl : props.url);
  let name = (props.name===undefined ? user?.name : props.name);
  let size = (props.size===undefined ? "xl" : props.size);

  return (
    <Grid container spacing={3} p={2}>
      <Grid item xs={12}>
        <SoftBox pt={5} mt={-6} display="flex" justifyContent="space-between" alignItems="center"
                    flexWrap="wrap">
          <Grid container spacing={3} alignItems="center">
              <Grid item>
                  <SoftAvatar
                      src={url}
                      alt="profile-image"
                      variant="rounded"
                      size={size}
                      shadow="sm"
                  />
              </Grid>
              <Grid item>
                  <SoftBox height="100%" mt={0.5} lineHeight={1}>
                    {
                      props.writer === "writer" ?
                      <SoftTypography variant="h5" fontWeight="medium" alignItems="center" display="flex">
                          {name}&nbsp;<Chip label="작성자" style={{ color: blue['A200'] , borderColor: blue['A200'] }} variant="outlined" size="small"/>
                      </SoftTypography>
                      :
                      <SoftTypography variant="h5" fontWeight="medium" alignItems="center" display="flex">
                          {name}
                      </SoftTypography>
                    }
                      <SoftTypography variant="button" color="text" fontWeight="medium">
                          <CommonCodeInfoReturn init={team}/> / <CommonCodeInfoReturn init={rank}/>
                      </SoftTypography>
                  </SoftBox>
              </Grid>
          </Grid>
        </SoftBox>
      </Grid>
    </Grid>
  )
}

export default CostWriterInput