import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material'
import { Grid, IconButton } from '@mui/material'
import GetNowHolyDay from 'api/date/GetNowHolyDay'
import SoftTypography from 'components/SoftTypography'
import { add, addDays, differenceInCalendarWeeks, format, getDay, getYear, subDays } from 'date-fns'
import { ko } from 'date-fns/locale'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useQuery } from 'react-query'
import Swal from 'sweetalert2'

const DateSelectItem = ({selectDate, setSelectDate}) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    
    const [filteredHolyDayList, setFilteredHolyDayList] = useState([]); // 공휴일 제외
    const toDayYear = {
        toDayYear: getYear(new Date()).toString()
        , nextYear: getYear(add(new Date(), {years: 1})).toString()
    }
    
    
    const {data:holyDay} = useQuery(["GetNowHolyDay", toDayYear] , ()=> GetNowHolyDay(toDayYear), {
        // enabled : filteredHolyDayList.length === 0 ,
        // staleTime: 1200000,
        // refetchOnWindowFocus: false,
        // refetchOnMount: false,
        onSuccess: (result) => {
            try {
                const nowYearHolyDayList = result.data.response.body.items.item;
                const filterHolyList = [];
                for (let i = 0; i < nowYearHolyDayList.length; i++) {
                    // 제헌절이 리스트에 있으나 공휴일이 아니고 N으로 표시되어있어서 Y 인것만
                    if (nowYearHolyDayList[i].isHoliday === "Y") {
                        const map = new Map;
                        map.set("name", nowYearHolyDayList[i].dateName);
                        map.set("date", nowYearHolyDayList[i].locdate);
                        filterHolyList.push(map);
                    }
                }
                const saveList = [...filteredHolyDayList];
                setFilteredHolyDayList(saveList.concat(filterHolyList));
            } catch (e) {
                console.log("now holy day list load error - " + e);
            }
        },
    });
   
    const isWeekend = (date) => {
        const day = getDay(date);
        return day === 0 || day === 6; 
    };
   
    
    const isHoliday = (date) => {
        const formattedDate = format(date, 'yyyyMMdd');
        return filteredHolyDayList.some(holyDay => holyDay.get('date') === parseInt(formattedDate));
    };


    const moveNextDay = () => {
        // const weekDifference = differenceInCalendarWeeks(selectDate, currentDate, { weekStartsOn: 1 });
        // if (weekDifference <= 1){
        //     setSelectDate(addDays(selectDate, 1))
        // }else{
        //     alert("다음주에 해당 기간이 오픈됩니다.")
        // }
        
        let nextDate = addDays(selectDate, 1);
        // 다음 날짜가 주말이나 공휴일인 경우에는 다음주 평일로 
        while (isWeekend(nextDate) || isHoliday(nextDate)) {
            nextDate = addDays(nextDate, 1);
        }
        const weekDifference = differenceInCalendarWeeks(nextDate, currentDate, { weekStartsOn: 1 });
        if (weekDifference <= 1) {
            setSelectDate(nextDate);
        } else {
            Swal.fire({
                icon:'warning',
                text:'다음주에 해당 기간이 오픈됩니다.',
                backdrop: false,
            })
        }
    }

    const movePrevDay = () => {
        // setSelectDate(subDays(selectDate, 1))
        let prevDate = subDays(selectDate, 1);
        // 이전 날짜가 주말이나 공휴일인 경우에는 이전주 평일로
        while (isWeekend(prevDate) || isHoliday(prevDate, filteredHolyDayList)) {
            prevDate = subDays(prevDate, 1);
        }
        setSelectDate(prevDate);
    }

    return(
        <Grid container alignItems={"center"}>
            <IconButton sx={{pl: isMobile? 0 : 'auto'}} size={"large"} onClick={movePrevDay} color="text">
                <ArrowBackIosNew/>
            </IconButton>
            <SoftTypography variant="h4" fontWeight="bold" color="text">{format(selectDate, 'yyyy-MM-dd ( iiii )', { locale: ko })}</SoftTypography>
            <IconButton sx={{pr: isMobile? 0 : 'auto'}} size={"large"} onClick={moveNextDay} color="text">
                <ArrowForwardIos/>
            </IconButton>
        </Grid>
    )
}
export default DateSelectItem;