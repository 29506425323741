import * as React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from "react-number-format";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SoftBox from 'components/SoftBox';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
   const { onChange, ...other } = props;
   
   return (
   <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
      onChange({
         target: {
         name: props.name,
         value: values.value,
         },
      });
      }}
      thousandSeparator
      isNumericString
      allowLeadingZeros={false}
   />
   );

});

NumberFormatCustom.propTypes = {
   name: PropTypes.string.isRequired,
   onChange: PropTypes.func.isRequired,
};
  
const SumNumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
   const { onChange, ...other } = props;
   
   return (
   <NumberFormat
      {...other}
      getInputRef={ref}
      thousandSeparator
      isNumericString
      allowLeadingZeros={false}
   />
   );

});


/** value = 원하는 값 / unit = 화폐 단위 ex)원 / 읽기만 할때 them=sum 추가 / size / */
export default function AmountFormatInput(props) {
   
   const [ values, setValues ] = React.useState({});
   
   const { value, unit , size, theme , width , setValue, temp } = props;

   const handleChange = (event) => {
      setValues({
         ...values,
         [event.target.name]: event.target.value,
      });
      setValue(event.target.value);
   };

   return (
      <>
      <SoftBox
      sx={{
         // '& > :not(style)': {
         // m: 1,
         // },
         width : width==undefined ? "100%" : width
      }}
      
      >
      { theme === "sum" ?
         <TextField
            value={value}
            size={size ? size : "normal"}
            sx={{ 
                  width: "100%" 
                  , fontWeight : "bold" 
                  , "& .MuiInputBase-root.MuiOutlinedInput-root": {
                     display: "flex",
                     justifyContent: "space-between",
                  },  
               }}
            InputProps={{
               readOnly: true,
               inputComponent: SumNumberFormatCustom,
               endAdornment: (
                  <InputAdornment position="end" sx={{ display: "flex", justifyContent: "space-between" }}>
                     <div className="amount-text">{unit}</div>
                  </InputAdornment>
               ),
            }}
         >
         </TextField>
      :
         <TextField
            //id="standard-basic" label="사용금액" variant="standard"
            value={value}
            onChange={handleChange}
            //size="small"
            sx={{ width: "100%" 
                  , "& .MuiInputBase-root.MuiOutlinedInput-root": {
                     display: "flex",
                     justifyContent: "space-between",
                  },  
            }}
            InputProps={{
               inputComponent: NumberFormatCustom,
               endAdornment: (
                  <InputAdornment position="end">
                     <div className="amount-text">{unit}</div>
                  </InputAdornment>
               ),
            }}
            
         >
         </TextField>
      }   
      </SoftBox>
      </>
   );
}