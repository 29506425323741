import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import React from 'react'

const SelectForm = (props) => {

    const [open, setOpen] = React.useState(false);

    const handleToggle = () => {
        setOpen(!open);
    };
    const handleYearChange = (event) => {
        props.setClassification(event.target.value)
    };
    return (
        <FormControl sx={{width: "90%", cursor: "pointer" }}>
            <InputLabel id="demo-year-select-label" sx={{ mt: -1 }}>{props.title}</InputLabel>
            <Select
                open={open}
                // onClose={handleClose}
                onOpen={handleToggle}
                onClick={handleToggle}
                labelId="demo-year-select-label"
                id="demo-year-select"
                value={props.value}
                onChange={handleYearChange}
                // input={<OutlinedInput label="Year" />}
                sx={{ cursor: "pointer" }}
            >
                {props.list.map((year) => (
                    <MenuItem key={year} value={year}>
                        {year}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default SelectForm