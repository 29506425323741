import React ,  {  useState  }from "react";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useQuery } from "react-query";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// api
import wpTemporaryDocList from "../../../../api/wp/wpTemporaryDocList";
import allCommonCodeMap from "api/commonCode/allCommonCodeMap";

// atom
import { loginUser } from "atom/member/loginUser";

// util
import { AmountFormat } from "utils/AmountFormat";
import { DateFormat } from "utils/DateFormat";

// @mui material components
import { IconButton, Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Pagination from "../../../../components/pagination/Pagination";
import SoftBadge from "components/SoftBadge";
// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import { getFormattedDateTitle } from "layouts/wp/components/getFormattedDateTitle";
import { wpTempListSearch } from "atom/wp/wpTempListSearch";
import { useEffect } from "react";



function WpTempListTable() {
    const user = useRecoilValue(loginUser);
	const navigate = useNavigate();

	const { light } = colors;
	const { size } = typography;
	const { borderWidth } = borders;

	const [ searchBody , setSearchBody ] = useRecoilState(wpTempListSearch);
	const [ start , setStart ] = useState(false);

	const [ page , setPage ] = useState(searchBody.page === 1 ? 1 : (searchBody.page / 10) + 1);	// 현재 페이지 - 초기 값 1 
	const [ pageSize , setPageSize] = useState(10); // 페이지당 아이템 개수
	/** 총 게시물 수  */
    let totalCount;

	const body = {
		...searchBody,
		mem_no : user?.id
		, page : page === 1 ? 0 : ((page - 1) * pageSize)
		, pageSize : pageSize
	}
	/**  */
	useEffect(()=>{
		const bodyData = {
			...searchBody,
			mem_no : user?.id
			, page : page === 1 ? 0 : ((page - 1) * pageSize)
			, pageSize : pageSize
		}
		setSearchBody(bodyData)
	},[page])

	const { data } = useQuery(['wpTemporaryDocList', body], () => wpTemporaryDocList(body) , {
        select : (data) => {
            return data.data;
        }
    }); 

	const { data:info} = useQuery('allCommonCodeMap', allCommonCodeMap, {
        select : (data) => {
            return data.data;
        }
    });


	/** 임시저장 목록 칼럼 */
	const columns = [
		{ key: "wp_no" , name: "번호", align: "center" , width : "10%" , },
		{ key: "mem_name" , name: "이름", align: "center" , width : "10%" , }, 
		{ key: "mem_team" , name: "소속", align: "center" , width : "10%" , },
		{ key: "mem_rank" , name: "직급", align: "center" , width : "10%" , },
		{ key: "title" , name: "제목", align: "center" , fontWeight : "bold" },
		{ key: "wp_totalpoint" , name: "합계", align: "center"  , fontWeight : "bold"},
		{ key: "wp_approved" , name: "결재상태", align: "center" },
		{ key: "wp_date" , name: "작성일", align: "center" },
	]

	/**  */
	const rows = []
	/**  */
	let wpdDto = []

	if(data && info && data.length > 0 ) {
		let wpdDtoRow = []
		/**  */
		data.map((list ,index) =>{
			let row ={}
			//columns.map((col, i)=>{
				// if(col.key === "wp_date"){
				// 	// 작성일 날짜 변환 
				//     row[col.name] = DateFormat(list[col.key])
				// } else if(col.key === "wp_totalpoint"){
				// 	// 합계 에 , 찍기
				//     row[col.name] = AmountFormat(list[col.key])
				// } else if(col.key === "mem_team" || col.key === "mem_rank" || col.key === "wp_approved" ) {
				// 	// 공용 코드 변환
				//     row[col.name] = info[list[col.key]].info
				// } else {
				//     row[col.name] = list[col.key]
				// }
			//})
				row["btn"] = index		// 버튼 인덱스
				row["wp_no"] = list["wp_no"]	// 복지포인트 게시물 번호
				row["mem_name"] = list["mem_name"]	// 작성자
				row["mem_team"] = info[list["mem_team"]].info	// 소속
				row["mem_rank"] = info[list["mem_rank"]].info	// 직급
				
				row["title"] = {	// 제목
                    value: getFormattedDateTitle(list["wp_date"], "복지포인트"),
                    style: "bold" ,
                };					
				row["wp_totalpoint"] = {	// 합계
                    value: `${AmountFormat(list["wp_totalpoint"])} 원`,
                    style: "bold" ,	
                };					

				row["wp_approved"] = info[list["wp_approved"]].info	// 결재 상태
				row["wp_date"] = DateFormat(list["wp_date"])	// 작성일 
			rows.push(row)
		})
	
		totalCount = data[0].totalCount
		//console.log("rows : ", totalCount )
	}

	/** 상위 칼럼 */
	const renderColumns = columns.map(({ name, align, width }, key) => {
		let pl;
		let pr;
		if (key === 0) {pl = 3;pr = 3;
		} else if (key === columns.length - 1) {pl = 3;pr = 3;
		} else {pl = 1;pr = 1;
		}	
		return (
			<SoftBox
				key={name} component="th" width={width || "auto"} pt={1.5} pb={1.25}  
				textAlign="center" fontSize={size.md} fontWeight="bold" color= "secondary" 
				opacity={0.7} borderBottom={`${borderWidth[1]} solid ${light.main}`}
			>	
				<SoftTypography
					variant="button"
					fontWeight="bold"
					color= "secondary"
					sx={{ display: "inline-block", width: "max-content" }}
					>
					{name.toUpperCase()}
				</SoftTypography>
			</SoftBox>
		);
	});
	
	/** 상위 row 내용 */
  	const renderRows = rows.map((row, key) => {
		const rowKey = `row-${key}`;
		const wp_no = row.wp_no
		
		let no = key+1

		let tempListNo = totalCount - (page - 1) * pageSize - key;

		if(tempListNo > totalCount) {
			tempListNo = totalCount;
		}

		const tableRow = columns.map(({ name,  key}) => {
			let data = row[key] ? (row[key].value ? row[key].value : row[key]) : undefined;
			if(key === "wp_no"){ data = no }
			let template;

				if (key === "wp_approved") {
					template = (
						<SoftBox
							key={uuidv4()}
							component="td"
							p={1.5}
							textAlign={"center"}
							borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
							>
							<SoftBadge 
								variant="contained" 
								badgeContent={data} 
								onClick={()=> navigate('/welfarePoint/detail',{state : wp_no}) }
								color={"secondary"} size="xs" container 
								sx={{ display: "inline-block", width: "max-content" }}
								/>
						</SoftBox>
					)   
				} else {
					template = (
						<SoftBox
							key={uuidv4()} component="td" p={1.5} 
							textAlign={"center"} 
							borderBottom={`${borderWidth[1]} solid ${light.main}`}
							//bgColor="#f0f8ff"
							>
							<SoftTypography
								variant="button"  
								color="dark"
								fontWeight={ row[key].style ? row[key].style.fontWeight : "regular"}
								sx={{ display: "inline-block", width: "max-content" }}
								textAlign="center"
								>
								{key === "wp_no" ? tempListNo :data}
							</SoftTypography>
						</SoftBox>
					)
				}  
			return template;
		});
        return (
			<>
				<TableRow key={rowKey} 
					onClick={()=> navigate('/welfarePoint/detail',{state : wp_no}) }
					sx={{ "&:hover": { backgroundColor: "#eceff1" , cursor: "pointer" , } }} 
					>
					{tableRow}
				</TableRow>
			</>
		);
    });

    return useMemo(
        () => (
				<>
                <TableContainer>
                    <MuiTable>
                    <SoftBox component="thead">
                        <TableRow>{renderColumns}</TableRow>
                    </SoftBox>
                    <TableBody>{renderRows}</TableBody>
                    </MuiTable>
                </TableContainer>
				<SoftBox pt={2} pb={1}
					    display="flex"
						justifyContent="center"
					>
					<Pagination 
						totalCount={totalCount} 
						pageSize={pageSize}
						page={page}
						setPage={setPage}
						size="small"
					/>
				</SoftBox>
				</>
            ),
            [columns, rows]
        );
    }

    // Setting default values for the props of Table
    WpTempListTable.defaultProps = {
    columns: [],
    rows: [{}],
    };

    // Typechecking props for the Table
    WpTempListTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    rows: PropTypes.arrayOf(PropTypes.object),
    };


export default WpTempListTable