import React ,  { useState }from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import moment from "moment";

// api
import wpList from "../../../api/wp/wpList.js";
import allCommonCodeMap from "api/commonCode/allCommonCodeMap";

// atom
import { loginUser } from "atom/member/loginUser";

// util
import { DateFormat } from "utils/DateFormat";
import { AmountFormat } from "utils/AmountFormat.js";

// components
import WpListTable from "./components/WpListTable.js";
import Pagination from "../../../components/pagination/Pagination";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Icon } from "@mui/material";

// Soft UI Dashboard React examples
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout/index.js";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import borders from "assets/theme/base/borders.js";
import Footer from "examples/Footer/index.js";
import { myIpAddress } from "config.js";
import { customAxios } from "../../../axios/CustomAxios.js";
import { getFormattedDateTitle } from "layouts/wp/components/getFormattedDateTitle.js";
import { useEffect } from "react";
import { wpListSearch } from "atom/wp/wpListSearch.js";


const WPListPage = () => {    
	const { borderWidth } = borders;

    const navigate = useNavigate();
    const user = useRecoilValue(loginUser);

	const [ searchBody , setSearchBody ] = useRecoilState(wpListSearch);

    const [ totalCount , setTotalCount ] = useState(0);
    const [ page , setPage ] = useState(searchBody.page === 1 ? 1 : (searchBody.page / 10) + 1);
    const [ pageSize , setPageSize] = useState(10); // 페이지당 아이템 개수
    //let currentPage = 1; // 현재 페이지 (초기값은 1)

    const columns = [
		{ key: "wp_no" , name: "번호", align: "center" , width: "5%"},
		{ key: "mem_name" , name: "이름", align: "center" , width: "10%"}, 
		{ key: "mem_team" , name: "소속", align: "center" , width: "10%"},
		{ key: "mem_rank" , name: "직급", align: "center" },
		{ key: "title" , name: "제목", align: "center" , fontWeight : "bold"},
		{ key: "wp_totalpoint" , name: "합계", align: "center" , fontWeight : "bold" },
		{ key: "wp_approved" , name: "결재상태", align: "center" },
		{ key: "wp_date" , name: "작성일", align: "center" },
	]

    const rows = []

    const body = { 
        ...searchBody,
        mem_no : user.id
        , page : page === 1 ? 0 : ((page - 1) * pageSize)
        , pageSize : pageSize
    };
    
    useEffect(()=>{
		const bodyData = {
			...searchBody,
			mem_no : user?.id
			, page : page === 1 ? 0 : ((page - 1) * pageSize)
			, pageSize : pageSize
		}
		setSearchBody(bodyData)
	},[page])

    const { data } = useQuery(['wpList', body], () => wpList(body) , {
        select : (data) => {
            return data.data;
        },
    }); 

    const wpDocTotalCount = (body) => {
        return customAxios.post(myIpAddress+'/wp/wpDocTotalCount', body); 
    }
    const { data:count } = useQuery(['wpDocTotalCount', body], () => wpDocTotalCount(body) , {
        onSuccess : (count) => {
            setTotalCount(count.data);
        },
    });

    const { data:info } = useQuery('allCommonCodeMap', allCommonCodeMap, {
        select : (data) => {
            return data.data;
        }
    }); 


    if(data && info && data.length > 0 ) {
        data.map((list,index) =>{
            let row ={}
            // columns.map((col, i)=>{
            //     if(col.name === "작성일"){
            //         row[col.name] = DateFormat(list[col.key])
            //     } else if(col.name === "합계"){
            //         row[col.name] = AmountFormat(list[col.key]) + " 원"; 
            //     } else if(col.name === "소속" || col.name === "직급" || col.name === "결재상태" ) {
            //         row[col.name] = info[list[col.key]].info
            //     } else {
            //         row[col.name] = list[col.key]
            //     }
            // })
                row["wp_no"] = list["wp_no"]    // 복지포인트 게시물 번호
                row["mem_name"] = list["mem_name"]    // 이름
                row["mem_team"] = info[list["mem_team"]].info    // 소속
                row["mem_rank"] = info[list["mem_rank"]].info    // 직급
                row["title"] = {	// 제목
                    value: getFormattedDateTitle(list["wp_date"], "복지포인트"),
                    style: "bold" ,
                };				
                row["wp_totalpoint"] = {	// 합계
                    value: `${AmountFormat(list["wp_totalpoint"])} 원`,
                    style: "bold" ,
                };				
                row["wp_approved"] = info[list["wp_approved"]].info	// 결재 상태
				row["wp_date"] = DateFormat(list["wp_date"])	// 작성일 
            rows.push(row)
        })
        //totalCount = data[0].totalCount
    }

    return (
        <>
            <DashboardLayout>
                <DashboardNavbar/>
                <Card
                    sx={{
                    backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
                    boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                    position: "relative",
                    mt: 2,
                    mx: 0,
                    mb : 4,
                    py: 2,
                    px: 2,
                    }}
                    >
                    <Grid container spacing={3} 
                        alignItems="center" 
                        pb={2}
                        >
                        <Grid item >
                            <SoftBox height="100%" mt={0.5} lineHeight={1} >
                                <SoftTypography variant="h5" fontWeight="bold" color="" pl={2}>
                                    복지포인트 전체 목록
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                        <Grid item  
                            sx={{ ml: "auto" }}
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            pr={3}
                            >
                            {/* <SoftBox>
                                <SoftButton color="success" onClick={()=> navigate('/welfarePoint')} mr={2}>
                                    메인으로
                                </SoftButton>
                            </SoftBox> */}
                            {/* <SoftBox pr={1}>
                                <SoftButton variant="gradient" color="info" onClick={()=> navigate('/welfarePoint/insert')} >
                                    <Icon sx={{fontWeight: "bold"}}>text_snippet</Icon>
                                        &nbsp;작성하기
                                </SoftButton>
                            </SoftBox> */}
                            <SoftBox>
                                <SoftButton variant="gradient" color="dark" onClick={()=> navigate('/welfarePoint/tempList')} >
                                    <Icon sx={{fontWeight: "bold"}}>format_list_bulleted</Icon>
                                        &nbsp;임시 저장 목록
                                </SoftButton>
                            </SoftBox>
                        </Grid>
                    </Grid>
                    <SoftBox 
                        sx={{
                            "& .MuiTableRow-root:not(:last-child)": {
                            "& td": {
                                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                `${borderWidth[1]} solid ${borderColor}`,
                            },
                            },
                        }}
                        mt={2}
                        >
                        <WpListTable columns={columns} rows={rows} totalCount={totalCount} page={page} pageSize={pageSize} />    
                    </SoftBox>
                    <SoftBox pt={2}
                        display="flex"
                        justifyContent="center"
                        >
                        {count &&  
                        <Pagination 
                            totalCount={totalCount} 
                            pageSize={pageSize}
                            page={page}
                            setPage={setPage}
                            size="small"
                        />}
                    </SoftBox>
                </Card>
                <Footer/>
            </DashboardLayout>
        </>
    );

}

export default WPListPage