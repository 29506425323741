import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 유저 휴가 결재문서 최근 5개
 * @param no : int
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const vacationNewApprovalDocs =(no)=>{
    return customAxios.post(myIpAddress+'/vacation/vacationNewApprovalDocs',
        {mem_no:no});
}
export default vacationNewApprovalDocs;
