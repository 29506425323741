import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import { getFormattedDateTitle } from 'layouts/wp/components/getFormattedDateTitle';
import { DateToString } from 'utils/DateToString';

// props.name=라벨 이름
// props.num=7이면 월 10이면 일수까지 (달력)
// props.type= date 일 month 월
// 2023-06-02 defaultValue , costDate 추가 
// 현재 시간 과 비용처리 결재일 기준으로 달력 defaultValue 변화
const Month = (props) => {
  //현재 날짜 년-월-일
  let nowDate = new Date().toISOString().substring(0, props.num);
  //let testDay = new Date(2023,5,11);
  // 결재일 10일 기준으로 이전이면 저번달 이후면 이번달 출력 
  let costDate = getFormattedDateTitle(nowDate)

  if (props.temp !== "") {
    nowDate = props.temp;
  }
  const [dateCreated, setDateCreated] = useState(props.temp);
  const defaultValue = props.costType === "cost" && props.temp === "" ? costDate : nowDate;

  useEffect(() => {
    if (props.temp === "") {
        props.setDate(defaultValue)
    } else {
      props.setDate(dateCreated)
    }
  }, [dateCreated, defaultValue])

  // 2023-06-08 width: 90 -> 100 으로 바꿈 2. 라벨 주석처리
  return (
    <TextField
      sx={{ width: '100%' }}
      id="date"
      //label={props.name}
      type={props.type}
      defaultValue={defaultValue}
      onChange={(e) => setDateCreated(e.target.value)}
      InputProps={{
        readOnly: props.readOnly
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  )
}

export default Month