import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import listUpdate from '../../atom/data/listUpdate';
import './AssetInsertModal.css';
import Swal from "sweetalert2";
import { customAxios } from '../../axios/CustomAxios';
import { myIpAddress } from '../../config';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import allCommonCodeList from 'api/commonCode/allCommonCodeList';
import SoftButton from 'components/SoftButton';
import userAll from 'api/user/userAll';
import CommonCodeInfoReturn from 'components/commonCode/CommonCodeInfoReturn';


function AssetInsertModal ({modalChange2}) {
          
    const [name, setName] = useState(0);
    const [nameList, setNameList] = useState([]);    


    const [newAsset, setNewAsset] = useState({       
      ass_cate : "", 
      ass_name : "", 
      ass_date : "", 
      ass_com : "", 
      ass_sn : "", 
      ass_cpu : "", 
      ass_ram : "", 
      ass_size : "", 
      ass_ssd_type : "",
      ass_ssd : "", 
      ass_hdd : "", 
      ass_res : "", 
      ass_os : "", 
      ass_memo : "" ,
      mem_no : ""
    })

    const closeModal2 = () => {
      modalChange2.current.style = "display:none;";
      document.querySelector('.modal-overlay').style.zIndex = "-1";
      setNewAsset({
        ass_cate : "", 
        ass_name : "", 
        ass_date : "", 
        ass_com : "", 
        ass_sn : "", 
        ass_cpu : "", 
        ass_ram : "", 
        ass_size : "", 
        ass_ssd_type : "",
        ass_ssd : "", 
        ass_hdd : "", 
        ass_res : "", 
        ass_os : "", 
        ass_memo : "",
        mem_no : 0
      });
      setName(0)
    }; 

    const setIsUpdate = useSetRecoilState(listUpdate);      
      
    const handleChange = (e) => {    
      const memNo = parseInt(e.target.value);
      setNewAsset((prev) => ({ ...prev, mem_no: memNo}))
      setName(e.target.value)
    };

    const handleClickAddButton = () => {
      if(newAsset.ass_cate && newAsset.ass_name) {
        customAxios.post(myIpAddress+'/asset/insert', newAsset)
        .then(()=>{
          Swal.fire({
            icon : 'success',
            title : '등록 성공!',
            backdrop: false,
          })
          setNewAsset({
            ass_cate : "",
            ass_name : "", 
            ass_date : "", 
            ass_com : "", 
            ass_sn : "", 
            ass_cpu : "", 
            ass_ram : "", 
            ass_size : "", 
            ass_ssd_type : "",
            ass_ssd : "", 
            ass_hdd : "", 
            ass_res : "", 
            ass_os : "", 
            ass_memo : "",
            mem_no : "" 
          })               
          setIsUpdate(true)
          closeModal2()           
        }) 
      } else {
        Swal.fire({
          icon : 'error',
          title : "등록 실패",
          text : '카테고리와 모델명은 반드시 입력해주세요',
          backdrop : false
        })
      }
    }    
    
    
    const [cateList, setCateList] = useState([]);
    const {data, isSuccess} = useQuery('allCommonCodeList',allCommonCodeList,{      
      staleTime : 120000,
      refetchOnWindowFocus : false,
      refetchOnMount : false,
      select : (data) => {
          return data.data
      }
    })

    const {data:userList, isSuccess:userSuccess} = useQuery('userAll',userAll,{
        select : (data)=>{
            return data.data
        }
    })

      
    useEffect(() => {
      if (isSuccess) {
        const assetCateList = data
          .filter(item => item.cc_category === 'ASSET')
          .map(item => ({ cate: item.cc_name, info: item.cc_info }));
        setCateList(assetCateList);
        setNewAsset((prev) => ({ ...prev, ass_cate: assetCateList[0].cate }))
      }
    }, [isSuccess, data]);

    const nameArr = [...nameList]

    
    useEffect(()=>{
      if(userSuccess){
        for(let i=0;i<userList.length;i++){
          let nar = {'name':userList[i].memName,'no':userList[i].memNo, 'rank':userList[i].memRank}
          nameArr.push(nar)
          setNameList(nameArr)
        }
      }
    },[userSuccess])



    // 물품 분류 
    useEffect(()=>{
      if(newAsset.ass_cate === "" && cateList.length > 0) {
        setNewAsset((prev) => ({ ...prev, ass_cate: cateList[0].cate }))
      }
    },[newAsset])



    const handleModalClick = (e) => {
      // 모달 바깥쪽 클릭 시 모달 닫기
      if (e.target === modalChange2.current) {
        closeModal2();
      }
    };
    

    const handleModalContentClick = (e) => {
      // 모달 안쪽 클릭 시 이벤트 전파 막기
      e.stopPropagation();
    };
   
      
    return(
        <>
        <div id="insert-modal" className="modal" ref={modalChange2} onClick={handleModalClick}>
          <div className="modalcontent" onClick={handleModalContentClick}>
           <div className="modalheader">
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label" style={{marginTop : "15px"}}>물품 분류</FormLabel>
                <RadioGroup
                 row
                 aria-labelledby="demo-row-radio-buttons-group-label"
                 name="row-radio-buttons-group"
                 value={newAsset.ass_cate}
                 style={{marginTop:"20px"}}
                 onChange={(e) =>
                  setNewAsset((prev) => ({ ...prev, ass_cate: e.target.value }))} 
          >
             {cateList.length > 0 && cateList.map((category, idx) => (
              <FormControlLabel key={idx} value={category.cate} control={<Radio />} label={category.info} 
                 onChange={(e) =>
                setNewAsset((prev) => ({ ...prev, ass_cate: e.target.value }))}
                style={{marginRight:"30px"}}/>
              ))}                   
            </RadioGroup>
          </FormControl>       
          </div>         
          {newAsset.ass_cate === "AS001" ?            

            <div className="modalbody">
              <ul>
                <li>
                  <p className="front">모델명</p>
                  <input className="back"  onChange={(e) =>
                    setNewAsset((prev) => ({ ...prev, ass_name: e.target.value }))
                  } value={newAsset.ass_name}/>
                </li>                 
                <li>
                  <p className="front">제조일</p>
                  <input className="back"  onChange={(e) =>
                    setNewAsset((prev) => ({ ...prev, ass_date: e.target.value }))
                  } value={newAsset.ass_date}/>
                </li>  
                <li>
                  <p className="front">제조사</p>
                  <input className="back"  onChange={(e) =>
                    setNewAsset((prev) => ({ ...prev, ass_com: e.target.value }))
                  } value={newAsset.ass_com}/>
                </li>  
                <li>
                  <p className="front">CPU</p>
                  <input className="back"  onChange={(e) =>
                    setNewAsset((prev) => ({ ...prev, ass_cpu: e.target.value }))
                  } value={newAsset.ass_cpu}/>
                </li>  
                <li>
                  <p className="front">RAM</p>
                  <input className="back"  onChange={(e) =>
                    setNewAsset((prev) => ({ ...prev, ass_ram: e.target.value }))
                  } value={newAsset.ass_ram}/>
                </li>                   
                <li>
                  <p className="front">SSD타입</p>
                  <input className="back"  onChange={(e) =>
                    setNewAsset((prev) => ({ ...prev, ass_ssd_type: e.target.value }))
                  } value={newAsset.ass_ssd_type}/>
                </li>  
                <li>
                  <p className="front">SSD</p>
                  <input className="back"  onChange={(e) =>
                    setNewAsset((prev) => ({ ...prev, ass_ssd: e.target.value }))
                  } value={newAsset.ass_ssd}/>
                </li>  
                <li>
                  <p className="front">HDD</p>
                  <input className="back"  onChange={(e) =>
                    setNewAsset((prev) => ({ ...prev, ass_hdd: e.target.value }))
                  } value={newAsset.ass_hdd}/>
                </li>            
                <li>
                  <p className="front">운영체제</p>
                  <input className="back"  onChange={(e) =>
                    setNewAsset((prev) => ({ ...prev, ass_os: e.target.value }))
                  } value={newAsset.ass_os}/>
                </li>  
                <li></li>
                <li className="memo">
                  <p className="front">비고(위치)</p>
                  <textarea className="back" id="input-memo" style={{ resize: "none" }} onChange={(e) =>
                    setNewAsset((prev) => ({ ...prev, ass_memo: e.target.value }))
                  } value={newAsset.ass_memo}/>
                </li>                 
                <li className="memo">
                  <p className="front">사용자 등록</p>
                  <select 
                    className="back insertuser"
                    value={name}   
                    onChange={handleChange}
                    label="사원"
                    style={{height:"40px", width:"80%", border:"1px solid black", marginTop : "5px"}}
                  >
                    { nameList && (
                        <>
                            <option key={0} value={0}>&nbsp;사용자 없음</option>
                            {nameList.map((name, idx) => (
                                <option key={idx + 1} value={name.no}>&nbsp;{name.name}&nbsp;<CommonCodeInfoReturn init={name.rank} /></option>
                            ))}
                        </>
                    )}
                  </select>
                </li>
               </ul>
            </div>    

             : newAsset.ass_cate === "AS002" ? 
            
            <div className="modalbody">
             <ul>
              <li>
                <p className="front">모델명</p>
                <input className="back"  onChange={(e) =>
                  setNewAsset((prev) => ({ ...prev, ass_name: e.target.value }))
                } value={newAsset.ass_name}/>
              </li> 
              <li>
                <p className="front">시리얼</p>
                <input className="back"  onChange={(e) =>
                  setNewAsset((prev) => ({ ...prev, ass_sn: e.target.value }))
                } value={newAsset.ass_sn}/>
              </li> 
              <li>
                <p className="front">제조일</p>
                <input className="back"  onChange={(e) =>
                  setNewAsset((prev) => ({ ...prev, ass_date: e.target.value }))
                } value={newAsset.ass_date}/>
              </li>  
              <li>
                <p className="front">제조사</p>
                <input className="back"  onChange={(e) =>
                  setNewAsset((prev) => ({ ...prev, ass_com: e.target.value }))
                } value={newAsset.ass_com}/>
              </li>                
              <li>
                <p className="front">크기</p>
                <input className="back"  onChange={(e) =>
                  setNewAsset((prev) => ({ ...prev, ass_size: e.target.value }))
                } value={newAsset.ass_size}/>
              </li>               
              <li>
                <p className="front">해상도</p>
                <input className="back"  onChange={(e) =>
                  setNewAsset((prev) => ({ ...prev, ass_res: e.target.value }))
                } value={newAsset.ass_res}/>
              </li>                
              <li className="memo">
                <p className="front">비고(위치)</p>
                <textarea className="back" id="input-memo" style={{ resize: "none" }} onChange={(e) =>
                  setNewAsset((prev) => ({ ...prev, ass_memo: e.target.value }))
                } value={newAsset.ass_memo}/>
              </li>                 
              <li className="memo">
                <p className="front">사용자 등록</p>
                <select 
                  className="back insertuser"
                  value={name}   
                  onChange={handleChange}
                  label="사원"
                  style={{height:"40px", width:"80%", border:"1px solid black", marginTop : "5px"}}
                >
                  { nameList && (
                      <>
                          <option key={0} value={0}>&nbsp;사용자 없음</option>
                          {nameList.map((name, idx) => (
                              <option key={idx + 1} value={name.no}>&nbsp;{name.name}&nbsp;<CommonCodeInfoReturn init={name.rank} /></option>
                          ))}
                      </>
                  )}
                </select>
              </li>
             </ul>
          </div> 
          
            : newAsset.ass_cate === "AS003" ?

            <div className="modalbody">
              <ul>
                <li>
                  <p className="front">제품명</p>
                  <input className="back"  onChange={(e) =>
                    setNewAsset((prev) => ({ ...prev, ass_name: e.target.value }))
                  } value={newAsset.ass_name}/>
                </li> 
                <li>
                  <p className="front">시리얼</p>
                  <input className="back"  onChange={(e) =>
                    setNewAsset((prev) => ({ ...prev, ass_sn: e.target.value }))
                  } value={newAsset.ass_sn}/>
                </li> 
                <li>
                  <p className="front">제조일</p>
                  <input className="back"  onChange={(e) =>
                    setNewAsset((prev) => ({ ...prev, ass_date: e.target.value }))
                  } value={newAsset.ass_date}/>
                </li>  
                <li>
                  <p className="front">제조사</p>
                  <input className="back"  onChange={(e) =>
                    setNewAsset((prev) => ({ ...prev, ass_com: e.target.value }))
                  } value={newAsset.ass_com}/>
                </li>                  
                <li className="memo">
                  <p className="front">비고(위치)</p>
                  <textarea className="back" id="input-memo" style={{ resize: "none" }} onChange={(e) =>
                    setNewAsset((prev) => ({ ...prev, ass_memo: e.target.value }))
                  } value={newAsset.ass_memo}/>
                </li>        
                <li className="memo">
                  <p className="front">사용자 등록</p>
                  <select 
                    className="back insertuser"
                    value={name}   
                    onChange={handleChange}
                    label="사원"
                    style={{height:"40px", width:"80%", border:"1px solid black", marginTop : "5px"}}
                  >
                    { nameList && (
                        <>
                            <option key={0} value={0}>&nbsp;사용자 없음</option>
                            {nameList.map((name, idx) => (
                                <option key={idx + 1} value={name.no}>&nbsp;{name.name}&nbsp;<CommonCodeInfoReturn init={name.rank} /></option>
                            ))}
                        </>
                    )}
                  </select>
                </li>         
               </ul>
            </div>  
                :     
                <div className="modalbody">
                  <ul>
                    <li>
                      <p className="front">모델명</p>
                      <input className="back"  onChange={(e) =>
                        setNewAsset((prev) => ({ ...prev, ass_name: e.target.value }))
                      } value={newAsset.ass_name}/>
                    </li> 
                    <li>
                      <p className="front">시리얼</p>
                      <input className="back"  onChange={(e) =>
                        setNewAsset((prev) => ({ ...prev, ass_sn: e.target.value }))
                      } value={newAsset.ass_sn}/>
                    </li> 
                    <li>
                      <p className="front">제조일</p>
                      <input className="back"  onChange={(e) =>
                        setNewAsset((prev) => ({ ...prev, ass_date: e.target.value }))
                      } value={newAsset.ass_date}/>
                    </li>  
                    <li>
                      <p className="front">제조사</p>
                      <input className="back"  onChange={(e) =>
                        setNewAsset((prev) => ({ ...prev, ass_com: e.target.value }))
                      } value={newAsset.ass_com}/>
                    </li>  
                    <li>
                      <p className="front">CPU</p>
                      <input className="back"  onChange={(e) =>
                        setNewAsset((prev) => ({ ...prev, ass_cpu: e.target.value }))
                      } value={newAsset.ass_cpu}/>
                    </li>  
                    <li>
                      <p className="front">RAM</p>
                      <input className="back"  onChange={(e) =>
                        setNewAsset((prev) => ({ ...prev, ass_ram: e.target.value }))
                      } value={newAsset.ass_ram}/>
                    </li>   
                    <li>
                      <p className="front">크기</p>
                      <input className="back"  onChange={(e) =>
                        setNewAsset((prev) => ({ ...prev, ass_size: e.target.value }))
                      } value={newAsset.ass_size}/>
                    </li>  
                    <li>
                      <p className="front">SSD타입</p>
                      <input className="back"  onChange={(e) =>
                        setNewAsset((prev) => ({ ...prev, ass_ssd_type: e.target.value }))
                      } value={newAsset.ass_ssd_type}/>
                    </li>  
                    <li>
                      <p className="front">SSD</p>
                      <input className="back"  onChange={(e) =>
                        setNewAsset((prev) => ({ ...prev, ass_ssd: e.target.value }))
                      } value={newAsset.ass_ssd}/>
                    </li>  
                    <li>
                      <p className="front">HDD</p>
                      <input className="back"  onChange={(e) =>
                        setNewAsset((prev) => ({ ...prev, ass_hdd: e.target.value }))
                      } value={newAsset.ass_hdd}/>
                    </li>  
                    <li>
                      <p className="front">해상도</p>
                      <input className="back"  onChange={(e) =>
                        setNewAsset((prev) => ({ ...prev, ass_res: e.target.value }))
                      } value={newAsset.ass_res}/>
                    </li>  
                    <li>
                      <p className="front">운영체제</p>
                      <input className="back"  onChange={(e) =>
                        setNewAsset((prev) => ({ ...prev, ass_os: e.target.value }))
                      } value={newAsset.ass_os}/>
                    </li>  
                    <li className="memo">
                      <p className="front">비고(위치)</p>
                      <textarea className="back" id="input-memo" style={{ resize: "none" }} onChange={(e) =>
                        setNewAsset((prev) => ({ ...prev, ass_memo: e.target.value }))
                      } value={newAsset.ass_memo}/>
                    </li>                 
                    <li className="memo">
                      <p className="front">사용자 등록</p>
                      <select 
                        className="back insertuser"
                        value={name}   
                        onChange={handleChange}
                        label="사원"
                        style={{height:"40px", width:"80%", border:"1px solid black", marginTop : "5px"}}
                      >
                        { nameList && (
                            <>
                                <option key={0} value={0}>&nbsp;사용자 없음</option>
                                {nameList.map((name, idx) => (
                                    <option key={idx + 1} value={name.no}>&nbsp;{name.name}&nbsp;<CommonCodeInfoReturn init={name.rank} /></option>
                                ))}
                            </>
                        )}
                      </select>
                    </li>
                   </ul>
                </div>            
            }

            
            <div className="modalfooter">
            <SoftButton variant="gradient" color="dark" onClick={handleClickAddButton}>저장</SoftButton>&nbsp;
            <SoftButton variant="gradient" color="dark"  onClick={closeModal2}>
                창 닫기
              </SoftButton>
            </div>
          </div>
        </div>
      </>


    )
}

export default AssetInsertModal;