import { Card, Grid, Tab, Tabs } from '@mui/material'
import { loginUser } from 'atom/member/loginUser'
import { isBefore, startOfDay } from 'date-fns'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useRecoilValue } from 'recoil'
import Swal from 'sweetalert2'
import { deleteReservation, getSelectDateMyReservation } from '../../../api/workPlace/WorkPlace'
import SoftButton from '../../../components/SoftButton'
import SoftTypography from '../../../components/SoftTypography'
import '../workPlace.css'




function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
  }



const MyWorkPlaceInfo = ({selectDate, insertWordPlace, handleUpdate, tabValue, setTabValue}) => {
    const user = useRecoilValue(loginUser);
    const [isBtnDisabled , setIsBtnDisabled] = useState(false);

    const {isLoading, isError, data} = useQuery(['getMyReservation',selectDate], () =>
        getSelectDateMyReservation(selectDate),{
        refetchOnWindowFocus:false
    });

    if(!isLoading){
        console.log(data)
    }


    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    
    useEffect(()=>{
        const today = startOfDay(new Date()); 
        const isBeforeDay = isBefore(selectDate, today);
        if(isBeforeDay) {
            setIsBtnDisabled(true)
        } else {
            setIsBtnDisabled(false)
        }
    },[selectDate])
    
    
    const queryClient = useQueryClient();

    const reservationDelete = useMutation(deleteReservation, {
        onSuccess: (data) =>{
            // if(!data.success){
                // let message = "예약 삭제에 실패했습니다."
                // const resultMap = new Map(Object.entries(data.results))
                // const keys = Array.from(resultMap.keys()).sort()
                // for (const key of keys){
                //     const result = resultMap.get(key)
                //     message = message + "\n" +
                //         key + " : "+resultMessageToKoMap[result]
                // }
                // alert(message)
            // }else{
            //     queryClient.invalidateQueries('reservationInfo')
            // }
            
            queryClient.invalidateQueries('reservationInfo')
            // 상단 버튼 상태 변경
            queryClient.invalidateQueries('getMyReservation')
        },
        onError: (e) =>{
            alert(e.response.data.replaceAll(/.*: /g,""))
        }
    })


    const handleDelete = (data) => {
        Swal.fire({
            title: '삭제 하시겠습니까??',
            icon: 'warning',
            html: `날짜 : ${data.reservationDate} <br/> 근무지 : ${data.region.regName}` ,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            backdrop: false
        }).then((result) => {
            if (result.isConfirmed) {
                const reservationData = {
                    reservationId: data?.reservationId
                }
                reservationDelete.mutate(reservationData)
            }
        });
    }

    
    
    return(
        <>
            <Card sx={{ mt: 2, py: 1, px: 2,  flexDirection: "row", justifyContent:"space-between", alignItems:"center"}}>
                <Grid sx={{minWidth: '40%'}}>
                    {   
                        user.role === 'ADMIN' || user.role === 'MASTER' ?
                            <Tabs value={tabValue} 
                                  onChange={handleChange}  
                                  className="workplace-tabs"
                            >
                                <Tab label={<SoftTypography variant="h4">{isMobile? "현황" : "근무지 현황"}</SoftTypography>} {...a11yProps(0)} />
                                <Tab label={<SoftTypography variant="h4">{isMobile? "통계" : "근무지 통계"}</SoftTypography>} {...a11yProps(1)} />
                            </Tabs>
                        :
                        <>
                            <SoftTypography variant="h4" fontWeight="bold">근무지 현황</SoftTypography>
                        </>
                    }
                </Grid>
                {
                    isLoading?<></>:
                        tabValue === 0 ? 
                        <Grid>
                            {
                                Object.keys(data).length === 0 ?
                                    <SoftButton variant="outlined" color="info" 
                                                onClick={insertWordPlace} disabled={isBtnDisabled}
                                                >등록</SoftButton>:
                                    <>
                                        <SoftButton variant="outlined" color="primary" 
                                                    onClick={()=>handleUpdate(data)} disabled={isBtnDisabled}
                                                    >수정</SoftButton>
                                        <SoftButton variant="outlined" color="dark" 
                                                    onClick={()=>handleDelete(data)} disabled={isBtnDisabled}
                                                    sx={{ml:'0.5rem'}}
                                                    >삭제</SoftButton>
                                    </>
                            }
                        </Grid>
                        : <></>
                }

            </Card>
        </>
    )
}
export default MyWorkPlaceInfo;