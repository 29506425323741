import {useEffect, useState} from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useQuery} from "react-query";
import allCommonCodeList from "../../api/commonCode/allCommonCodeList";





const CommonCodeSelectBox = (props) =>{
	// 저장된 value 값 	, 공통 코드 카테고리 값 
	const { selectedValue , category ,value } = props;
	//const [ value , setValue ] = props;

	let setvalue = "";
	
	// option list
	const options = [];
	// 옵션과 키값 매칭 map
	const inputMap = new Map;
	// 저장되는 입력된 input값
	const [inputValue, setInputValue] = useState("");

	useEffect (() =>{
		setInputValue(selectedValue);
	},[])



	const handleChange = (event) => {
		setInputValue(event.target.value)
		value(event.target.value)
	};

	// react-query전체 공용코드 리스트 불러오기
	const {data, isSuccess, isError, isLoading, isFetching, error} = useQuery("allCommonCodeList", allCommonCodeList, {
		// 데이터 불러오지(fetch) 않는 시간 지정 20분
		staleTime : 120000, 
		refetchOnWindowFocus : false,
		refetchOnMount : false,
		select : (data) => {
			return data.data
		}
	});

	if(isSuccess){
		//option list set
		const commonCode = data;
		for(let i =0; i<commonCode.length; i++){
			if (commonCode[i].cc_category === String(category)){
				// 옵션리스트값
				options.push({ info : commonCode[i].cc_info , name : commonCode[i].cc_name});
				// 추후 옵션값 선택시 가져올 공용코드 값
				inputMap.set(commonCode[i].cc_info,commonCode[i].cc_name);
			}
			if (commonCode[i].cc_name === props.defualtVal){
				// defailt 값
				setvalue = commonCode[i].cc_info;

			}
		}
	}

	return (
		<FormControl sx={{ m: 1, minWidth: 120 }}>
			<Select
				onChange={handleChange}
				displayEmpty
				size="small"
				value={inputValue}
				>
				<MenuItem value="">
					-선택-
				</MenuItem>
				{
					options.map((list , index) => (
						<MenuItem 
						value={list.name}
						key={index}
						>
							{list.info}
						</MenuItem>
					))
				}
			</Select>
		</FormControl>
	);
}



export default CommonCodeSelectBox
