import data from "./data";
import SoftBox from "../../../../../../components/SoftBox";
import Pagination from "@mui/material/Pagination";
import {useRecoilState, useRecoilValue} from "recoil";
import {toMap} from '../../../../../../utils/ToMap';
import {receiveDocTotalCount} from "../../../../../../atom/approval/receive/receiveDocTotalCount";
import {vacationReceiveState} from "../../../../../../atom/approval/receive/vacationReceiveState";
import ReferenceTable from "../../../../../../examples/Tables/ReferenceTable";
import { docReferenceState } from "../../../../../../atom/approval/reference/docReferenceState";
import { referenceDocTotalCount } from "../../../../../../atom/approval/reference/referenceDocTotalCount";

/**
 * 전체 참조함 문서
 * @returns {JSX.Element}
 * @constructor
 */
function AllReferenceDocs () {
    const {columns, rows} = data();

    const [receiveState, setReceiveState ] = useRecoilState(docReferenceState);

    const totalCount = useRecoilValue(referenceDocTotalCount);
    const itemsPerPage = receiveState.docsNum;
    const totalPages =  Math.ceil(totalCount / itemsPerPage);

    // page 변경
    function handlePageChange(event, value) {
        const thisSentMap = toMap({...receiveState});
        thisSentMap.set("nowPage",value);
        setReceiveState(Object.fromEntries(thisSentMap));
    }

    return (
        <>
            <ReferenceTable columns={columns} rows={rows}/>
            <SoftBox display='flex' justifyContent='center'>
                <Pagination
                    count={totalPages}
                    page={receiveState.nowPage}
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton
                />
            </SoftBox>
        </>
    );
}

export default AllReferenceDocs;