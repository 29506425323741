import { Grid, Icon } from '@mui/material';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React, { useState } from 'react'
import Card from "@mui/material/Card";
import { useLocation, useNavigate } from 'react-router-dom';
import ApproverReferrer from 'layouts/vacationInsert/components/ApproverReferrer';
import CostClassification from './components/CostClassification';
import Month from "components/month/Month";
import CostWriterInput from "./components/CostWriterInput";
import DetailCostClassification from './components/DetailCostClassification';
import { useRecoilState } from 'recoil';
import { CostList } from 'atom/cost/CostWriteDetail';
import { myIpAddress } from 'config';
import { customAxios } from "../../axios/CustomAxios";
import Swal from "sweetalert2";

const NewCostInsert = () => {

    // navigate
    const navigate = useNavigate();

    // 하위 컴포넌트에서 아톰패밀리 접근 키값
    const approverKey = "approver_vac";
    const referrerKey = "referrer_vac";
    // 구분별 참조문구 띄우기
    const [showExplanation, setShowExplanation] = useState(false)
    const [explanation, setExplanation] = useState("")

    //날짜 받기
    const [date, setDate] = useState("");
    const { state } = useLocation();
    //전체 정보
    const [costList, setCostList] = useRecoilState(CostList)

    //목록버튼
    const mainbutton = () => {
        navigate('/cost')
    }
    //임시저장버튼
    const costTempSave = () => {
        customAxios.post(myIpAddress + '/cost/temporary', costList, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                Swal.fire({
                    icon: 'success',
                    title: '입력 완료!',
                    backdrop: false,
                })
                navigate('/cost')
            })
    }


    const costSave1 = () => {
        const errors = [];

        if (costList.cost_category === "") {
            errors.push("구분");
        }

        if (costList.detail_otherList !== undefined) {
            for (let i = 0; i < costList.detail_otherList.length; i++) {
                if (costList.detail_otherList[i].other_category === "") {
                    errors.push("용도");
                }
                if (costList.detail_otherList[i].other_contents === "") {
                    errors.push("내용");
                }
                if (costList.detail_otherList[i].other_personnel === "") {
                    errors.push("인원");
                }
                if (costList.detail_otherList[i].other_file === "") {
                    errors.push("영수증");
                }
                if (costList.detail_otherList[i].other_amount === "") {
                    errors.push("금액");
                }
                if (costList.detail_otherList[i].other_paytype === "") {
                    errors.push("결제수단");
                }
            }
        }

        if (costList.detail_trafficList !== undefined) {
            for (let i = 0; i < costList.detail_trafficList.length; i++) {
                if (costList.detail_trafficList[i].traffic_purpose === "") {
                    errors.push("방문목적");
                }
                if (costList.detail_trafficList[i].traffic_method === "") {
                    errors.push("교통수단");
                }
                if (costList.detail_trafficList[i].traffic_location === "") {
                    errors.push("방문장소");
                }
                if (costList.detail_trafficList[i].traffic_way === "") {
                    errors.push("편도/왕복");
                }
                if (costList.detail_trafficList[i].traffic_file === "") {
                    errors.push("영수증");
                }
                if (costList.detail_trafficList[i].traffic_amount === "") {
                    errors.push("금액");
                }
            }
        }
        if (costList.fuelList !== undefined) {
            for (let i = 0; i < costList.fuelList.length; i++) {
                if (costList.fuelList[i].fuel_amount === "") {
                    errors.push("금액");
                } else if (costList.fuelList[i].fuel_carnum === "") {
                    errors.push("차량번호");
                } else if (costList.fuelList[i].fuel_category === "") {
                    errors.push("운행용도");
                } else if (costList.fuelList[i].fuel_distance === "") {
                    errors.push("운행거리");
                } else if (costList.fuelList[i].fuel_end === "") {
                    errors.push("도착지");
                } else if (costList.fuelList[i].fuel_paytype === "") {
                    errors.push("결제수단");
                } else if (costList.fuelList[i].fuel_start === "") {
                    errors.push("출발지");
                } else if (costList.fuelList[i].fuel_file === "") {
                    errors.push("영수증");
                }
            }
        };
        if (errors.length > 0) {
            Swal.fire({
                title: '저장 실패',
                text: `${errors.join(", ")}을(를) 입력해주세요.`,
                icon: 'error',
                backdrop: false
            });
        } else {
            Swal.fire({
                title: '상신 하시겠습니까??',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: '상신',
                backdrop: false
            }).then((result) => {
                if (result.isConfirmed) {
                    customAxios.post(myIpAddress + '/cost/save', costList, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                        .then((response) => {
                            Swal.fire({
                                icon: 'success',
                                title: '입력 완료!',
                                backdrop: false,
                            })
                            navigate('/cost')
                        });
                }
            })


        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {showExplanation && (
                        <Card sx={{ height: "100%" }}>
                            <Grid container p={2}>
                                <SoftTypography variant="h6" textTransform="capitalize" pl={2} dangerouslySetInnerHTML={{ __html: explanation }}>
                                </SoftTypography>
                            </Grid>
                        </Card>
                    )}
                </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                    <Card sx={{ height: "100%" }}>
                        <SoftBox mt={1} mb={1} p={1} display="flex" justifyContent="space-between" alignItems="center">
                            <SoftTypography variant="h4" fontWeight="medium" sx={{ fontWeight: 'bold' }}>
                                비용처리 결재 문서
                            </SoftTypography>
                            <SoftButton variant="gradient" color="dark" onClick={mainbutton}>
                                <Icon sx={{ fontWeight: "bold" }}>arrow_back</Icon>
                                &nbsp;뒤로가기
                            </SoftButton>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
            <SoftBox mb={2}>
                <ApproverReferrer approverKey={approverKey} referrerKey={referrerKey} />
            </SoftBox>
            <Grid container spacing={3} sx={{ mb: 2.5 }}>
                <Grid item xs={12} sm={5} md={5} xl={5}>
                    <Card sx={{ height: "100%" }}>
                        <SoftBox mb={3} px={1} pt={3} display="flex" justifyContent="space-between" alignItems="center">
                            <Grid container>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h5" fontWeight="bold" textTransform="capitalize" pl={2} >
                                        작성자
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12}>
                                    <CostWriterInput size="lg" />
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={7} md={7} xl={7}>
                    <Card sx={{ height: "100%", zIndex: 0 }}>
                        <SoftBox px={1} pt={3.5} display="flex" justifyContent="space-between" alignItems="center">
                            <Grid container>
                                <Grid item xs={12}>
                                    <SoftBox mb={3}>
                                        <Grid container>
                                            <Grid item xs={12} sm={3} lg={2} >
                                                <SoftTypography variant="h5" fontWeight="bold" textTransform="capitalize" pl={2} >
                                                    작성일
                                                </SoftTypography>
                                            </Grid>
                                            <Grid item xs={12} sm={9} lg={10}>
                                                <Month name="DATE" num='10' type='date' setDate={setDate} temp={date} readOnly={true}></Month>
                                            </Grid>
                                        </Grid>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={3} lg={2}>
                                    <SoftTypography variant="h5" fontWeight="bold" textTransform="capitalize" pl={2} mt={1}>
                                        구분
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={9} lg={10}>
                                    <CostClassification update={state} setShowModal={setShowExplanation} setModalMent={setExplanation} />
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card sx={{ height: "100%" }}>
                        <Grid container p={2}>
                            <DetailCostClassification />
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <SoftBox>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <button className="w-btn-outline w-btn-blue-outline" type="button" onClick={costTempSave}>임시저장</button>
                            </Grid>
                            <Grid item ml={2}>
                                <button className="w-btn-outline w-btn-blue-outline" type="button" onClick={costSave1}>문서상신</button>
                            </Grid>
                        </Grid>
                    </SoftBox>
                </Grid>
            </Grid>
            <Footer />
        </DashboardLayout >
    )
}

export default NewCostInsert