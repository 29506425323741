import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {useEffect, useMemo, useState} from "react";
import {nameCompleteInputValueAtomFamily} from "../../../../../atom/approval/approver_referrer/nameCompleteInputValue";
import {
    nameCompleteInputStatusAtomFamily
} from "../../../../../atom/approval/approver_referrer/nameCompleteInputStatusAtomFamily";
import cloneDeep from "lodash.clonedeep";
import {toMap} from "../../../../../utils/ToMap";
import {useQueries} from "react-query";
import userWorkInfo from "../../../../../api/user/userWorkInfo";
import {referrerInsertAtomFamily} from "../../../../../atom/approval/approver_referrer/referrerInsertAtomFamily";
import allCommonCodeMap from "../../../../../api/commonCode/allCommonCodeMap";
import {Autocomplete, TextField} from "@mui/material";
import {loginUser} from "../../../../../atom/member/loginUser";

/**
 * 결재라인 순서와  키이름을 받아서 해당하는 참조자 정보 전달
 * @param num : int 참조자순서
 * @param keyName : String 컴포넌트 식별을 위한 키 이름
 * @param defaultValue : String 기본값
 * @constructor
 */
function Referrer({num, keyName, defaultValue}) {
    // 로그인 리코일
    const user = useRecoilValue(loginUser);
    // 리코일 식별 키값
    const key = keyName+"_"+num
    // 팀코드
    const parentsTeamCode = user.team;
    // userInfo
    const [userInfo, setUserInfo] = useState();
    // common code map
    const [codeMap, setCodeMap] = useState({});
    // query 실행여부
    const [queryStart, setQueryStart] = useState(false);
    // option list
    const options = [];
    // 팀장급이상만 호출 여부
    const rankCutBool = false;
    // 옵션과 키값 매칭 map
    const inputMap = new Map();
    // 이름과 유저번호 map
    const nameUserMap = new Map();

    // 저장되는 입력된 input값
    const [inputValue, setInputValue] = useState('');

    // recoil insert값 set
    const [referrerInfo,setReferrerInfo] = useRecoilState(referrerInsertAtomFamily(keyName));

    // recoil family - 참조자 정보 호출
    const [referrerValue, setReferrerValue] = useRecoilState(nameCompleteInputValueAtomFamily(key));

    // 참조자 상태
    const [status, setStatus] = useRecoilState(nameCompleteInputStatusAtomFamily(key));

    // 추가된 결재자 저장하는 리코일 리셋
    const referrerReset = useResetRecoilState(nameCompleteInputValueAtomFamily(key));

    // 카테고리
    const category = referrerValue.category;

    // 기본 값 세팅
    useEffect(() => {
        if (defaultValue !== undefined) {
            setInputValue(defaultValue);
            setStatus(false);
        }
    }, []);

    // 쿼리 실행 후
    useEffect(()=>{
        if(queryStart){
            if (defaultValue !== undefined) {
                setInputValue(defaultValue);
                setStatus(false);
            } else {
                setInputValue("["+codeMap[parentsTeamCode]?.info+"]");
            }
        }
    },[queryStart]);

    // 참조자 set
    useEffect(() => {
        // 임시저장 리스트
        let saveList = [];
        // 합산 리스트
        let concat;
        // 참조자 번호
        if (referrerValue?.NO !== '' && defaultValue === undefined) {
            // 저장된 참조자 리스트
            const referrerList = cloneDeep(referrerInfo)?.referrerList;
            // 변경가능한 참조자 리스트
            const insert = toMap({...referrerInfo});
            // 저장용 맵
            const referrerInfoMap = new Map();
            for (let i = 0; i < userInfo?.length; i++) {
                // 참조자 번호와 유저 번호와 맞는 정보 가져오기
                if (userInfo[i]?.memNo === referrerValue?.NO) {
                    referrerInfoMap.set("referrer_key", key);
                    referrerInfoMap.set("ref_mem_no", userInfo[i]?.memNo);
                    referrerInfoMap.set("ref_rank", userInfo[i]?.memRank);
                    saveList.push(Object.fromEntries(referrerInfoMap));
                    break;
                }
            }
            // 1번 이상 실행시 이전데이터와 합산
            if (referrerList !== null) {
                // 중복된 인덱스 리스트
                let duplicateIndexList = []
                // 중복된 인덱스 push
                for(let i = 0; i<referrerList?.length; i++){
                    if(referrerList[i]?.referrer_key === key){
                        duplicateIndexList.push(i);
                    }
                }
                // 중복된 인덱스 자르기
                for(let i in duplicateIndexList){
                    referrerList.splice(i,1);
                }
                // 합산
                concat = referrerList.concat(saveList);
                insert.set("referrerList", concat);
            }
            // 1번째 실행시
            if(referrerList === null){
                insert.set("referrerList", saveList);
            }
            // 저장
            setReferrerInfo(Object.fromEntries(insert));
            referrerReset();
        } else if (referrerValue?.NO !== '') {
            // 저장된 참조자 리스트
            const referrerList = cloneDeep(referrerInfo)?.referrerList;
            // 현재 선택된 결재자와 이전 결재자와 다를때
            if(String(referrerList[num-1].approver_mem_no) !== referrerValue.NO) {
                // 변경가능한 참조자 리스트
                const insert = toMap({...referrerInfo});
                // 저장용 맵
                const referrerInfoMap = new Map();
                for (let i = 0; i < userInfo?.length; i++) {
                    // 참조자 번호와 유저 번호와 맞는 정보 가져오기
                    if (userInfo[i]?.memNo === referrerValue?.NO) {
                        referrerInfoMap.set("referrer_key", key);
                        referrerInfoMap.set("ref_mem_no", userInfo[i]?.memNo);
                        referrerInfoMap.set("ref_rank", userInfo[i]?.memRank);
                        saveList.push(Object.fromEntries(referrerInfoMap));
                        break;
                    }
                }
                referrerList[num-1] = saveList[0];
                insert.set("referrerList", referrerList);
                setReferrerInfo(Object.fromEntries(insert));
                referrerReset();
            }
        }
    }, [referrerValue])

    // react-query
    const queries = useQueries([
        // queries[0] : 유저 리스트
        {
            queryKey: ["userWorkInfo"],
            queryFn: () => userWorkInfo(),
            onSuccess: (data) => {
                setUserInfo(data.data);
            }
        },
        // queries[1] : 공용코드 리스트
        {
            queryKey: ["allCommonCodeMap"],
            queryFn: () => allCommonCodeMap(),
            onSuccess: (data) => {
                setCodeMap(data.data);
                setQueryStart(true);
            }
        }

    ]);

    // option 값 세팅
    useMemo(() => {
        for (let i = 0; i < userInfo?.length; i++) {
            const thisUserName = userInfo[i]?.memName; /* 이름 */
            const thisUserRank = userInfo[i]?.memRank; /* 직급 */
            const thisUserTeam = userInfo[i]?.memTeam; /* 부서 */
            // // 팀장 이상급만 리턴하는 정규식
            // const rankCut = /^R00[4-9]/;
            // try {
            //     if (rankCut.test(thisUserRank) && rankCutBool) {
            //         const optionVal = "[" + (codeMap[thisUserTeam]?.info) + "]"
            //             + thisUserName + " " + (codeMap[thisUserRank]?.info)
            //         // optionList set
            //         options.push(optionVal);
            //
            //         // 추후 옵션값 선택시 가져올 이름 값 및 번호
            //         inputMap.set(optionVal, true);
            //         nameUserMap.set(optionVal, userInfo[i]?.memNo);
            //     }
            // } catch (err) {
            //     console.log(err);
            // }

            // 모든 사원 호출
            if (rankCutBool === false) {
                try {
                    if (codeMap[thisUserTeam]?.info !== undefined && codeMap[thisUserRank]?.info !== undefined && userInfo[i]?.role !== "GUEST") {
                        const optionVal = "[" + (codeMap[thisUserTeam]?.info) + "]" + thisUserName + " " + (codeMap[thisUserRank]?.info)
                        // optionList set
                        options.push(optionVal);

                        // 추후 옵션값 선택시 가져올 이름 값 및 번호
                        inputMap.set(optionVal, true);
                        nameUserMap.set(optionVal, userInfo[i].memNo);
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        }
    }, [queries])

    // input값이 변경될때 실행되는 Effect -> 부모로 파라메터 전달
    useEffect(() => {
        // 선택한 인풋값의 공용코드
        if (inputValue !== "" || inputValue !== null) {
            if (inputMap.get(inputValue)) {
                // 입력한 값과 옵션값이 맞는지 체크
                setStatus(false);
                // 공용코드 recoil set
                const saveInputVal = toMap(referrerValue);
                saveInputVal.set(category, nameUserMap.get(inputValue));
                setReferrerValue(Object.fromEntries(saveInputVal));
            } else {
                setStatus(true);
            }
        }
    }, [inputValue]);

    return (
        <Autocomplete
            renderInput={(params) => (
                <
                    TextField
                    {...params}
                    label={status ? '옳바른값을 선택해주세요' : ''}
                    InputProps={{
                        ...params.InputProps,
                        style: { marginTop: '10px' }
                    }}
                />
            )}
            // query에서 가져온 해당하는 카테고리의 리스트 옵션값으로 넣어주기
            options={options}
            value={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
        >
        </Autocomplete>
    );
}

export default Referrer;
