import { selectValueAtomFamily } from 'atom/selectBox/selectBox';
import { customAxios } from '../../axios/CustomAxios';
import { myIpAddress } from 'config';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import AS000 from '../../assets/images/AS000.png';
import AS001 from '../../assets/images/AS001.png';
import AS002 from '../../assets/images/AS002.png';
import AS003 from '../../assets/images/AS003.png';
import './AssetInfoModal.css';
import Swal from 'sweetalert2';
import listUpdate from 'atom/data/listUpdate';
import SoftButton from 'components/SoftButton';
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import CommonCodeInfoReturn from 'components/commonCode/CommonCodeInfoReturn';
import { useQuery } from 'react-query';
import userAll from 'api/user/userAll';
import SoftInput from 'components/SoftInput';
import AssetInsertModal from './AssetInsertModal';
import allCommonCodeList from 'api/commonCode/allCommonCodeList';

function AssetInfoModal ({modalChange, ass_no}) {

    const key = "mem_no"; 
    const [mem_no] = useRecoilState(selectValueAtomFamily(key));

    const [datas, setDatas] = useState({});  
    const [isChange, setIsChange] = useRecoilState(listUpdate);
    // 수정 
    const [isUpdate, setIsUpdate] = useState(false);

    // 수정 시 보낼 데이터
    const [newAsset, setNewAsset] = useState({     
      mem_no : mem_no, 
      ass_no : ass_no,
      ass_cate : "", 
      ass_name : "", 
      ass_date : "", 
      ass_com : "", 
      ass_sn : "", 
      ass_cpu : "", 
      ass_ram : "", 
      ass_size : "", 
      ass_ssd_type : "",
      ass_ssd : "", 
      ass_hdd : "", 
      ass_res : "", 
      ass_os : "", 
      ass_memo : "" 
    })
  

    const [cateList, setCateList] = useState([]);
    const {data:assetData, isSuccess:isAssetSuccess} = useQuery('allCommonCodeList',allCommonCodeList,{      
      staleTime : 120000,
      refetchOnWindowFocus : false,
      refetchOnMount : false,
      select : (data) => {
          return data.data
      }
    })
    useEffect(() => {
      if (isAssetSuccess) {
        const assetCateList = assetData
        .filter(item => item.cc_category === 'ASSET')
        .map(item => ({ cate: item.cc_name, info: item.cc_info }));
        setCateList(assetCateList);
      }
    }, [isAssetSuccess, assetData]);
      


    const closeModal = () => {
      modalChange.current.style = "display:none;";
      document.querySelector('.modal-overlay').style.zIndex = "-1";
      // 같은 자산 클릭 시 사용자가 리셋되는 현상이 있어서 주석 처리 
      // setName(0);
      // setNameForRecoil(0);
      setIsUpdate(false)
    };    


    useEffect(() => {
      customAxios.get(myIpAddress + `/asset/${ass_no}`)
        .then(response => {
          setDatas(response.data)
          setName(response.data.mem_no);
          setNewAsset(response.data)
        })
        .catch(error => {
        console.log(error)
        }) 
    },[ass_no, isChange])



    // 모달 바깥쪽 클릭 시 모달 닫기
    const handleModalClick = (e) => {
      if (e.target === modalChange.current) {
        closeModal();
      }
    };
  
    const handleModalContentClick = (e) => {     
      e.stopPropagation();
    };

    const [name, setName] = useState(0);
    const [nameForRecoil, setNameForRecoil] = useRecoilState(selectValueAtomFamily(key));
   
    const [nameList, setNameList] = useState([]);    
    
    const {data, isSuccess} = useQuery('userAll',userAll,{
        select : (data)=>{
            return data.data
        }
    })
  
    const handleChange = (event) => {    
        setName(event.target.value);            
        setNameForRecoil(event.target.value);
        setNewAsset((prev) => ({ ...prev, mem_no: event.target.value }))
      };

   
    const nameArr = [...nameList]

    
    useEffect(()=>{
        if(isSuccess){
            for(let i=0;i<data.length;i++){
              let nar = {'name':data[i].memName,'no':data[i].memNo, 'rank':data[i].memRank}
              nameArr.push(nar)
              setNameList(nameArr)
          }
        }
    },[isSuccess])


    const handleSave = () => {
      customAxios.post(myIpAddress+'/asset/update',
        newAsset
      ).then(()=>{
        Swal.fire({
          icon : 'success',
          title : '수정 완료',
          backdrop: false,
        })     
        setIsChange(true);
        setIsUpdate(false);        
      })
    }

    

    const handleUpdate = () => {
      setIsUpdate(!isUpdate)
    }

    const handleDelete = () => {
      Swal.fire({
        title: '해당 자산을 삭제할까요?',
        text:"삭제한 자산 정보는 다시 불러올 수 없습니다.",      
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '삭제',
        backdrop:false         
      }).then((result) => {
        if (result.isConfirmed) {
          customAxios.post(myIpAddress+'/asset/delete',{
            ass_no
          }).then(()=>{
            setIsChange(true);
          })
          Swal.fire({
            title:'삭제 성공',
            text:'해당 자산 정보가 삭제되었습니다.',          
            icon:'success',
            backdrop:false
          })    
            closeModal();     
          }     
        })     
    }


    return(
        <>
          <div className="modal-overlay" onClick={closeModal}>
            <div id="my-modal" className="modal" ref={modalChange} onClick={handleModalClick}>
            <div className="modalcontent-info" onClick={handleModalContentClick}>
            {
              isUpdate ? 
                <>
                <div className="modalheader" style={{marginTop: "20px",marginBottom: "20px"}}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label" style={{marginTop : "15px"}}>물품 분류</FormLabel>
                    {
                        cateList.length > 0 && 
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={newAsset.ass_cate}
                            style={{marginTop:"20px"}}
                            onChange={(e) =>
                            setNewAsset((prev) => ({ ...prev, ass_cate: e.target.value }))} 
                        >
                            {cateList.map((category, idx) => (
                            <FormControlLabel key={idx} value={category.cate} control={<Radio />} label={category.info} 
                                onChange={(e) =>
                                setNewAsset((prev) => ({ ...prev, ass_cate: e.target.value }))}
                                style={{marginRight:"30px"}}/>
                            ))}                   
                        </RadioGroup>
                    }
                  </FormControl>       
                </div>       
                  {newAsset.ass_cate === "AS001" ?            
                    <div className="modalbody-info">
                      <ul>
                        <li>
                          <p className="front">모델명</p>
                          <input className="back"  onChange={(e) =>
                            setNewAsset((prev) => ({ ...prev, ass_name: e.target.value }))
                          } value={newAsset.ass_name}/>
                        </li>                 
                        <li>
                          <p className="front">제조일</p>
                          <input className="back"  onChange={(e) =>
                            setNewAsset((prev) => ({ ...prev, ass_date: e.target.value }))
                          } value={newAsset.ass_date}/>
                        </li>  
                        <li>
                          <p className="front">제조사</p>
                          <input className="back"  onChange={(e) =>
                            setNewAsset((prev) => ({ ...prev, ass_com: e.target.value }))
                          } value={newAsset.ass_com}/>
                        </li>  
                        <li>
                          <p className="front">CPU</p>
                          <input className="back"  onChange={(e) =>
                            setNewAsset((prev) => ({ ...prev, ass_cpu: e.target.value }))
                          } value={newAsset.ass_cpu}/>
                        </li>  
                        <li>
                          <p className="front">RAM</p>
                          <input className="back"  onChange={(e) =>
                            setNewAsset((prev) => ({ ...prev, ass_ram: e.target.value }))
                          } value={newAsset.ass_ram}/>
                        </li>                   
                        <li>
                          <p className="front">SSD타입</p>
                          <input className="back"  onChange={(e) =>
                            setNewAsset((prev) => ({ ...prev, ass_ssd_type: e.target.value }))
                          } value={newAsset.ass_ssd_type}/>
                        </li>  
                        <li>
                          <p className="front">SSD</p>
                          <input className="back"  onChange={(e) =>
                            setNewAsset((prev) => ({ ...prev, ass_ssd: e.target.value }))
                          } value={newAsset.ass_ssd}/>
                        </li>  
                        <li>
                          <p className="front">HDD</p>
                          <input className="back"  onChange={(e) =>
                            setNewAsset((prev) => ({ ...prev, ass_hdd: e.target.value }))
                          } value={newAsset.ass_hdd}/>
                        </li>            
                        <li>
                          <p className="front">운영체제</p>
                          <input className="back"  onChange={(e) =>
                            setNewAsset((prev) => ({ ...prev, ass_os: e.target.value }))
                          } value={newAsset.ass_os}/>
                        </li>  
                        <li></li>
                        <li className="memo">
                          <p className="front">비고(위치)</p>
                          <textarea className="back" id="input-memo" style={{ resize: "none" }} onChange={(e) =>
                            setNewAsset((prev) => ({ ...prev, ass_memo: e.target.value }))
                          } value={newAsset.ass_memo}/>
                        </li>                 
                      </ul>
                    </div>    

                    : newAsset.ass_cate === "AS002" ? 

                    <div className="modalbody-info">
                    <ul>
                      <li>
                        <p className="front">모델명</p>
                        <input className="back"  onChange={(e) =>
                          setNewAsset((prev) => ({ ...prev, ass_name: e.target.value }))
                        } value={newAsset.ass_name}/>
                      </li> 
                      <li>
                        <p className="front">시리얼</p>
                        <input className="back"  onChange={(e) =>
                          setNewAsset((prev) => ({ ...prev, ass_sn: e.target.value }))
                        } value={newAsset.ass_sn}/>
                      </li> 
                      <li>
                        <p className="front">제조일</p>
                        <input className="back"  onChange={(e) =>
                          setNewAsset((prev) => ({ ...prev, ass_date: e.target.value }))
                        } value={newAsset.ass_date}/>
                      </li>  
                      <li>
                        <p className="front">제조사</p>
                        <input className="back"  onChange={(e) =>
                          setNewAsset((prev) => ({ ...prev, ass_com: e.target.value }))
                        } value={newAsset.ass_com}/>
                      </li>                
                      <li>
                        <p className="front">크기</p>
                        <input className="back"  onChange={(e) =>
                          setNewAsset((prev) => ({ ...prev, ass_size: e.target.value }))
                        } value={newAsset.ass_size}/>
                      </li>               
                      <li>
                        <p className="front">해상도</p>
                        <input className="back"  onChange={(e) =>
                          setNewAsset((prev) => ({ ...prev, ass_res: e.target.value }))
                        } value={newAsset.ass_res}/>
                      </li>                
                      <li className="memo">
                        <p className="front">비고(위치)</p>
                        <textarea className="back" id="input-memo" style={{ resize: "none" }} onChange={(e) =>
                          setNewAsset((prev) => ({ ...prev, ass_memo: e.target.value }))
                        } value={newAsset.ass_memo}/>
                      </li>                 
                    </ul>
                    </div> 

                    : newAsset.ass_cate === "AS003" ?

                    <div className="modalbody-info">
                      <ul>
                        <li>
                          <p className="front">제품명</p>
                          <input className="back"  onChange={(e) =>
                            setNewAsset((prev) => ({ ...prev, ass_name: e.target.value }))
                          } value={newAsset.ass_name}/>
                        </li> 
                        <li>
                          <p className="front">시리얼</p>
                          <input className="back"  onChange={(e) =>
                            setNewAsset((prev) => ({ ...prev, ass_sn: e.target.value }))
                          } value={newAsset.ass_sn}/>
                        </li> 
                        <li>
                          <p className="front">제조일</p>
                          <input className="back"  onChange={(e) =>
                            setNewAsset((prev) => ({ ...prev, ass_date: e.target.value }))
                          } value={newAsset.ass_date}/>
                        </li>  
                        <li>
                          <p className="front">제조사</p>
                          <input className="back"  onChange={(e) =>
                            setNewAsset((prev) => ({ ...prev, ass_com: e.target.value }))
                          } value={newAsset.ass_com}/>
                        </li>                  
                        <li className="memo">
                          <p className="front">비고(위치)</p>
                          <textarea className="back" id="input-memo" style={{ resize: "none" }} onChange={(e) =>
                            setNewAsset((prev) => ({ ...prev, ass_memo: e.target.value }))
                          } value={newAsset.ass_memo}/>
                        </li>                 
                      </ul>
                    </div>  
                        :     
                        <div className="modalbody-info">
                          <ul>
                            <li>
                              <p className="front">모델명</p>
                              <input className="back"  onChange={(e) =>
                                setNewAsset((prev) => ({ ...prev, ass_name: e.target.value }))
                              } value={newAsset.ass_name}/>
                            </li> 
                            <li>
                              <p className="front">시리얼</p>
                              <input className="back"  onChange={(e) =>
                                setNewAsset((prev) => ({ ...prev, ass_sn: e.target.value }))
                              } value={newAsset.ass_sn}/>
                            </li> 
                            <li>
                              <p className="front">제조일</p>
                              <input className="back"  onChange={(e) =>
                                setNewAsset((prev) => ({ ...prev, ass_date: e.target.value }))
                              } value={newAsset.ass_date}/>
                            </li>  
                            <li>
                              <p className="front">제조사</p>
                              <input className="back"  onChange={(e) =>
                                setNewAsset((prev) => ({ ...prev, ass_com: e.target.value }))
                              } value={newAsset.ass_com}/>
                            </li>  
                            <li>
                              <p className="front">CPU</p>
                              <input className="back"  onChange={(e) =>
                                setNewAsset((prev) => ({ ...prev, ass_cpu: e.target.value }))
                              } value={newAsset.ass_cpu}/>
                            </li>  
                            <li>
                              <p className="front">RAM</p>
                              <input className="back"  onChange={(e) =>
                                setNewAsset((prev) => ({ ...prev, ass_ram: e.target.value }))
                              } value={newAsset.ass_ram}/>
                            </li>   
                            <li>
                              <p className="front">크기</p>
                              <input className="back"  onChange={(e) =>
                                setNewAsset((prev) => ({ ...prev, ass_size: e.target.value }))
                              } value={newAsset.ass_size}/>
                            </li>  
                            <li>
                              <p className="front">SSD타입</p>
                              <input className="back"  onChange={(e) =>
                                setNewAsset((prev) => ({ ...prev, ass_ssd_type: e.target.value }))
                              } value={newAsset.ass_ssd_type}/>
                            </li>  
                            <li>
                              <p className="front">SSD</p>
                              <input className="back"  onChange={(e) =>
                                setNewAsset((prev) => ({ ...prev, ass_ssd: e.target.value }))
                              } value={newAsset.ass_ssd}/>
                            </li>  
                            <li>
                              <p className="front">HDD</p>
                              <input className="back"  onChange={(e) =>
                                setNewAsset((prev) => ({ ...prev, ass_hdd: e.target.value }))
                              } value={newAsset.ass_hdd}/>
                            </li>  
                            <li>
                              <p className="front">해상도</p>
                              <input className="back"  onChange={(e) =>
                                setNewAsset((prev) => ({ ...prev, ass_res: e.target.value }))
                              } value={newAsset.ass_res}/>
                            </li>  
                            <li>
                              <p className="front">운영체제</p>
                              <input className="back"  onChange={(e) =>
                                setNewAsset((prev) => ({ ...prev, ass_os: e.target.value }))
                              } value={newAsset.ass_os}/>
                            </li>  
                            <li className="memo">
                              <p className="front">비고(위치)</p>
                              <textarea className="back" id="input-memo" style={{ resize: "none" }} onChange={(e) =>
                                setNewAsset((prev) => ({ ...prev, ass_memo: e.target.value }))
                              } value={newAsset.ass_memo}/>
                            </li>                 
                          </ul>
                        </div>            
                    }
                </>
              :
                <>
                <div className="modalheader">
                  {datas.ass_cate === "AS002" ? <img src={AS002} alt={"img" + datas.ass_no} /> : 
                    datas.ass_cate === "AS000" ? <img src={AS000} alt={"img" + datas.ass_no} /> :
                    datas.ass_cate === "AS001" ? <img src={AS001} alt={"img" + datas.ass_no} /> : 
                    <img src={AS003} alt={"img" + datas.ass_no} /> }
                </div>
                  {datas.ass_cate === "AS000" ? 
                    <div className="modalbody-info">
                      <ul>
                        <li>
                          <p className="front">모델명</p>
                          <p className="back">{datas.ass_name}</p>
                        </li>
                        <li>
                          <p className="front">현재 사용자</p>
                          <p className="back" >{datas.mem_name}</p>
                        </li>
                        <li>
                          <p className="front">제조일</p>
                          <p className="back">{datas.ass_date}</p>
                        </li>
                        <li>
                          <p className="front">제조사</p>
                          <p className="back">{datas.ass_com}</p>
                        </li>
                        <li>
                          <p className="front">시리얼넘버</p>
                          <p className="back">{datas.ass_sn}</p>
                        </li>              
                        <li>
                          <p className="front">CPU</p>
                          <p className="back">{datas.ass_cpu}</p>
                        </li>
                        <li>
                          <p className="front">RAM</p>
                          <p className="back">{datas.ass_ram}</p>
                        </li>
                        
                        <li>
                          <p className="front">SIZE</p>
                          <p className="back">{datas.ass_size}</p>
                        </li>
                        <li>
                          <p className="front">SSD 타입</p>
                          <p className="back">{datas.ass_ssd_type}</p>
                        </li>
                        <li>
                          <p className="front">SSD</p>
                          <p className="back">{datas.ass_ssd}</p>
                        </li>
                        <li>
                          <p className="front">HDD</p>
                          <p className="back">{datas.ass_hdd}</p>
                        </li>
                        <li>
                          <p className="front">해상도</p>
                          <p className="back">{datas.ass_res}</p>
                        </li>
                        <li>
                          <p className="front">운영체제</p>
                          <p className="back">{datas.ass_os}</p>
                        </li>
                        <li>
                          <p className="front">비고(위치)</p>
                          <p className="back">{datas.ass_memo}</p>
                        </li>
                      </ul>
                      </div>
                        : datas.ass_cate === "AS001" ? 
                      <div className="modalbody-info">
                        <ul>
                          <li>
                            <p className="front">모델명</p>
                            <p className="back" >{datas.ass_name}</p>
                          </li>
                          <li>
                            <p className="front">현재 사용자</p>
                            <p className="back" >{datas.mem_name}</p>
                          </li>
                          <li>
                            <p className="front">제조일</p>
                            <p className="back">{datas.ass_date}</p>
                          </li>
                          <li>
                            <p className="front">제조사</p>
                            <p className="back">{datas.ass_com}</p>
                          </li>                             
                          <li>
                            <p className="front">CPU</p>
                            <p className="back">{datas.ass_cpu}</p>
                          </li>
                          <li>
                            <p className="front">RAM</p>
                            <p className="back">{datas.ass_ram}</p>
                          </li>
                          <li>
                            <p className="front">SSD 타입</p>
                            <p className="back">{datas.ass_ssd_type}</p>
                          </li>
                          <li>
                            <p className="front">SSD</p>
                            <p className="back">{datas.ass_ssd}</p>
                          </li>
                          <li>
                            <p className="front">HDD</p>
                            <p className="back">{datas.ass_hdd}</p>
                          </li>
                          <li>
                            <p className="front">해상도</p>
                            <p className="back">{datas.ass_res}</p>
                          </li>
                          <li>
                            <p className="front">운영체제</p>
                            <p className="back">{datas.ass_os}</p>
                          </li>
                          <li>
                            <p className="front">비고(위치)</p>
                            <p className="back">{datas.ass_memo}</p>
                          </li>
                        </ul>
                      </div> 
                      : 
                        datas.ass_cate === "AS002" ? 
                        <div className="modalbody-info">
                          <ul>
                            <li>
                              <p className="front">모델명</p>
                              <p className="back" >{datas.ass_name}</p>
                            </li>
                            <li>
                              <p className="front">현재 사용자</p>
                              <p className="back" >{datas.mem_name}</p>
                            </li>
                            <li>
                              <p className="front">제조일</p>
                              <p className="back">{datas.ass_date}</p>
                            </li>
                            <li>
                              <p className="front">제조사</p>
                              <p className="back">{datas.ass_com}</p>
                            </li>
                            <li>
                              <p className="front">시리얼넘버</p>
                              <p className="back">{datas.ass_sn}</p>
                            </li> 
                            <li>
                              <p className="front">크기</p>
                              <p className="back">{datas.ass_size}</p>
                            </li>                
                            <li>
                              <p className="front">해상도</p>
                              <p className="back">{datas.ass_res}</p>
                            </li>               
                            <li>
                              <p className="front">비고(위치)</p>
                              <p className="back">{datas.ass_memo}</p>
                            </li>
                          </ul>
                        </div> 
                      :
                      <div className="modalbody-info">
                      <ul>
                        <li>
                          <p className="front">제품명</p>
                          <p className="back" >{datas.ass_name}</p>
                        </li>
                        <li>
                          <p className="front">현재 사용자</p>
                          <p className="back" >{datas.mem_name}</p>
                        </li>
                        <li>
                          <p className="front">제조일</p>
                          <p className="back">{datas.ass_date}</p>
                        </li>
                        <li>
                          <p className="front">제조사</p>
                          <p className="back">{datas.ass_com}</p>
                        </li>
                        <li>
                          <p className="front">시리얼넘버</p>
                          <p className="back">{datas.ass_sn}</p>
                        </li>                             
                        <li>
                          <p className="front">비고(위치)</p>
                          <p className="back">{datas.ass_memo}</p>
                        </li>
                      </ul>
                    </div>
                    }
                  </>
              }
            <div className="modalfooter">
              <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
              <Typography variant="h5" fontWeight="300">사용자 변경</Typography>
              <select 
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={name}   
                disabled={!isUpdate}           
                onChange={handleChange}
                label="사원"
                style={{height:"40px", width:"70%", margin:"auto", border:"1px solid black", marginTop : "5px", marginBottom : "30px"}}
              >
                { nameList && (
                    <>
                        <option key={0} value={0}>&nbsp;사용자 없음</option>
                        {nameList.map((name, idx) => (
                            <option key={idx + 1} value={name.no}>&nbsp;{name.name}&nbsp;<CommonCodeInfoReturn init={name.rank} /></option>
                        ))}
                    </>
                )}
              </select>
              </FormControl>
              </Box> 
              <Box sx={{marginBottom : "10px"}}>
                {
                  isUpdate ? 
                    <SoftButton variant="gradient" color="dark" onClick={handleSave}>저장</SoftButton>
                  :
                    <SoftButton variant="gradient" color="dark" onClick={handleUpdate}>수정</SoftButton>
                }
                &nbsp;
                {
                  isUpdate ? 
                    <SoftButton variant="gradient" color="dark" onClick={handleUpdate}>취소</SoftButton>
                  :
                    <SoftButton variant="gradient" color="dark" onClick={handleDelete}>물품 삭제</SoftButton>
                }
                &nbsp;<SoftButton variant="gradient" color="dark" onClick={closeModal}>
                  창 닫기
                </SoftButton>
              </Box>
            </div>
          </div>
        </div>   
        </div>
      </>
    )
}

export default AssetInfoModal;

 