import {useEffect, useState} from "react";
import SoftBox from "../../../../../../components/SoftBox";
import {useRecoilState, useRecoilValue} from "recoil";
import WpTable from "../../../../../../examples/Tables/WpTable";
import Pagination from "../../../../../../components/pagination/Pagination";
import { loginUser } from "atom/member/loginUser";
import { useQuery } from "react-query";
import allCommonCodeMap from "api/commonCode/allCommonCodeMap";
import moment from "moment";
import { Tooltip } from "chart.js";
import SoftAvatar from "components/SoftAvatar";
import SoftProgress from "components/SoftProgress";
import wpDocList from "../../../../../../api/wp/wpDocList";
import noApprove from "../../../../../../assets/images/-_image.png";
import check from "../../../../../../assets/images/check.png";
import { customAxios } from "../../../../../../axios/CustomAxios";
import { myIpAddress } from "config";
import SoftTypography from "components/SoftTypography";
import wpTemporaryDocList from "api/wp/wpTemporaryDocList";
import { TimestampToStringDate } from "utils/DateUtils";
import { getFormattedDateTitle } from "layouts/wp/components/getFormattedDateTitle";


/**
 * 복지포인트 문서 보관함
 * @returns 
 */
function WpStoreDocs () {

    const user = useRecoilValue(loginUser);
    // 타이틀
    let title = "";
    let progress;

    // 현재 페이지 
    const [ page , setPage ] = useState(1);
    // 전체 페이지 수
    const [ totalCount , setTotalCount ] = useState(0);
    // 페이지당 아이템 개수
    const [ pageSize , setPageSize] = useState(10); 


    /**  */
    const body = { 
        mem_no : user.id
        , page : page === 1 ? 0 : ((page - 1) * pageSize)
        , pageSize : pageSize
        , stype : "store"
        , wp_approved : "AP005"  // 임시 보관 문서만
    };

    const { data:list } = useQuery(['wpDocList', body ] , () => wpDocList(body) , {
        select : (data) => {
            return data.data;
        },
    })


    const { data:info} = useQuery('allCommonCodeMap', allCommonCodeMap, {
        select : (data) => {
            return data.data;
        }
    });

    const countBody = {
        mem_no : user.id
        , stype : "store"
        , wp_approved : "AP005"  // 임시 보관 문서만
    }

    const wpDocTotalCount = (body) => {
        return customAxios.post(myIpAddress+'/wp/wpDocTotalCount', body); 
    }
    const { data:count } = useQuery(['wpDocTotalCount', body], () => wpDocTotalCount(countBody) , {
        onSuccess : (count) => {
            setTotalCount(count.data);
        },
    });

    // useEffect(()=> {
    //     customAxios.post(myIpAddress + '/wp/wpDocTotalCount', countBody, { 
    //         headers: {
    //             "Content-Type": "application/json"
    //         }
    //     })
    //     .then((response) => {
    //         console.log(response)
    //         setTotalCount(response.data);
    //     })
    // })


    const tableDataList= [];

    if(list && info) {
        const wpList = list;

        for(let i=0; i<wpList.length; i++){
             // 결재여부(x차 결재)
            const approvalDo = wpList[i]["approval_do"];
            // 결재자 수
            const approvalApprovers = wpList[i]["approval_approvers"];
            // 문서 번호
            const docNo = wpList[i]["wp_no"];
            // 작성일
            const wp_date = wpList[i]["wp_date"]
            // 복지 포인트 제목
            title = getFormattedDateTitle(wp_date, "복지포인트");
            // 작성자 이름
            const member = "["+info[wpList[i]["mem_team"]]?.info+"] "+wpList[i]["mem_name"]+" "+info[wpList[i]["mem_rank"]]?.info+"";
            // 문서 번호
            const viewNo = i+1;
            // 결재자 정보
            const approverDataList = wpList[i]["approvalList"];
            // 결재자 정보 저장용 리스트
            const approverList = [];
            // 문서 결재상태
            const approved = wpList[i]["wp_approved"];

            if(approverDataList[0].approver_name === null || approverDataList[0].approver_name === ""){
                const tinyInfoSetList = [];
                // 저장용 리스트에 저장
                tinyInfoSetList.push(noApprove);
                tinyInfoSetList.push("무결재 문서(결재자 없음)");

                // 저장용 리스트에 저장
                approverList.push(tinyInfoSetList);

            }else{   
                for(let j=0; j<approverDataList.length; j++){

                    const tinyInfoSetList = [];
                    const approverRank = info[approverDataList[j]["approver_rank"]]?.info; // 결재자 랭크
                    const approverName = approverDataList[j]["approver_name"]; // 결재자 이름
                    const approverLevel = approverDataList[j]["approver_level"];
                    const approverImg = approverDataList[j]["approver_img"]; // 결재자 프로필사진
    
                    // 저장용 리스트에 저장
                    tinyInfoSetList.push(approverImg);
                    tinyInfoSetList.push("["+(approverLevel+1)+"번 결재자] "+approverName+" "+approverRank);
    
                    // 저장용 리스트에 저장
                    approverList.push(tinyInfoSetList);
                }
            }
            // 결재 진행 상황
            const storeDate =  TimestampToStringDate(wpList[i]["wp_date"]);
            const saveMap ={
                viewNo: viewNo,
                docNo:docNo,
                title: title,
                member:member,
                approvers: approverList,
                storeDate: storeDate
            }

            // 전체 리스트에 저장
            tableDataList.push(saveMap);            
            //console.log(tableDataList);
        }
    }
  
    const columns = [
        { title: "no", name : "번호" , align: "center", width: "10%"},
        { title: "wpinfo", name: "문서정보", align: "left" , width: "35%"},
        { title: "member", name: "작성자", align: "left" , width: "23%"},
        { title: "approver", name: "결재자",align: "left" , width: "12%"},
        { title: "approvalprogress", name: "작성일", align: "center"  , width: "20%"},
    ]
  
    
    return (
        tableDataList && 
        tableDataList.length > 0 ? (
            <>
                <WpTable 
                    columns={columns} 
                    tableDataList={tableDataList} 
                    type="store" 
                    totalCount={totalCount} 
                    pageSize={pageSize}
                    page={page}
                />
                <SoftBox pt={2}
                    display="flex"
                    justifyContent="center"
                    >
                    <Pagination 
                        totalCount={totalCount} 
                        pageSize={pageSize}
                        page={page}
                        setPage={setPage}
                        size="small"
                    />
                </SoftBox>
            </>
         ) : (
            <>
                <SoftBox>
                    <SoftTypography
                        variant="button"
                        fontWeight="lg"
                        color= "secondary"
                        >
                        보관함 문서 내역이 없습니다.
                    </SoftTypography>
                </SoftBox>
            </>
        )
        
    );
}

export default WpStoreDocs;