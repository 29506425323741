/**
 * date를 받아서 String "yyyy-MM-dd" 형태로 변환
 * @param date : date
 * @returns String
 */
export function DateToString(date){
    const year = date.getFullYear();
    const month=()=>{
        let result = '';
        const getThisMonth = date.getMonth() + 1;
        getThisMonth >= 10 ? result = getThisMonth.toString() : result = '0'+(getThisMonth.toString());
        return result;
    }
    const day=()=> {
        let result = '';
        const getThisDay = date.getDate();
        getThisDay >= 10 ? result = getThisDay.toString() : result = '0'+(getThisDay.toString());
        return result;
    }
    return '' + year + '-' + month() + '-' + day() + '';
}