import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 쿠키 가져오기
 * @returns {Promise<axios.AxiosResponse<any>>}
 * @constructor
 */
const LoginPass =()=>{
    return customAxios.post(myIpAddress+'/auth/loginPass');
}
export default LoginPass;