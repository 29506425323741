import React, { useEffect, useRef } from 'react';
import './modalCss.css';

const WpImgModal = (props) => {
    // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
    const { open, header , setModalOpen , imgSrc , ref } = props;
    const modalBackground = useRef();

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    // useEffect(() => {
    // }, [setModalOpen])
    
    return (
        // 모달이 열릴때 openModal 클래스가 생성된다.
        <div className={open ? 'openModal modalWp' : 'modalWp'} ref={modalBackground} onClick={e => {
            if (e.target === modalBackground.current) {
              setModalOpen(false);
            }
          }}>
                <section>
                    <header>
                        {header}
                        <button className="close" onClick={handleCloseModal}>
                            &times;
                        </button>
                    </header>
                    <main>
                        <img
                            src={imgSrc}
                            width={"100%"}
                            height={"100%"}
                            objectFit={"cover"}
                            >
                        </img>
                    </main>
                    <footer>
                        <button className="close" onClick={handleCloseModal}>
                            닫기
                        </button>
                    </footer>
                </section>
        </div>
    );
};

export default WpImgModal;