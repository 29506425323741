import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import vacationCalendarImg from "assets/images/vacation_calendar.jpg";
import {useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";

/**
 * 휴가 캘린더 이동 버튼
 * @returns {JSX.Element}
 * @constructor
 */
function VacationCalendarButton() {

    const navigate = useNavigate();
    return (
        <Grid
            className="vacation-calendar-button"
            onClick={() => navigate('calendar')}
            item
            xs={12}
            sx={{
                cursor: "pointer",
                "&:hover .material-icons-round": {
                    transform: `translate(6px, -0.5px)`,
                },
                "&:hover > div:nth-of-type(1)": {
                    backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                        `${linearGradient(
                            rgba(gradients.info.main, 0.8),
                            rgba(gradients.info.state, 0.8)
                        )}, url(${vacationCalendarImg})`,
                },
            }}
        >
            <SoftBox
                p={2}
                borderRadius="lg"
                sx={{
                    backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.8),
                            rgba(gradients.dark.state, 0.8)
                        )}, url(${vacationCalendarImg})`,
                    backgroundSize: "cover",
                }}
            >
                <SoftBox mb={3}>
                    <SoftTypography variant="h5" color="white" fontWeight="bold">
                        휴가 캘린더
                    </SoftTypography>
                </SoftBox>
                <SoftTypography
                    variant="button"
                    color="white"
                    fontWeight="medium"
                    sx={{
                        mt: "auto",
                        mr: "auto",
                        display: "inline-flex",
                        alignItems: "center",

                        "& .material-icons-round": {
                            fontSize: "1.125rem",
                            transform: `translate(2px, -0.5px)`,
                            transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                        },

                    }}
                >
                    Go
                    <Icon sx={{fontWeight: "bold"}}>arrow_forward</Icon>
                </SoftTypography>
            </SoftBox>
        </Grid>
    );
}

export default VacationCalendarButton;
