import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import approvalStoreImg  from "assets/images/approval_store.png";
import {useNavigate} from "react-router-dom";

/**
 * 휴가 결재 보관함 버튼
 * @returns {JSX.Element}
 * @constructor
 */
function VacationApprovalStoreBoxButton() {
    const navigate = useNavigate();
    return (
        <SoftBox
            xs={12}
            item
            className="vacation-approval-store-box-button"
            onClick={() => navigate('/docs/store',{state:'APT000'})}
            sx={{
                cursor: "pointer",
                "&:hover .material-icons-round": {
                    transform: `translate(6px, -0.5px)`,
                },
                "&:hover > div:nth-of-type(1)": {
                    backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                        `${linearGradient(
                            rgba(gradients.info.main, 0.8),
                            rgba(gradients.info.state, 0.8)
                        )}, url(${approvalStoreImg})`,
                },
            }}
        >
            <SoftBox
                p={2}
                borderRadius="lg"
                sx={{
                    backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.8),
                            rgba(gradients.dark.state, 0.8)
                        )}, url(${approvalStoreImg})`,
                    backgroundSize: "cover",
                }}
            >
                <SoftBox mb={3}>
                    <SoftTypography variant="h5" color="white" fontWeight="bold">
                        휴가 보관함
                    </SoftTypography>
                </SoftBox>
                <SoftTypography
                    variant="button"
                    color="white"
                    fontWeight="medium"
                    sx={{
                        mt: "auto",
                        mr: "auto",
                        display: "inline-flex",
                        alignItems: "center",

                        "& .material-icons-round": {
                            fontSize: "1.125rem",
                            transform: `translate(2px, -0.5px)`,
                            transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                        },

                    }}
                >
                    GO
                    <Icon sx={{fontWeight: "bold"}}>arrow_forward</Icon>
                </SoftTypography>
            </SoftBox>
        </SoftBox>
    );
}

export default VacationApprovalStoreBoxButton;
