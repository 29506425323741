import {atom} from "recoil";

/**
 * 복지포인트 관리자 페이지 body 
 * @type { RecoilState<{key : value}> }
 */


export const wpAdminListSearch = atom({
    key: "wpAdminListSearch",
    default: {
        mem_no : 0
        , stype : "all"
        , sword : ""
        , page : 1
        , pageSize : 10
        , monthList : []
        , year : ""
	}
});
