import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 버그 입력
 * @param body
 * @returns {Promise<axios.AxiosResponse<any>>}
 * @constructor
 */
const bugInsert =(body)=>{
    return customAxios.post(myIpAddress+'/bug/bugInsert', body );
}
export default bugInsert;