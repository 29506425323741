import {useNavigate, useParams} from "react-router-dom";
import {useQuery} from "react-query";
import Swal from "sweetalert2";
import vacationDocCheck from "../../../../api/vacation/vacationDocCheck";

const VacationViewRedirect=()=>{
    const {viewNo} = useParams();
    const navigate = useNavigate();
    // 휴가 문서 존재 여부 체크 1,0 리턴
    const {data} = useQuery(['vacationDocCheck', viewNo], () => vacationDocCheck(parseInt(viewNo)), {
        // 데이터 불러오지(fetch) 않는 시간 지정 20분
        staleTime: 120000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onSuccess: (data)=> {
            if(data.data === 1) {
                navigate("/vacation/view", {state: viewNo});
            } else {
                Swal.fire({
                    title: '해당문서는 작성자에 의해 삭제된 문서입니다.',
                    icon: 'warning',
                    backdrop: true
                })
                    .then(() => {
                        navigate("/dashboard")
                    });
            }
        },
        onError: ()=>{
            Swal.fire({
                title: '오류가 발생했습니다. 잠시후 다시 시도해주세요.',
                icon: 'warning',
                backdrop: true
            })
                .then(() => {
                    navigate("/dashboard")
                });
        }
    })
}

export default VacationViewRedirect;