import { Form } from 'react-bootstrap';
import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 복지포인트 저장
 * @param {*} body 
 * @returns 
 */

const config = {
	headers: { 'Content-Type': 'multipart/form-data' },
};

const wpInsert = (body) =>{
    return customAxios.post(myIpAddress+'/wp/insert', body ,config);
}
export default wpInsert;