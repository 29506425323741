import React ,  { useEffect,  useState }from "react";
import { useMutation, useQuery } from "react-query";
import {useRecoilState, useRecoilValue} from "recoil";
import { useNavigate } from "react-router-dom";
import PropTypes, { bool } from "prop-types";
import Swal from "sweetalert2";
import moment from "moment";

// api
import wpInsert from "../../../api/wp/wpInsert";
import wpPointList from "api/wp/wpPointList";

// atom
import { loginUser } from "../../../atom/member/loginUser";
import { wpThisRowNumAtom } from "../../../atom/wp/wpThisRowNumAtom";
import { wpRowCountAtom } from "../../../atom/wp/wpRowCountAtom";
import { wpDetailRowAtom } from "../../../atom/wp/wpDetailRowAtom";
import { wpSumPointAtom } from "../../../atom/wp/wpSumPointAtom";
import { approverInsertAtomFamily } from "atom/approval/approver_referrer/approverInsertAtomFamily";

// util
import { AmountFormat } from "utils/AmountFormat";
// compoent
import AmountFormatInput from '../../../components/textField/AmountFormatInput';
import MiniStatisticsCard from "../../../examples/Cards/StatisticsCards/MiniStatisticsCard";
import WpInsertTableRow from "./WpInsertTableRow";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";
// @mui material components
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {  Icon, Table as MuiTable, TableBody, TableCell, TableContainer, TableRow, TextField, Tooltip } from "@mui/material";
// Soft UI Dashboard React examples
import SoftButton from 'components/SoftButton'
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography.js";
import 'react-datepicker/dist/react-datepicker.css';
import { getFormattedDateTitle } from "../../../layouts/wp/components/getFormattedDateTitle";
import { TryRounded } from "@mui/icons-material";




const WpInsertTable = ({isResetting , keyName }) => {
	useEffect(()=>{
		// 리셋 props 실행시 리렌더링 되게 하기 위해서
	},[isResetting])

    const { light } = colors;
	const { size, fontWeightBold } = typography;
	const { borderWidth } = borders;

    const user = useRecoilValue(loginUser);
	const navigate = useNavigate();

	// 상신 버튼 중복 클릭 방지
	let [isDisabled, setIsDisabled] = useState(false);

	/** 오늘 */
	const currentDate = new Date();
	//const testDay = new Date(2023,0,10);
	const month = currentDate.getMonth() + 1; // 1월은 0으로 시작하므로 +1
	const day = currentDate.getDate();

	/** 이번 연도 - 이번 연도 쓴 복지포인트 계산을 불러오기 위해서 */
	const thisYear = moment(currentDate).format('YYYY');

	/** 작년 */
	const lastYearData = currentDate.getFullYear() - 1;
	const lastYear = lastYearData.toString(); 
	
	
	/** 1월1일~10일 경우 true */
	const isJanuary1To10 = (month === 1 && day <= 10);
	
	//console.log("lastYear : :::::::::::: " , lastYear)
	//console.log("isJanuaryFirstTen:", isJanuaryFirstTen , DateFormat(currentDate));

	/** 제목 */
	const title = getFormattedDateTitle(currentDate , "복지포인트");
	//const day = 10;
	

	/** 복지포인트 상세내역 - 추가 버튼 클릭 시 + 되는 값 */
	const [ WriteDetailRowNum , setWriteDetailRowNum ] = useRecoilState(wpRowCountAtom);
	/** 복지포인트 상세내역 - 삭제버튼 클릭 한 해당 row의 key 값 */
	const [ WriteThisRowNum , setWriteThisRowNum ]  = useRecoilState(wpThisRowNumAtom);
	/** 복지포인트 상세내역 - 복지포인트 row 리스트 */
	const [ detailListRow , setDetailListRow] = useRecoilState(wpDetailRowAtom);
	/** 결재자 정보 */
	const approverInfo = useRecoilValue(approverInsertAtomFamily(keyName));
	/** 사용한 금액 합계 리코일 */
	const [ totalpoint , setTotalpoint ] = useRecoilState(wpSumPointAtom);
	/** 예외 처리 */
	const [ wp_exception, setWp_exception ] = useState("EH000");

	
	
	/** 사용 가능 한 금액 , 이번 연도 총 금액 , 몰아쓰기 배열 */
	const writeInfoArray = []
	/** 사용 가능한 금액 */
	let usablePoint; 
	/** 이번 연도 금액 */
	let yearTotalWPoint; 
	/** 사용한 총 금액 */
	let usedYearTotalPoint; 


	/** wpPointList 에 담아서 보내야할 정보 */
	const body = {
        mem_no : user.id
        , wp_usedate : isJanuary1To10 ? lastYear : thisYear
        , mem_join_date : user.joinDate
        , mem_rank : user.rank
		, pageName : "insert"
    }

	
	/** 사용 가능 한 금액 및 이번 연도 총 금액 */
    const {data:pointData } = useQuery(['wpPointList', body] , () => wpPointList(body), {
        select : (data) => {
            return data.data;
        }
    });
	/** 사용 가능 한 금액 및 이번 연도 총 금액 배열에 push */
	if(pointData){
		writeInfoArray.push(
			{ title : "복지포인트", content : title },
			{ title : "사용 가능한 금액", content : AmountFormat(pointData.usablePoint) , init : "원"},
			{ title : "사용한 금액", content : AmountFormat(pointData.usedYearTotalPoint) , init : "원"},
			{ title : "이번 연도 총 금액", content : AmountFormat(pointData.year_point), init : "원"},
		)
		usablePoint = pointData.usablePoint;
		yearTotalWPoint = pointData.year_point;
		usedYearTotalPoint = pointData.usedYearTotalPoint;
	}


	/** 복지포인트 상세 내역 row 추가 */
	const AddwpWriteDetailRow = () => {
    	const detailRowArray  = [...detailListRow];
    	const count = WriteDetailRowNum+1;
        detailRowArray.push({
			wp_rowIndex : count
			, wp_detail_date : ""
			, wp_category : ""
			, wp_content : ""
			, wp_filename : ""
			, wp_file : ""
			, wp_usedpoint : 0
			, wp_paytype : ""
			, wp_usedate : ""
		})
        setDetailListRow(detailRowArray)
		setWriteDetailRowNum(count)
	};

	/** 복지포인트 row 삭제 시 wpWriteThisRowNum 변경 되어 detailListRow WriteThisRowNum 를 가진 객체 filter */
	useEffect (() =>{
		if(WriteDetailRowNum > 1) {
			const filteredList = detailListRow.filter(list => {
                return list.wp_rowIndex !== WriteThisRowNum;
            });
            setDetailListRow(filteredList);

			const totalusedpoint = filteredList.reduce((acc, cur) => acc + parseInt(cur.wp_usedpoint || 0), 0);
			setTotalpoint(totalusedpoint)
			//console.log(" 삭제 후 합계 : " + totalusedpoint);
		}
	},[WriteThisRowNum])

	/** 저장 */
	function insert(){
		usablePointCheck();
		isEmptyCheck();
		// 금액 10원 단위 절삭 로직 추가
		// cutBy10Check();
		approverInfoCheck();

		const formData = insertDataform("AP001"); // FormData 객체 생성

		// 상신 버튼 중복 클릭 시 swal 경고창 뜨게 함
		if( isDisabled === false && usablePointCheck() === true && isEmptyCheck() === true && approverInfo.approverList ) {
			wpSave(formData);
			setIsDisabled(true);
			
		} else if (isDisabled === true) {
			Swal.fire({
				title: '상신진행중입니다.',
				icon: 'warning',
				confirmButtonColor: '#3085d6',
				confirmButtonText: '확인',
				backdrop: false,
			})
		}
	}

	/** 복지 포인트 작성 -> 전체목록 리스트로 */
	const { mutate:wpSave } = useMutation(wpInsert, {
		onSuccess: () => {
			Swal.fire({
				title: '정상처리 되었습니다.',
				icon: 'success',
				//showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: '작성 목록으로 이동',
				backdrop: false,
			}).then((result) => {
					if (result.isConfirmed) {
						navigate('/welfarePoint/list', {state: "update"})
					}
				}
			)
		},
		onError: () => {
			Swal.fire({
				title: '저장 중 에러가 발생했습니다. 잠시후 다시 실행해주세요',
				icon: 'error',
				backdrop: false,
			})
		}
	});

	/** 임시저장 */
	function tempSave() {
		usablePointCheck();
		const formData = insertDataform(); // FormData 객체 생성

		if( usablePointCheck() === true) {
			const array = {
				mem_team : user.team
				, mem_rank : user.rank
				, mem_no : user.id
				, wp_totalpoint : totalpoint
				, wp_approved : "AP005"
				//, wp_checker : "관리팀"
				, wp_exception : wp_exception
				, wpdDto : detailListRow
				//, usablePoint : usablePoint
				, year_point : yearTotalWPoint
				, approvalList : approverInfo.approverList
		        , doc_type:"APT003"
			}
			//console.log(array)
			wpTemp(formData)
		}
	}

	/** 복지 포인트 임시저장 -> 임시저장 리스트로  */
	const { mutate:wpTemp } = useMutation(wpInsert, {
		onSuccess: () => {
			Swal.fire({
				title: '임시 저장 처리 되었습니다.',
				icon: 'success',
				//showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: '임시저장 목록으로 이동',
				backdrop: false
			}).then((result) => {
					if (result.isConfirmed) {
						navigate('/welfarePoint/tempList' , {state: "update"})
					}
				}
			)
		},
		onError: () => {
			Swal.fire({
				title: '업데이트 중 에러가 발생했습니다. 잠시후 다시 실행해주세요',
				icon: 'error',
				backdrop: false,
			})
		}
	});

	/** 10원 단위로 절삭한 금액인지 체크 */
	// function cutBy10Check () {
	// 	let check = true;

	// 	// 입력한 금액 레코드값 전부 확인
	// 	detailListRow.map((row) => {
	// 		if (row.wp_usedpoint !== 0) {
	// 			Swal.fire({
	// 				icon: 'error',
	// 				text: '금액은 100원 단위까지만 입력해야합니다.',
	// 				backdrop: false,
	// 			}); 
	// 			check = false;
	// 			return check
	// 		} else {
	// 			return check
	// 		}
	// 	})
	// }

	/** 사용 가능한 금액인지 체크 */
	function usablePointCheck () {
		let check = true;
		
		// 예외처리 아닐때
		if (wp_exception === "EH000"){
			if(usablePoint < totalpoint) {
				Swal.fire({
					icon: 'error',
					text: '사용 가능 금액이 초과 하였습니다.',
					backdrop: false,
				}); 
				check = false;
				return check
			} else {
				return check
			}
		// 예외 일때
		} else {
			if(parseInt(yearTotalWPoint) < parseInt(usedYearTotalPoint)+parseInt(totalpoint)) {
				Swal.fire({
					icon: 'error',
					text: '이번 연도 총 금액을 초과 하였습니다.',
					backdrop: false,
				}); 
				check = false;  
				return check
			} else{
				return check
			}
		}
	}

	/** 빈 값 여부 체크  */
	function isEmptyCheck() {
		let hasEmptyValue = false;
		const arrayRow = [...detailListRow];

		const isEmptyCheckRowData = {
			wp_usedate : "사용일을 선택해주세요"
		   , wp_category : "사용처를 선택해주세요"
		   , wp_content : "상세 내역을 입력해주세요"
		   , wp_usedpoint : "금액을 입력해주세요"
		   , wp_paytype : "결제수단을 선택해주세요"
	   }

		// for (const key in array) {
		// 	let obj = array[key];
		// 	for (const prop in obj) {
		// 		let val = obj[prop];
		// 			if (prop === "wp_detail_update") continue;
		// 			if (prop === "wp_detail_date") continue;
		// 			if (prop === "wp_filename") continue; // 아직... 
		// 			if (prop === "wp_file") continue; // 아직... 
		// 			if (!val) {
		// 				Swal.fire({
		// 					icon: "error",
		// 					title: "에러",
		// 					text: "빈칸을 채워주세요.",
		// 					backdrop: false,
		// 				});
		// 			hasEmptyValue = true;
		// 			break;
		// 		}
		// 	}
		// 	if (hasEmptyValue) break;
		// }

		for (const key in arrayRow) {
			let obj = arrayRow[key];
			for (const prop in isEmptyCheckRowData) {
				if (!obj[prop]) {
					Swal.fire({
						icon: "error",
						title: isEmptyCheckRowData[prop],
						backdrop: false,
					});
					hasEmptyValue = true;
					break;
				} else if(prop === "wp_usedpoint" && obj[prop] < 1 ){
					Swal.fire({
						icon: "error",
						title: '금액은 0원 이상이어야 합니다.',
						backdrop: false,
					});
					hasEmptyValue = true;
					break;
				} 
			}
			if (hasEmptyValue) {
				break;
			}
		}

		return !hasEmptyValue;
	}


	/**
	 * 복지포인트 저장 시 필요한 데이터 형식 FormData로 변환
	 * (일단 json 형식으로 보내서 서버에서 다시 객체로 만들어줌...)
	 * @param {*} save 
	 * @returns formData
	 */
	function insertDataform(save){
		/**
		 * formData 에 append 되는 key의 종류 
		 * 'dto' , 'wpdDto' , 'approvalList' , 'fileData'
		 */
		const formData = new FormData();

		/** 복지포인트 작성자,합계,결재,몰아쓰기,이번연도포인트,문서타입 */
		const dto = {
			mem_team : user.team
			, mem_rank : user.rank
			, mem_no : user.id
			, mem_id : user.email
			, wp_totalpoint : totalpoint
			, wp_approved : save? "AP001" : "AP005"
			, wp_exception : wp_exception
			, year_point : yearTotalWPoint
			, doc_type:"APT003"
		}

		const detailListRowArray = [];
		
		// wp_file 영수증 사진이 없을 경우 및 이미지 데이터라서 제외
		for (const [index, detail] of detailListRow.entries()) {
			const filteredDetail = Object.entries(detail)
				.filter(([key, value]) => key !== 'wp_file')
				.reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
			
				detailListRowArray.push(filteredDetail)

			// 이미지 데이터 있을 경우만 formData에 append
			if (detail.wp_file && detail.wp_file !== null && detail.wp_file.file) {
				formData.append('fileData', detail.wp_file.file , detail.wp_rowIndex+"_"+detail.wp_file.name);
			}
		}
		
		formData.append('dto', JSON.stringify(dto));
		/** 결재자  */		
		formData.append('approvalList', JSON.stringify(approverInfo.approverList));
		/** row 데이터 */
		formData.append('wpdDto', JSON.stringify(detailListRowArray));

		return formData
	}

	/** 결재자 체크 */
	function approverInfoCheck(){
		if(!approverInfo.approverList){
			Swal.fire({
				icon: "error",
				title: "결재자를 선택해주세요.",
				backdrop: false,
			});
		}
	}


	/** 복지포인트 작성 칼럼 : 수정중 */
	const columns = [
		{ key: "wp_usedate" , name: "사용일", align: "left" , color : "warning" , icon : "event_available" ,width : "7%"},
		{ key: "wp_category" , name: "사용처", align: "center" , color : "success" , icon : "fact_check" ,width : "7%"},
		{ key: "wp_content" , name: "상세내역", align: "center" , color : "info" , icon : "notes" ,width : "60%"},
		{ key: "wp_filename" , name: "영수증", align: "left" , color : "secondary" , icon : "insert_photo" ,width : "7%"}, 
		{ key: "wp_usedpoint" , name: "금액", align: "center" , color : "primary" , icon : "paid" , width : "7%"},
		{ key: "wp_paytype" , name: "결제수단", align: "center" , color : "dark" , icon : "payment" , width : "7%"},
		{ key: "btn" , name: "추가", align: "center" , color : "dark" , icon : "payment" ,width : "7%"},
	]
	
	/** 칼럼 map */
    const renderColumns = columns.map(({ name, width, color, icon }) => {

		if( name ==="추가" ) {
			return (
				<SoftBox
					key={name} component="th" width={width || "auto"} pt={1} pb={1.25}  
					textAlign="center" alignItems="center"
					opacity={0.7} borderBottom={`${borderWidth[1]} solid ${light.main}`} 
				>   
					<IconButton aria-label="add" color= "dark" >
						<AddCircleIcon onClick={AddwpWriteDetailRow}/>
					</IconButton>
				</SoftBox>
			);
		} else {
			return (
				<SoftBox
					key={name} component="th" width={width || "auto"} pt={1} pb={1.25} 
					textAlign="center" fontSize={size.lg} fontWeight={fontWeightBold} color= "secondary" alignItems="center"
					opacity={0.7} borderBottom={`${borderWidth[1]} solid ${light.main}`} 
				>   
					<SoftBox display="flex" alignItems="center" justifyContent="center">
						<SoftBox
							bgColor={color}
							width="1.5rem"
							height="1.5rem"
							borderRadius="sm"
							color="white"
							fontSize={size.md}
							display="flex"
							justifyContent="center"
							alignItems="center"
							shadow="md"
							mr={1}
							variant="gradient"
						>
							<Icon>{icon}</Icon>
						</SoftBox>
						<SoftTypography
							variant="h6"
							textTransform="capitalize"
							fontWeight="medium"
							color="text"
							sx={{ width: "max-content" ,   }}
						>
							{name.toUpperCase()}
						</SoftTypography>
					</SoftBox>
				</SoftBox>
			);
		}
	});

    return (
			<>
				<Grid container spacing={3}>
					{ writeInfoArray.length > 0 && writeInfoArray.map(({ title , content, init},index) => 
						<Grid item xs={12} sm={6} xl={3} key={index}>
							<SoftBox bgColor="white" shadow="lg" pb={1} borderRadius="lg">
								<SoftBox>
									<MiniStatisticsCard
										title={{ text: title }}	
										count= {content}
										min = "min"
										init={init}
										/>
								</SoftBox>
							</SoftBox>
						</Grid>
					)}    
				</Grid>
				
				<SoftBox mt={2}>
					<TableContainer>
						<MuiTable>
						<SoftBox component="thead">
							<TableRow>{renderColumns}</TableRow>
						</SoftBox>
						<TableBody>
							{
								detailListRow.map((list) => (
									<WpInsertTableRow
									key={`detailListRow${list.wp_rowIndex}`}
									rowindex={list.wp_rowIndex}
									list={list}
									isResetting={isResetting}
									/>
								))
							}
							<TableRow key={uuidv4()} bgColor="#b2dfdb" >
								<TableCell colSpan={7} key={uuidv4()} style={{ borderTop: `${borderWidth[1]} solid ${light.main}` }}>
									<Grid container justifyContent="flex-end" alignItems="center">
										<SoftBox pr={1}  >
											<SoftTypography
												variant="h4"
												color="white"
												fontWeight="bold"  
												sx={{ display: "inline-block", width: "max-content" ,   }}>
												합계 
											</SoftTypography>
										</SoftBox>
										<SoftBox>
											<AmountFormatInput
												label ="합계"
												theme = "sum"
												size="Normal"
												value = {totalpoint} 
												unit = "원"
											/>  
										</SoftBox>
									</Grid>
								</TableCell>
							</TableRow>    
						</TableBody>
						</MuiTable>
					</TableContainer>
				</SoftBox>
				<SoftBox mb={3}>
					<Grid container spacing={3} mt={0.5} mb={0.5}>
						<Grid item xs={12} md={12} lg={12} display="flex" justifyContent="center" alignItems="center">
							<SoftBox>
								<SoftButton variant="gradient" color="info" onClick={tempSave}>
									<Icon sx={{fontWeight: "bold"}}>person_add</Icon>
									&nbsp;임시저장
								</SoftButton>
							</SoftBox>
							&nbsp;&nbsp;&nbsp;
							<SoftBox>
								<SoftButton variant="gradient" color="success" onClick={insert}>
									<Icon sx={{fontWeight: "bold"}}>person_add</Icon>
									&nbsp;문서상신
								</SoftButton>
							</SoftBox>
						</Grid>
					</Grid>
				</SoftBox>
			</>
        );
    }

    // Setting default values for the props of Table
    WpInsertTable.defaultProps = {
    columns: [],
    rows: [{}],
    };

    // Typechecking props for the Table
    WpInsertTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    rows: PropTypes.arrayOf(PropTypes.object),
    };

export default WpInsertTable