import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 복지포인트 게시물 삭제
 * @param {*} body 
 * @returns 
 */

const wpDelete =(body)=>{
    return customAxios.post(myIpAddress+'/wp/delete', body ); 
}
export default wpDelete;
