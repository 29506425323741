import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 임시보관문서 삭제
 * @param body
 * @returns {Promise<axios.AxiosResponse<any>>}
 * @constructor
 */
const vacationDocDelete =(body)=>{
    return customAxios.post(myIpAddress+'/vacation/vacationDocDelete', body);
}
export default vacationDocDelete;