const assetTableData = {
  columns: [
    { name: "구분", align: "center"},
    { name: "제조년월", align: "center" },
    { name: "제조사", align: "center" },
    { name: "모델번호", align: "center"},
    { name: "SN", align: "center"},
    { name: "CPU", align: "center"},
    { name: "RAM", align: "center"},
    { name: "크기", align: "center"},
    { name: "SSDTYPE", align: "center"},
    { name: "SSD", align: "center"},
    { name: "HDD", align: "center"},
    { name: "해상도", align: "center"},
    { name: "OS", align: "center"},   
    { name: "비고", align: "center"},
    { name: "현재사용자", align: "center"},
  ],
};

export default assetTableData;
