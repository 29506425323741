import { number } from 'prop-types';
import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 특정 달의 결재된 문서 리스트 조회
 * @returns 
 */
const monthlyVacationApprovalDocList =(year, month)=>{

    year = year.toString();
    month = month.toString();

    if (month.toString().length == 1) {
        month = '0' + month;
    }

    return customAxios.post(myIpAddress+'/vacation/monthlyVacationApprovalDocList', {
        year: year,
        month: month
    });
}
export default monthlyVacationApprovalDocList;