import { Card, Grid, MenuItem, Select } from "@mui/material";
import { insertCommonCode } from "api/commonCode/commonCode";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { useState } from "react";
import { isMobile } from 'react-device-detect';
import { useMutation, useQueryClient } from "react-query";
import Swal from 'sweetalert2';


const CommonCodeInsert = (props) => {

    const {
        codeMap,
        rows
    } = props;


    const [selectInputValue, setSelectInputValue] = useState("");

    const [category, setCategory] = useState("");
    const [categoryInfo, setCategoryInfo] = useState("");
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [info, setInfo] = useState("");


    const handleInputCategoryChange = (e) => {
        let maxCCName = null;
        const target = e.target.value;
        setSelectInputValue(target)
        if(target) {
            rows.forEach((item) => {
                if(target === item.cc_category) {
                    setCategory(item.cc_category)
                    setCategoryInfo(item.cc_category_info)
                    if(!maxCCName || item.cc_name > maxCCName) {
                        maxCCName = item.cc_name;
                    }
                }
            }); 
        setLastName(maxCCName)
        } else {
            setCategory("")
            setCategoryInfo("")
            setLastName("")
        }
        
    }
    
    
    const saveCommonCode = () => {
        const commonData = {
            cc_category: category,
            cc_category_info: categoryInfo,
            cc_name: name,
            cc_info: info
        };
        
        const errors = [];
        
        Object.entries(commonData).forEach(([key, value]) => {
            if (!value) {
                errors.push(key);
            }
        });
        
        if (errors.length > 0) {
            Swal.fire({
                icon: 'warning',
                text: `${errors.join(", ")}을(를) 입력해주세요.`,
                backdrop: false
            });
        } else {
            mutate(commonData);
            // console.log(commonData);
        }
    }

    
    const queryClient = useQueryClient();

    const { mutate } = useMutation(insertCommonCode, {
        onSuccess: (result)=>{
            if(result.data.includes("완료")) {
                Swal.fire({
                    title: result.data,
                    icon: 'success',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6', 
                    cancelButtonColor: '#d33',
                    backdrop: false
                }).then((result) => {
                        if (result.isConfirmed) {
                            queryClient.invalidateQueries('commonCodeAdmin');
                        }
                    }
                )
            } else {
                Swal.fire({
                    title: result.data,
                    icon: 'error',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6', 
                    cancelButtonColor: '#d33',
                    backdrop: false
                })
            }
		},
		onError: () => {
			Swal.fire({
				title: '등록 중 에러가 발생했습니다. 잠시후 다시 실행해주세요',
				icon: 'error',
                backdrop: false,
			})
		}
    });



    return (
        <Grid item md={6} lg={6} >
            <Card sx={{ mt: 2, mr: isMobile? 0 : 2 , py: 2, px: 2, }}>
                <Grid sx={{ mt: 2, mx: 0 }}>
                    <SoftTypography variant="h5" fontWeight="bold">
                        공용 코드 추가 
                    </SoftTypography>
                </Grid>
                {/* <Grid>
                    <SoftTypography variant="button" fontWeight="light" color="secondary">
                        예 시 -  category : APPROVAL_TYPE / category_info : 결재타입 / name : APT000 / info : 휴가
                    </SoftTypography>
                </Grid> */}
                <Grid container display={"flex"} sx={{mt:2, alignItems: 'flex-end'}}>
                    <Grid item md={12} lg={12} sx={{ py: 1, pr: 1}}> 
                        <SoftTypography variant="h6" fontWeight="medium">
                            항목에 추가 
                        </SoftTypography>
                        <Select
                            className="commonCode"
                            displayEmpty
                            size="large"
                            onChange={handleInputCategoryChange}
                            value={selectInputValue}
                            sx={{
                                "& .MuiOutlinedInput-input": {
                                    width: '-webkit-fill-available !important'
                                },
                            }}
                        >
                            <MenuItem value="" key={"all"}>-선택-</MenuItem>
                            {   
                                codeMap.size > 0 &&
                                Array.from(codeMap).map(([key, value]) => (
                                    <MenuItem value={value.cc_category} key={value.cc_category}>
                                        {value.cc_category_info}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </Grid>
                    <Grid item sx={{ py: 1, pr: 1}}> 
                        <SoftTypography variant="button" fontWeight="light" color="secondary">예시 : APPROVAL_TYPE</SoftTypography>
                        <SoftInput type="category" placeholder="category" 
                                    value={category}
                                    onChange={
                                        (e)=> setCategory(e.target.value)
                                    } />
                    </Grid>
                    <Grid item sx={{ py: 1, pr: 1}}> 
                        <SoftTypography variant="button" fontWeight="light" color="secondary">예시 : 결재타입</SoftTypography>
                        <SoftInput type="category_info" placeholder="category_info"
                                    value={categoryInfo}
                                    onChange={
                                        (e)=> setCategoryInfo(e.target.value)
                                    }
                        />
                    </Grid>
                    <Grid item sx={{ py: 1, pr: 1}}>
                        <SoftTypography variant="button" fontWeight="light" color="secondary">예시 : APT000</SoftTypography>
                        <SoftInput type="name" placeholder={lastName ? `마지막 Name : ${lastName}` : "name"}
                                    value={name}
                                    onChange={
                                        (e)=> setName(e.target.value)
                                    }
                        />
                    </Grid>
                    <Grid item sx={{ py: 1, pr: 1}}>
                        <SoftTypography variant="button" fontWeight="light" color="secondary">예시 : 휴가</SoftTypography>
                        <SoftInput type="info" placeholder="info" 
                                    value={info}
                                    onChange={
                                        (e)=> setInfo(e.target.value)
                                    }
                        />
                    </Grid>
                    <Grid item sx={{ py: 1}}>
                        <SoftButton variant="outlined" color="info" onClick={saveCommonCode}>추가</SoftButton>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    )
}

export default CommonCodeInsert