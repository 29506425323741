import Tooltip from "@mui/material/Tooltip";
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftProgress from "components/SoftProgress";
import {QueryClient, useQueries} from "react-query";
import {isRecoilValue, useRecoilValue} from "recoil";
import {loginUser} from "../../../../../atom/member/loginUser";
import {useEffect} from "react";
import vacationNewApprovalDocs from "../../../../../api/vacation/vacationNewApprovalDocs";
import userWorkInfo from "../../../../../api/user/userWorkInfo";
import allCommonCodeMap from "../../../../../api/commonCode/allCommonCodeMap";
import SoftBadge from "../../../../../components/SoftBadge";
import SoftTypography from "../../../../../components/SoftTypography";

/**
 * 휴가 테이블 데이터
 * @returns {{columns: [{name: string, align: string},{name: string, align: string},{name: string, align: string}], rows: *[]}}
 */
export default function data() {

    // 타이틀
    let progress;

    // react query
    const queryClient = new QueryClient();

    // login 정보
    const recoilState = isRecoilValue(loginUser);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const user = useRecoilValue(loginUser);

    // recoil 정보가 가져와 지면 refetch
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (recoilState) {
            // Only execute the query if myState is not null
            queryClient.refetchQueries('vacationNewApprovalDocs');
        }
    }, [recoilState]);

    const tableDataList= [];

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const queries = useQueries([
        // queries[0] : 휴가 정보
        {
            queryKey: ["vacationNewApprovalDocs", user?.id],
            queryFn: () => vacationNewApprovalDocs(parseInt(user?.id)),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            }
        },
        // queries[1] : 공용코드 리스트
        {
            queryKey: ["allCommonCodeMap"],
            queryFn: () => allCommonCodeMap(),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            }
        },
        // queries[2] : 유저 리스트
        {
            queryKey: ["userWorkInfo"],
            queryFn: () => userWorkInfo(),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            }
        }
    ]);

    if(queries[0].isSuccess && queries[1].isSuccess && queries[2].isSuccess){
        // 유저리스트
        const approvalDoc = queries[0].data.data;
        // 공용코드 리스트
        const codeMap = queries[1].data.data;
        // 모든 유저 정보
        const allUser = queries[2].data.data;

        for(let i=0; i<approvalDoc.length; i++){

            // 결재여부(x차 결재)
            let approvalDo;
            // 결재자 수
            let approvalApprovers;
            // 휴가타입
            const category = codeMap[approvalDoc[i]["vac_category"]]?.info;
            // 결재 상태
            const approved = codeMap[approvalDoc[i]?.vac_approved]?.info;
            // 휴가 시작일
            const start = approvalDoc[i]["vac_start"];
            // 휴가 종료일
            const end = approvalDoc[i]["vac_end"];
            // 총 휴가 일 수
            const days = approvalDoc[i]["vac_days"];
            // 문서 번호
            const docNo = approvalDoc[i]["vac_no"];

            // 결재자 정보
            const approverDataList = approvalDoc[i]["approverList"];
            // 결재자 정보 저장용 리스트
            const approverList = [];

            for(let j=0; j<approverDataList.length; j++){
                const tinyInfoSetList = [];
                const approverRank = codeMap[approverDataList[j]["approver_rank"]].info; // 결재자 랭크
                const approverName = approverDataList[j]["approver_name"]; // 결재자 이름
                const approverMemNo = approverDataList[j]["approver_mem_no"]; // 결재자 멤버번호
                const approverLevel = approverDataList[j]["approver_level"];
                let approverImg; // 결재자 프로필사진
                let approverTeam; // 결재자 팀
                for(let k=0; k<allUser.length; k++){
                    if(approverMemNo === (parseInt(allUser[k]["memNo"]))) {
                        approverTeam = codeMap[allUser[k]["memTeam"]].info;
                        approverImg = allUser[k]["imageUrl"];
                        break;
                    }
                }
                // 결재한 결재자 수
                approvalDo = approverDataList[j]["approval_do"];
                // 결재가 필요한 결재자 수
                approvalApprovers = approverDataList[j]["approval_approvers"];

                // 저장용 리스트에 저장
                tinyInfoSetList.push(approverImg);
                tinyInfoSetList.push("["+(approverLevel+1)+"번 결재자] "+approverName+" "+approverRank);

                // 저장용 리스트에 저장
                approverList.push(tinyInfoSetList);
            }
            // 결재 진행 상황
            progress = (approvalDo/approvalApprovers)*100;
            const saveMap ={
                docNo:docNo,
                category: category,
                start: start,
                end: end,
                day: days,
                approvers: approverList,
                approvedInfo: approved,
                progress: progress
            }
            // 전체 리스트에 저장
            tableDataList.push(saveMap);
        }
    }

    /**
     * 이름과 이미지로 호버시 팀,이름,직책 나옴
     * @param members
     * @returns {*}
     */
    const avatars = (members) =>
        members.map(([image, name], index) => (
            <Tooltip key={name+index} title={name} placeholder="bottom">
                <SoftAvatar
                    src={image}
                    alt="name"
                    size="xs"
                    sx={{
                        border: ({borders: {borderWidth}, palette: {white}}) =>
                            `${borderWidth[2]} solid ${white.main}`,
                        cursor: "pointer",
                        position: "relative",

                        "&:not(:first-of-type)": {
                            ml: -1.25,
                        },

                        "&:hover, &:focus": {
                            zIndex: "10",
                        },
                    }}
                />
            </Tooltip>
        ));

    /**
     * row에 데이터 렌더링
     * @returns {*[]}
     */
    const tabelDataRender = () => {
        const dataArr = [];
        for (let i = 0; i < tableDataList.length; i++) {
            dataArr.push(
                {
                    docNo: tableDataList[i]["docNo"],
                    vacationInfo: (
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                            <SoftBadge
                                variant="contained"
                                badgeContent={tableDataList[i]?.start}
                                color="secondary" size="md" container
                                sx={{display: "inline-block", width: "max-content"}}
                            />
                            <SoftTypography variant="button" fontWeight="bold">
                                &nbsp;-&nbsp;
                            </SoftTypography>
                            <SoftBadge
                                variant="contained"
                                badgeContent={tableDataList[i]?.end}
                                color="secondary" size="md" container
                                sx={{display: "inline-block", width: "max-content"}}
                            />
                        </SoftBox>
                    ),
                    vacationDate: (
                        <>
                            <SoftBadge
                                variant="contained"
                                badgeContent={tableDataList[i]?.category}
                                color="secondary" size="md" container
                                sx={{display: "inline-block", width: "max-content"}}
                            />
                            <SoftTypography variant="button" fontWeight="bold">
                                &nbsp;{tableDataList[i]?.day} 일
                            </SoftTypography>
                        </>
                    ),
                    approver: (
                        <SoftBox display="flex" py={1}>
                            {avatars(
                                tableDataList[i]["approvers"]
                            )
                            }
                        </SoftBox>
                    ),
                    approved: (
                        <SoftBadge
                            variant="contained"
                            badgeContent={tableDataList[i]?.approvedInfo}
                            color={
                                tableDataList[i]?.approvedInfo === "결재완료"
                                    ? "success" : tableDataList[i]?.approvedInfo === "반려"
                                        ? "error" : tableDataList[i]?.approvedInfo === "상신취소"
                                            ? "warning" : "info"}
                            size="lm" container
                            sx={{display: "inline-block", width: "max-content"}}
                        />
                    ),
                    approvalProgress: (
                    <SoftBox width="8rem" textAlign="left">
                        <SoftProgress value={tableDataList[i]["progress"]} color="info" variant="gradient" label={true}/>
                    </SoftBox>
                ),
            }
            );
        }
        return dataArr;
    };

    return {
        columns: [
            // 2023-06-02 VacationTable 컴포넌트 변경으로 인해 value 값 추가
            {name: "vacationInfo", align: "center", value : "휴가일정",  width: "30%"},
            {name: "vacationDate", align: "center", value : "휴가정보",  width: "20%"},
            {name: "approver", align: "center" , value : "결재자",  width: "15%"},
            {name: "approved", align: "center" , value : "결재상태",  width: "15%"},
            {name: "approvalProgress", align: "center" , value : "결재완료율",  width: "20%"},
        ],

        rows: tabelDataRender(),
    };
}
