import {atom} from "recoil";

/**
 * 복지포인트 관리자 페이지 body 
 * @type { RecoilState<{key : value}> }
 */


export const wpCsvDownloadRow = atom({
    key: "wpCsvDownloadRow",
    default:[{
      
	}]
});
