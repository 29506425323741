import React from "react";
import { useNavigate } from "react-router-dom";
// api
// atom
// component
import WpTempListTable from "./components/WpTempDocsList";
// util

// @mui material components
import Grid from '@mui/material/Grid';
import { Icon } from "@mui/material";
import Card from "@mui/material/Card";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import 'react-datepicker/dist/react-datepicker.css';
import Footer from "examples/Footer";





const WPTempListPage = () => {
	const navigate = useNavigate();

	return (
		<>
		<DashboardLayout>
            <DashboardNavbar/> 
			<Card
				sx={{
				backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
				boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
				position: "relative",
				mt: 2,
				mx: 0,
				mb : 4,
				py: 2,
				px: 2,
				}}
				>
				<Grid container spacing={3} alignItems="center">
					<Grid item>
						<SoftBox height="100%"  mt={0.5} lineHeight={1}  display="flex" justifyContent="space-between">
							<SoftTypography variant="h5" fontWeight="bold" ml={2} >
								복지포인트 임시저장 목록
							</SoftTypography>
						</SoftBox>
					</Grid>
					<Grid item  
						sx={{ ml: "auto" }}
						display="flex"
						flexDirection="row"
						justifyContent="flex-end"
						pr={3}
						>		
						{/* <SoftBox pr={1}>
							<SoftButton color="info" onClick={() => navigate('/welfarePoint/insert')} variant="gradient">
								<Icon sx={{fontWeight: "bold"}}>text_snippet</Icon>
									&nbsp;작성하기
							</SoftButton>
						</SoftBox>				 */}
						{/* <SoftBox pr={1}>
							<SoftButton color="success" onClick={() => navigate('/welfarePoint') } variant="gradient">
								<Icon sx={{fontWeight: "bold"}}>home</Icon>
									&nbsp;메인으로
							</SoftButton>
						</SoftBox>				 */}
						<SoftBox pr={1}>
							<SoftButton mr={1} color="dark" onClick={() => navigate('/welfarePoint/list')} variant="gradient">
								<Icon sx={{fontWeight: "bold"}}>format_list_bulleted</Icon>
									&nbsp;전체 목록
							</SoftButton>
						</SoftBox>				
					</Grid>
				</Grid>
				<SoftBox 
					sx={{
						"& .MuiTableRow-root:not(:last-child)": {
						"& td": {
							borderBottom: ({ borders: { borderWidth, borderColor } }) =>
							`${borderWidth[1]} solid ${borderColor}`,
						},
						},
					}}
                    mt={2}>
					<WpTempListTable></WpTempListTable>
				</SoftBox>
			</Card>
			<Footer/>

		</DashboardLayout>
		</>
	);
	

}

export default WPTempListPage
