import * as React from 'react';
import {useQuery} from 'react-query';
import {useRecoilState, useRecoilValue} from 'recoil';
import {useEffect} from 'react';

// api
import wpPointList from '../../../api/wp/wpPointList.js';

// atom
import {loginUser} from '../../../atom/member/loginUser';
import {wpUsableMonth} from "../../../atom/wp/wpUsableMonth.js";

// wp components

// components 

// @mui material components
import {styled} from '@mui/material/styles';
import LinearProgress, {linearProgressClasses} from '@mui/material/LinearProgress';
import {Card, Grid, Icon, Paper, useMediaQuery} from '@mui/material';

// Soft UI Dashboard React components
import SoftBox from 'components/SoftBox';

// Soft UI Dashboard React examples
import MiniStatisticsCard from '../../../examples/Cards/StatisticsCards/MiniStatisticsCard';
import SoftTypography from 'components/SoftTypography/index.js';
import {useState} from 'react';
import Carousel from "react-material-ui-carousel";
import {useSoftUIController} from "../../../context";
import {useLocation} from "react-router-dom";


// 바 css
const BorderLinearProgress = styled(LinearProgress)(({theme, value}) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#82b1ff' : '#308fe8',
        height: 11,
        width: value + '%',
    },
}));


/**
 * 복지포인트 금액 카드
 * @returns {JSX.Element}
 */
const WpPointCard = () => {
    const isXsScreen = useMediaQuery('(max-width:800px)');

    const location = useLocation();

    const user = useRecoilValue(loginUser);

    /** 날짜 */
    const todayDate = new Date();
    const year = todayDate.getFullYear();
    const month = todayDate.getMonth() + 1;
    const day = todayDate.getDate();
    /** 작년 */
    const lastYearData = todayDate.getFullYear() - 1;
    const lastYear = lastYearData.toString();
    /** 1월1일~10일 경우 true */
    const isJanuary1To10 = (month === 1 && day <= 10);

    /** 이번 연도 - 이번 연도 쓴 복지포인트 계산을 위해서 필요 */
    const thisYear = String(year);
    /** 입사일이 오늘보다 이후 일 경우 */
    const isJoinDate = todayDate < new Date(user.joinDate);

    /** 복지포인트 사용 가능한 달 */
    const [usableMonth, setUsableMonth] = useRecoilState(wpUsableMonth);

    const body = {
        mem_no: user.id,
        wp_usedate: isJanuary1To10 ? lastYear : thisYear,
        mem_join_date: user.joinDate,
        mem_rank: user.rank,
        pageName : "main"
    }
    
    const { data } = useQuery(
        ['wpPointList', body],
        () => wpPointList(body),
            {
                select: (data) => {
                    return data.data;
                },
                compare: (prevData, nextData) => {
                    return JSON.stringify(prevData) === JSON.stringify(nextData);
                },
            }
     );


    /**
     *
     */
    useEffect(() => {
        if (data) {
            const wpPointCardText = {
                year_point: '이번 연도 복지포인트',
                usablePoint: '사용 가능한 복지포인트',
                usedYearTotalPoint: '사용한 복지포인트',
                futurePoint: '앞으로 적립될 복지포인트',
            };
            const filteredData = Object.entries(data)
                .filter(([key]) => wpPointCardText[key])
                .map(([key, value]) =>
                    key === 'futurePoint' ? {
                        key: wpPointCardText[key], value,
                        addCount: data.year_point
                    } : {key: wpPointCardText[key], value}
                );
            setUsableMonth(data.usableDate);
            setWpPointCardDataObject(filteredData);
        }
    }, [data, setUsableMonth]);

    const [wpPointCardDataObject, setWpPointCardDataObject] = useState([]);

    return (
        <>
            <Grid container spacing={2}>
                {   // 입사일이 오늘 이후 일 경우
                    data &&
                    isJoinDate ? (
                        <Card
                            sx={{
                                backgroundColor: ({functions: {rgba}, palette: {white}}) => rgba(white.main, 0.8),
                                boxShadow: ({boxShadows: {navbarBoxShadow}}) => navbarBoxShadow,
                                position: "relative",
                                mt: 2,
                                mx: 3,
                                py: 2,
                                px: 2,
                            }}
                        >
                            <SoftBox height="100%" width="100%" mt={0.5} lineHeight={1}>
                                <SoftTypography variant="h5" fontWeight="bold">
                                    입사일을 확인해 주십시오.
                                </SoftTypography>
                            </SoftBox>
                        </Card>
                    ) : location.pathname === "/dashboard" || (location.pathname === "/welfarePoint" && isXsScreen) ?
                        <>
                            <Grid item xs={12} sm={12} xl={12}>
                                <Carousel
                                    animation="slide"
                                    autoPlay="true"
                                    indicators={false}
                                    timeout="6000"
                                >
                                    {
                                        wpPointCardDataObject.map((list, index) => (
                                            <SoftBox bgColor="white" pb={1} borderRadius="lg">
                                                <SoftBox>
                                                    <MiniStatisticsCard
                                                        title={{text: list.key}}
                                                        count={list.value.toLocaleString()}
                                                        addCount={list.addCount}
                                                        key={`card_${index}`}
                                                        //percentage={{ color: "success", text: "~" }}
                                                        //icon={{ color: "info", component: "paid" }}
                                                    />
                                                </SoftBox>
                                                <SoftBox pr={4} pl={2} pt={1} pb={1}>
                                                    <BorderLinearProgress
                                                        variant="determinate"
                                                        value={parseInt((list.value / data?.year_point) * 100)}
                                                        key={`progress_${index}`}
                                                    />
                                                </SoftBox>
                                            </SoftBox>
                                        ))
                                    }
                                </Carousel>
                            </Grid>
                        </> : <>
                            {
                                wpPointCardDataObject.map((list, index) => (
                                    <Grid item xs={12} sm={6} xl={3} key={`grid_${index}`}>
                                        <SoftBox bgColor="white" shadow="lg" pb={1} borderRadius="lg">
                                            <SoftBox>
                                                <MiniStatisticsCard
                                                    title={{text: list.key}}
                                                    count={list.value.toLocaleString()}
                                                    addCount={list.addCount}
                                                    key={`card_${index}`}
                                                    //percentage={{ color: "success", text: "~" }}
                                                    //icon={{ color: "info", component: "paid" }}
                                                />
                                            </SoftBox>
                                            <SoftBox pr={4} pl={2} pt={1} pb={1}>
                                                <BorderLinearProgress
                                                    variant="determinate"
                                                    value={parseInt((list.value / data?.year_point) * 100)}
                                                    key={`progress_${index}`}
                                                />
                                            </SoftBox>
                                        </SoftBox>
                                    </Grid>
                                ))
                            }
                        </>
                }
            </Grid>
        </>
    )
}


export default WpPointCard