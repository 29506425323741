// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard layout components
import VacationInsertButton from "./components/VacationInsertButton";
import VacationBarChart from "./components/VacationBarChart";

// Data
import VacationDaysData from "layouts/vacation/data/VacationDaysData";
import VacationBarChartData from "./data/VacationBarChartData";
import MyVacationApprovalList from "./components/MyVacationApprovalList";
import Card from "@mui/material/Card";
import VacationApprovalReceiveBoxButton from "./components/VacationApprovalReceiveBoxButton";
import VacationCalendarButton from "./components/VacationCalendarButton";
import VacationApprovalSentBoxButton from "./components/VacationApprovalSentBoxButton";
import VacationApprovalStoreBoxButton from "./components/VacationApprovalStoreBoxButton";

function Vacation() {

    // 하위 컴포넌트에서 아톰패밀리 접근 키값
    const approverKey = "approver_vac";
    const referrerKey = "referrer_vac";
    const insertKey = "insert_vac";

    // 휴가 일수 정보
    const {items} = VacationDaysData().dayData;
    // 당해년도 월당 휴가 사용 정보
    const {chart} = VacationBarChartData().chartData;

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <SoftBox py={2}>
                <SoftBox mb={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <VacationBarChart
                                title="나의 휴가 정보"
                                chart={chart}
                                items={items}
                                chartOn={true}
                            />
                        </Grid>
                    </Grid>
                </SoftBox>
                <SoftBox mb={2}>
                    <Card>
                        <Grid container spacing={1} p={1}>
                            <Grid item xs={6} md={6} lg={3}>
                                <VacationCalendarButton/>
                            </Grid>
                            <Grid item xs={6} md={6} lg={3}>
                                <VacationApprovalSentBoxButton/>
                            </Grid>
                            <Grid item xs={6} md={6} lg={3}>
                                <VacationApprovalReceiveBoxButton/>
                            </Grid>
                            <Grid item xs={6} md={6} lg={3}>
                                <VacationApprovalStoreBoxButton/>
                            </Grid>
                        </Grid>
                    </Card>
                </SoftBox>
                <SoftBox mb={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4} lg={4}>
                            <VacationInsertButton approverKey={approverKey} referrerKey={referrerKey} insertKey={insertKey} />
                        </Grid>
                        <Grid item xs={12} md={8} lg={8}>
                            <MyVacationApprovalList/>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default Vacation;
