import React ,  { forwardRef, useState , useEffect, useCallback }from "react";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";

// atom
import { wpTemporalListRowAtom } from "../../../atom/wp/wpTemporalListRowAtom";
import { wpSumPointAtom } from "../../../atom/wp/wpSumPointAtom";
import { wpThisRowNumAtom } from "../../../atom/wp/wpThisRowNumAtom";
import { wpRowCountAtom } from "../../../atom/wp/wpRowCountAtom";

// utils
import {StringToDate} from "../../../utils/DateUtils";
import { DateFormat } from "utils/DateFormat";

// components 
import CommonCodeSelectBox from '../../../components/inputBox/CommonCodeSelectBox'
//import UploadButtons from '../../../components/button/UploadButtons'
import AmountFormatInput from "../../../components/textField/AmountFormatInput";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko  } from 'date-fns/esm/locale'; //한국어 설정
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import { TableCell, TableRow } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import "./index.css"
import { DateBeforeAndAfterTenthDay } from "utils/DateBeforeAndAfterTenthDay";
import UploadButtons from "components/button/UploadButtons";


/** DatePicker 커스텀 - 버튼 모양 */
const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
        {value}
    </button>
));


const WpEditTableRow = ({ list , rowindex , }) => {
	
	/** 오늘 날짜 */
	const currentDate = new Date();
	//const testDate = new Date(2023,0,1);
	/** datePicker 날짜 범위 */
	const datePickerRange = DateBeforeAndAfterTenthDay(currentDate);
	/** 사용일 시작일 */
	const minDate = datePickerRange.minDate;
	/** 사용일 끝일 */
	const maxDate = datePickerRange.maxDate;
	//console.log(moment(datePickerRange.minDate).format('YYYY-MM-DD') ," ::::::::::: ", moment(datePickerRange.maxDate).format('YYYY-MM-DD'));


	/** 복지포인트 row 갯수*/
    const [ tempRowCount , setTempRowCount] = useRecoilState(wpRowCountAtom);
	/** 삭제버튼 클릭한 해당 row의 key 값 */
    const [ tempThisRowNum , setTempThisRowNum ]  = useRecoilState(wpThisRowNumAtom);
	/** 복지포인트 상세내역 - row 리스트 */
	const [ tempListRow , setTempListRow] = useRecoilState(wpTemporalListRowAtom);
	/** 사용한 금액 합계 리코일 - row 삭제 시 실행 되게 하기 위해서 */
    const [ totalpoint, setTotalpoint ] = useRecoilState(wpSumPointAtom);


    /** 작성 내용 state  */
    const [ usedate, setUsedate ] = useState(list.wp_usedate ? StringToDate(list.wp_usedate) : minDate);
    const [ category , setCategory ] = useState(list.wp_category);   
    const [ content, setContent ] = useState(list.wp_content);
	const fileNo = list.wp_fno ? list.wp_fno : 0;
    const [ filename, setFilename ] = useState(list.wp_filename);
	const [ wpFile, setWpFile ] = useState(null);
    const [ usedpoint, setUsedpoint ] = useState(list.wp_usedpoint);
    const [ paytype, setPaytype ] = useState(list.wp_paytype);

    /** state 값에 업데이트 있을 시 tempListRow 에 값 업데이트 */
    const updateTempListRow = useCallback(() => {
        const array = tempListRow.map(list => {
            if (list.wp_rowIndex === rowindex) {
                return {
                    ...list,
                    wp_rowIndex : rowindex,
                    wp_usedate: DateFormat(usedate),
                    wp_category: category,
                    wp_content: content,
                    wp_filename: filename,
					wp_fno: fileNo,
                    wp_file: wpFile,
                    wp_usedpoint: usedpoint,
                    wp_paytype: paytype
                };
            } else {
                return list;
            }
        });
        setTempListRow(array);

        //console.log(" array : " + JSON.stringify(array));
        //console.log(" usedpoint : " + usedpoint);
        const totalusedpoint = array.reduce((acc, cur) => acc + parseInt(cur.wp_usedpoint || 0), 0);
        setTotalpoint(totalusedpoint)
        //console.log(" 합계 : " + totalusedpoint);
    }, [usedate, category, content, filename, usedpoint, paytype , tempThisRowNum]);

    useEffect(() => {
        updateTempListRow();
    }, [updateTempListRow]);


	/** 복지포인트 상세 내역 row 삭제 */
	const RemoveWpTempRow = (wp_rowIndex) => {
        const row = [...tempListRow];

        if(tempRowCount > 0 && row.length > 1){
            setTempThisRowNum(wp_rowIndex)
           	//console.log(" 클릭 한 row num : " + tempThisRowNum);
        } else {
            Swal.fire({
                icon: 'error',
                title: '에러',
                text: '삭제에 실패했습니다.',   
				backdrop: false,             
              }); 
        } 
    }


	return (
		<>
            <TableRow key={rowindex}  sx={{ width: "100%" }} >
				<TableCell 
					sx={{ textAlign :"center", paddingX : "0px" , paddingLeft : "5px" , lineHeight : 0 , paddingY :0.5}}>
					<DatePicker
						key={rowindex+"usedate"}
						selected={usedate}
						locale={ko}
                        onChange={(date) => {
                            setUsedate(date)
                        }}
						// minDate={minDate}
  						// maxDate={maxDate}
                        customInput={<ExampleCustomInput />}
                        dateFormat="yyyy-MM-dd"
						//value = {setUsedate}
					/>
				</TableCell>
				<TableCell 
					sx={{ textAlign :"center", paddingX : "0px" , lineHeight : 0 , paddingY :0.5}}>
					<CommonCodeSelectBox
						key={rowindex+"category"}
						label="사용처"
						category="WELFARE_CATEGORY"
						selectedValue={category}
						value={setCategory}
						textAlign="center"
					/>
				</TableCell>
				<TableCell sx={{ paddingX : "0px" , lineHeight : 0 , paddingY :0.5}}>
					{/* 상세 내역 */}
					<SoftInput
						sx={{  minWidth : 200 ,  textAlign :"center", paddingX : "0px", paddingLeft : "10px",  }}
						variant="button" fontWeight="regular"
						key={rowindex+"content"} 
						onChange={(e) => {
							setContent(e.target.value)
						}}
						value={content}
						>
					</SoftInput>
				</TableCell>
				{/* 영수증 */}
				<TableCell  sx={{ paddingX : "0px" }}>
					<UploadButtons
						key={rowindex+"filename"}
						theme = "icon"
						value = {filename}
						setValue = {setFilename}
						setFile = {setWpFile}
						docType = "wp"
					/>
				</TableCell>
				<TableCell 
					sx={{ paddingX : "0px" , lineHeight : 0 , paddingY :0.5}} 
					>
					<SoftBox
						sx={{
							"@media screen and (max-width: 1300px)": {
								width: "200px",
							},
							"@media screen and (max-width: 1000px)": {
								width: "130px",
							},
							paddingLeft : "7px"
						}}
						>
						<AmountFormatInput
							key={rowindex}
							unit = "원"
							value={usedpoint}
							setValue={setUsedpoint}
						/>
					</SoftBox>
				</TableCell>
				<TableCell sx={{ paddingX : "0px" , textAlign :"center" , lineHeight : 0 , paddingY :0.5}} >
					<CommonCodeSelectBox
						key={rowindex+"paytype"} 
						category="PAY_METHOD"
						selectedValue={paytype}
						value={setPaytype}
					/>
				</TableCell>
				<TableCell sx={{ paddingX : "0px" , lineHeight : 0 , paddingY :0.5}} >
					<SoftBox
						key={rowindex+"delete"} 
						sx={{ padding:"0px" , textAlign :"-webkit-center",}}
						>
						<IconButton 
							aria-label="delete" 
							key = {rowindex}
							onClick={() => {
								RemoveWpTempRow(rowindex);
							}}
							>
						<RemoveIcon />
						</IconButton>
					</SoftBox>
				</TableCell>
			</TableRow>
		</>
	);
}

export default WpEditTableRow
