import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useMemo } from "react";
import { useQuery } from "react-query";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";

// api 
import wpList from "../../../api/wp/wpList";
import allCommonCodeMap from "../../../api/commonCode/allCommonCodeMap";
// atom
import { loginUser } from "atom/member/loginUser";
// utils
import { AmountFormat } from "utils/AmountFormat";
import { DateFormat } from "utils/DateFormat";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Table as MuiTable, TableCell } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";

// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import { getFormattedDateTitle } from "./getFormattedDateTitle";



/**
 * 복지포인트 이번 연도 작성 리스트
 * @returns {JSX.Element}
*/
function WpThisYearList() {
	/** css base styles */
	const { light } = colors;
	const { borderWidth } = borders;

	const user = useRecoilValue(loginUser);
	const navigate = useNavigate();

	/** 리스트 업데이트 여부 확인 = true 경우 다시 데이터 가져옴 */
	//const location = useLocation();
	//const isUpdated = location && location.state && location.state.updated;

	/** 테이블 열 데이터 */
    const [ rows , setRows ] = useState([]);
	/** 게시물 개수 */
    const [ totalCount , setTotalCount ] = useState(0);
	
	/** 
	 * 메인페이지 이번 연도 작성 리스트에 필요한 데이터 body , 
	 * pageSize : 불러올 row 개수  --> 5개로 고정
	 * */
	const Listbody = { 
        mem_no : user.id
        , page : 0
        , pageSize : 5
        //, updated: isUpdated // 업데이트 여부 확인
    };
	
	// 공용코드
	const { data:commonCode } = useQuery('allCommonCodeMap', allCommonCodeMap, {
        select : (data) => {
            return data.data;
        }
    });
	
	/** 메인 페이지 이번 연도 리스트 useQuery */
    const { data } = useQuery(['wpList' , Listbody ], () => wpList(Listbody), {
		select : (listData) => {
			return listData.data;
		}
	}); 

	/** 테이블의 항목 Columns */
    const columns = [
		{ key: "wp_no" , name: "번호", width: "5%" },
		{ key: "mem_name" , name: "이름", width: "10%" },
		{ key: "mem_team" , name: "소속", width: "10%" },
		{ key: "mem_rank" , name: "직급", width: "10%" },
		{ key: "title" , name: "제목", width: "center" },
		{ key: "wp_totalpoint" , name: "합계", width: "center" },
		{ key: "wp_approved" , name: "결재상태", width: "center" },
		{ key: "wp_date" , name: "작성일", width: "center" },
	]

	/** 테이블 열 데이터 */
	useEffect(() => {
		if (data && commonCode && data.length > 0 && data.length > 0) {
			const thisYearList = data.map((list) => {
				const row = {};
					row["wp_no"] = list["wp_no"];		// 게시물 번호
					row["mem_name"] = list["mem_name"];	// 이름
					row["mem_team"] = commonCode[list["mem_team"]].info;	// 소속
					row["mem_rank"] = commonCode[list["mem_rank"]].info;	// 직급
					row["title"] = {	// 제목
						value: getFormattedDateTitle(list["wp_date"], "복지포인트"),
						style: "bold",
					};
					row["wp_totalpoint"] = {	// 합계
						value: `${AmountFormat(list["wp_totalpoint"])} 원`,
						style: "bold" ,
					};
					row["wp_approved"] = commonCode[list["wp_approved"]].info;	// 결재상태
					row["wp_date"] = DateFormat(list["wp_date"]);	// 작성일
				return row;
			});
			setRows(thisYearList);
			setTotalCount(data.length);
		}
	}, [data, commonCode]);
	  
	
	/** 테이블의 항목 Columns */
	const renderColumns = columns.map(({ name, width }, key) => {
		let pl;
		let pr;

		if (key === 0) { pl = 3; pr = 3;} 
		else if (key === columns.length - 1) { pl = 3; pr = 3;} 
		else { pl = 1; pr = 1;}

		return (
			<SoftBox
				key={name}
				component="th"
				width={width || "auto"}
				pt={1}
				pb={1}
				textAlign={"center"}
				opacity={0.7}
				borderTop={`${borderWidth[1]} solid ${light.main}`}
				borderBottom={`${borderWidth[1]} solid ${light.main}`}
			>
				<SoftTypography
					variant="button"
					fontWeight="bold"
					color= "secondary"
					sx={{ display: "inline-block", width: "max-content" }}
					>
					{name.toUpperCase()}
				</SoftTypography>
			</SoftBox>
		);
	});

	/** 테이블의 열 row */
	const renderRows = rows.map((row, index ,) => {
		const rowKey = `row-${index}`;
		const wpNo = row.wp_no;

		/** 테이블의 열 row (column에 맞춰서) */	
		const tableRow = columns.map(({ name, key,}) => {
			let data = row[key] ? (row[key].value ? row[key].value : row[key]) : undefined;
			
			let template;

			if(key === "wp_no"){
				data = totalCount - index;
			}

			if (key === "wp_approved") {
				template = (
					<SoftBox
						key={key+"_"+rowKey}
						component="td"
						p={1.5}
						textAlign={"center"}
						borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
						>
						<SoftBadge variant="contained" badgeContent={data} 							
							color={ data === "결재완료" ? "success" : data === "상신문서" ? "info" : data === "임시보관" ? "secondary" : "error"} 
							size="sm" container 
						/>
					</SoftBox>
				)
			} else{
				template = (
					<SoftBox
						key={name+rowKey}
						component="td"
						p={1.5}
						textAlign={"center"}
						borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
							>
						<SoftTypography
							variant="button"
							textAlign={"center"}
							fontWeight={ row[key].style ? row[key].style : "regular"}
							color="text"
							sx={{ display: "inline-block", width: "max-content" }}
							>
							{data}
						</SoftTypography>
					</SoftBox>
				)
			}

			return template;
		});

		return (
			<TableRow
				key={rowKey}
				onClick={()=> navigate('/welfarePoint/detail',{state : wpNo})}
				sx={{ "&:hover": { backgroundColor: "#eceff1", cursor: "pointer" , } }}
				>
				{tableRow}
			</TableRow>
		);
	});

	return useMemo(
        () => (
				<TableContainer>
					<MuiTable>
						<SoftBox component="thead">
							<TableRow>{renderColumns}</TableRow>
						</SoftBox>
						<TableBody>
							{rows.length > 0 ? (
								renderRows
							) : (
								<TableRow>
								<TableCell colSpan={columns.length}>
									<SoftBox p={1.5} textAlign="left">
									<SoftTypography
										variant="button"
										fontWeight="regular"
										color="text"
										sx={{ display: "inline-block", width: "max-content" }}
									>
										이번 연도 작성 목록이 없습니다.
									</SoftTypography>
									</SoftBox>
								</TableCell>
								</TableRow>
							)}
						</TableBody>
					</MuiTable>
				</TableContainer>
			),
			[columns, rows]
		);
	}

    // Setting default values for the props of Table
    WpThisYearList.defaultProps = {
    columns: [],
    rows: [{}],
    };

    // Typechecking props for the Table
    WpThisYearList.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    rows: PropTypes.arrayOf(PropTypes.object),
    };


export default WpThisYearList