import { useEffect } from "react";
import { createBrowserHistory } from "history";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const history = createBrowserHistory();

/**
 * 
 * @param {*} callback ={path:현재주소,state:문서구분}
 */
const useBackButton = (callback) => {
    const navigate = useNavigate();
    useEffect(() => {
        const unlisten = history.listen((location, action) => {

            if (location.action === "POP") {
                // navigate(callback.path, { state: { oldPath: callback.state } }); // 해당 코드가 없으면 뒤로 페이지가 이동 되어버림 코드 수정 후 해당 코드 삭제 필요
                navigate(callback.oldPath, { state: callback.state })
            }
        });
        return () => {
            unlisten();
        };
    }, [callback]);
};

export default useBackButton;
