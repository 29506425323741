import axios from "axios";

/**
 * 내년도 공휴일
 * @param props.nextYear : {nextYear: string, toDayYear: string}
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const GetNextHolyDay = (props) => {
    // serviceKey 만료일 2025-02-03
    // https://www.data.go.kr/tcs/dss/selectApiDataDetailView.do?publicDataPk=15012690 - 공공데이터포털(한국천문연구원_특일 정보)
    const serviceKey = 'IzZ1ERXpwQ%2BahxPK9xPLgMYipy4G3SkIRRj4%2F8ITTaLJGKwRWdlqjOt77SIAAZvKytmH5zFPZyAdNvZFddlwZg%3D%3D';
    return axios.get('https://apis.data.go.kr/B090041/openapi/service/SpcdeInfoService/getHoliDeInfo?'+
        'solYear=' + props.nextYear +
        '&_type=json' +
        '&numOfRows=100' +
        '&ServiceKey='+ serviceKey
    )
}

export default GetNextHolyDay;