import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 문서 체크 여부
 * @param wpNo : 복지포인트 문서번호
 * @returns {Promise<axios.AxiosResponse<any>>}
 * @constructor
 */
const wpDocCheck =(wpNo)=>{
    return customAxios.post(myIpAddress+'/wp/wpDocCheck', {
        wp_no : wpNo
    });
}
export default wpDocCheck;