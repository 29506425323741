/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseTextchildren,
  collapseItemchildren,
} from "examples/Sidenav/styles/sidenavCollapse";

// Soft UI Dashboard React context
import { useSoftUIController } from "context";

function SidenavCollapseChildren({ key , color, icon, name, children, active, noCollapse, open, ...rest }) {
  const [controller] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;

  return (
    <>
      <ListItem component="li">
        <SoftBox {...rest} sx={(theme) => collapseItemchildren(theme, { active, transparentSidenav })}>
          <Icon></Icon>
          <ListItemText
            primary={name}
            sx={(theme) => collapseTextchildren(theme, { miniSidenav, transparentSidenav, active })}
          />
        </SoftBox>
      </ListItem>
    </>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapseChildren.defaultProps = {
  color: "info",
  active: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapseChildren.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  name: PropTypes.string.isRequired,
  noCollapse: PropTypes.bool,
  active: PropTypes.bool,
};

export default SidenavCollapseChildren;
