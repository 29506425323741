import moment from "moment";
import { DateFormat } from "utils/DateFormat";

/**
 * date(날짜형식 String도 가능)를 받아 10일 기준으로 사용일 datepicker 시작일과 끝일 리턴
 * / type 있을 경우 title 리턴 
 * @param {*} dateString 
 * @param String type 
 * @returns 
 */
export function getFormattedDateTitle(dateString , type){

    const date = new Date(dateString);
	const year = date.getFullYear();
	const month = date.getMonth();
	const day = date.getDate();

	/** 제목 */
	let title;
	let titleDate;

	// 1월일 경우
	if (month === 0) {
		// 1월 이면서 10일 이내 
		if (day <= 10) {
			const lastYear = year - 1;
			titleDate = new Date(lastYear, 11, 1);	// lastYear-12-01 
		} else {
			titleDate = new Date(year, month, 1);
		}
	} else {
		if (day <= 10) {
			titleDate = new Date(year, month - 1, 1);
		  // 5월 11일 이후  ex 2023-05-15
		} else {
			titleDate = new Date(year, month, 1);
		}
	}

	if(type){
		title = "[ "+type+" ] "+ moment(titleDate).format('YYYY년 MM월');
	} else{
		const formattedTitleDate = moment(titleDate).format('YYYY-MM-DD');
		title = formattedTitleDate;
	}
	

    return title;
}