import { Card, Grid, Icon } from "@mui/material";
import CostSaveInsert from "api/cost/CostSaveInsert";
import { approverCountAtomFamily } from "atom/approval/approver_referrer/approverCountAtomFamily";
import { approverInsertAtomFamily } from "atom/approval/approver_referrer/approverInsertAtomFamily";
import { referrerCountAtomFamily } from "atom/approval/approver_referrer/referrerCountAtomFamily";
import { referrerInsertAtomFamily } from "atom/approval/approver_referrer/referrerInsertAtomFamily";
import { TransportationCostDetail } from "atom/cost/CostWriteDetail";
import { CostBenefitDetail } from "atom/cost/CostWriteDetail";
import { FuelCostDetail } from "atom/cost/CostWriteDetail";
import { PublicTransportationCostDetail } from "atom/cost/CostWriteDetail";
import { CostEmployeeBenefitList, CostSave, FuelCostDetailList, PublicTransportationCostDetailList, TotalAmount, TransportationCostDetailList } from "atom/cost/CostWriteDetail";
import { loginUser } from "atom/member/loginUser";
import Month from "components/month/Month";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { myIpAddress } from "config";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import Swal from "sweetalert2";
import { customAxios } from "../../../axios/CustomAxios";
import CostClassification from "./CostClassification";
import CostWriterInput from "./CostWriterInput";
import DetailCostClassification from "./DetailCostClassification";
import CostTempSaveInsert from "api/cost/CostTempSaveInsert";



const CostInsertButton = (props) => {
    const {
        state,
        costCode
    } = props;

    const user = useRecoilValue(loginUser);
    const navigate = useNavigate();

    // 하위 컴포넌트에서 아톰패밀리 접근 키값
    let approverKey = "approver_vac";
    let referrerKey = "referrer_vac";

    if (state.state === undefined) {
        approverKey = "approver_vac";
        referrerKey = "referrer_vac";
    } else {
        approverKey = "approverEditKey_" + state?.state
        referrerKey = "referrerEditKey_" + state?.state
    }

    //날짜 받기
    let nowDate = new Date().toISOString().substring(0, 10);

    // 저장
    const [costSave, setCostSave] = useRecoilState(CostSave);
    
    // //비용처리 카테고리(복리후생비,교통비,대중교통비)
    // const cost_category = useRecoilValue(CostDetailCalssificationCode);
    const cost_category = costCode;

    //전체 정보
    // const [costList, setCostList] = useState({});
    // const [costNo, setCostNo] = useState(null);
    //상세내역 총 금액
    // const [detailListAmountTotal] = useRecoilState(TotalAmount);
    
    const costNo = state?.state ? state?.state : null;
    //복리후생 상세내용 리스트
    const [costBenefitList, setCostBenefitList] = useRecoilState(CostEmployeeBenefitList);
    //교통비 상세내용 리스트
    const [transportationCostList, setTransportationCostList] = useRecoilState(TransportationCostDetailList);
    //대중교통비 상세내용 리스트
    const [publicTransportationCostList, setPublicTransportationCostList] = useRecoilState(PublicTransportationCostDetailList);
    //유류비 상세내용 리스트
    const [fuelCostListList, setFuelCostListList] = useRecoilState(FuelCostDetailList);


    // 결재자 정보
    const [approverInfo, setApproverInfo] = useRecoilState(approverInsertAtomFamily(approverKey));
    // const setApproverNum = useSetRecoilState(approverCountAtomFamily(approverKey));
    // 참조자 정보
    const [referrerInfo, setReferrerInfo] = useRecoilState(referrerInsertAtomFamily(referrerKey));
    // const setReferrerNum = useSetRecoilState(referrerCountAtomFamily(referrerKey));
    // 저장 타입
    const [insertType, setInsertType] = useState('');
    
    //복리후생 상세내용 리스트 리셋
    const benefitListReset = useResetRecoilState(CostEmployeeBenefitList);
    //교통비 상세내용 리스트 리셋
    const transportationListReset = useResetRecoilState(TransportationCostDetailList);
    //대중교통비 상세내용 리스트 리셋
    const publicTransportationListReset = useResetRecoilState(PublicTransportationCostDetailList);
    //유류비 상세내용 리스트 리셋
    const fuelListReset = useResetRecoilState(FuelCostDetailList);
    //상세내역 총금액 리셋
    const detailAmountReset = useResetRecoilState(TotalAmount);

    //상세내역 복리후생,대중교통비,유류비 로우갯수 리셋
    const benefitRowReset = useResetRecoilState(CostBenefitDetail);
    const transportRowReset = useResetRecoilState(TransportationCostDetail);
    const publicRowReset = useResetRecoilState(PublicTransportationCostDetail);
    const fuelRowReset = useResetRecoilState(FuelCostDetail);
    const approverListReset = useResetRecoilState(approverInsertAtomFamily(approverKey)); // 결재자 리스트
    const referrerListReset = useResetRecoilState(referrerInsertAtomFamily(referrerKey)); // 참조자 리스트
    const approverNumReset = useResetRecoilState(approverCountAtomFamily(approverKey)); // 결재자 수
    const referrerNumReset = useResetRecoilState(referrerCountAtomFamily(referrerKey)); // 참조자 수


    const resetAmtom = () =>{
        benefitListReset()
        transportationListReset()
        publicTransportationListReset()
        fuelListReset()
        detailAmountReset()

        benefitRowReset()
        transportRowReset()
        publicRowReset()
        fuelRowReset()
        approverListReset()
        referrerListReset()
        approverNumReset()
        referrerNumReset()
    }   

    useEffect(() => {
        resetAmtom()
    },[])



    //상세내역 금액이 30만원이 넘는지 체크
    const totalAmountCheck = () => {
        let total = 0;
        if(costCode === 'C000') {
            costBenefitList.map(list => {
                if (list.other_amount === "" || list.other_amount === null) {
                    total += 0;
                } else {
                    total += parseInt(list.other_amount);
                }
            });
        } else if (costCode === 'C001') {
            transportationCostList.map(list => {
                if (list.other_amount == "" || list.other_amount == null) {
                    total += 0;
                } else {
                    total += parseInt(list.other_amount)
                }
            });
        }else if (costCode === 'C002') {
            publicTransportationCostList.map(list => {
                if (list.traffic_amount === "" || list.traffic_amount === null) {
                    total += 0;
                } else {
                    total += parseInt(list.traffic_amount);
                }
            });
        }else if (costCode === 'C003') {
            fuelCostListList.forEach(list => {
                if (list.fuel_amount === "" || list.fuel_amount === null) {
                    total += 0;
                } else {
                    total += parseInt(list.fuel_amount);
                }
            });
        }
        if(total >= 300000) {
            return 1
        } else {
            return 0
        }
    }
   
    // 문서 상신 버튼
    const costListSave = () => {
        setCostSave(true)
        setInsertType('costSave')
    };

    //임시저장버튼
    const costTempSave = () => {
        setCostSave(true)
        setInsertType('costTempSave')
    }

    // 상세항목값 변경될 때 실행
    useEffect(() =>{
        if(costSave){
            const { updateCostList, updateDetailListRow, detailListRowName } = insertCostData();
            insertCost(updateCostList, updateDetailListRow, detailListRowName);
        }
    },[costBenefitList, transportationCostList, publicTransportationCostList, fuelCostListList])


    // 비용처리 데이터 set
    const insertCostData = () => {

        // 상세항목
        let updateDetailListRow;
        // 전체 데이터
        let updateCostList;
        // updateCostList에 저장한 상세항목 key 값
        let detailListRowName;

        // 복리후생
        if (cost_category === 'C000') {
            detailListRowName = 'detail_otherList';
            updateDetailListRow = costBenefitList;
            updateCostList = {
                cost_no: costNo,
                mem_no: user.id,
                mem_id: user.email,
                auth_no: "",
                cost_team: user.team,
                cost_rank: user.rank,
                cost_date: nowDate,
                cost_category: cost_category,
                cost_history: costBenefitList.length,
                cost_approved: "AP001",
                cost_flow: totalAmountCheck(),
                cost_regdate: nowDate,
                referrerList: referrerInfo.referrerList,
                detail_otherList: costBenefitList,
                approvalList: approverInfo.approverList,
                doc_type: 'APT001'
            }

        // 교통비
        } else if (cost_category === 'C001') {
            detailListRowName = 'detail_otherList';
            updateDetailListRow = transportationCostList;
            updateCostList = {
                cost_no: costNo,
                mem_no: user.id,
                mem_id: user.email,
                auth_no: "",
                cost_team: user.team,
                cost_rank: user.rank,
                cost_date: nowDate,
                cost_category: cost_category,
                cost_history: transportationCostList.length,
                cost_approved: "AP001",
                cost_flow: totalAmountCheck(),
                cost_regdate: nowDate,
                referrerList: referrerInfo.referrerList,
                detail_otherList: transportationCostList,
                approvalList: approverInfo.approverList,
                doc_type: 'APT001'
            }

        // 대중교통비
        } else if (cost_category === 'C002') {
            detailListRowName = 'detail_trafficList';
            updateDetailListRow = publicTransportationCostList;
            updateCostList = {
                cost_no: costNo,
                mem_no: user.id,
                mem_id: user.email,
                auth_no: "",
                cost_team: user.team,
                cost_rank: user.rank,
                cost_date: nowDate,
                cost_category: cost_category,
                cost_history: publicTransportationCostList.length,
                cost_approved: "AP001",
                cost_flow: totalAmountCheck(),
                cost_regdate: nowDate,
                referrerList: referrerInfo.referrerList,
                detail_trafficList: publicTransportationCostList,
                approvalList: approverInfo.approverList,
                doc_type: 'APT001'
            }
        // 유류비
        } else if (cost_category === 'C003') {
            detailListRowName = 'fuelList';
            updateDetailListRow = fuelCostListList;
            updateCostList = {
                cost_no: costNo,
                mem_no: user.id,
                mem_id: user.email,
                auth_no: "",
                cost_team: user.team,
                cost_rank: user.rank,
                cost_date: nowDate,
                cost_category: cost_category,
                cost_history: fuelCostListList.length,
                cost_approved: "AP001",
                cost_flow: totalAmountCheck(),
                cost_regdate: nowDate,
                referrerList: referrerInfo.referrerList,
                fuelList: fuelCostListList,
                approvalList: approverInfo.approverList,
                doc_type: 'APT001'
            }

        } else {
            detailListRowName = 'fuelList';
            updateDetailListRow = fuelCostListList;
            updateCostList = {
                cost_no: costNo,
                mem_no: user.id,
                mem_id: user.email,
                auth_no: "",
                cost_team: user.team,
                cost_rank: user.rank,
                cost_date: nowDate,
                cost_category: cost_category,
                cost_history: fuelCostListList.length,
                cost_approved: "AP001",
                cost_flow: totalAmountCheck(),
                cost_regdate: nowDate,
                referrerList: referrerInfo.referrerList,
                approvalList: approverInfo.approverList,
                doc_type: 'APT001'
            }

        }

        return { updateCostList, updateDetailListRow, detailListRowName }
    }

    // 유효성검사 및 데이터 전송
    const insertCost = (costList, detailListRow, detailListRowName) => {
        if(costList) {
            setCostSave(false)
            const errors = [];
            if(insertType === 'costSave') {
                if (costList.cost_category === "") {
                    errors.push("구분");
                }
                if (costList.approvalList === null || costList.approvalList?.length === 0) {
                    errors.push("결재자")
                }
                if (costList.detail_otherList !== undefined) {
                    for (let i = 0; i < costList.detail_otherList.length; i++) {
                        if (costList.detail_otherList[i].other_category === "") {
                            errors.push("용도");
                        }
                        if (costList.detail_otherList[i].other_contents === "") {
                            errors.push("내용");
                        }
                        if (costList.detail_otherList[i].other_personnel === "") {
                            errors.push("인원");
                        }
                        if (costList.detail_otherList[i].other_amount === "") {
                            errors.push("금액");
                        }
                        if (costList.detail_otherList[i].other_paytype === "") {
                            errors.push("결제수단");
                        }
                    }
                }
        
                if (costList.detail_trafficList !== undefined) {
                    for (let i = 0; i < costList.detail_trafficList.length; i++) {
                        if (costList.detail_trafficList[i].traffic_purpose === "") {
                            errors.push("방문목적");
                        }
                        if (costList.detail_trafficList[i].traffic_method === "") {
                            errors.push("교통수단");
                        }
                        if (costList.detail_trafficList[i].traffic_location === "") {
                            errors.push("방문장소");
                        }
                        if (costList.detail_trafficList[i].traffic_way === "") {
                            errors.push("편도/왕복");
                        }
                        if (costList.detail_trafficList[i].traffic_amount === "") {
                            errors.push("금액");
                        }
                    }
                }
                if (costList.fuelList !== undefined) {
                    for (let i = 0; i < costList.fuelList.length; i++) {
                        if (costList.fuelList[i].fuel_amount === "") {
                            errors.push("금액");
                        } else if (costList.fuelList[i].fuel_carnum === "") {
                            errors.push("차량번호");
                        } else if (costList.fuelList[i].fuel_category === "") {
                            errors.push("운행용도");
                        } else if (costList.fuelList[i].fuel_distance === "") {
                            errors.push("운행거리");
                        } else if (costList.fuelList[i].fuel_end === "") {
                            errors.push("도착지");
                        } else if (costList.fuelList[i].fuel_paytype === "") {
                            errors.push("결제수단");
                        } else if (costList.fuelList[i].fuel_start === "") {
                            errors.push("출발지");
                        }
                    }
                };
                if (errors.length > 0) {
                    const uniqueErrors = [...new Set(errors)];
                    Swal.fire({
                        title: '저장 실패',
                        text: `${uniqueErrors.join(", ")}을(를) 입력해주세요.`,
                        icon: 'error',
                        backdrop: false
                    }); 
                } else {
                    // customAxios.post(myIpAddress + '/cost/save', costList, {
                    //     headers: {
                    //         "Content-Type": "application/json"
                    //     }
                    // })
                    //     .then((response) => {
                    //         Swal.fire({
                    //             icon: 'success',
                    //             title: '입력 완료!',
                    //             backdrop: false,
                    //         })
                    //         navigate('/cost')
                    //     });
                    Swal.fire({
                        title: '상신 하시겠습니까?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: '상신',
                        backdrop: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            const formData = insertDataform(costList, detailListRow, detailListRowName);
                            costInsert(formData);
                        }
                    })
        
                }
            } else if(insertType === 'costTempSave') {
                if (costList.cost_category === "") {
                    errors.push("구분");
                }
        
                if (errors.length > 0) {
                    const uniqueErrors = [...new Set(errors)];
                    Swal.fire({
                        title: '저장 실패',
                        text: `${uniqueErrors.join(", ")}을(를) 입력해주세요.`,
                        icon: 'error',
                        backdrop: false
                    });
                } else {
                    // Swal.fire({
                    //     icon: 'info',
                    //     title: '저장 중...',
                    //     backdrop: false,
                    //     showConfirmButton: false,
                    //     allowOutsideClick: false
                    // });
                    Swal.fire({
                        title: '저장 하시겠습니까?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: '저장',
                        backdrop: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            const formData = insertDataform(costList, detailListRow, detailListRowName);
                            costTempInsert(formData);
                        }
                    })
                }
            }
            
        }
    }

    /**
	 * 비용처리 저장 시 필요한 데이터 형식 FormData로 변환
	 * @param {*} save 
	 * @returns formData
	 */
	function insertDataform(costList, detailListRow, detailListRowName){
		/**
		 * formData 에 append 되는 key의 종류 
		 * 'dto' , 'fileData'
		 */
		const formData = new FormData();
        const detailListRowArray = [];
		
		// cost_file 영수증 사진이 없을 경우 및 이미지 데이터라서 제외
		for (const [index, detail] of detailListRow.entries()) {
            const filteredDetail = Object.entries(detail)
				.filter(([key, value]) => key !== 'cost_file')
				.reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
			
				detailListRowArray.push(filteredDetail)

                costList[detailListRowName] = detailListRowArray;

			// 이미지 데이터 있을 경우만 formData에 append
			if (detail.cost_file && detail.cost_file !== null && detail.cost_file.file ) {
                if ('employee_benefit_detail' in detail) {
                    formData.append('fileData', detail.cost_file.file , detail.employee_benefit_detail+"_"+detail.cost_file.name);
                } else if ('fuel_cost_detail' in detail) {
                    formData.append('fileData', detail.cost_file.file , detail.fuel_cost_detail+"_"+detail.cost_file.name);
                }
			}
		}

		formData.append('dto', JSON.stringify(costList));

		return formData
	}

    // 상신 시 실행
    const { mutate : costInsert } = useMutation(CostSaveInsert, {
        onSuccess: () => {
            resetAmtom()
            navigate('/cost')
        }
    });

    // 임시보관함에 저장 시 실행
    const { mutate : costTempInsert } = useMutation(CostTempSaveInsert, {
        onSuccess: () => {
            resetAmtom()
            navigate('/cost')
        }
    });



    return (
        <SoftBox mb={3}>
            <Grid container spacing={3} mt={0.5} mb={0.5}>
                <Grid item xs={12} md={12} lg={12} display="flex" justifyContent="center" alignItems="center">
                    <SoftBox>
                        <SoftButton variant="gradient" color="info" onClick={costTempSave}>
                            <Icon sx={{ fontWeight: "bold" }}>person_add</Icon>
                            &nbsp;임시저장
                        </SoftButton>
                    </SoftBox>
                    &nbsp;&nbsp;&nbsp;
                    <SoftBox>
                        <SoftButton variant="gradient" color="success" onClick={costListSave}>
                            <Icon sx={{ fontWeight: "bold" }}>person_add</Icon>
                            &nbsp;문서상신
                        </SoftButton>
                    </SoftBox>
                </Grid>
            </Grid>
        </SoftBox>
    )
}


const CostWriteContent = (props) => {
    const {
        setShowModal,
        setModalMent,
        date,
        setDate,
        costCode,
        setCostCode
    } = props;
    
    return ( 
        <Grid container spacing={3} sx={{ mb: 2.5 }}>
            <Grid item xs={12} sm={5} md={5} xl={5}>
                <Card sx={{ height: "100%" }}>
                    <SoftBox mb={3} px={1} pt={3} display="flex" justifyContent="space-between" alignItems="center">
                        <Grid container>
                            <Grid item xs={12}>
                                <SoftTypography variant="h5" fontWeight="bold" textTransform="capitalize" pl={2} >
                                    작성자
                                </SoftTypography>
                            </Grid>
                            <Grid item xs={12}>
                                <CostWriterInput size="lg" />
                            </Grid>
                        </Grid>
                    </SoftBox>
                </Card>
            </Grid>
            <Grid item xs={12} sm={7} md={7} xl={7}>
                <Card sx={{ height: "100%", zIndex: 0 }}>
                    <SoftBox px={1} pt={3.5} display="flex" justifyContent="space-between" alignItems="center">
                        <Grid container>
                            <Grid item xs={12}>
                                <SoftBox mb={3}>
                                    <Grid container>
                                        <Grid item xs={12} sm={3} lg={2} >
                                            <SoftTypography variant="h5" fontWeight="bold" textTransform="capitalize" pl={2} >
                                                작성일
                                            </SoftTypography>
                                        </Grid>
                                        <Grid item xs={12} sm={9} lg={10}>
                                            <Month name="DATE" num='10' type='date' setDate={setDate} temp={date} readOnly={true}></Month>
                                        </Grid>
                                    </Grid>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={12} sm={3} lg={2}>
                                <SoftTypography variant="h5" fontWeight="bold" textTransform="capitalize" pl={2} mt={1}>
                                    구분
                                </SoftTypography>
                            </Grid>
                            <Grid item xs={12} sm={9} lg={10}>
                                <CostClassification setShowModal={setShowModal} 
                                                    setModalMent={setModalMent} 
                                                    costCode={costCode}
                                                    setCostCode={setCostCode}
                                                    />
                            </Grid>
                        </Grid>
                    </SoftBox>
                </Card>
            </Grid>
        </Grid>
    )
}


const CostDetailContent = (props) => {

    const {
        setShowModal,
        setModalMent
    } = props;

    const { state } = useLocation();
    
    //날짜 받기
    const [date, setDate] = useState("");
    // 비용처리 구분
    const [costCode, setCostCode] = useState("");

   
    return (
        <>
            {/* 작성자 , 작성일 , 비용처리 구분 */}
            <CostWriteContent setShowModal={setShowModal} 
                              setModalMent={setModalMent}
                              date={date} 
                              setDate={setDate} 
                              costCode={costCode} 
                              setCostCode={setCostCode}
            />
            {/* 비용처리 디테일 */}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card sx={{ height: "100%" }}>
                        <Grid container p={2}>
                            <DetailCostClassification state={state} 
                                                      costCode={costCode} 
                                                      setCostCode={setCostCode}
                            />
                        </Grid>
                    </Card>
                </Grid>
            </Grid>

            {/* 임시저장 및 저장 버튼 */}
            <CostInsertButton state={state} costCode={costCode} />
        </>
    )
}

export default CostDetailContent