import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material'
import SoftBadge from 'components/SoftBadge'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { getAllRegionList } from '../../../api/workPlace/WorkPlace'
import SoftTypography from '../../../components/SoftTypography'



const SelectBoxWorkPlace = (props) =>{

    const {
        selectedPlace,
        setSelectedPlace
    } = props;

    const {isLoading, isError, data} = useQuery(['getAllRegionInfo'], () => getAllRegionList(),{
        refetchOnWindowFocus:false
    });

    if(isError){
        return (
            <></>
        )
    }

    const getSelectBoxItem = (data) => {
        return (
            data.map(region =>(
                <>
                    <FormControlLabel value={region.regId} control={<Radio />} label={region.regName} />
                </>
            ))
        )
    }

    return(
        <>
            <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">근무지 선택</FormLabel>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={selectedPlace}
                    value={selectedPlace}
                    name="radio-buttons-group"
                    sx={{ px: 2 }}
                    onChange={(event) => {
                        setSelectedPlace(event.target.value)
                    }}
                >
                    <Grid container sx={{ mt: 1 }}>
                        {   
                            data &&
                                getSelectBoxItem(data)
                        }
                    </Grid>
                </RadioGroup>
            </FormControl>
        </>
    )
}
export default SelectBoxWorkPlace;