import { atom } from "recoil";

/**
 *  리스트 변경 사항 적용 시 업데이트 
 * @type {RecoilState<boolean>,  default : false}
 */
export default atom({
    key: 'listUpdate',
    default: false,
});
