import { approverCountAtomFamily } from 'atom/approval/approver_referrer/approverCountAtomFamily';
import { approverInsertAtomFamily } from 'atom/approval/approver_referrer/approverInsertAtomFamily';
import { referrerCountAtomFamily } from 'atom/approval/approver_referrer/referrerCountAtomFamily';
import { referrerInsertAtomFamily } from 'atom/approval/approver_referrer/referrerInsertAtomFamily';
import { CostBenefitDetail, CostEmployeeBenefitList, FuelCostDetail, FuelCostDetailList, PublicTransportationCostDetail, PublicTransportationCostDetailList, TransportationCostDetail, TransportationCostDetailList } from 'atom/cost/CostWriteDetail';
import { myIpAddress } from 'config';
import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { toMap } from 'utils/ToMap';
import { customAxios } from '../../../axios/CustomAxios';
import EmployeeBenefit from './EmployeeBenefit';
import FuelCost from './FuelCost';
import PublicTransportaionCost from './PublicTransportaionCost';
import TransportationCost from './TransportationCost';


const DetailCostClassification = (props) => {
  
  const {
    state,
    costCode,
    setCostCode
  } = props;

  //비용처리 카테고리(복리후생비,교통비,대중교통비)
  // const costCode = useRecoilValue(CostDetailCalssificationCode);
  
  // 하위 컴포넌트에서 아톰패밀리 접근 키값
  let approverKey = "approver_vac";
  let referrerKey = "referrer_vac";
  // const insertKey = "insert_vac";
  if (state.state === undefined) {
      approverKey = "approver_vac";
      referrerKey = "referrer_vac";
  } else {
      approverKey = "approverEditKey_" + state?.state
      referrerKey = "referrerEditKey_" + state?.state
  }
  
  // const [date, setDate] = useState("");
  // const [costNo, setCostNo] = useState(null);

  //복리후생 상세내역 로우 갯수
  const [benefitRowNum, setBenefitRowNum] = useRecoilState(CostBenefitDetail);
  //교통비 상세내역 로우 갯수
  const [transportRowNum, setTransportRowNum] = useRecoilState(TransportationCostDetail);
  //대중교통비 상세내역 로우 갯수
  const [publicRowNum, setPublicRowNum] = useRecoilState(PublicTransportationCostDetail);
  //유류비 상세내역 로우 갯수
  const [fuelRowNum, setFuelRowNum] = useRecoilState(FuelCostDetail);
  //전체 정보
  // const [costList, setCostList] = useRecoilState(CostList);
  //복리후생 상세내용 리스트
  const [costBenefitList, setCostBenefitList] = useRecoilState(CostEmployeeBenefitList);
  //교통비 상세내용 리스트
  const [transportationCostList, setTransportationCostList] = useRecoilState(TransportationCostDetailList);
  //대중교통비 상세내용 리스트
  const [publicTransportationCostList, setPublicTransportationCostList] = useRecoilState(PublicTransportationCostDetailList);
  //유류비 상세내용 리스트
  const [fuelCostListList, setFuelCostListList] = useRecoilState(FuelCostDetailList);

  //상세내역 총 금액
  // const [detailListAmountTotal] = useRecoilState(TotalAmount);
  
  // 결재자 정보
  const [approverInfo, setApproverInfo] = useRecoilState(approverInsertAtomFamily(approverKey));
  const setApproverNum = useSetRecoilState(approverCountAtomFamily(approverKey));

  // 참조자 정보
  const [referrerInfo, setReferrerInfo] = useRecoilState(referrerInsertAtomFamily(referrerKey));
  const setReferrerNum = useSetRecoilState(referrerCountAtomFamily(referrerKey));


  useEffect(() => {
    if(state.state) {
      customAxios.post(myIpAddress + '/cost/costUpdate', {
          cost_no: state.state,
          approval_no : state.approval_no
      }, {
          headers: {
              "Content-Type": "application/json"
          }
      })
        .then((response) => {
          if (response.data != "") {
            // setCostNo(response.data.cost_no)
            // setDate(response.data.cost_date);
            setCostCode(response.data.cost_category)
            if (response.data.detail_otherList !== null && response.data.cost_category === 'C000') {
                setCostBenefitList(response.data.detail_otherList)
                setBenefitRowNum(response.data.detail_otherList.length)
            }
            if (response.data.detail_otherList !== null && response.data.cost_category === 'C001') {
                setTransportationCostList(response.data.detail_otherList)
                setTransportRowNum(response.data.detail_otherList.length)
            }
            if (response.data.detail_trafficList !== null && response.data.cost_category === 'C002') {
                setPublicTransportationCostList(response.data.detail_trafficList)
                setPublicRowNum(response.data.detail_trafficList?.length)
            }
            if (response.data.fuelList !== null && response.data.cost_category === 'C003') {
                setFuelCostListList(response.data.fuelList)
                setFuelRowNum(response.data.fuelList.length)
            }
  
            const approverList = response.data.approvalList;
            const referrerList = response.data.referrerList;
            for (let i = 0; i < approverList?.length; i++) {
                approverList[i].approver_key = `${approverKey}_${(approverList[i].approver_level) + 1}`;
            }
            for (let i = 0; i < referrerList?.length; i++) {
                referrerList[i].referrer_key = `${referrerKey}_${i + 1}`;
            }
            const approverInsert = toMap({ ...approverInfo });
            const referrerInsert = toMap({ ...referrerInfo });
            approverInsert.set("approverList", approverList);
            referrerInsert.set("referrerList", referrerList);
            setApproverInfo(Object.fromEntries(approverInsert));
            setReferrerInfo(Object.fromEntries(referrerInsert));
  
            if (response.data.approvalList !== null) {
                setApproverNum({
                    count: response.data.approvalList.length
                });
            } else {
                setApproverNum({
                    count: null
                });
            }
            if (response.data.referrerList !== null) {
                setReferrerNum({
                    count: response.data.referrerList.length
                });
            } else {
                setReferrerNum({
                    count: null
                });
            }
          }
        });
    }
  }, [])


  //""빈값 C000-복리후생비 C001교통비(기타비용) C002 대중교통비
  if (costCode == "" || costCode=="-선택-") {
    return (
      <div className='DetailCostClassification-Box'>
        <h4>구분을 선택해주세요</h4>
      </div>
    )

  } else if (costCode == 'C000') {
    return (
      <EmployeeBenefit />
    )
  } else if (costCode == 'C001') {
    return (
      <TransportationCost />
    )
  } else if (costCode == 'C002') {
    return (
      <PublicTransportaionCost />
    )
  } else if (costCode == 'C003') {
    return (
      <FuelCost />
    )

  }
}

export default DetailCostClassification