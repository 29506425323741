import React from "react";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import { Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import SoftBadge from "components/SoftBadge";


function WpListTable({ columns, rows , totalCount , page , pageSize}) {

	const { light } = colors;
	const { borderWidth } = borders;
	const { size } = typography;

	const navigate = useNavigate();


	/** 복지포인트 리스트 칼럼  */
	const renderColumns = columns.map(({ name, align, width , }, key) => {
		let pl;
		let pr;

		if (key === 0) { pl = 3; pr = 3; } 
		else if (key === columns.length - 1) { pl = 3; pr = 3; } 
		else { pl = 1; pr = 1; }

		return (
			<SoftBox
				key={name}
				component="th"
				width={width || "auto"}
				pt={1.5}
				pb={1.5}
				pl={align === "left" ? pl : 3}
				pr={align === "right" ? pr : 3}
				textAlign={"center"}
				fontSize={size.sm}
        		color="secondary"
				opacity={0.7}
				sx={{  width: "max-content" }}
				//borderTop={`${borderWidth[1]} solid ${light.main}`}
				borderBottom={`${borderWidth[1]} solid ${light.main}`}
			>
				<SoftTypography
					variant="button"
					fontWeight="bold"
					color= "secondary"
					sx={{ display: "inline-block", width: "max-content" }}
					>
					{name.toUpperCase()}
				</SoftTypography>
			</SoftBox>
		);
	});

  	const renderRows = rows.map((row, index,) => {
		const rowKey = `row-${index}`;
		// 복지포인트 게시물 번호
		const wp_no = row.wp_no

		let listNo =  totalCount - (page - 1) * pageSize - index;
		
		if (listNo > totalCount ) {
			listNo = totalCount
		}
		
		const tableRow = columns.map(({ name, align , key}) => {
			let data = row[key] ? (row[key].value ? row[key].value : row[key]) : undefined;

			let template;
			

			//console.log(listNo , totalCount, index)
			if (key === "wp_approved") {
				template = (
					<SoftBox
					key={uuidv4()}
					component="td"
					p={1.5}
					textAlign={"center"}
					borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
					>
						<SoftBadge 
							variant="contained" 
							badgeContent={data} 
							color={ data === "결재완료" ? "success" : data === "상신문서" ? "info" : data === "임시보관" ? "secondary" : "error"} 
							size="sm" container
							sx={{ display: "inline-block", width: "max-content" , color : "#000"}}
							/>
					</SoftBox>
				);
			} else if (key === "wp_no"){
				template = (
					<SoftBox
					key={uuidv4()}
					component="td"
					p={1.5}
					textAlign={"center"}
					borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
					sx={{  width: "max-content" }}
					>
						<SoftTypography
							variant="button"
							fontWeight={ row[key].style ? row[key].style : "regular"}
							color="dark"
							sx={{ display: "inline-block", width: "max-content" }}
							>
							{listNo}
						</SoftTypography>
					</SoftBox>
				);
			} else{
				template = (
					<SoftBox
					key={uuidv4()}
					component="td"
					p={1.5}
					textAlign={"center"}
					borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
					sx={{  width: "max-content" }}
					>
						<SoftTypography
							variant="button"
							fontWeight={ row[key].style ? row[key].style : "regular"}
							color="dark"
							sx={{ display: "inline-block", width: "max-content" }}
							>
								{key === "wp_no" ? listNo :data}
						</SoftTypography>
					</SoftBox>
				);
			}

			return template;
		});

        return (
			<TableRow 
				onClick={()=> navigate('/welfarePoint/detail',{state : wp_no})} 
				key={rowKey}
				sx={{ "&:hover": { backgroundColor: "#eceff1" , cursor: "pointer" , } }} 
				>
				{tableRow}
			</TableRow>
		)
    });

    return useMemo(
        () => (
                <TableContainer>
                    <MuiTable>
                    <SoftBox component="thead">
                        <TableRow>{renderColumns}</TableRow>
                    </SoftBox>
                    <TableBody>{renderRows}</TableBody>
                    </MuiTable>
                </TableContainer>
            ),
            [columns, rows]
        );
    }

    // Setting default values for the props of Table
    WpListTable.defaultProps = {
    columns: [],
    rows: [{}],
    };

    // Typechecking props for the Table
    WpListTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    rows: PropTypes.arrayOf(PropTypes.object),
    };


export default WpListTable;