import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 휴가 캘린더 데이터.
 * @param vac_start : String
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const vacationCalendarData =(vac_start, approveStatus)=>{
    return customAxios.post(myIpAddress+'/vacation/vacationCalendarData',
        {
            vac_start: vac_start,
            approvedList: approveStatus
        });
}
export default vacationCalendarData;
