import { atom } from "recoil";

//복리후생 상세내역 row 갯수
export const CostBenefitDetail = atom({
    key: 'CostBenefitDetail',
    default:1,
});
//교통비 상세내역 row 갯수
export const TransportationCostDetail = atom({
    key: 'TransportationCostDetail',
    default:1,
});
//대중교통비 상세내역 row 갯수
export const PublicTransportationCostDetail = atom({
    key: 'PublicTransportationCostDetail',
    default:1,
});
//유류비 상세내역 row 갯수
export const FuelCostDetail = atom({
    key: 'FuelCostDetail',
    default:1,
});

export const CostBenefitDetailDelete = atom({
    key: 'CostBenefitDetailDelete',
    default:1,
});

export const SaveCheck = atom({
    key: 'SaveCheck',
    default:0,
});
//상세내용 사용금액 합계
export const TotalAmount = atom({
    key : "TotalAmount",
    default : 0,
});

//복리후생비 상세내용 리스트
export const CostEmployeeBenefitList = atom({
    key: "CostEmployeeBenefitList",
    default: [{
        employee_benefit_detail : 1,
        other_date : "",
        other_category : "",
        other_contents : "",
        other_personnel : "",
        other_amount : "",
        other_paytype : "",
        other_fueldo : ""
    }]
});

//교통비 상세내용 리스트
export const TransportationCostDetailList = atom({
    key: "TransportationCostDetailList",
    default: [{
        employee_benefit_detail : 1,
        other_date : "",
        other_category : "",
        other_contents : "",
        other_personnel : "",
        other_amount : "",
        other_paytype : "",
        other_fueldo : ""
    }]
});

//유류비 상세내용 리스트
export const FuelCostDetailList = atom({
    key: "FuelCostDetailList",
    default: [{
        fuel_cost_detail : 1,
        fuel_date : "",
        fuel_category : "",
        fuel_carnum : "",
        fuel_start : "",
        fuel_end : "",
        fuel_distance : "",
        fuel_amount : "",
        fuel_paytype : ""
    }]
});

//대중교통비 상세내용 리스트
export const PublicTransportationCostDetailList = atom({
    key: "PublicTransportationCostDetailList",
    default: [{
        traffic_detail : 1,
        traffic_date : "",
        traffic_purpose : "",
        traffic_method : "",
        traffic_location : "",
        traffic_way : "",
        traffic_amount : ""
    }]
});

//비용처리 리스트
export const CostList = atom({
    key : "CostList",
    default : {
        mem_no : "",
        auth_no : "",
        cost_team : "",
        cost_rank : "",
        cost_date : "",
        cost_category : "",
        cost_history : "",
        cost_approved : "",
        cost_flow : "",
        cost_regdate : "",
        referrerList : "",
        detail_otherList : "",
        detail_trafficList : "",
        fuelList : ""
    }
})

export const NameCode = atom({
    key : "NameCode",
    default : ""
})
export const RankCode = atom({
    key : "RankCode",
    default : ""
})
export const TeamCode = atom({
    key : "TeamCode",
    default : ""
})
export const CostAdminMonth = atom({
    key : "CostAdminMonth",
    default : []
})

export const CostAdminYear = atom({
    key : "CostAdminYear",
    default : new Date().getFullYear()
})


export const CostAdminPageNumber = atom({
    key : "CostAdminPageNumber",
    default : 1
})

export const CostSentPageNumber = atom({
    key : "CostSentPageNumber",
    default : 1
})

export const CostReceivePageNumber = atom({
    key : "CostReceivePageNumber",
    default : 1
})

export const CostMainPageNumber = atom({
    key : "CostMainPageNumber",
    default : 1
})

export const CostMaintitle = atom({
    key : "CostMaintitle",
    default : 10
})

export const CostSave = atom({
    key : "CostSave",
    default : false
})