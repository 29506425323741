import { ClassNames } from '@emotion/react'
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import React from 'react'
import "../../../styles/Cost/CostSt.css"

const DocumentStatus = (props) => {
  return (
    <Grid item xs={6} sm={6} xl={3}>
        <Card sx={{ boxShadow:3, display:"flex", alignItems:'center', justifyContent:"space-between", cursor:"pointer" }} onClick={()=>{props.setDocumentStateSort(props.title)}} className={props.cName} >
            <CardHeader title={props.title} sx={{pb:0}}></CardHeader>
            <CardContent>
            <Typography sx={{ fontSize: 25 }} color={props.color} gutterBottom>
                {props.count}
            </Typography>
            </CardContent>
        </Card>
    </Grid>
  )
}

export default DocumentStatus