import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

// 공용코드 맵
const CostReceiveList =(memno, page, size)=>{
    return customAxios.get(myIpAddress+`/cost/receiveList/${memno}`,{
        params: {
            page,
            size
        }
    });
}
export default CostReceiveList;