import {Autocomplete, Card, FormGroup, FormLabel, Modal, TextField} from "@mui/material";
import Grid from "@mui/material/Grid";
import SoftTypography from "../../../../../components/SoftTypography";
import React, {useRef, useState} from "react";
import AllUserVacationMonthData from "../../data/AllUserVacationMonthData";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {allUserVacationMonthAtom} from "../../../../../atom/vacation/alluserVacationMonthAtom";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useQueries} from "react-query";
import allCommonCodeMap from "../../../../../api/commonCode/allCommonCodeMap";
import SoftBox from "../../../../../components/SoftBox";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import SelectInput from "@mui/material/Select/SelectInput";
import AdminVacationTable from "./component/AdminVacationTable";
import {vacationAdminPdfAtom} from "../../../../../atom/vacation/vacationAdminPdfAtom";
import VacationPDF from "../../../../vacationView/components/VacationPDF";
import Pagination from "@mui/material/Pagination";
import {receiveDocTotalCount} from "../../../../../atom/approval/receive/receiveDocTotalCount";
import {toMap} from "../../../../../utils/ToMap";
import SoftButton from "components/SoftButton";
import AllVacationPDFViewer from "../AllVacationPDFViewer";

const AllUserMonthVacationInfo = () => {
    const {columns, rows, monthList, yearList} = AllUserVacationMonthData();

    // 월 별 데이터 조회 파라미터 리코일
    const [vacationMonthRecoil, setVacationMonthRecoil] = useRecoilState(allUserVacationMonthAtom);

    // 전체 페이지 수
    const totalCount = vacationMonthRecoil?.totalCount;
    const itemsPerPage = vacationMonthRecoil?.docsNum;
    const totalPages =  Math.ceil(totalCount / itemsPerPage);

    // page 변경
    function handlePageChange(event, value) {
        setVacationMonthRecoil((prevValue) => ({
            ...prevValue,
            nowPage: value,
        }));
    }

    // modal open
    const [modalOpen, setModalOpen] = useState(false);

    /**
     * modal close
     * @param event
     */
    const modalClose = (event) => {
        if (event.target === event.currentTarget) {
            setModalOpen(false);
        }
    };

    // 보여줄 결재 문서 상태
    const [checkboxList, setCheckboxList] = useState(vacationMonthRecoil?.approvedList);

    // 체크박스 값 리스트
    const checkValue = ["AP001", "AP002", "AP003", "AP004"];

    // 배열을 반으로 나누는 함수
    const splitArray = (arr) => {
        const middleIndex = Math.ceil(arr.length / 2);
        const firstHalf = arr.slice(0, middleIndex);
        const secondHalf = arr.slice(middleIndex);
        return [firstHalf, secondHalf];
    };

    const [firstColumnValues, secondColumnValues] = splitArray(checkValue);

    // 체크박스 핸들러
    const handleCheckboxChange = (label) => (event) => {
        const checked = event.target.checked;

        if (checked) {
            setCheckboxList((prevList) => [...prevList, label]);
        } else {
            setCheckboxList((prevList) => prevList.filter((item) => item !== label));
        }
        setVacationMonthRecoil((prevValue) => ({
            ...prevValue,
            approvedList: checked ? [...checkboxList, label] : checkboxList.filter((item) => item !== label),
        }));
    };

    // 공용코드 맵
    const [commonCodeMap, setCommonCodeMap] = useState(new Map());

    const queries = useQueries([
        {
            queryKey: ["allCommonCodeMap"],
            queryFn: () => allCommonCodeMap(),
            onSuccess: (result) => {
                setCommonCodeMap(result.data);
            }
        }
    ]);

    // 월 선택 핸들러
    const handleMonthChange = (event) => {
        setVacationMonthRecoil((prevValue) => ({
            ...prevValue,
            month: event.target.value,
        }));
    };

    // 연 선택 핸들러
    const handleYearChange = (event) => {
        setVacationMonthRecoil((prevValue) => ({
            ...prevValue,
            year: event.target.value,
        }));
    };

    return (
        <Card>
            <Grid container spacing={1} p={1} display="flex" justifyContent="flex-start" alignItems="center">
                <Grid item xs={3} md={3} lg={3}>
                    <SoftTypography variant="h5" fontWeight="bold">
                        월별 휴가 정보 조회
                    </SoftTypography>
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                    {[
                        {values: firstColumnValues},
                        {values: secondColumnValues},
                    ].map(({values}) => (
                        <FormGroup row>
                            {values.map((value) => (
                                <SoftBox mr={2}>
                                    <FormControlLabel
                                        key={value}
                                        control={
                                            <Checkbox
                                                checked={checkboxList.includes(value)}
                                                onChange={handleCheckboxChange(value)}
                                            />
                                        }
                                        label={commonCodeMap[value]?.info}
                                    />
                                </SoftBox>
                            ))}
                        </FormGroup>
                    ))}
                </Grid>
                <Grid container xs={6} md={6} lg={6} spacing={2}>
                    <Grid item xs={5} md={5} lg={5}>
                        <FormControl sx={{width: "100%", border : "1px solid #2a2f4f" , borderRadius: "0.5rem", height : "38px" ,}}>
                            <InputLabel sx={{left: "85%", top:"-35%"}}>년</InputLabel>
                            <SelectInput
                                value={vacationMonthRecoil?.year}
                                onChange={handleYearChange}
                            >
                                {yearList?.map((value) => (
                                    <MenuItem value={value}>{value}</MenuItem>
                                ))}
                            </SelectInput>
                        </FormControl>
                    </Grid>
                    <Grid item xs={5} md={5} lg={5}>
                        <FormControl sx={{width: "100%", border : "1px solid #2a2f4f" , borderRadius: "0.5rem", height : "38px" ,}}>
                            <InputLabel sx={{left: "85%", top:"-35%"}}>월</InputLabel>
                            <SelectInput
                                value={vacationMonthRecoil?.month}
                                onChange={handleMonthChange}
                            >
                                {monthList?.map((value) => (
                                    <MenuItem value={value}>{value}</MenuItem>
                                ))}
                            </SelectInput>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2} md={2} lg={2}>
                        <SoftButton sx={{width: "100%", border : "1px solid #2a2f4f"}} onClick={() => setModalOpen(true)}>
                            프린트
                        </SoftButton>
                        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}
                                onClick={modalClose}>
                                    <AllVacationPDFViewer year={vacationMonthRecoil.year} month={vacationMonthRecoil.month} />
                            </div>
                        </Modal>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <AdminVacationTable columns={columns} rows={rows}/>
                <SoftBox display='flex' justifyContent='center'>
                    <Pagination
                        count={totalPages}
                        page={vacationMonthRecoil?.nowPage}
                        onChange={handlePageChange}
                        showFirstButton
                        showLastButton
                    />
                </SoftBox>
            </Grid>
            {/*<SoftBox>*/}
            {/*    {pdfStatus?.flag? <VacationPDF docNo={pdfStatus?.docNo} flag={pdfStatus?.flag}/>: <></>}*/}
            {/*</SoftBox>*/}
        </Card>
    );
}

export default AllUserMonthVacationInfo;