import {atom} from "recoil";

/**
 * 복지포인트 사용 가능한 달 
 * @type {RecoilState<number>}
 */

export const wpUsableMonth = atom({
    key: "wpUsableMonth"
    , default: 0
})
