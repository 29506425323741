import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import Footer from 'examples/Footer'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import { useEffect } from 'react'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { getAllMemberReservation, getReservationInfo } from '../../api/workPlace/WorkPlace'
import { SelectReservationAtom } from '../../atom/workPlace/ReservationAtom'
import Loading from '../../components/loading/Loading'
import SoftBox from '../../components/SoftBox'
import AllMemberWorkPlace from './components/AllMemberWorkPlace'
import MyWorkPlaceInfo from './components/MyWorkPlaceInfo'
import WorkPlaceContent from './components/WorkPlaceContent'
import WorkPlaceInsertDialog from './components/WorkPlaceInsertDialog'
import WorkPlaceUpdateDialog from './components/WorkPlaceUpdateDialog'
import './workPlace.css'

const WorkPlacePage = () => {

    const navigate = useNavigate();
    const [openInsert, setOpenInsert] = useState(false)
    const [openUpdate, setOpenUpdate] = useState(false);
    const [selectDate,setSelectDate] = useState(new Date());
    const [tabValue, setTabValue] = useState(0);
    const {isLoading, isError, data} = useQuery(['reservationInfo',selectDate]
        , ()=> getReservationInfo(selectDate),{
        refetchOnWindowFocus:false
    });
    const setSelectReservation = useSetRecoilState(SelectReservationAtom);

    if (isLoading){
        return(
            <Loading/>
        )
    }

    const handleUpdate = (reservation) => {
        setSelectReservation(reservation)
        if(isMobile){
            navigate("/workPlace/update")
        }else{
            setOpenUpdate(true)
        }

    }

    const insertWordPlace = () => {
        if(isMobile){
            navigate("/workPlace/insert")
        }else{
            setOpenInsert(!openInsert)
        }
    }

    return (
        <>
            <DashboardLayout>
                <DashboardNavbar/>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={12} >
                        <MyWorkPlaceInfo
                            selectDate={selectDate}
                            insertWordPlace={insertWordPlace}
                            handleUpdate={handleUpdate}
                            tabValue={tabValue}
                            setTabValue={setTabValue}/>
                    </Grid>
                </Grid>
                {
                    tabValue === 1 ?
                        <>
                            <AllMemberWorkPlace selectDate={selectDate} />
                        </>
                    :
                        <>
                            <WorkPlaceContent
                                reservationInfo={data}
                                setSelectDate={setSelectDate}
                                selectDate={selectDate}
                            />
                            
                            <WorkPlaceInsertDialog open={openInsert} setOpen={setOpenInsert} />
                            <WorkPlaceUpdateDialog open={openUpdate} setOpen={setOpenUpdate} />
                        </>
                }
                
                <Footer/>
            </DashboardLayout>
        </>
    )
}

export default WorkPlacePage