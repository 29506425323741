import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 비용처리 결재 반려 
 * @param {*} body 
 * @returns 
 */
const costApprovalReject =(body)=>{
    return customAxios.post(myIpAddress+'/cost/approvalReject', body ); 
}

export default costApprovalReject