import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 복지포인트 해당 게시물 상세보기 
 * @param {*} body 
 * @returns 
 */

const wpDetail =(body)=>{
    return customAxios.post(myIpAddress+'/wp/detail', body);
    
}
export default wpDetail;
