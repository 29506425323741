import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 모든 유저 휴가 정보
 * @param body
 * @returns {Promise<axios.AxiosResponse<any>>}
 * @constructor
 */
const allUserVacationInfo =()=>{
    return customAxios.post(myIpAddress+'/vacation/allUserVacationInfo');
}
export default allUserVacationInfo;