import { Grid, Table as MuiTable, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import AmountFormatInput from 'components/textField/AmountFormatInput';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { v4 as uuidv4 } from "uuid";
import { CostSave, FuelCostDetail, FuelCostDetailList, TotalAmount } from '../../../atom/cost/CostWriteDetail';
import FuelCostList from './FuelCostList';
import TableColumns from './TableColumns';

const FuelCost = () => {

    /** 유류비 상세내역 row 값 (행 갯수)- */
    const [costWriteDetailRowNum, setCostWriteDetailRowNum] = useRecoilState(FuelCostDetail);
    // 유류비 상세내용 리스트
    const [fuelCostListList, setFuelCostListList] = useRecoilState(FuelCostDetailList);
    // 유류비 상세내용 Map
    const [fuelCostListMap, setFuelCostListMap] = useState(new Map);
    // 금액 합계
    const [totalAmount, setTotalAmount] = useState(0);
    // 저장
    const costSave = useRecoilValue(CostSave);


    let [delay, setdelay] = useState(false);
    useEffect(() => {
        let timer = setTimeout(() => { setdelay(true) }, 100);
    })


    useEffect(() => {
        const newMap = new Map(fuelCostListList.map(item => [item.fuel_cost_detail, { ...item }]));
        setFuelCostListMap(newMap);
    }, []); 


    // 저장 
    useEffect(()=>{
        if(costSave) {
            const updatedList = Array.from(fuelCostListMap.values());
            setFuelCostListList(updatedList);
            // console.log("EmployeeBenefit costSave :: ", updatedList)  
        }
    },[costSave])


    const AddCostWriteDetailRow = () => {
        const newMap = new Map(fuelCostListMap);
        // map의 맨 마지막 key 값 이후에 생성되어야 해서 
        const maxKey = Math.max(...fuelCostListMap.keys()) + 1;
        // 새로운 항목 추가
        newMap.set(maxKey, {
            fuel_cost_detail: maxKey,
            fuel_date: "",
            fuel_category: "",
            fuel_carnum: "",
            fuel_start: "",
            fuel_end: "",
            fuel_distance: "",
            cost_filename: "",
            cost_file : "",
            fuel_amount: "",
            fuel_paytype: ""
        });
        setCostWriteDetailRowNum(newMap.size)
        setFuelCostListMap(newMap)
        // console.log("바꾼 맵 값 : ", newMap);
    }


    const removeDetailRow = (rowindex) => {
        // const newRestailRow = fuelCostListMap.filter((item) => item.fuel_cost_detail !== rowindex);
        // setFuelCostListMap(newRestailRow)
        const newFuelCostListMap = new Map(fuelCostListMap);
        newFuelCostListMap.forEach((value, key) => {
            if (value.fuel_cost_detail === rowindex) {
                newFuelCostListMap.delete(key);
            }
        });
        setFuelCostListMap(newFuelCostListMap)
    }

    useEffect(()=> {
        sumAmount()
    },[fuelCostListMap])


    
    const sumAmount = () => {
        let total = 0;
        fuelCostListMap.forEach((value, key) => {
            const fuelAmount = value.fuel_amount;
            if (fuelAmount === "" || fuelAmount === null) {
                total += 0;
            } else {
                total += parseInt(fuelAmount);
            }
        });
        setTotalAmount(total)
    }

    const fuelcolumns = [
        // { key: "grab", name: '', align: "center",  width: "30px" },
        { key: "fuel_date", name: '운행일', align: "center", color: "warning", icon: "event_available", width: "150px" },
        { key: "fuel_category", name: '운행용도', align: "center", color: "success", icon: "fact_check", min_width: "130px" },
        { key: "fuel_carnum", name: '차량번호', align: "center", color: "info", icon: "123", width:"130px",min_width: "130px" },
        { key: "fuel_start", name: '출발지', align: "center", color: "secondary", icon: "directions_run", width: "140px", min_width: "140px" },
        { key: "fuel_end", name: '도착지', align: "center", color: "dark", icon: "keyboard_tab_rtl", width: "140px", min_width: "140px" },
        { key: "fuel_distance", name: '운행거리', align: "center", color: "success", icon: "moving", width: "130px", min_width: "130px" },
        { key: "cost_filename", name: '영수증', align: "center", color: "secondary", icon: "insert_photo", width: "120px", min_width: "120px" },
        { key: "fuel_amount", name: '금액', align: "center", color: "error", icon: "paid", width: "130px",min_width: "130px" },
        { key: "fuel_paytype", name: '결제수단', align: "center", color: "primary", icon: "payment", width: "130px", min_width: "130px" },
        { key: "btn", name: "추가", align: "center", color: "dark", icon: "payment", width: "30px" }
    ]

    const { light } = colors;
    const { borderWidth } = borders;


    const renderColumns = <TableColumns column={fuelcolumns} add={AddCostWriteDetailRow} />


    //드래그를할 애를 잡았을때 인덱스값 저장용
    const [grab, setGrab] = useState(null)

    //드래그 할때
    const _onDragOver = e => {
        e.preventDefault();
    }

    //드래그 시작
    const _onDragStart = (e, item) => {
        const index = Array.from(fuelCostListMap.values())
                        .findIndex(detail => detail.fuel_cost_detail === item.fuel_cost_detail);
        if (index !== -1) {
            setGrab(index);
        }
    }

    //드래그한거를 놨을때
    const _onDrop = (e, item) => {
        e.preventDefault();
        // let grabPosition = Number(grab);
        // let targetPosition = 0;
        // for (const [key, value] of fuelCostListMap.entries()) {
        //     if (value === item) {
        //         targetPosition = key 
        //     }
        // }
        // let _list = [...Array.from(fuelCostListMap.values())];
        
        // // 드롭 대상 위치가 더 뒤에 있는 경우에만 요소 위치를 변경
        // if (grabPosition < targetPosition) {
        //     _list.splice(targetPosition + 1, 0, _list[grabPosition]);
        //     _list.splice(grabPosition, 1);
        // } else {
        //     _list.splice(targetPosition, 0, _list[grabPosition]);
        //     _list.splice(grabPosition + 1, 1);
        // }
        // const newMap = new Map();
        // _list.forEach((value, index) => {
        //     newMap.set(index, value);
        // });
        // setFuelCostListMap(newMap);
        const grabPosition = Number(grab);
        const list = Array.from(fuelCostListMap.values());
        const targetPosition = list.findIndex(detail => detail.fuel_cost_detail === item.fuel_cost_detail);

        // -1 아닐 때만 Map 재배치
        if (targetPosition !== -1) {
            const newList = [...list];
            const movedItem = newList.splice(grabPosition, 1)[0];
            newList.splice(targetPosition, 0, movedItem);

            fuelCostListMap.clear();
            newList.forEach((value, index) => {
                fuelCostListMap.set(index, value);
            });
            setFuelCostListMap(new Map(fuelCostListMap));
        }

    }

    const _onDragLeave = (e, item) => {
        if (grab === fuelCostListList.indexOf(item)) {
            e.target.style.visibility = "hidden";
        }
    }




    return (
        <>

            <TableContainer>
                <MuiTable>
                    <SoftBox component="thead">
                        <TableRow>{renderColumns}</TableRow>
                    </SoftBox>
                    <TableBody>
                        {delay === true ?
                            Array.from(fuelCostListMap.values()).map((item) => (
                                <FuelCostList
                                    key={item.fuel_cost_detail}
                                    rowindex={item.fuel_cost_detail}
                                    removeDetailRow={removeDetailRow}
                                    item={item}
                                    fuelCostListMap={fuelCostListMap}
                                    setFuelCostListMap={setFuelCostListMap}
                                    _onDragOver={_onDragOver}
                                    _onDragStart={_onDragStart}
                                    _onDrop={_onDrop} 
                                    sumAmount={sumAmount}
                                />
                            )) : null
                        }
                        <TableRow key={uuidv4()} bgColor="#b2dfdb" >
                            <TableCell colSpan={11} key={uuidv4()} style={{ borderTop: `${borderWidth[1]} solid ${light.main}` }}>
                                <Grid container justifyContent="flex-end" alignItems="center">
                                    <SoftBox pr={1}  >
                                        <SoftTypography
                                            variant="h4"
                                            color="white"
                                            fontWeight="bold"
                                            sx={{ display: "inline-block", width: "max-content", }}>
                                            합계
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox>
                                        <AmountFormatInput
                                            label="합계"
                                            theme="sum"
                                            size="Normal"
                                            value={totalAmount}
                                            unit="원"
                                        />
                                    </SoftBox>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </MuiTable>
            </TableContainer>
        </>
    )
}


export default FuelCost