import {myIpAddress} from "../../config";
import {customAxios} from "../../axios/CustomAxios";

/**
 * 각종 옵션으로 페이지 게시물 리스트 업
 * @param no : int 멤버번호
 * @param docType : String 문서 종류 공용코드
 * @param category : String 휴가타입
 * @param team : String 팀
 * @param name : String 이름
 * @param startStart : String 출발일 검색시작일
 * @param startEnd : String 출발일 검색종료일
 * @param endStart : String 도착일 검색시작일
 * @param endEnd : String 도착일 검색종료일
 * @param ascending : String 정렬순서
 * @param nowPage : int 요청 페이지
 * @param docsNum : int 최대 보고자 하는 게시글 갯수
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const vacationApprovalDocList = ({
                                     no,
                                     docType,
                                     category,
                                     team,
                                     name,
                                     startStart,
                                     startEnd,
                                     endStart,
                                     endEnd,
                                     ascending,
                                     nowPage,
                                     docsNum
                                 }) => {
    return customAxios.post(myIpAddress + '/vacation/vacationApprovalDocList',
        {
            approver_mem_no: no
            , doc_type: docType
            , vac_category: category
            , vac_team: team
            , mem_name: name
            , vac_start_start: startStart
            , vac_start_end: startEnd
            , vac_end_start: endStart
            , vac_end_end: endEnd
            , ascending: ascending
            , now_page: nowPage
            , docs_num: docsNum
        });
}

export default vacationApprovalDocList;
