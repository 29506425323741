import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";




export const insertCommonCode =(body)=>{
    return customAxios.post(myIpAddress+'/commonCode/insertCode', body);
}


export const deleteCommonCode =(body)=>{
    return customAxios.post(myIpAddress+'/commonCode/deleteCode', body);
}
