import SoftBox from "../../components/SoftBox";
import Card from "@mui/material/Card";
import {useEffect, useState} from 'react';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {useQueries} from "react-query";
import allCommonCodeMap from "../../api/commonCode/allCommonCodeMap";
import vacationCalendarData from "../../api/vacation/vacationCalendarData";
import {add, getYear} from "date-fns";
import GetNowHolyDay from "../../api/date/GetNowHolyDay";
import GetNextHolyDay from "../../api/date/GetNextHolyDay";
import {DateToString} from "../../utils/DateToString";
import SoftTypography from "../../components/SoftTypography";
import Icon from "@mui/material/Icon";
import {useNavigate} from "react-router-dom";
import iconConfig from "../../iconConfig";
import {CircularProgress} from "@mui/material";
import {styled} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import SoftButton from "../../components/SoftButton";
import 'moment/locale/ko';

const CenteredSoftBox = styled(SoftBox)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

/**
 * 캘린더 데이터
 * @param height : String-높이
 * @param minWidth : String-최소 넓이
 * @param fullView : boolean-상세보기 버튼
 * @returns {JSX.Element}
 * @constructor
 */
function CalendarData({height, minWidth, fullView}) {

    const {teamIcon} = iconConfig;

    // 로컬스토리지
    const localStorage = window.localStorage;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate();

    let events = []; // 이벤트
    let holyDayDateList = []; // 공휴일 날짜 list

    // 휴가 검색 기준일
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [vacDate, setVacDate] = useState(DateToString(new Date()).slice(0, 8) + '01')
    // 검색용 결재상태 리스트
    const approveStatus = ["AP003"];

    // 캘린더 처음 마운트 된 여부
    const [isMounted, setIsMounted] = useState(false);

    // query 처음 돌은 여부
    const [firstQuery, setFirstQuery] = useState(false);

    useEffect(() => {
        queries[0].refetch();
    }, [vacDate])

    // 오늘 연도와 다음해 연도
    const toDayYearAndMonth = {
        toDayYear: getYear(new Date()).toString()
        , nextYear: getYear(add(new Date(), {years: 1})).toString()
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const queries = useQueries([
        // queries[0] : 휴가 리스트
        {
            queryKey: ["vacationCalendarData", vacDate, approveStatus],
            queryFn: () => vacationCalendarData(vacDate, approveStatus),
        },
        // queries[1] : 공용코드 리스트
        {
            queryKey: ["allCommonCodeMap"],
            queryFn: () => allCommonCodeMap(),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            }
        },
        // queries[2] : 당해년도 공휴일
        {
            queryKey: ["GetNowHolyDay"],
            queryFn: () => GetNowHolyDay(toDayYearAndMonth),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            }
        },
        // queries[3] : 내년도 공휴일
        {
            queryKey: ["GetNextHolyDay"],
            queryFn: () => GetNextHolyDay(toDayYearAndMonth),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            }
        }
    ]);

    if (queries[0].isLoading && queries[1].isLoading && queries[2].isLoading && queries[3].isLoading) {
        return (
            <CenteredSoftBox>
                <CircularProgress/>
                <SoftTypography variant="h4" fontWeight="medium">
                    Loading.....
                </SoftTypography>
            </CenteredSoftBox>
        );
    }

    // 휴가 리스트 set
    let codeMap = null;
    if (queries[0].isSuccess && queries[1].isSuccess && queries[2].isSuccess && queries[3].isSuccess) {
        // 휴가 리스트
        const vacationList = queries[0].data.data;
        // 공용코드 리스트
        codeMap = queries[1].data.data;
        // 모든 유저 정보
        const GetNowHolyDay = queries[2].data.data;
        // 모든 유저 정보
        const GetNextHolyDay = queries[3].data.data;

        // 휴가 정보
        const vacationDataList = [];

        // 전체유저 휴가 정보
        for (let i = 0; i < vacationList.length; i++) {
            const eventObj = {
                title: `${vacationList[i]["mem_name"]} ${codeMap[vacationList[i]["vac_rank"]]?.info} - ${codeMap[vacationList[i]["vac_category"]]?.info}`,
                start: new Date(vacationList[i]["vac_start"]),
                end: new Date(vacationList[i]["vac_end"]),
                allDay: true,
                groupId: vacationList[i]["vac_team"],
                docNo: vacationList[i]["vac_no"]
            };
            vacationDataList.push(eventObj);
        }

        // 스토리지에 값이 없을때만
        if (localStorage.getItem("localStorageHolyDayData") === null) {
            localStorageSet();
        } else {
            const storageData = JSON.parse(localStorage.getItem("localStorageHolyDayData"));
            const saveTime = new Date(storageData.createdAt);
            const nowTime = new Date();
            const threeWeekInMillis = 21 * 24 * 60 * 60 * 1000;
            // 3주가 지났을 때
            if (nowTime.getTime() - saveTime.getTime() > threeWeekInMillis) {
                // 로컬 데이터 지우고
                localStorage.removeItem('localStorageHolyDayData');
                // 새롭게 저장
                localStorageSet();
            } else {
                // 30일 이전
                events = storageData.value.concat(vacationDataList);
                holyDayDateList = storageData.holyDayStringDateList;
            }
        }

        /**
         * 로컬에 공휴일 데이터 set
         */
        function localStorageSet() {
            // 공휴일 정보
            let holyDayList = [];
            // 공휴일 날짜 String정보
            let holyDayStringDateList = [];

            // api 호출 실행시 200이기 때문에 에러핸들링
            try {
                const thisYearholyDayList = GetNowHolyDay.response.body.items.item; // 올해 공휴일
                const nextYearholyDayList = GetNextHolyDay.response.body.items.item; // 내년 공휴일
                const {yearList, holyDayStringDate} = holyData(thisYearholyDayList, nextYearholyDayList);
                holyDayList = yearList;
                holyDayStringDateList = holyDayStringDate;
            } catch (e) {
                console.log(e);
            }

            const holyDayData = {
                value: holyDayList,
                holyDayStringDateList: holyDayStringDateList,
                createdAt: new Date().toISOString()
            };

            // 로컬에 저장
            localStorage.setItem("localStorageHolyDayData", JSON.stringify(holyDayData));
            // 이벤트 리스트 저장
            events = vacationDataList.concat(holyDayList);
            // 배경색 칠하는 데이터
            holyDayDateList = holyDayStringDateList;
        }

    }

    // 이벤트 색상 변경
    const eventStyleGetter = (event, start, end, isSelected) => {
        let backgroundColor = '';

        // 부서에 맞게 컬러 변경
        if (event.groupId === 'T000') {
            backgroundColor = '#0b4475';
        } else if (event.groupId === 'T001') {
            backgroundColor = '#E384FF';
        } else if (event.groupId === 'T002') {
            backgroundColor = '#749F82';
        } else {
            backgroundColor = '#E96479';
        }

        const style = {
            backgroundColor,
            borderRadius: '2px',
            opacity: 0.8,
            color: 'white',
            fontWeight: 'bold',
            border: '0px',
            overflow: 'hidden',
            textAlign: 'center',
            fontSize: '12px',
            height: '20px',
        };
        return {
            style,
        };
    };

    // 휴일 배경색 변경
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dayPropGetter =
        (date, dateList) => {
            const formattedDate = moment(date).format('YYYYMMDD');
            const isWeekend = moment(date).day() === 6 || moment(date).day() === 0;
            const isHighlighted = dateList.includes(formattedDate);
            return {
                ...(isWeekend && {
                    style: {
                        backgroundColor: '#ffe5e5',
                    },
                }),
                ...(isHighlighted && {
                    style: {
                        backgroundColor: '#ffe5e5',
                    },
                }),
            };
        };

    // 툴바 커스텀
    const MyToolbar = (toolbar) => {

        const goToBack = () => {
            toolbar.onNavigate('PREV');
            toolbar.date.setMonth(toolbar.date.getMonth() - 1);
            const calendarDate = DateToString(toolbar.date);
            setVacDate(calendarDate.slice(0, 8) + '01');  // 연도와 월을 '01'로 변경
        };

        const goToNext = () => {
            toolbar.onNavigate('NEXT');
            toolbar.date.setMonth(toolbar.date.getMonth() + 1);
            const calendarDate = DateToString(toolbar.date);
            setVacDate(calendarDate.slice(0, 8) + '01');  // 연도와 월을 '01'로 변경
        };

        const goToCurrent = () => {
            const now = new Date();
            toolbar.onNavigate('current');
            toolbar.date.setMonth(now.getMonth());
            toolbar.date.setFullYear(now.getFullYear());
            setVacDate(DateToString(now).slice(0, 8) + '01');  // 연도와 월을 '01'로 변경
        };

        const label = () => {
            const date = moment(toolbar.date);
            const monthIndex = date.month();
            return (
                <span>
                    {date.format('YYYY년')}
                    {' '}
                    {monthIndex + 1}월
                 </span>
            );
        };

        return (
            <div className="rbc-toolbar" style={{ flexDirection: "row" }}>
                <div className="rbc-btn-group">
                    <button type="button" onClick={goToBack}>{'<'}</button>
                </div>
                <div className="rbc-toolbar-label" style={{cursor: "pointer",}} onClick={goToCurrent}>{label()}</div>
                <div className="rbc-btn-group">
                    <button type="button" onClick={goToNext}>{'>'}</button>
                </div>
            </div>
        );
    };

    /**
     * 공휴일 리스트 구하는 함수
     * @param thisYearholyDayList : List-올해공휴일리스트
     * @param nextYearholyDayList : List-내년공휴일리스트
     * @returns {yearList,holyDayStringDate}
     */
    function holyData(thisYearholyDayList, nextYearholyDayList) {
        const yearList = [];
        const holyDayStringDate = [];
        for (let i = 0; i < thisYearholyDayList.length; i++) {
            // 제헌절이 리스트에 있으나 공휴일이 아니고 N으로 표시되어있어서 Y 인것만
            if (thisYearholyDayList[i].isHoliday === "Y") {
                const dateString = String(thisYearholyDayList[i].locdate);
                holyDayStringDate.push(dateString);
                const year = dateString.substr(0, 4);
                const month = dateString.substr(4, 2) - 1; // months are zero-based
                const day = dateString.substr(6, 2);
                const holyDate = new Date(year, month, day);
                const thisHolyObj = {
                    title: `${thisYearholyDayList[i].dateName}`,
                    start: new Date(holyDate),
                    end: new Date(holyDate),
                    allDay: true,
                    groupId: 'holyDay'
                }
                yearList.push(thisHolyObj);
            }
        }
        for (let i = 0; i < nextYearholyDayList.length; i++) {
            // 제헌절이 리스트에 있으나 공휴일이 아니고 N으로 표시되어있어서 Y 인것만
            if (nextYearholyDayList[i].isHoliday === "Y") {
                const dateString = String(nextYearholyDayList[i].locdate);
                holyDayStringDate.push(dateString);
                const year = dateString.substr(0, 4);
                const month = dateString.substr(4, 2) - 1; // months are zero-based
                const day = dateString.substr(6, 2);
                const holyDate = new Date(year, month, day);
                const thisHolyObj = {
                    title: `${nextYearholyDayList[i].dateName}`,
                    start: new Date(holyDate),
                    end: new Date(holyDate),
                    allDay: true,
                    groupId: 'holyDay'
                }
                yearList.push(thisHolyObj);
            }
        }
        return ({yearList, holyDayStringDate});
    }

    // 시간 맞추기
    moment.locale('ko-KR');
    const localize = momentLocalizer(moment);

    return (

        <Grid container>
            <Grid item xs={12} md={12} lg={12}>
                <Card>
                    <SoftBox p={1.5}>
                        <SoftBox mt={-1} mb={1} display="flex" justifyContent="space-between"
                                 alignItems="center">
                            <SoftBox display="flex" style={{gap: '10px'}}>
                                <SoftTypography variant="caption" fontWeight="bold" textTransform="capitalize">
                                <span style={{color: '#0b4475'}}><Icon
                                    sx={{fontWeight: "bold"}}>{teamIcon["T000"]}</Icon>{codeMap ? codeMap.T000.info : "IT사업본부"}</span>
                                </SoftTypography>
                                <SoftTypography variant="caption" fontWeight="bold" textTransform="capitalize">
                                <span style={{color: '#E384FF'}}><Icon
                                    sx={{fontWeight: "bold"}}>{teamIcon["T001"]}</Icon>{codeMap ? codeMap.T001.info : "영업본부"}</span>
                                </SoftTypography>
                                <SoftTypography variant="caption" fontWeight="bold" textTransform="capitalize">
                                <span style={{color: '#749F82'}}><Icon
                                    sx={{fontWeight: "bold"}}>{teamIcon["T002"]}</Icon>{codeMap ? codeMap.T002.info : "관리본부"}</span>
                                </SoftTypography>
                            </SoftBox>
                            {fullView ?
                                <SoftBox display="flex" alignItems="center">
                                    <SoftButton variant="gradient" size="small" color="dark"
                                                onClick={() => navigate("/vacation/calendar")} sx={{ whiteSpace: 'nowrap' }}>
                                        <Icon sx={{fontWeight: "bold"}}>zoom_in</Icon>
                                        &nbsp;크게보기
                                    </SoftButton>
                                </SoftBox> : <></>}
                        </SoftBox>
                        <Calendar
                            events={events}
                            localizer={localize}
                            views={['month']}
                            eventPropGetter={eventStyleGetter}
                            style={{height: `${height}vh`, minWidth: `${minWidth}vh`}}
                            dayLayoutAlgorithm = 'no-overlap'
                            onSelectEvent={(e) => {
                                navigate("/vacation/view", {state: e.docNo});
                            }}
                            // selected={(e) => {
                            //     console.log("");
                            // }}
                            dayPropGetter={(date) => dayPropGetter(date, holyDayDateList)}
                            showAllEvents={true}
                            components={{
                                toolbar: MyToolbar
                            }}
                        />
                    </SoftBox>
                </Card>
            </Grid>
        </Grid>

    );
}

export default CalendarData;