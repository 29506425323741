/**
 * 두 날짜의 차이
 * @param date1 : Date
 * @param date2 : Date
 * @returns {number}
 */
export function diffDays(date1, date2) {
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

/**
 * 두 날짜 사이에 해당하는 날짜가 존재하는지 확인
 * @param date : Date
 * @param startTargetDate : Date
 * @param endTargetDate : Date
 * @returns {boolean}
 */
export function dateExists(date, startTargetDate, endTargetDate) {
    return date >= startTargetDate && date <= endTargetDate;
}
