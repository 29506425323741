import {ACCESS_TOKEN, REFRESH_TOKEN} from 'config';
import React, {useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

function LoginSuccess() {

    const navigate = useNavigate();
    // const [searchParams, setSearchParams] = useSearchParams();
    // const token = searchParams.get("token");
    // const refreshToken = searchParams.get("refresh_token");
    navigate("/dashboard");
    // localStorage.setItem(ACCESS_TOKEN, JSON.stringify({token: token, createdAt: new Date().toISOString()}));
    // localStorage.setItem(REFRESH_TOKEN, JSON.stringify({token: refreshToken, createdAt: new Date().toISOString()}));

}

export default LoginSuccess;