import React ,  { useEffect, useMemo, useRef, useState }from "react";
import PropTypes from "prop-types";

// api
// atom
// util
import AmountFormatInput from '../../../components/textField/AmountFormatInput';

// wp components

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import {  Grid, Icon, IconButton, Table as MuiTable, TableBody, TableCell } from "@mui/material";
import { TableContainer, TableRow } from "@mui/material";

// Soft UI Dashboard React examples
import borders from "assets/theme/base/borders";
import SoftBox from "components/SoftBox/index.js";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography.js";
import SoftTypography from "components/SoftTypography/index.js";
import SoftButton from "components/SoftButton";
import "./modalCss.css";
import WpImgModal from "./WpImgModal";
import { useLocation } from "react-router-dom";


/**
 * 복지포인트 상세 페이지 테이블
 * @param {columns : [{}], rows : [{}] , totalpoint : int} 
 * @returns 
 */
function WpDetailTable ({ columns, rows, totalpoint, wp_date }) {
    /** 이미지 관련 모달 창 */
    const [modalOpen, setModalOpen] = useState(false);
    const [imgSrc, setImgSrc] = useState('');

    const handleClick = (img) => {
        setImgSrc(img);
        // setModalOpen(true);
        window.open(window.location.origin + img, '_blank');
    };

    const { light } = colors;
	const { size, fontWeightBold } = typography;
	const { borderWidth } = borders;

    const renderColumns = columns.map(({ name, align, color, icon, key }, index) => {
		let pl;
		let pr;
		if (key === 0) {pl = 3;pr = 3;
		} else if (key === columns.length - 1) {pl = 3;pr = 3;
		} else {pl = 1;pr = 1;
		}

        return (
			<SoftBox
                key={`columns_${key}_${index}`} 
                component="th" 
                width={"auto"} pt={1.5} pb={1.75} pl={align === "left" ? pl : 3} pr={align === "right" ? pr : 3}
				textAlign="center" fontSize={size.lg} fontWeight={fontWeightBold} color= "secondary" alignItems="center"
				opacity={0.7} borderBottom={`${borderWidth[1]} solid ${light.main}`} 
			    >   
                <SoftBox display="flex" alignItems="center" justifyContent="center">
                    <SoftBox
                        bgColor={color}
                        width="1.5rem"
                        height="1.5rem"
                        borderRadius="sm"
                        color="white"
                        fontSize={size.md}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        shadow="md"
                        mr={1}
                        variant="gradient"
                    >
                        <Icon>{icon}</Icon>
                    </SoftBox>
                    <SoftTypography
                        variant="h6"
                        textTransform="capitalize"
                        fontWeight="medium"
                        color="text"
                        sx={{ display: "inline-block", width: "max-content" ,   }}
                        >
                        {name}
                    </SoftTypography>
                </SoftBox>
			</SoftBox>
		);
	});


  	const renderRows = rows.map((row, rowIndex) => {
		const rowKey = `row-${rowIndex}`;
        
		const tableRow = columns.map(({ name, key }, colIndex) => {
            let data = row[name]
            
            // 영수증 처리
            if(key === "wp_filename"){

                return (
                    <SoftBox
                        key={`${key}_${colIndex}`}
                        component="td" pt={1.5} pb={1.6} textAlign={"center"}
                        >
                        {  row[name] ? (
                            <div>
                                <SoftButton color="info" size="small" onClick={() => handleClick(row["wp_filename_real"])} >
                                    영수증 보기
                                </SoftButton>
                                {/* {modalOpen && (
                                    <WpImgModal
                                        open={modalOpen}
                                        header="영수증"
                                        setModalOpen={setModalOpen}
                                        imgSrc={imgSrc}
                                        >
                                    </WpImgModal>
                                )} */}
                            </div>) 
                            : 
                            <SoftButton key={`${key}_${colIndex}`} variant="outlined" color="info" size="small" disabled>
                                영수증 없음
                            </SoftButton>     
                        }
                    </SoftBox>
                )
            }
            return (
                <SoftBox
                    key={`${key}_${colIndex}`} 
                    component="td" pt={1.5} pb={1.6} textAlign={"center"}
                    >
                    <SoftTypography
                        variant="button" fontWeight="regular"
                        sx={{ display: "inline-block", width: "max-content" }}
                        > 
                        {data}
                    </SoftTypography>
                </SoftBox>
            )
   
		});
        return <TableRow key={`tableRow_${rowKey}`}>{tableRow}</TableRow>;
    });

    return useMemo(
        () => (
            <>
            <SoftBox>
                <TableContainer>
                    <MuiTable>
                        <SoftBox component="thead">
                            <TableRow>{renderColumns}</TableRow>
                        </SoftBox>
                        <TableBody>
                            {renderRows}
                            <TableRow sx={{ backgroundColor: "#b2dfdb" }}  >
                                <TableCell colSpan={6} style={{ borderTop: `${borderWidth[1]} solid ${light.main}` }}>
                                    <Grid container justifyContent="flex-end" alignItems="center">
                                        <SoftBox pr={1}  >
                                            <SoftTypography
                                                variant="h4"
                                                color="white"
                                                fontWeight="bold"  
                                                sx={{ display: "inline-block", width: "max-content" ,   }}>
                                                합계 
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftBox>
                                            <AmountFormatInput
                                                theme = "sum"
                                                size="Normal"
                                                value = {totalpoint} 
                                                unit = "원"
                                            />  
                                        </SoftBox>
                                    </Grid>
                                </TableCell>
                            </TableRow>    
                        </TableBody>
                    </MuiTable>
                </TableContainer>
            </SoftBox>
            </>
            ),
        [columns, rows]
    );
}

    // Setting default values for the props of Table
    WpDetailTable.defaultProps = {
    columns: [{}],
    rows: [{}],
    };

    // Typechecking props for the Table
    WpDetailTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    rows: PropTypes.arrayOf(PropTypes.object),
    };


 

export default WpDetailTable;
