import {atom} from "recoil";

/**
 * 복지포인트 row 의 count 
 * @type {RecoilState<number>}
 */

export const wpRowCountAtom = atom({
    key : "wpRowCountAtom",
    default : 1
})