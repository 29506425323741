import {atomFamily} from "recoil";

/**
 * 참조자 입력용 아톰패밀리
 * @type {(param: SerializableParam) => RecoilState<{referrerList: null, key: *}>}
 */
export const referrerInsertAtomFamily = atomFamily({
    key: 'referrerInsertAtomFamily',
    default:(key) => ({
        key: key,
        referrerList:null
    })
});
