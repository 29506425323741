import SoftBox from "../../../../../../components/SoftBox";
import Pagination from "../../../../../../components/pagination/Pagination";
import {useRecoilValue} from "recoil";
import WpTable from "../../../../../../examples/Tables/WpTable";
import { loginUser } from "atom/member/loginUser";
import { useState } from "react";
import { useQuery } from "react-query";
import allCommonCodeMap from "api/commonCode/allCommonCodeMap";
import wpReceiveDocList from "api/wp/wpReceiveDocList";
import SoftTypography from "components/SoftTypography";
import { myIpAddress } from "config";
import { customAxios } from "../../../../../../axios/CustomAxios";
import { getFormattedDateTitle } from "layouts/wp/components/getFormattedDateTitle";




/**
 * 복지포인트 문서 수신함
 * @returns 
 */
function WpReceiveDocs () {

    const user = useRecoilValue(loginUser);

    // 타이틀
    let title = "";
    let progress;
    let cancelReason = '';

    // 현재 페이지 
    const [ page , setPage ] = useState(1);
    // 전체 페이지 수
    //let totalCount;
    const [ totalCount , setTotalCount ] = useState(0);
    // 페이지당 아이템 개수
    const [ pageSize , setPageSize] = useState(10); 


    /**  */
    const body = { 
        mem_no : user.id
        , page : page === 1 ? 0 : ((page - 1) * pageSize)
        , pageSize : pageSize
        , stype : ""
        , wp_approved : ""  // 임시 보관 문서만 제외 하기때문에
    };

    //
    const { data:list } = useQuery(['wpReceiveDocList', body ] , () => wpReceiveDocList(body) , {
        select : (data) => {
            return data.data;
        },
    })

    const countBody = {
        mem_no : user.id
        , stype : "receive"
    }

    // useEffect(()=> {
    //     customAxios.post(myIpAddress + '/wp/wpDocTotalCount', countBody, {
    //     })
    //     .then((response) => {
    //         console.log(response)
    //         response.data = totalCount;
    //     })
    // },[countBody])


    const wpDocTotalCount = (body) => {
        return customAxios.post(myIpAddress+'/wp/wpDocTotalCount', body);
    }
    const { data:count } = useQuery(['wpDocTotalCount', body], () => wpDocTotalCount(countBody) , {
        onSuccess : (count) => {
            setTotalCount(count.data);
        },
    });

    const { data:info} = useQuery('allCommonCodeMap', allCommonCodeMap, {
        select : (data) => {
            return data.data;
        }
    });

    /**  */
    const tableDataList= [];

    if(list && info) {
        const wpList = list;

        for(let i=0; i<wpList.length; i++){

            let cancelBool = false;
             // 결재여부(x차 결재)
            const approvalDo = wpList[i]["approval_do"];
            // 결재자 수
            const approvalApprovers = wpList[i]["approval_approvers"];
            // 문서 번호
            const docNo = wpList[i]["wp_no"];
            // 작성일
            const wp_date = wpList[i]["wp_date"]
            // 복지 포인트 제목
            title = getFormattedDateTitle(wp_date, "복지포인트");
            // 작성자 이름
            const member = "["+info[wpList[i]["mem_team"]]?.info+"] "+wpList[i]["mem_name"]+" "+info[wpList[i]["mem_rank"]]?.info+"";
            // 문서 번호
            const viewNo = i+1;
            // 결재자 정보
            const approverDataList = wpList[i]["approvalList"];
            // 결재자 정보 저장용 리스트
            const approverList = [];
            // 문서 결재상태
            const approved = wpList[i]["wp_approved"];

            if(approved === "AP004") {
                for(let j=0; j<approverDataList.length; j++){
                    const tinyInfoSetList = [];
                    const approverRank = info[approverDataList[j]["approver_rank"]]?.info; // 결재자 랭크
                    const approverName = approverDataList[j]["approver_name"]; // 결재자 이름
                    const approverLevel = approverDataList[j]["approver_level"];
                    const approverImg = approverDataList[j]["approver_img"]; // 결재자 프로필사진
    
                    // 저장용 리스트에 저장
                    tinyInfoSetList.push(approverImg);
                    tinyInfoSetList.push("["+(approverLevel+1)+"번 결재자] "+approverName+" "+approverRank);
    
                    // 저장용 리스트에 저장
                    approverList.push(tinyInfoSetList);
                }

                cancelBool = true;
                cancelReason = wpList[i]["approval_cancel_reason"];
                // 결재 진행 상황
                progress = (approvalDo/approvalApprovers)*100;
            } else{
                for (let j = 0; j < approverDataList.length; j++) {
                    const tinyInfoSetList = [];
                    const approverRank = info[approverDataList[j]["approver_rank"]]?.info; // 결재자 랭크
                    const approverName = approverDataList[j]["approver_name"]; // 결재자 이름
                    const approverLevel = approverDataList[j]["approver_level"];
                    const approverImg = approverDataList[j]["approver_img"]; // 결재자 프로필사진

                    // 저장용 리스트에 저장
                    tinyInfoSetList.push(approverImg);
                    tinyInfoSetList.push("[" + (approverLevel + 1) + "번 결재자] " + approverName + " " + approverRank);

                    // 저장용 리스트에 저장
                    approverList.push(tinyInfoSetList);
                }
            }
            // 결재 진행 상황
            progress = (approvalDo/approvalApprovers)*100;

            const saveMap ={
                viewNo: viewNo,
                docNo:docNo,
                title: title,
                member:member,
                approvers: approverList,
                progress: progress,
                cancelBool: cancelBool,
                cancelReason: cancelReason
            }

            // 전체 리스트에 저장
            tableDataList.push(saveMap);            
            //console.log(tableDataList);
        }
    }
  
    const columns = [
        { title: "no", name : "번호" , align: "center" , width : "10%"},
        { title: "wpinfo", name: "문서정보", align: "left" , width : "35%"},
        { title: "member", name: "작성자", align: "left" , width : "23%"},
        { title: "approver", name: "결재자",align: "left", width : "12%"},
        { title: "approvalprogress", name: "결재상태", align: "center" , width : "20%"},
    ]

    return (
        list &&
        tableDataList &&
        tableDataList.length > 0 ? (
            <>
                <WpTable 
                    columns={columns} 
                    tableDataList={tableDataList} 
                    totalCount={totalCount} 
                    pageSize={pageSize}
                    page={page} />
                <SoftBox pt={2}
                    display="flex"
                    justifyContent="center"
                    >
                    <Pagination 
                        totalCount={totalCount} 
                        pageSize={pageSize}
                        page={page}
                        setPage={setPage}
                        size="small"
                    />
                </SoftBox>
            </>
         ) : (
            <>
                <SoftBox>
                    <SoftTypography
                        variant="button"
                        fontWeight="lg"
                        color= "secondary"
                        >
                        수신 문서 내역이 없습니다.
                    </SoftTypography>
                </SoftBox>
            </>
        )
    );
}

export default WpReceiveDocs;