import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 복지포인트 상신문서 임시보관함으로 변경
 * @param {*} body 
 * @returns 
 */

const wpTemporaryStore =(body)=>{
    return customAxios.post(myIpAddress+'/wp/tempStore', body ); 
}
export default wpTemporaryStore;
