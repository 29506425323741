import Footer from "../../examples/Footer";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import SoftBox from "../../components/SoftBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SoftTypography from "../../components/SoftTypography";
import SoftButton from "../../components/SoftButton";
import Icon from "@mui/material/Icon";
import {QueryClient, useMutation, useQueries} from "react-query";
import allCommonCodeMap from "../../api/commonCode/allCommonCodeMap";
import {useEffect, useState} from "react";
import Swal from "sweetalert2";
import bugInsert from "../../api/bug/bugInsert";
import Loading from "../../components/loading/Loading";
import {isRecoilValue, useRecoilValue, useResetRecoilState} from "recoil";
import {loginUser} from "../../atom/member/loginUser";
import bugUpdate from "../../api/bug/bugUpdate";
import Pagination from "@mui/material/Pagination";
import BugTable from "../../examples/Tables/BugTable";
import getBugList from "../../api/bug/getBugList";
import Tooltip from "@mui/material/Tooltip";
import SoftAvatar from "../../components/SoftAvatar";
import {TimestampToStringDate} from "../../utils/DateUtils";
import SoftProgress from "../../components/SoftProgress";
import {bugDocState} from "../../atom/bug/bugDocState";
import {Modal} from "@mui/material";
import SoftInput from "../../components/SoftInput";
import DatePicker from "react-datepicker";
import {format} from "date-fns";
import "../../styles/datePicker/DatePicker.css"
import { bugContents } from "../../atom/bug/bugContents";

/**
 * 버그 리포트 페이지
 * @returns {JSX.Element}
 * @constructor
 */
function BugReportPage() {

    // react query
    const queryClient = new QueryClient();

    // login 정보
    const recoilState = isRecoilValue(loginUser);
    const user = useRecoilValue(loginUser);

    useEffect(() => {
        if (recoilState) {
            queryClient.refetchQueries('allCommonCodeMap');
        }
    }, [recoilState]);

    // 로그인 유저 정보
    const [userNameCard, setUserNameCard] = useState("");

    // 공용코드 맵
    const [codeMap, setCodeMap] = useState(new Map);

    // 현재 페이지
    const [nowPage, setNowPage] = useState(1);

    // 페이지당 문서 갯수
    const [size, setSize] = useState(10);

    // 전체 문서 갯수
    const [totalPages, setTotalPages] = useState(0);

    // 문서 리스트
    const [docList, setDocList] = useState([]);

    // 테이블 데이터 리스트
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);

    // 클릭한 문서 번호
    const docNumStateRecoil = useRecoilValue(bugDocState);
    // 리코일 초기화
    const docNumRecoilReset = useResetRecoilState(bugDocState);
    // 상세보기 뜨는 관련 bool값
    const [firstReport, setFirstReport] = useState(false);

    // modal open
    const [modalViewOpen, setModalViewOpen] = useState(false);
    const [modalInsertOpen, setModalInsertOpen] = useState(false);

    // 버그 발생 위치
    const [bugLocation, setBugLocation] = useState("");
    // 버그 발생 상황
    const [bugMoment, setBugMoment] = useState("");
    // 버그 발생 내용
    const [bugContent, setBugContent] = useState("");
    const editorBugContents = useRecoilValue(bugContents)
    // 버그 발생 시간
    const [nowDate, setNowDate] = useState(new Date);

    // 리코일 값 초기화
    useEffect(()=>{
        docNumRecoilReset();
        setFirstReport(true);
    },[]);

    /**
     * modalOpne fuc
     */
    const modalInsertOpenFuc = () => {
        setUserNameCard("["+codeMap[user?.team]?.info+"] "+user?.name+" "+codeMap[user?.rank]?.info+"");
        setModalInsertOpen(true);
    };

    /**
     * modal close
     * @param event
     */
    const modalViewClose = (event) => {
        if (event.target === event.currentTarget) {
            setModalViewOpen(false);
            docNumRecoilReset();
            setBugLocation("")
            setBugMoment("")
            setBugContent("")
        }
    };

    /**
     * modal close
     * @param event
     */
    const modalInsertClose = (event) => {
        if (event.target === event.currentTarget) {
            setModalInsertOpen(false);
            setBugLocation("")
            setBugMoment("")
            setBugContent("")
        }
    };

    useEffect(()=>{
        if(firstReport && docNumStateRecoil !== 0) {
            for(let i=0; i<docList.length; i++) {
                if(docNumStateRecoil === docList[i]?.bugNo) {
                    setUserNameCard("["+codeMap[docList[i]?.memTeam]?.info+"] "+docList[i]?.memName+" "+codeMap[docList[i]?.memRank]?.info+"");
                    setNowDate(new Date(docList[i]?.bugDate));
                    setBugMoment(docList[i]?.bugMoment);
                    setBugLocation(docList[i]?.bugLocation);
                    setBugContent(docList[i]?.bugContent);
                    break;
                }
            }
            setModalViewOpen(true);
        }
    },[docNumStateRecoil])

    const queries = useQueries([
        // 공용코드 리스트
        {
            queryKey: ["allCommonCodeMap"],
            queryFn: () => allCommonCodeMap(),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
                initialData: [],
            },
            onSuccess: (data) => {
                setCodeMap(data.data);
            }
        },
        // 전체 문서
        {
            queryKey: ["getBugList", {
                page: nowPage-1,
                size : size
            }],
            queryFn: () => getBugList({page:nowPage-1, size:size}),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
                initialData: [],
            },
            onSuccess: (data) => {
                setTotalPages(data.data.totalPages);
                setDocList(data.data.content);
            }
        },
    ]);

    useEffect(()=>{
        tableDataRender();
    },[docList])

    /**
     * row set
     */
    const tableDataRender = () => {
        const dataArr = [];
        const tableDataList = [...docList];
        for (let i = 0; i < tableDataList.length; i++) {
            const reporterCard = "["+codeMap[tableDataList[i]?.memTeam]?.info+"] "
                + tableDataList[i]?.memName+" "
                + codeMap[tableDataList[i]?.memRank]?.info
            dataArr.push(
                {
                    no: tableDataList[i]?.bugNo,
                    docNo: tableDataList[i]?.bugNo,
                    bugLocation: tableDataList[i]?.bugLocation,
                    reporter: (
                        <SoftBox display="flex" py={1}>
                            {avatars(
                                tableDataList[i]?.memImg,
                                reporterCard
                            )
                            }
                        </SoftBox>
                    ),
                    bugDate: TimestampToStringDate(tableDataList[i]?.bugDate),
                    fixProgress: (
                        <SoftBox width="8rem" textAlign="left">
                                <SoftProgress value={(tableDataList[i]?.bugFix * 50)} color="info" variant="gradient"
                                              label={true}/>
                        </SoftBox>
                    )
                }
            );
        }
        setRows(dataArr);

        const colArr = [
            {name: "no", align:"center"},
            {name: "reporter", align: "center"},
            {name: "bugLocation", align: "left"},
            {name: "bugDate", align: "center"},
            {name: "fixProgress", align: "center"},
        ];

        setColumns(colArr);
    };

    const {mutate: addInsertBody, isLoading: insertLoading} = useMutation(bugInsert, {
        onSuccess: (data) => {
            setModalInsertOpen(false);
            queries[1].refetch()
                .then(()=>handleSucess("입력"))
                .catch(e => console.log(e));
        },
        onError: () => {
            handleError("등록")
        }
    })

    const {mutate: addEditBody, isLoading: editLoading} = useMutation(bugUpdate, {
        onSuccess: (data) => {
            setModalViewOpen(false);
            queries[1].refetch()
                .then(()=>handleSucess("업데이트"))
                .catch(e => console.log(e));
        },
        onError: () => {
            handleError("수정")
        }
    })

    /**
     * 버그리포트 에러 핸들
     */
    const handleError = (title) => {
        Swal.fire({
            title: `${title}중 에러가 발생했습니다.`,
            icon: 'warning',
            backdrop: false
        })
    }

    /**
     * 버그리포트 성공시
     */
    const handleSucess = (title) => {
        Swal.fire({
            title: `정상적으로 ${title}되었습니다.`,
            icon: 'success',
            backdrop: false
        })
    }

    /**
     * 버그 리포트 입력
     */
    const insertBug = () => {
        addInsertBody({
            memNo: user?.id,
            memRank: user?.rank,
            memTeam: user?.team,
            memImg: user?.imageUrl,
            memName: user?.name,
            bugLocation: bugLocation,
            bugMoment: bugMoment,
            bugContent: bugContent,
            bugDate: nowDate,
        })
    }

    /**
     * 버그 리포트 수정
     * @param fix : int-( 1: 진행중, 2:수정완료)
     */
    const editBug = (fix) => {
        addEditBody({
            bugNo: docNumStateRecoil,
            bugFix: fix,
        })
    }

    // 로딩화면
    if (editLoading || insertLoading) {
        return (
            <Loading/>
        )
    }

    // page 변경
    function handlePageChange(event, value) {
        setNowPage(value);
    }

    /**
     * 이름과 이미지로 호버시 팀,이름,직책 나옴
     * @returns {*}
     * @param image
     * @param name
     */
    const avatars = (image,name) =>
        (
            <Tooltip title={name} placeholder="bottom">
                <SoftAvatar
                    src={image}
                    alt="name"
                    size="xs"
                    sx={{
                        border: ({borders: {borderWidth}, palette: {white}}) =>
                            `${borderWidth[2]} solid ${white.main}`,
                        cursor: "pointer",
                        position: "relative",

                        "&:not(:first-of-type)": {
                            ml: -1.25,
                        },

                        "&:hover, &:focus": {
                            zIndex: "10",
                        },
                    }}
                />
            </Tooltip>
        );

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <SoftBox py={1}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        <Card>
                            <Grid p={3} container spacing={1}>
                                <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
                                    <SoftTypography variant="h5" gutterBottom fontWeight="bold">
                                        버그리포트
                                    </SoftTypography>
                                    <SoftButton variant="gradient" color="dark" onClick={()=> {
                                        modalInsertOpenFuc()
                                    }}>
                                        <Icon sx={{fontWeight: "bold"}}>person_add</Icon>
                                        &nbsp;리포트 추가
                                    </SoftButton>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <BugTable columns={columns} rows={rows}/>
                                    <SoftBox display='flex' justifyContent='center'>
                                        <Pagination
                                            count={totalPages}
                                            page={nowPage}
                                            onChange={handlePageChange}
                                            showFirstButton
                                            showLastButton
                                        />
                                    </SoftBox>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
            {/* modal - insert*/}
            <Modal open={modalInsertOpen} onClose={() => setModalInsertOpen(false)} >
                <SoftBox display="flex" justifyContent="center" alignItems="center" height="100%" width="80%" margin="auto"
                     onClick={modalInsertClose}>
                    <Grid p={3} contatiner>
                        <Grid item xs={12}>
                            <Card sx={{borderRadius: 10, border: '1px solid black'}}>
                                <Grid pt={3} mt={2} container spacing={1} >
                                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center"
                                          sx={{backgroundColor: "#f0f0f0"}}
                                    >
                                        <SoftTypography variant="h4" fontWeight="bold">
                                            버그 리포트
                                        </SoftTypography>
                                    </Grid>
                                </Grid>
                                <Grid p={3} mb={3} container spacing={1}>
                                    <Grid item xs={3} display="flex" justifyContent="center" alignItems="flex-start">
                                        <SoftTypography variant="h4" fontWeight="bold">
                                            작성자
                                        </SoftTypography>
                                    </Grid>
                                    <Grid item xs={9} justifyContent="center">
                                        <SoftTypography variant="h5" fontWeight="bold">
                                            {[...userNameCard]}
                                        </SoftTypography>
                                    </Grid>
                                    <Grid item xs={3} mt={2} display="flex" justifyContent="center" >
                                        <SoftTypography variant="h4" fontWeight="bold">
                                            버그 발생 위치
                                        </SoftTypography>
                                    </Grid>
                                    <Grid item xs={3} mt={2} display="flex" justifyContent="center" alignItems="center">
                                        <SoftInput
                                            size="small"
                                            value={bugLocation}
                                            onChange={(event)=>setBugLocation(event.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={3} mt={2} display="flex" justifyContent="center">
                                        <SoftTypography variant="h4" fontWeight="bold">
                                            버그 발생 시간
                                        </SoftTypography>
                                    </Grid>
                                    <Grid item xs={3} mt={2} display="flex" justifyContent="center" alignItems="center">
                                        <SoftInput
                                            size="small"
                                            value={format(nowDate, 'yyyy-MM-dd HH:mm')}
                                            onClick={() => document.getElementsByClassName("bug-insert-datepicker")[0].click()}
                                        />
                                        <DatePicker
                                            selected={nowDate}
                                            onChange={(date) => setNowDate(date)}
                                            showTimeSelect
                                            className="bug-insert-datepicker"
                                            dateFormat="yyyy-MM-dd hh:mm"
                                        />
                                    </Grid>
                                    <Grid item xs={3} mt={2} display="flex" justifyContent="center">
                                        <SoftTypography variant="h4" fontWeight="bold">
                                            버그 발생 상황
                                        </SoftTypography>
                                    </Grid>
                                    <Grid item xs={9} mt={2} display="flex" justifyContent="center" alignItems="center">
                                        <SoftInput
                                            multiline
                                            rows={2}
                                            onChange={(event)=>setBugMoment(event.target.value)}
                                            value={bugMoment}
                                        />
                                    </Grid>
                                    <Grid item xs={3} mt={2} display="flex" justifyContent="center">
                                        <SoftTypography variant="h4" fontWeight="bold">
                                            버그 발생 내용
                                        </SoftTypography>
                                    </Grid>
                                    <Grid item xs={9} mt={2} display="flex" justifyContent="center" alignItems="center">
                                    <SoftInput
                                            multiline
                                            rows={2}
                                            onChange={(event)=>setBugContent(event.target.value)}
                                            value={bugContent}
                                        />
                                    </Grid>
                                    <Grid item xs={12} mt={2} display="flex" justifyContent="center" alignItems="center">
                                        <SoftButton variant="gradient" color="dark" onClick={insertBug} sx={{marginRight : "10px"}}>
                                            <Icon fontSize="large" sx={{fontWeight: "bold"}}>post_add</Icon>
                                            &nbsp;리포트 입력
                                        </SoftButton>
                                        <SoftButton variant="gradient" color="dark" onClick={modalInsertClose}>
                                            <Icon fontSize="large" sx={{fontWeight: "bold"}}>cancel</Icon>
                                            &nbsp;취소
                                        </SoftButton>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </SoftBox>
            </Modal>
            {/* modal - view*/}
            <Modal open={modalViewOpen} onClose={() => setModalViewOpen(false)}>
                <SoftBox display="flex" justifyContent="center" alignItems="center" height="100%" width="80%" margin="auto"
                         onClick={modalViewClose}>
                    <Grid p={3} contatiner>
                        <Grid item xs={12}>
                            <Card sx={{borderRadius: 10, border: '1px solid black'}}>
                                <Grid pt={3} mt={2} container spacing={1} >
                                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center"
                                          sx={{backgroundColor: "#f0f0f0"}}
                                    >
                                        <SoftTypography variant="h4" fontWeight="bold">
                                            버그 리포트
                                        </SoftTypography>
                                    </Grid>
                                </Grid>
                                <Grid p={3} mb={3} container spacing={1}>
                                    <Grid item xs={3} display="flex" justifyContent="center" alignItems="flex-start">
                                        <SoftTypography variant="h4" fontWeight="bold">
                                            작성자
                                        </SoftTypography>
                                    </Grid>
                                    <Grid item xs={9} justifyContent="center" >
                                        <SoftTypography variant="h5" fontWeight="bold">
                                            {[...userNameCard]}
                                        </SoftTypography>
                                    </Grid>
                                    <Grid item xs={3} mt={2} display="flex" justifyContent="center" >
                                        <SoftTypography variant="h4" fontWeight="bold">
                                            버그 발생 위치
                                        </SoftTypography>
                                    </Grid>
                                    <Grid item xs={3} mt={2} pr={3} display="flex" justifyContent="center" alignItems="center">
                                        <SoftInput
                                            size="small"
                                            value={bugLocation}
                                            onChange={(event)=>setBugLocation(event.target.value)}                                            
                                            readOnly
                                        />
                                    </Grid>
                                    <Grid item xs={3} mt={2} display="flex" justifyContent="center">
                                        <SoftTypography variant="h4" fontWeight="bold">
                                            버그 발생 시간
                                        </SoftTypography>
                                    </Grid>
                                    <Grid item xs={3} mt={2} display="flex" justifyContent="center" alignItems="center">
                                        <SoftInput
                                            size="small"
                                            value={format(nowDate, 'yyyy-MM-dd HH:mm')}
                                            readOnly
                                        />
                                        <DatePicker
                                            selected={nowDate}
                                            onChange={(date) => setNowDate(date)}
                                            showTimeSelect
                                            className="bug-insert-datepicker"
                                            dateFormat="yyyy-MM-dd hh:mm"
                                        />
                                    </Grid>
                                    <Grid item xs={3} mt={2} display="flex" justifyContent="center">
                                        <SoftTypography variant="h4" fontWeight="bold">
                                            버그 발생 상황
                                        </SoftTypography>
                                    </Grid>
                                    <Grid item xs={9} mt={2} display="flex" justifyContent="center" alignItems="center">
                                        <SoftInput
                                            multiline
                                            rows={2}
                                            onChange={(event)=>setBugMoment(event.target.value)}
                                            value={bugMoment}
                                            readOnly
                                        />
                                    </Grid>
                                    <Grid item xs={3} mt={2} display="flex" justifyContent="center">
                                        <SoftTypography variant="h4" fontWeight="bold">
                                            버그 발생 내용
                                        </SoftTypography>
                                    </Grid>
                                    <Grid item xs={9} mt={2} display="flex" justifyContent="center" alignItems="center">
                                        <SoftInput
                                            multiline
                                            rows={2}
                                            onChange={(event)=>setBugContent(event.target.value)}
                                            value={bugContent}
                                            readOnly
                                        />
                                    </Grid> 
                                    <Grid item xs={12} mt={2} display="flex" justifyContent="center" alignItems="center">
                                        <SoftButton variant="gradient" color="dark" onClick={()=>editBug(1)}>
                                            <Icon fontSize="large" sx={{fontWeight: "bold"}}>post_add</Icon>
                                            &nbsp;수정중
                                        </SoftButton>
                                        &nbsp;&nbsp;&nbsp;
                                        <SoftButton variant="gradient" color="dark" onClick={()=>editBug(2)}>
                                            <Icon fontSize="large" sx={{fontWeight: "bold"}}>post_add</Icon>
                                            &nbsp;수정완료
                                        </SoftButton>
                                        &nbsp;&nbsp;&nbsp;
                                        <SoftButton variant="gradient" color="dark" onClick={modalViewClose}>
                                            <Icon fontSize="large" sx={{fontWeight: "bold"}}>cancel</Icon>
                                            &nbsp;닫기
                                        </SoftButton>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </SoftBox>
            </Modal>
            <Footer/>
        </DashboardLayout>
    );
}

export default BugReportPage;
