import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 문서 체크 여부
 * @param vacNo : 휴가문서번호
 * @returns {Promise<axios.AxiosResponse<any>>}
 * @constructor
 */
const vacationDocCheck =(vacNo)=>{
    return customAxios.post(myIpAddress+'/vacation/vacationDocCheck', {
        vac_no : vacNo
    });
}
export default vacationDocCheck;