import {atom} from "recoil";

/**
 * 복지포인트 - row 금액의 합계 
 * @type {RecoilState<number>}
 */

export const wpSumPointAtom = atom({
    key: "wpSumPointAtom"
    , default: 0
})

