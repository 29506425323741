/* eslint-disable react/prop-types */
// Soft UI Dashboard React components
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { wpAdminListSearch } from "../../../../atom/wp/wpAdminListSearch";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import AdminWpAllDocList from "./AdminWpAllDocList";
import { Card } from "react-bootstrap";
import MemberWpUsageStatusList from "./MemberWpUsageStatusList";
import AdminWPCompleteList from "./AdminWPCompleteList";




const AdminToggleMenu = ()  => {

	const [ body , setBody ] = useRecoilState(wpAdminListSearch);
	const [ seletedMenu, setSeletedMenu] = useState(body.stype);

	const handleChangeOption = (event, newSeletedMenu) => {
		setSeletedMenu(newSeletedMenu);
		const stypePageReset = {
			...body, 
			stype : newSeletedMenu
			, monthList : []
        	, year : ""
			, page : 1
		}
		setBody(stypePageReset);
	};


	return (
		<>
		<Grid container alignItems="center" justifyContent="space-between">			
			<Grid>
				{	seletedMenu === "memberList"  &&
					<SoftBox p={1}>
						<SoftTypography pl={2} variant="body2" fontWeight="bold" sx={{ display: "inline-block", width: "max-content" , }}>
							직원 별 복지포인트 사용 현황
						</SoftTypography>
					</SoftBox>
				}
			</Grid>
			<Grid
				display="flex"
				>
				<Grid item >
					<SoftBox p={1} mr={2}>
						<ToggleButtonGroup
							color="primary"
							value={seletedMenu}
							exclusive
							onChange={handleChangeOption}
							aria-label="Platform"
							>
							<ToggleButton value="all">전체</ToggleButton>
							<ToggleButton value="complete">결재완료</ToggleButton>
							<ToggleButton value="memberList">복지포인트 사용 현황</ToggleButton>
						</ToggleButtonGroup>
					</SoftBox>
				</Grid>

			</Grid>
		</Grid>

		{	
			seletedMenu === "all" || !seletedMenu ?
				<AdminWpAllDocList />
				: seletedMenu === "memberList" ?
					<MemberWpUsageStatusList />
						: seletedMenu === "complete" ?
							<AdminWPCompleteList />
								: <div></div>
		}	
	
		</>
	);
}

export default AdminToggleMenu;
