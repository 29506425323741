import RemoveIcon from '@mui/icons-material/RemoveCircle'
import { IconButton, TableCell, TableRow } from '@mui/material'
import NumberInput from 'components/inputBox/NumberInput'
import SoftTypography from 'components/SoftTypography'
import { useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import Swal from 'sweetalert2'
import { TotalAmount } from '../../../atom/cost/CostWriteDetail'
import Month from '../../../components/month/Month'
import ClassificationList from './ClassificationList'
import InputType from './InputType'

const PublicTransportationCostList = (props) => {
    const {
        rowindex, 
        removeDetailRow, 
        item, 
        _onDragOver,
         _onDragStart, 
         _onDragEnd, 
         _onDrop, 
         _onDragLeave,
        publicTransportationCostDetailMap,
        setPublicTransportationCostDetailMap,
        sumAmount
    } = props;

    
    const removeRow = () => {
        Swal.fire({
            title: '삭제 하시겠습니까?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '삭제',
            cancelButtonText: '취소',
            backdrop: false
        }).then((result) => {
            if (result.isConfirmed) {
                removeDetailRow(rowindex)
                Swal.fire({
                    title: '삭제 성공',
                    icon: 'success',
                    backdrop: false
                })
            }
        })
    }


    
    //날짜 받아오기
    const [date, setDate] = useState(item.traffic_date);
    //방문회사/목적
    const [purpose, setPurpose] = useState(item.traffic_purpose)
    //교통수단
    const [transportation, setTransportation] = useState(item.traffic_method)
    //방문장소
    const [location, setLocation] = useState(item.traffic_location)
    //편도/왕복 여부
    const [oneWayRoundTrip, setOneWayRoundTrip] = useState(item.traffic_way)
    //금액
    const [amount, setAmount] = useState(item.traffic_amount)
    // 총 금액
    // const setSumAmount = useSetRecoilState(TotalAmount);



    // const amountSum = () => {
    //     let total = 0;
    //     publicTransportationCostDetailMap.forEach((value, key) => {
    //         const trafficAmount = value.traffic_amount;
    //         if (trafficAmount === "" || trafficAmount === null) {
    //             total += 0;
    //         } else {
    //             total += parseInt(trafficAmount);
    //         }
    //     });
    //     setSumAmount(total)
    // }


    useEffect(() => {
        publicTransportationCostDetailMap.set(rowindex , {
            traffic_detail: rowindex,
            traffic_date: date,
            traffic_purpose: purpose,
            traffic_method: transportation,
            traffic_location: location,
            traffic_way: oneWayRoundTrip,
            traffic_amount: amount
        })
    }, [date, purpose, transportation, location, oneWayRoundTrip])


    useEffect(() => {
        publicTransportationCostDetailMap.set(rowindex , {
            traffic_detail: rowindex,
            traffic_date: date,
            traffic_purpose: purpose,
            traffic_method: transportation,
            traffic_location: location,
            traffic_way: oneWayRoundTrip,
            traffic_amount: amount
        })
        sumAmount();
    }, [amount])



    
    return (
        <>                  
            {/* 2023-06-08  드래그앤드롭버튼 추가 및 TableCell css 수정 */}
            {/* 2024-04-24  쿼리로 date별로 정렬 되기도하고 오류나서 주석 처리 */}
            <TableRow key={rowindex} sx={{ width: "100%" }} 
                // onDragOver={_onDragOver} 
                // onDragStart={(e) => { _onDragStart(e, item) }} 
                // onDrop={(e) => { _onDrop(e, item) }}
                >
                {/* <TableCell sx={{ textAlign: "right",  cursor: "pointer" , lineHeight : 0 , paddingY :0.5}}>
                    <SoftTypography draggable>::</SoftTypography>
                </TableCell> */}
                <TableCell sx={{ width: "max-content", textAlign: "-webkit-right", paddingLeft: "5px", paddingRight: "5px" , lineHeight : 0 , paddingY :0.5}}>
                    {/* 2023-06-02  costType="cost" 추가 */}
                    <Month type='date' num='10' name='일자' setDate={setDate} temp={date} costType="cost" />
                </TableCell>
                <TableCell sx={{ textAlign: "-webkit-right", paddingLeft: "5px", paddingRight: "5px" , lineHeight : 0 , paddingY :0.5}}>
                    <InputType type='text' value={setPurpose} temp={purpose} />
                </TableCell>
                <TableCell sx={{ textAlign: "-webkit-right", paddingLeft: "5px", paddingRight: "5px" , lineHeight : 0 , paddingY :0.5}}>
                    <InputType type='text' value={setTransportation} temp={transportation} />
                </TableCell>
                <TableCell sx={{ paddingLeft: "5px", paddingRight: "5px" , lineHeight : 0 , paddingY :0.5}} >
                    <InputType type='text' value={setLocation} temp={location} />
                </TableCell>
                <TableCell sx={{ paddingLeft: "5px", paddingRight: "5px" , lineHeight : 0 , paddingY :0.5}}>
                    <ClassificationList setClassification={setOneWayRoundTrip} temp={oneWayRoundTrip} category="WAYS" />
                </TableCell>
                <TableCell sx={{ paddingLeft: "5px", paddingRight: "5px" , lineHeight : 0 , paddingY :0.5}}>
                    <NumberInput endMent='attach_money' value={setAmount} temp={amount} />
                </TableCell>
                <TableCell sx={{ paddingLeft: "5px", paddingRight: "5px" , lineHeight : 0 , paddingY :0.5}}>
                    <IconButton aria-label="delete" onClick={removeRow}>
                        <RemoveIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        </>
    )
}

export default PublicTransportationCostList