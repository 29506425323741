import { Icon } from '@mui/material';
import borders from 'assets/theme/base/borders';
import SoftTypography from 'components/SoftTypography';
import React from 'react'
import SoftBox from '.';

const CostCustomSoftBox = (props) => {

  const { borderWidth, borderColor } = borders;
  let iconWhether = props.icon ? true : false;

  return (
    <SoftBox
      border={`${borderWidth[1]} solid ${borderColor}`}
      borderRadius="lg"
      display="flex"
      justifyContent={props.justifyContent}
      alignItems="center"
      height={props.height}
      p={3}

    >
      {iconWhether &&
        <SoftBox
          bgColor={props.color}
          width="1.5rem"
          height="1.5rem"
          borderRadius="sm"
          color="white"
          display="flex"
          justifyContent="center"
          alignItems="center"
          shadow="md"
          mr={1}
          variant="gradient"
        >
          <Icon>{props.icon}</Icon>
        </SoftBox>
      }
      <SoftTypography variant="h6" fontWeight={props.fontWeight}>
        {props.item}
      </SoftTypography>
    </SoftBox>
  )
}

export default CostCustomSoftBox