import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";


/**
 * 유저 문서 갯수 정보
 * @param no : int-멤버번호
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const myDocCount =(no)=>{
    return customAxios.post(myIpAddress+'/approval/myDocCount',
        {mem_no:no});
}
export default myDocCount;