import { Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Icon } from "@mui/material";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { add, differenceInDays, format, getYear, isEqual, isSameDay } from 'date-fns';
import { ko } from "date-fns/esm/locale";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { dateExists, diffDays } from "utils/DateUils";
import { insertReservation } from '../../../api/workPlace/WorkPlace';
import '../workPlace.css';
import MyWorkPlace from './MyWorkPlace';
import SeatCount from "./SeatCount";
import SelectBoxWorkPlace from "./SelectBoxWorkPlace";
import WorkPlaceDatePicker from "./WorkPlaceDatePicker";




const WorkPlaceInsertDialog = (props) => {
    const {
        open,
        setOpen,
        isMobile,
    } = props;

    const resultMessageToKoMap = {
        INVALID_RESERVATION_DATE: "선택한 날에 다른 예약이 되어있습니다.",
        REGION_MAX_RESERVATION: "선택한 지역의 최대 좌석 수가 초과되었습니다.",
        SYSTEM_EXCEPTION: "현재 시스템 문제로 예약에 실패하였습니다. 다시 시도해 주세요."
    }

    // 근무지
    const [selectedPlace, setSelectedPlace] = useState('');
    // 기간
    const [checkDate, setCheckDate] = useState(true)
    // datePicker 관련 state
    const [dateRange, setDateRange] = useState([new Date(), new Date()])
    const [startDate, endDate] = dateRange;
    const [filteredHolyDayList, setFilteredHolyDayList] = useState([]) // 공휴일 제외

    const navigate = useNavigate();
    
    const queryClient = useQueryClient();

    const reservationInsert = useMutation(insertReservation,{
        onSuccess: (data) =>{
            if(!data.success){
                let message = "다음과 같은 날짜에 예약이 실패했습니다."
                const resultMap = new Map(Object.entries(data.results))
                const keys = Array.from(resultMap.keys()).sort()
                for (const key of keys){
                    const result = resultMap.get(key)
                    message = message + "\n" +
                        key + " : "+resultMessageToKoMap[result]
                }
                alert(message)
            }else{
                queryClient.invalidateQueries('reservationInfo')
            }
            if(isMobile){
                navigate('/workPlace')
            }else{
                setOpen(false)
            }
        },
        onError: (e) =>{
            alert(e.response.data.replaceAll(/.*: /g,""))
        }
    })


    /**
     *
     */
    const dateRangeCheck = () => {
        // 시작일과 종료일 같음 여부
        let equal = false
        // 시작일과 종료일의 차이
        let diff = 0;
        if(startDate && endDate) {
            if (!isSameDay(startDate, endDate)) {
                diff = diffDays(startDate, endDate) + 1;
            } else {
                equal = true;
            }
            // 공휴일 카운트
            let holyDayCount = 0;
            let weekCount = 0;
            // 요일값 비교
            if (startDate.getDay() <= endDate.getDay()) {
                if (Math.floor(diff / 7) > 0 || diff % 7 === 0) {
                    weekCount = weekCount + Math.floor(diff / 7);
                }
            }
            if (startDate.getDay() > endDate.getDay()) {
                if (Math.floor(diff / 7) > 0 || diff % 7 === 0) {
                    weekCount = weekCount + Math.floor(diff / 7);
                } else {
                    weekCount = weekCount + 1;
                }
            }
            // 공휴일 체크
            for (let i = 0; i < filteredHolyDayList.length; i++) {
                const fullDate = String(filteredHolyDayList[i].get('date'));
                let year = fullDate.substring(0, 4);
                let month = fullDate.substring(4, 6) - 1;
                let day = fullDate.substring(6, 8);
                let targetDate = new Date(year, month, day);
                // 공휴일이 시작일과 종료일 사이에 있으면 +1
                if (dateExists(targetDate, startDate, endDate)) {
                    holyDayCount++
                }
            }
            if (equal) { // 시작일과 종료일 같음
                const count = diff - holyDayCount - (weekCount * 2) + 1;
                setCheckDate(count <= 10)
            } else {
                const count = diff - holyDayCount - (weekCount * 2);
                setCheckDate(count <= 10)
            }
        }
    }


    useEffect(() =>{
        if (endDate !== null) {
            dateRangeCheck() // 시작일과 종료일이 10일을 넘기는지 안넘기는지 체크
        }
    }, [dateRange, selectedPlace])


    useEffect(() =>{
        if(!checkDate) {
            setDateRange([]);
        }
    },[checkDate])


    const overWorkDays = ((!checkDate) ? (
        <>
            <Grid sx={{pb:'1rem'}}>
                <Alert variant="outlined" severity="error">
                    근무지 좌석 <strong>사용 기한</strong>은 <br/>
                    2주를 넘길 수 없습니다.
                </Alert>
            </Grid>
        </>
        ) : (
            <></>
        )
    )



    const saveWorkPlace = () => {
        const res = {
            range: !isEqual(startDate,endDate),
            startDate: startDate,
            regionId: selectedPlace,
            selectDay: (differenceInDays(endDate, startDate) + 1)
        }
        reservationInsert.mutate(res)
    }

    const handleClose = () => {
        if(isMobile){
            navigate("/workPlace")
        }else{
            setOpen(false)
        }
        // 
        setCheckDate(true)
        setSelectedPlace('')
        setDateRange([new Date(), new Date()])
    }

    const refreshData = () => {
        queryClient.invalidateQueries('getSeatCountByReservationDate');
    }

    const setDate = (dateRange) =>{
        setDateRange(dateRange)
    }


    return (
        <>
        <Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
            <DialogTitle>
                <Grid container justifyContent={"space-between"} alignItems="center">
                    <SoftTypography fontWeight="bold" variant="h4">근무지 등록</SoftTypography>
                    <SoftButton variant="text" color="info" iconOnly onClick={refreshData}>
                        <Icon>refresh</Icon>
                    </SoftButton>
                </Grid>
            </DialogTitle>
            <DialogContent className="workPlace-dialog">
                <DialogContentText>
                    <Grid container>
                        {/* 좌석 현황 */}
                        <Grid container className="workPlace-grid">
                            <SeatCount  startDate={startDate}
                                        endDate={endDate}
                                        selectedPlace={selectedPlace} />
                        </Grid>
                        
                        <Grid container className="workPlace-grid">
                            <Grid item xs={12} className="workPlace-content">
                                <MyWorkPlace
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: '#fff !important' ,
                                            cursor: 'unset !important'
                                        },
                                    }}
                                    color={selectedPlace}
                                    image={selectedPlace}
                                    title={["선택 내역", ""]}
                                    date={ startDate && endDate ? format(startDate, "MM.dd (EEE)", {locale : ko})
                                                    + " ~ " + format(endDate, "MM.dd (EEE)", {locale : ko})
                                            : "기간을 선택해 주세요" }
                                />
                            </Grid>
                        </Grid>
                        {/*  */}
                        <Grid container className="workPlace-grid">
                            <Grid item xs={12} className="workPlace-content">
                                <SelectBoxWorkPlace selectedPlace={selectedPlace} setSelectedPlace={setSelectedPlace}/>
                            </Grid>
                        </Grid>
                        <Grid container className="workPlace-grid">
                            <Grid item xs={12} className="workPlace-content">
                                <SoftTypography fontWeight="bold" variant="h4" sx={{pb:'0.5rem'}}>기간</SoftTypography>
                                <WorkPlaceDatePicker
                                    setDate={setDate}
                                />
                                {overWorkDays}
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContentText>
                <DialogActions>
                    <SoftButton disabled={!checkDate || selectedPlace === '' || endDate == null} onClick={saveWorkPlace} color="success">저장</SoftButton>
                    <SoftButton onClick={handleClose} color="light">취소</SoftButton>
                </DialogActions>
            </DialogContent>
        </Dialog>
        </>
    )
}
export default WorkPlaceInsertDialog;