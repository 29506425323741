/**
 * 전화번호 받아서 패턴 검사
 * @param num : String (xxx-xxxx-xxxx전화번호형식)
 * @returns {boolean}
 */
export const PhoneNumCheck = (num) => {
    const regex = /^\d{3}-\d{4}-\d{4}$/;
    if(regex.test(num)) {
        return true
    } else {
        return false
    }
}
