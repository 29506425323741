import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 상신문서 결재
 * @param body
 * @returns {Promise<axios.AxiosResponse<any>>}
 * @constructor
 */
const vacationApprovalSignUp =(body)=>{
    return customAxios.post(myIpAddress+'/vacation/vacationApprovalSignUp', body);
}
export default vacationApprovalSignUp;