// react
import React from "react";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";


const WpInsertInfo = () => {
    return (
        <>
        <SoftBox p={2} pt={1}>
            <SoftTypography variant="body1">
                주의사항
            </SoftTypography>
            <SoftTypography variant="button">
                ● 복지포인트 사용 가능 시기 <br></br>
            </SoftTypography>
            <SoftTypography variant="overline">
                &nbsp;인턴사원 및 경력사원 입사자 입사일로부터 3개월이 되는 해당 월의 다음 달 1일부터<br></br>
            </SoftTypography>
            <SoftTypography variant="button">
                ● 복지포인트 사용 가능 금액<br></br>
            </SoftTypography>
            <SoftTypography variant="overline">
                &nbsp;직원 : 1년 120만원 , 팀장이상 : 1년 180만원<br></br>
            </SoftTypography>
            <SoftTypography variant="button">
                ● 복지포인트 선사용 불가 되도록 반기로 나눠서 사용요청<br></br>
            </SoftTypography>
            <SoftTypography variant="button">
                ● 사용항목 <br></br>
            </SoftTypography>
            <SoftTypography variant="overline">
                &nbsp;건강관리, 자기 개발, 여가 활동, 가족 친화, 품위유지, 기타 <br></br>
            </SoftTypography>
            <SoftTypography variant="button">
                ● 사용 불가항목(사행성이 있거나 유가증권 구매 항목) <br></br>
            </SoftTypography>
            <SoftTypography variant="overline">
                &nbsp;유흥비(술집, 노래방 등), 주유비, 차량 관련 비용(차량 수리비 등), 상품권 or 모바일상품권 구입(현금성 상품권 구입x), 복권구입 , 보석구입 <br></br>
            </SoftTypography>
            {/* <SoftTypography variant="button" color="error">
                * 복지포인트 금액 작성 시 10원 단위는 절삭해서 올려주시기 바랍니다. (100원 단위까지 작성)<br></br>
            </SoftTypography> */}
        </SoftBox>
        </>
    )
}

export default WpInsertInfo
