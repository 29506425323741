import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import VacationTable from "../../../../examples/Tables/VacationTable";
import tableData from "./data";

function MyVacationApprovalList() {

    const {columns, rows} = tableData();

    return (
        <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} pl={2}>
                <SoftBox>
                    <SoftTypography variant="h5" gutterBottom fontWeight="bold">
                        최근 상신 문서
                    </SoftTypography>
                    <SoftBox display="flex" alignItems="center" lineHeight={0}>
                        <Icon
                            sx={{
                                fontWeight: "bold",
                                color: ({palette: {info}}) => info.main,
                                mt: -0.5,
                            }}
                        >
                            checklist_rtl
                        </Icon>
                        <SoftTypography variant="button" fontWeight="regular" color="text">
                            &nbsp;<strong>최근 5개 </strong> 상신 휴가 문서 (클릭시 이동)
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            </SoftBox>
            <SoftBox
                sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                        "& td": {
                            borderBottom: ({borders: {borderWidth, borderColor}}) =>
                                `${borderWidth[1]} solid ${borderColor}`,
                        },
                    },
                }}
            >
                <VacationTable columns={columns} rows={rows}/>
            </SoftBox>
        </Card>
    );
}

export default MyVacationApprovalList;
