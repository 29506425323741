import { Card, Grid } from "@mui/material";
import { wpAdminListSearch } from "atom/wp/wpAdminListSearch";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useResetRecoilState } from "recoil";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import AdminWPListTable from "./list/AdminWpAllDocList";
import AdminToggleMenu from "./list/AdminToggleMenu";
import WpBasicSetting from "./wpBasicsetting/WpBasicSetting";



const AdminWPpage = () => {


    return (
        <> 
        <DashboardLayout>
            <DashboardNavbar/>
            <Card
                sx={{
                backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
                boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                position: "relative",
                mt: 2,
                mx: 0,
                py: 2,
                px: 2,
                }}
                >
                <Grid container spacing={3} alignItems="center" display="flex" justifyContent="space-between">
                    <Grid item>
                        <SoftBox height="100%" mt={0.5} lineHeight={1}>
                            <SoftTypography variant="h5" fontWeight="bold">
                                복지포인트 관리자 
                            </SoftTypography>
                        </SoftBox>
                    </Grid>
                </Grid>
            </Card>

            {/* 복지포인트 기본 금액 */}
            <SoftBox mt={2} mb={2}>
                <Card>
                    <WpBasicSetting/>
                </Card>
            </SoftBox>

            <Card>
                <AdminToggleMenu />
            </Card>

            {/* <SoftBox mt={2} mb={2}>
				<Card>
					<AdminWPListTable />
        		</Card>
			</SoftBox> */}
            
        </DashboardLayout>
        </>
    );
    
}

export default AdminWPpage