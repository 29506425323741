import Grid from "@mui/material/Grid";
import ApproverInsertCard from "./ApproverInsertCard";
import ReferrerInsertCard from "./ReferrerInsertCard";

/**
 * 결재자 참조자 카드 모음
 * @param approverKey : String (식별용 키)
 * @param referrerKey : String (식별용 키)
 * @param docNo : Int (문서번호)
 * @returns {JSX.Element}
 * @constructor
 */
function ApproverReferrer({approverKey, referrerKey, docNo}) {
    return (
        <Grid container spacing={1} mb={1} mt={1}>
            <ApproverInsertCard keyName={approverKey} gridXs={12} gridMd={6} gridLg={6}/>
            <ReferrerInsertCard keyName={referrerKey} gridXs={12} gridMd={6} gridLg={6}/>
        </Grid>
    );
}

export default ApproverReferrer;
