import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import vacationInsertImg from "assets/images/vacationInsertImg.jpg";
import {useNavigate} from "react-router-dom";
import {isRecoilValue, useRecoilValue} from "recoil";
import {loginUser} from "../../../../atom/member/loginUser";
import {useEffect} from "react";

/**
 * 휴가 입력 버튼
 * @param approverKey : String
 * @param referrerKey : String
 * @param insertKey : String
 * @returns {JSX.Element}
 * @constructor
 */
function VacationInsertButton({approverKey, referrerKey, insertKey}) {

    // login 정보
    let recoilState = isRecoilValue(loginUser);
    const user = useRecoilValue(loginUser);

    let auth;
    let no;
    let team;
    let rank;

    // recoil 정보가 가져와 지면 유저값 저장
    useEffect(() => {
        if (recoilState) {
            auth = user?.role;
            no = user?.id;
            team = user?.team;
            rank = user?.rank;
        }
    }, [recoilState]);

    const navigate = useNavigate();
    return (
        <Card
            className="vacation-insert-button"
            sx={{
                height: "100%",
                cursor: "pointer",
                "&:hover .material-icons-round": {
                    transform: `translate(6px, -0.5px)`,
                },
                "&:hover .gradient-box": {
                    backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                        `${linearGradient(
                            rgba(gradients.info.main, 0.8),
                            rgba(gradients.info.state, 0.8)
                        )}, url(${vacationInsertImg})`,
                }
            }}
            onClick={() => navigate('insert', {
                state: {
                    approverKey: approverKey
                    , referrerKey: referrerKey
                    , insertKey: insertKey
                    , auth : auth
                    , no : no
                    , team : team
                    , rank : rank
                }
            })}
        >
            <SoftBox position="relative" height="100%" p={1}>
                <SoftBox
                    className="gradient-box"
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    py={2}
                    px={2}
                    borderRadius="lg"
                    sx={{
                        backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                            `${linearGradient(
                                rgba(gradients.dark.main, 0.8),
                                rgba(gradients.dark.state, 0.8)
                            )}, url(${vacationInsertImg})`,
                        backgroundSize: "cover",
                    }}
                >
                    <SoftBox mb={3} pt={1}>
                        <SoftTypography variant="h5" color="white" fontWeight="bold">
                            휴가 결재 문서 작성
                        </SoftTypography>
                    </SoftBox>
                    <SoftTypography
                        variant="button"
                        color="white"
                        fontWeight="medium"
                        sx={{
                            mt: "auto",
                            mr: "auto",
                            display: "inline-flex",
                            alignItems: "center",

                            "& .material-icons-round": {
                                fontSize: "1.125rem",
                                transform: `translate(2px, -0.5px)`,
                                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                            },

                        }}
                    >
                        Insert
                        <Icon sx={{fontWeight: "bold"}}>arrow_forward</Icon>
                    </SoftTypography>
                </SoftBox>
            </SoftBox>
        </Card>
    );
}

export default VacationInsertButton;
