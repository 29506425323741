// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
 
.item img {
    width : 84px;
    height: 70px;
    margin-bottom: 20px;
    margin-top : 20px;  
    &:hover {
    cursor: pointer;    
  }

}

@media screen and (max-width: 600px) {
    .item img {
      width: 60px;
      height : 48px;
    }
  }

.asset-list .container .ass-cate ul li{
    display : inline;      
    font-size : 20px;
}




.asset-list .container-asset .item-list .item {
    border : 1px solid #eee;
    border-radius : 5px;
    height : 200px;
    width : 200px;
    padding : 5px;
    margin-bottom : 10px;   
}

.asset-list .container-asset .item-list .item p{
    font-size : 20px;
    opacity : .1.1;
}

.search {
    font-size : 13px;
    width : 240px;
    height : 30px;    
}

.select-member {
    height : 30px;
}`, "",{"version":3,"sources":["webpack://./src/layouts/assetManagement/components/AssetList.css"],"names":[],"mappings":";;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;IACjB;IACA,eAAe;EACjB;;AAEF;;AAEA;IACI;MACE,WAAW;MACX,aAAa;IACf;EACF;;AAEF;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;;;;AAKA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,aAAa;IACb,aAAa;IACb,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB","sourcesContent":["\n \n.item img {\n    width : 84px;\n    height: 70px;\n    margin-bottom: 20px;\n    margin-top : 20px;  \n    &:hover {\n    cursor: pointer;    \n  }\n\n}\n\n@media screen and (max-width: 600px) {\n    .item img {\n      width: 60px;\n      height : 48px;\n    }\n  }\n\n.asset-list .container .ass-cate ul li{\n    display : inline;      \n    font-size : 20px;\n}\n\n\n\n\n.asset-list .container-asset .item-list .item {\n    border : 1px solid #eee;\n    border-radius : 5px;\n    height : 200px;\n    width : 200px;\n    padding : 5px;\n    margin-bottom : 10px;   \n}\n\n.asset-list .container-asset .item-list .item p{\n    font-size : 20px;\n    opacity : .1.1;\n}\n\n.search {\n    font-size : 13px;\n    width : 240px;\n    height : 30px;    \n}\n\n.select-member {\n    height : 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
