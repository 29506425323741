/* eslint-disable react/prop-types */
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";


const AdminWPListTableUser = ({ name , team, rank , imageUrl})  => {
  return (
    <SoftBox display="flex" alignItems="center">
      <SoftBox mr={1}>
        <SoftAvatar src={imageUrl} alt={name} size="sm" variant="rounded" />
      </SoftBox >
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium" color="dark" sx={{ display: "inline-block", width: "max-content" , }} >
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary" sx={{ display: "inline-block", width: "max-content" , }} >
          {rank} / {team}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

export default AdminWPListTableUser;
