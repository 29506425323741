import Box from '@mui/material/Box';
import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import SoftInput from 'components/SoftInput';

const InputType = (props) => {
  const [detail, setDetail] = useState(props.temp)

  const defaultValue = props.temp;

  useEffect(() => {
    props.value(detail)
  }, [detail])

  return (
    // <TextField type={props.type} id="outlined-basic" label={props.name} variant="outlined" defaultValue={props.temp} onChange={(e) => {setDetail(e.target.value)}}/> 
    <SoftInput
      type={props.type}
      id="outlined-basic"
      label={props.name}
      variant="outlined"
      defaultValue={props.temp}
      onChange={(e) => { setDetail(e.target.value) }}>
    </SoftInput>
  )
}

export default InputType