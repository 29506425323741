import {styled, Typography} from "@mui/material";
import {useQueries, useQuery} from "react-query";
import allCommonCodeList from "../../api/commonCode/allCommonCodeList";
import {Fragment, useEffect, useState} from "react";
import Tooltip, {tooltipClasses, TooltipProps} from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import allCommonCodeMap from "../../api/commonCode/allCommonCodeMap";


/**
 * 공용코드 리스트 이름을 받아서 해당하는 리스트를 옵션값으로 보여주고 선택할 수 있게하고 타입으로 inputType 식별
 * @param props.init : String - category
 * @param props.type : String - inputType
 * @param props.status : boolean - status
 * @returns {JSX.Element}
 * @constructor
 */
const HoverTip = (props) => {
    // 사용할 공용코드 리스트
    const useCommonCodeList = [];
    // 공용코드에 해당하는 라벨을 만들어주는 map
    const labelMap = new Map;
    // 호버 콘텐츠맵
    let hoverContentMap = new Map;

    // 콘텐츠 별 저장
    const queries = useQueries([
        // queries[0] : 공용코드 리스트
        {
            queryKey: ["allCommonCodeList"],
            queryFn: () => allCommonCodeList()
        },
        // queries[2] : 공용코드 맵
        {   queryKey: ["allCommonCodeMap"],
            queryFn: () => allCommonCodeMap()
        }
    ]);

    // 쿼리 로딩 성공시 실행
    if(queries[0].isSuccess && queries[1].isSuccess){
        // 공용코드 리스트 맵 세팅
        let commonCodeList = queries[0].data.data;
        // props 카테고리와 같은 값만 set
        for(let i=0; i<commonCodeList.length; i++){
            if(commonCodeList[i].cc_category === props.category){
                useCommonCodeList.push(commonCodeList[i].cc_name);
                labelMap.set(commonCodeList[i].cc_name, commonCodeList[i].cc_info);
            }
        }

        // 공용코드 맵
        const commonCodeMap = queries[1].data.data;
        for(let i =0; i<useCommonCodeList.length; i++){
            if(commonCodeMap[useCommonCodeList[i]].info === "연차"){
                const content=
                    <>
                        <b>{'● 현재 사용가능한 연차는 전체 부여 연차에서 당겨쓴 연차와 사용연차를 뺀값입니다.'}</b><br/>
                        <b>{'● 당겨쓰는 휴가는 관리팀에게 확인 후 사용하시기 바랍니다.'}</b>
                    </>
                hoverContentMap.set(useCommonCodeList[i], content);

            }
            if(commonCodeMap[useCommonCodeList[i]].info === "병가"){
                const content=
                    <>
                        <b>{'● 병가 증빙서류는 관리팀에게 제출하여 주시기 바랍니다.'}</b>
                    </>

                hoverContentMap.set(useCommonCodeList[i], content);
            }
            if(commonCodeMap[useCommonCodeList[i]].info === "경조휴가"){
                const content=
                    <>
                        <b>{'● 휴가일수는 휴일을 제외한 일수를 의미합니다'}</b><br/>
                        <b>{'● 본가 및 처가에 한하여 적용하며 외가는 적용하지 않습니다.'}</b><br/>
                        <b>{'● 상황에 따른 휴가 일 수 는 아래와 같습니다.'}</b><br/>
                        <b>{'본인 결혼 휴가 : '}</b>{'5일'}<br/>
                        <b>{'자녀 결혼 휴가 : '}</b>{'2일'}<br/>
                        <b>{'형제자매 결혼 휴가 : '}</b>{'1일'}<br/>
                        <b>{'친부모 회갑 및 칠순 휴가 : '}</b>{'1일'}<br/>
                        <b>{'외부모 회갑 및 칠순 휴가 : '}</b>{'1일'}<br/>
                        <b>{'자녀 출생 휴가 : '}</b>{'3~5일'}<br/>
                        <b>{'부모 사망 휴가 : '}</b>{'5일'}<br/>
                        <b>{'장인장모 사망 휴가 : '}</b>{'5일'}<br/>
                        <b>{'배우자 사망 휴가 : '}</b>{'5일'}<br/>
                        <b>{'자녀 사망 휴가 : '}</b>{'5일'}<br/>
                        <b>{'형제자매 사망 휴가 : '}</b>{'3일'}<br/>
                        <b>{'본인 조부모 사망 휴가: '}</b>{'3일'}<br/>

                    </>

                hoverContentMap.set(useCommonCodeList[i], content);
            }
            if(commonCodeMap[useCommonCodeList[i]].info === "기타휴가"){
                const content =
                    <>
                        <b>{'● 기타휴가는 예비군 훈련 및 민방위 훈련등 입니다.'}</b><br/>
                    </>

                hoverContentMap.set(useCommonCodeList[i], content);
            }
        }
    }

    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
            <Tooltip {...props} classes={{ popper: className }} />
        )
    )
    (
        ({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: '#f5f5f9',
                color: 'rgba(0, 0, 0, 0.87)',
                maxWidth: 220,
                fontSize: theme.typography.pxToRem(15),
                border: '1px solid #dadde9',
            },
        })
    );

    return(
        <HtmlTooltip placement="bottom" title={
            <Fragment>
                <Typography sx={{fontWeight:'bold', color:"#3953A4"}}>{labelMap.get(props.commonCode)}</Typography>
                {hoverContentMap.get(props.commonCode)}
            </Fragment>
        }
        >
            <FormControlLabel
                key={props.commonCode}
                value={props.commonCode}
                control={<Radio/>}
                label={labelMap.get(props.commonCode)}
                style={{ paddingRight: '1rem' }}
            />
        </HtmlTooltip>
    )
}
export default HoverTip;