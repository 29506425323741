import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useQuery } from 'react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { CostDetailCalssification } from '../../../atom/cost/CostDetailClassification';
import { customAxios } from '../../../axios/CustomAxios';
import { myIpAddress } from '../../../config';
import allCommonCodeList from 'api/commonCode/allCommonCodeList';
import SoftBox from 'components/SoftBox';
import { loginUser } from 'atom/member/loginUser';
import { ListItemText, styled, Tooltip, tooltipClasses } from '@mui/material';
import "../../../styles/Cost/CostSt.css"

const CostClassification = (props) => {

  const {
    costCode, 
    setCostCode
  } = props;

  const costListDetailInfo = [
    //복리후생비
    {
      'C000': `<span style='color:red'>● 사용금액 30만 초과시 사장님 결재가 필요합니다.</span> <br><span style='color:red'>● 소모품비 결재시 구매한 소모품 내역이 영수증에 반드시 나와야 합니다.</span><br> 1차 결재자 상위 기재(각 프로젝트 리더)
      <br> 2차 결재자 방건식 이사<br><span style='color:red'><br>● 사용금액 30만 초과시 결재자는 다음과 같습니다.</span><br>1차 결재자 김남영 전무<br>2차 결재자 오철 사장님`},
    //교통비(기타비용)
    { 'C001': `<span style='color:red'>● 사용금액 30만 초과시 사장님 결재가 필요합니다.</span>` },
    //대중교통비
    { 'C002': `<span style='color:red'>● 사용금액 30만 초과시 사장님 결재가 필요합니다.</span><br> ● 영수증이 없는 버스, 전철 이용시 대중교통비 양식을 첨부해서 경비청구시 제출하면 됩니다.</b>` },
    //유류비
    { 'C003': `<span style='color:red'>● 사용금액 30만 초과시 사장님 결재가 필요합니다.</span>` }
  ];

  //비용처리 구분 리스트 받기
  const [costList, setCostList] = useState([]);
  //비용처리 공용코드 받기
  // const [costCode, setCostCode] = useRecoilState(CostDetailCalssification);

  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = (event) => {
    if (event.target.value) {
      setCostCode(event.target.value);
    }
    setOpen(false);
  };

  const handleOpenModal = (e) => {
    for (let c = 0; c < costListDetailInfo.length; c++) {
      if (Object.keys(costListDetailInfo[c])[0] === e) {
        props.setModalMent((Object.values(costListDetailInfo[c]))[0])
      }
    }
    props.setShowModal(true);
  }


  const { data, isSuccess, isFetching, isLoading, isError } = useQuery('allCommonCodeList', allCommonCodeList, {
    // 데이터 불러오지(fetch) 않는 시간 지정 20분
    staleTime: 120000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    select: (data) => {
      return data.data
    }
  })

  const costArr = [...costList];
  //데이터 불러오기를 성공했을때 리스트 생성
  useEffect(() => {
    if (isSuccess) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].cc_category == 'COST') {
          let co = { 'cost': data[i].cc_name, 'info': data[i].cc_info }
          costArr.push(co)
          setCostList(costArr)
        }
      }
    }
  }, [isSuccess])

  //작성한 문서 수정하기
  if(costCode) {
    for (let c = 0; c < costListDetailInfo.length; c++) {
      if (Object.keys(costListDetailInfo[c])[0] === costCode) {
        props.setModalMent((Object.values(costListDetailInfo[c]))[0])
      }
    }
    props.setShowModal(true);
  }




  return (
    <>
      <FormControl sx={{ width: '90%' }} >
        {/* <InputLabel id="demo-simple-select-label" sx={{ mt: -1 }}>비용처리분류</InputLabel> */}
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleToggle}
          onClick={handleToggle}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          displayEmpty
          value={costCode}
          label="cost"
          MenuProps={{
            variant: 'menu',
          }}
          sx={{ cursor: "pointer" }}
        >
          <MenuItem value="">
            -선택-
          </MenuItem>
          {costList.map((cost, idx) => (
            <MenuItem key={idx} value={cost.cost} onClick={() => { setCostCode(cost.cost); handleOpenModal(cost.cost) }} selected={costCode === cost.cost} className="costMenuInfo">
              {cost.info}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

export default CostClassification