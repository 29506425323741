import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";


/**
 * 수신함의 확인해야할 상신문서,결재중 문서 수 and 그외 결재완료, 반려 문서 수
 * @param no : int
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const checkDocCount =(body)=>{
    return customAxios.post(myIpAddress+'/approval/checkDocCount', body);
}
export default checkDocCount;