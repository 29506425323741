import Tooltip from "@mui/material/Tooltip";
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftProgress from "components/SoftProgress";
import {QueryClient, useQueries} from "react-query";
import {isRecoilValue, useRecoilState, useRecoilValue} from "recoil";
import {loginUser} from "../../../../../../../atom/member/loginUser";
import {useEffect} from "react";
import allCommonCodeMap from "../../../../../../../api/commonCode/allCommonCodeMap";
import vacationApprovalDocList from "../../../../../../../api/vacation/vacationApprovalDocList";
import {receiveDocTotalCount} from "../../../../../../../atom/approval/receive/receiveDocTotalCount";
import {vacationReceiveState} from "../../../../../../../atom/approval/receive/vacationReceiveState";
import approveCancel from "../../../../../../../assets/images/x_image.png";
import SoftTypography from "../../../../../../../components/SoftTypography";

/**
 * 휴가 수신함 문서 테이블 데이터
 * @returns {{columns: [{name: string, align: string},{name: string, align: string},{name: string, align: string}], rows: *[]}}
 */
export default function data() {

    // 타이틀
    let title = "";
    let progress;
    let cancelReason = '';

    // react query
    const queryClient = new QueryClient();

    // login 정보
    const recoilState = isRecoilValue(loginUser);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const user = useRecoilValue(loginUser);

    // recoil 정보가 가져와 지면 refetch
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (recoilState) {
            // Only execute the query if myState is not null
            queryClient.refetchQueries('vacationApprovalDocList');
        }
    }, [recoilState]);

    const tableDataList= [];

    // 카테고리 state
    // eslint-disable-next-line react-hooks/rules-of-hooks,no-use-before-define
    const vacationDocState = useRecoilValue(vacationReceiveState);

    // 전체 페이지 수
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [totalCount, setTotalCount] = useRecoilState(receiveDocTotalCount);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const queries = useQueries([
        // queries[0] : 휴가 정보
        {
            queryKey: ["vacationApprovalDocList", {
                no: parseInt(user?.id)
                , docType: "APT000"
                , category: vacationDocState.searchCategory
                , team: vacationDocState.searchTeam
                , name: vacationDocState.searchName
                , startStart: vacationDocState.searchStartStart
                , startEnd: vacationDocState.searchStartEnd
                , endStart: vacationDocState.searchEndStart
                , endEnd: vacationDocState.searchEndEnd
                , ascending: vacationDocState.ascending
                , nowPage: vacationDocState.nowPage
                , docsNum: vacationDocState.docsNum
            }],
            queryFn: () => vacationApprovalDocList({
                no: parseInt(user?.id)
                , docType: "APT000"
                , category: vacationDocState.searchCategory
                , team: vacationDocState.searchTeam
                , name: vacationDocState.searchName
                , startStart: vacationDocState.searchStartStart
                , startEnd: vacationDocState.searchStartEnd
                , endStart: vacationDocState.searchEndStart
                , endEnd: vacationDocState.searchEndEnd
                , ascending: vacationDocState.ascending
                , nowPage: vacationDocState.nowPage
                , docsNum: vacationDocState.docsNum
            }),
            onSuccess: (data) => {
                setTotalCount(data.data[0].total_count);
            },
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            }
        },
        // queries[1] : 공용코드 리스트
        {
            queryKey: ["allCommonCodeMap"],
            queryFn: () => allCommonCodeMap(),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
                initialData: [],
            }
        },
    ]);

    if(queries[0].isSuccess && queries[1].isSuccess){
        // 휴가 정보
        const vacationList = queries[0].data.data;
        // 공용코드 리스트
        const codeMap = queries[1].data.data;

        for(let i=0; i<vacationList.length; i++){

            let cancelBool = false;
            // 결재여부(x차 결재)
            const approvalDo = vacationList[i]["approval_do"];
            // 결재자 수
            const approvalApprovers = vacationList[i]["approval_approvers"];
            // 휴가타입
            const category = codeMap[vacationList[i]["vac_category"]].info;
            // 휴가 시작일
            const start = vacationList[i]["vac_start"];
            // 휴가 종료일
            const end = vacationList[i]["vac_end"];
            // 총 휴가 일 수
            const days = vacationList[i]["vac_days"];
            // 문서 번호
            const docNo = vacationList[i]["vac_no"];
            // 문서 결재 상태
            const approved = vacationList[i]["vac_approved"];
            // 휴가 정보(제목)
            title = "["+category+"] "+start+"~"+end+"-"+days+"일";
            // 멤버이름
            const member = "["+codeMap[vacationList[i]["vac_team"]]?.info+"] "+vacationList[i]["mem_name"]+" "+codeMap[vacationList[i]["vac_rank"]]?.info+"";
            // 문서 번호
            const viewNo = vacationList[i]["view_no"];

            // 결재자 정보
            const approverDataList = vacationList[i]["approverList"];
            // 결재자 정보 저장용 리스트
            const approverList = [];

            if (approved === "AP004") {
                for (let j = 0; j < approverDataList.length; j++) {
                    const tinyInfoSetList = [];
                    const approverRank = codeMap[approverDataList[j]["approver_rank"]]?.info; // 결재자 랭크
                    const approverName = approverDataList[j]["approver_name"]; // 결재자 이름
                    const approverLevel = approverDataList[j]["approver_level"];
                    const approverImg = approverDataList[j]["approver_img"]; // 결재자 프로필사진
                    // 저장용 리스트에 저장
                    tinyInfoSetList.push(approverImg);
                    tinyInfoSetList.push("[" + (approverLevel + 1) + "번 결재자] " + approverName + " " + approverRank);

                    // 저장용 리스트에 저장
                    approverList.push(tinyInfoSetList);
                }
                cancelBool = true;
                cancelReason = vacationList[i]["approval_cancel_reason"];
                progress = (approvalDo / approvalApprovers) * 100;
            } else {
                for (let j = 0; j < approverDataList.length; j++) {
                    const tinyInfoSetList = [];
                    const approverRank = codeMap[approverDataList[j]["approver_rank"]]?.info; // 결재자 랭크
                    const approverName = approverDataList[j]["approver_name"]; // 결재자 이름
                    const approverLevel = approverDataList[j]["approver_level"];
                    const approverImg = approverDataList[j]["approver_img"]; // 결재자 프로필사진

                    // 저장용 리스트에 저장
                    tinyInfoSetList.push(approverImg);
                    tinyInfoSetList.push("[" + (approverLevel + 1) + "번 결재자] " + approverName + " " + approverRank);

                    // 저장용 리스트에 저장
                    approverList.push(tinyInfoSetList);
                }
            }

            // 결재 진행 상황
            progress = (approvalDo/approvalApprovers)*100;
            const saveMap ={
                viewNo: viewNo,
                docNo:docNo,
                title: title,
                member:member,
                approvers: approverList,
                progress: progress,
                cancelBool: cancelBool,
                cancelReason: cancelReason
            }

            // 전체 리스트에 저장
            tableDataList.push(saveMap);
        }
    }

    /**
     * 이름과 이미지로 호버시 팀,이름,직책 나옴
     * @param members
     * @returns {*}
     */
    const avatars = (members) =>
        members.map(([image, name]) => (
            <Tooltip key={name} title={name} placeholder="bottom">
                <SoftAvatar
                    src={image}
                    alt="name"
                    size="xs"
                    sx={{
                        border: ({borders: {borderWidth}, palette: {white}}) =>
                            `${borderWidth[2]} solid ${white.main}`,
                        cursor: "pointer",
                        position: "relative",

                        "&:not(:first-of-type)": {
                            ml: -1.25,
                        },

                        "&:hover, &:focus": {
                            zIndex: "10",
                        },
                    }}
                />
            </Tooltip>
        ));

    /**
     * row에 데이터 렌더링
     * @returns {*[]}
     */
    const tabelDataRender = () => {
        const dataArr = [];
        for (let i = 0; i < tableDataList.length; i++) {
            dataArr.push(
                {
                    no: tableDataList[i]["viewNo"],
                    docNo: tableDataList[i]["docNo"],
                    vacationinfo: [tableDataList[i]["title"]],
                    member: tableDataList[i]["member"],
                    approver: (
                        <SoftBox display="flex" py={1}>
                            {avatars(
                                tableDataList[i]["approvers"]
                            )
                            }
                        </SoftBox>
                ),
                    approvalprogress: (
                        <SoftBox width="8rem" textAlign="left">
                            {tableDataList[i]?.cancelBool ?
                                <>
                                    <SoftBox display="flex" justifyContent="flex-start" alignItems="center">
                                        <Tooltip key={i} title={"반려사유: "+tableDataList[i]?.cancelReason} placeholder="bottom">
                                            <SoftAvatar
                                                src={approveCancel}
                                                alt="profile-image"
                                                variant="rounded"
                                                size="xs"
                                                shadow="sm"
                                            />
                                        </Tooltip>
                                        <SoftTypography ml={1} variant="h6" fontWeight="medium">
                                            결재 문서 반려
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftProgress value={tableDataList[i]["progress"]} color="info" variant="gradient"
                                                  label={true}/>
                                </>
                                :
                                <SoftProgress value={tableDataList[i]["progress"]} color="info" variant="gradient"
                                              label={true}/>
                            }
                        </SoftBox>
                ),
            }
            );
        }
        return dataArr;
    };

    return {
        columns: [
            // 2023-06-02 내문서함 칼럼 통일
            {name: "no", align: "center" , value : "번호" , width : "10%"},
            {name: "vacationinfo", align: "left" , value : "문서정보" , width : "35%"},
            {name: "member", align: "left" , value : "작성자" , width : "23%"},
            {name: "approver", align: "left" , value : "결재자" , width : "12%"},
            {name: "approvalprogress", align: "center" , value : "결재상태" , width : "20%"},
        ],

        rows: tabelDataRender(),
    };
}
