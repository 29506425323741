import {QueryClient, useQuery} from "react-query";
import { toMap } from "../../utils/ToMap";
import allCommonCodeMap from "../../api/commonCode/allCommonCodeMap";
import {isRecoilValue} from "recoil";
import {loginUser} from "../../atom/member/loginUser";
import {useEffect} from "react";



/**
 * 공용코드를 입력하면 해당 코드의 info가 단순히 text로 리턴
 *
 */
const CommonCodeInfoReturn = (props) => {

let thisMap;
let info;

    // react query
    const queryClient = new QueryClient();

    // login 정보
    const recoilState = isRecoilValue(loginUser);

    // recoil 정보가 가져와 지면 refetch
    useEffect(() => {
        if (recoilState) {
            // Only execute the query if myState is not null
            queryClient.refetchQueries('allCommonCodeMap');
        }
    }, [recoilState]);

// 테스트 query
// 공용코드에서 해당하는 공용코드로 하는 쿼리로 변경 필요
const {data, isSuccess } = useQuery('allCommonCodeMap', allCommonCodeMap, {
    select : (data) => {
        return data.data;
    }
});

if(isSuccess){
    if(props.init !== undefined && props.init !== "" ){
        info = data[props.init]?.info;
    }
}

return(
    <>
    {info}
    </>

)

}

export default CommonCodeInfoReturn;