/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, {useMemo, useState} from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import { Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import {useNavigate} from "react-router-dom";
import Pagination from "@mui/material/Pagination";

function ReferenceTable({ columns, rows }) {
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;

  const navigate = useNavigate();

  function navigationURL (orgDocType, docNo) {
    let url;

    if(orgDocType === 'APT000') {
      url = "/vacation/view"
    } else if (orgDocType === 'APT001') {
      url = `/cost/${docNo}`;
    } else if (orgDocType === 'APT002') {
      url = ""
    } else if (orgDocType === 'APT003') {
      url = "/welfarePoint/detail"
    }
    console.log(orgDocType)
    return url;
  }
  

  const renderColumns = columns.map(({ name, align, width, value }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {/* 2023-06-02 name->value 로 변경 : 칼럼 너비 고정 및 한글로 변경해서 */}
        {value}
      </SoftBox>
    );
  });

  const renderRows = rows.map((row, key) => {
    const rowKey = `row-${key}`;
    const docNo = rows[key]["docNo"];
    const orgDocType = rows[key]["orgDocType"];

    const tableRow = columns.map(({ name, align }) => {
      let template;

      if (Array.isArray(row[name])) {
        template = (
          <SoftBox
            key={uuidv4()}
            component="td"
            p={1}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
          >
            <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
              <SoftTypography variant="button" fontWeight="medium" sx={{ width: "max-content" }}>
                {row[name][0]}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        );
      } else {
        template = (
          <SoftBox
            key={uuidv4()}
            component="td"
            p={1}
            textAlign={align}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
          >
            <SoftTypography
              variant="button"
              fontWeight="regular"
              color="secondary"
              sx={{ display: "inline-block", width: "max-content" }}
            >
              {row[name]}
            </SoftTypography>
          </SoftBox>
        );
      }

      return template;
    });

    return <TableRow
        key={rowKey}
        onClick={()=>{
          navigate(navigationURL(orgDocType ,docNo), {state:docNo});
        }}
        //onMouseEnter={()=>{setIsHovered(true)}}
        //onMouseLeave={()=>{setIsHovered(false)}}
        // style={{
        //   backgroundColor: isHovered ? '#f5f5f5' : 'inherit',
        //   cursor: isHovered ? 'pointer' : 'inherit',
        // }}
        sx={{ "&:hover": { backgroundColor: "#f5f5f5" , cursor: "pointer" , } }} 

    >
      {tableRow}
    </TableRow>;
  });

  return (
        <TableContainer>
          <MuiTable>
            <SoftBox component="thead">
              <TableRow>{renderColumns}</TableRow>
            </SoftBox>
            <TableBody>{renderRows}</TableBody>
          </MuiTable>
        </TableContainer>
  )
}

// Setting default values for the props of Table
ReferenceTable.defaultProps = {
  columns: [],
  rows: [{}],
};

// Typechecking props for the Table
ReferenceTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default ReferenceTable;
