import * as React from 'react';
import { useNavigate } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { useEffect } from 'react';
import Swal from 'sweetalert2';

// api
// atom
import { loginUser } from "../../../atom/member/loginUser"
import { wpUsableMonth } from "../../../atom/wp/wpUsableMonth"
// wp components
import wpWritebtnImg from '../../../assets/images/wp/WpWriteBtnImg.png'
// api
import { customAxios } from '../../../axios/CustomAxios'
import { myIpAddress } from "config";

// components 
// @mui material components
import SoftTypography from "components/SoftTypography"
import { Badge, Grid, Icon } from "@mui/material"
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox"
import { useState } from 'react';




/**
 * 
 */
const HtmlTooltip = styled(function ({ className, ...props }) {
    return (
      <Tooltip {...props} classes={{ popper: className }} followCursor disableFocusListener />
    );
})(
    function ({ theme }) {
        return {
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: '#000',
                color: '#fff',
                maxWidth: 1220,
                fontSize: theme.typography.pxToRem(12),
                border: '1px solid #dadde9',
            },
        };
    }
);
  

/**
 * 복지포인트 작성하기 및 임시저장 체크
 * @returns {JSX.Element}  
 */
const WpWriteAndTemporary = () => {

    const user = useRecoilValue(loginUser);
    const navigate = useNavigate();

    const todayDate = new Date();
    const MONTH = todayDate.getMonth()+1;

    // 임시저장 글 수 
    const [tempCount, setTempCount] = useState(0);

    /** 복지포인트 작성 가능 달 */
    const usableMonth = useRecoilValue(wpUsableMonth);
    
    useEffect(()=>{
        tempDocCount();
    },[user])

    
    const tempDocCount = () => {
        try {
            customAxios.post(myIpAddress+"/wp/tempList", { 
                mem_no : user.id
                , page : 0
                , pageSize : 5
            })
            .then((response)=>{
                //console.log(response)
                setTempCount(response.data.length);
            })
        } catch (error) {
            Swal.fire({
                title: "에러 발생",
                text: "서버와의 통신에 실패했습니다." + error,
                icon: "error",
                backdrop: false,
            });
        }
        
    }  
 


    return (
        <>  
        <Grid container display="flex" flexDirection="row" flexWrap="nowrap"
           sx={{ 
                width:"100%",
                '@media(max-width:575px)': {
                    flexDirection: 'column',
                    alignItems: 'center',
                }
            }}
            >
            <Grid item 
                sx={{ 
                    width:"100%",
                    marginRight:"10px",
                    '@media(max-width:575px)': {
                        width:"100%",
                        margin : 0 ,
                        marginBottom : "10px",
                    }
                }}
                >
                <Card 
                    sx={{ 
                        // marginLeft:"5px",
                        // marginRight:"5px",
                        height: "100%"
                    }}
                    >
                    <SoftBox position="relative" height="100%" p={1.6} 
                        >
                        {
                            usableMonth !== 0 && usableMonth > MONTH ?
                            <HtmlTooltip
                                title={
                                <React.Fragment>
                                    <SoftTypography variant="button" color="white" fontWeight="bold">복지포인트 사용 가능 시기</SoftTypography>
                                    <br/>복지포인트는 <u>입사일</u>로부터 3개월이 되는 해당 월의 <u>다음 달</u> 1일부터 사용할 수 있습니다.
                                </React.Fragment>
                                }
                                >
                                <SoftBox
                                    display="flex"
                                    flexDirection="column"
                                    height="100%"
                                    py={2}
                                    px={2}
                                    borderRadius="lg"
                                    bgColor="dark"
                                    >
                                    <SoftBox mb={1}>
                                        <SoftTypography variant="h5"  color="white">
                                            {usableMonth}월에 작성하실 수 있습니다.
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftTypography
                                        component="a"
                                        variant="button"
                                        color="white"
                                        fontWeight="bold"
                                        sx={{
                                        mt: "auto",
                                        mr: "auto",
                                        display: "inline-flex",
                                        alignItems: "center",
                                        cursor: "pointer",

                                        "& .material-icons-round": {
                                            fontSize: "1.125rem",
                                            transform: `translate(2px, -0.5px)`,
                                            transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                                        },

                                        "&:hover .material-icons-round, &:focus  .material-icons-round": {
                                            transform: `translate(6px, -0.5px)`,
                                        },
                                        }}
                                        >
                                            작성하기
                                        <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                                    </SoftTypography>
                                </SoftBox>
                            </HtmlTooltip>
                            :
                            <SoftBox
                                position="relative"
                                height="100%"
                                onClick={() => navigate('/welfarePoint/insert')}
                                sx={{
                                    height: "100%",
                                    cursor: "pointer",
                                    "&:hover .material-icons-round": {
                                        transform: `translate(6px, -0.5px)`,
                                    },
                                    "&:hover > div:nth-of-type(1)": {
                                        backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                                            `${linearGradient(
                                                rgba("#7986cb", 0.8),
                                                rgba("#283593", 0.8)
                                            )}, url(${wpWritebtnImg})`,
                                    },
                                }}
                                >
                                <SoftBox
                                    display="flex"
                                    flexDirection="column"
                                    height="100%"
                                    py={2}
                                    px={2}
                                    borderRadius="lg"
                                    sx={{
                                        backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                                            `${linearGradient(
                                                rgba(gradients.dark.main, 0.8),
                                                rgba(gradients.dark.state, 0.8)
                                            )}, url(${wpWritebtnImg})`,
                                        backgroundSize: "cover",
                                    }}
                                    >
                                    <SoftBox mb={1}>
                                        <SoftTypography variant="h5"  color="white" >
                                            복지포인트 작성하기
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftTypography
                                        onClick={() => navigate('/welfarePoint/insert')}
                                        component="a"
                                        variant="button"
                                        color="white"
                                        fontWeight="bold"
                                        sx={{
                                        mt: "auto",
                                        mr: "auto",
                                        display: "inline-flex",
                                        alignItems: "center",
                                        cursor: "pointer",

                                        "& .material-icons-round": {
                                            fontSize: "1.125rem",
                                            transform: `translate(2px, -0.5px)`,
                                            transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                                        },

                                        "&:hover .material-icons-round, &:focus  .material-icons-round": {
                                            transform: `translate(6px, -0.5px)`,
                                        },
                                        }}
                                        >
                                            작성하기
                                        <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>
                        }
                    </SoftBox>
                </Card>
            </Grid>




            <Grid item
                sx={{
                    width:"100%",
                    marginLeft:"10px", 
                    '@media(max-width:575px)': {
                        width:"100%",
                        margin : 0
                    }
                }}
                >
                <Card
                    sx={{  }}
                    >
                    <SoftBox p={2} >
                        <SoftBox
                            height="100%"
                            py={1}
                            px={2}
                            borderRadius="lg"
                            >
                                { tempCount > 0 ?
                                    <>
                                    <Grid
                                        display = "flex"
                                        justifyContent = "flex-end"
                                        >
                                        <SoftBox mr={1}>
                                            <Badge color="error" variant="dot">
                                                <Icon
                                                    sx={{
                                                        color: "#1a237e",
                                                        mt: -0.5,
                                                    }}
                                                    >
                                                    event_note_outlined
                                                </Icon>
                                            </Badge>
                                        </SoftBox>
                                        <SoftBox>
                                            <SoftTypography variant="h5" color="dark" fontWeight="bold">
                                                임시 저장한 게시물이 있습니다.
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid>
                                        <SoftBox mr={2} textAlign="right">
                                            <SoftTypography
                                                component="a"
                                                onClick={() => navigate('/welfarePoint/tempList')}
                                                //href="/welfarePoint/tempList"
                                                variant="button"
                                                color="dark"
                                                fontWeight="bold"
                                                sx={{
                                                mt: "auto",
                                                mr: "auto",
                                                display: "inline-flex",
                                                alignItems: "center",
                                                cursor: "pointer",

                                                "& .material-icons-round": {
                                                    fontSize: "1.125rem",
                                                    transform: `translate(2px, -0.5px)`,
                                                    transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                                                },

                                                "&:hover .material-icons-round, &:focus  .material-icons-round": {
                                                    transform: `translate(6px, -0.5px)`,
                                                },
                                                }}
                                                >
                                                    이어서 작성하기
                                                <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    </>
                                    :
                                    <>
                                    <Grid
                                        display = "flex"
                                        justifyContent = "flex-end"
                                        >
                                        <SoftBox mr={1}>
                                            <Icon
                                                sx={{
                                                    color: "dark",
                                                    mt: -0.5,
                                                }}
                                                >
                                                event_note_outlined
                                            </Icon>
                                        </SoftBox>
                                        <SoftBox>
                                            <SoftTypography variant="h5" color="dark" fontWeight="bold">
                                                임시 저장한 게시물이 없습니다.
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid>
                                    <SoftBox mr={2} textAlign="right">
                                        <SoftTypography
                                            component="a"
                                            variant="button"
                                            color="dark"
                                            fontWeight="bold"
                                            sx={{
                                            mt: "auto",
                                            mr: "auto",
                                            display: "inline-flex",
                                            alignItems: "center",

                                            "& .material-icons-round": {
                                                fontSize: "1.125rem",
                                                transform: `translate(2px, -0.5px)`,
                                                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                                            },

                                            "&:hover .material-icons-round, &:focus  .material-icons-round": {
                                                transform: `translate(6px, -0.5px)`,
                                            },
                                            }}
                                            >
                                                게시물을 작성해주십시오.
                                        </SoftTypography>
                                    </SoftBox>
                                    </Grid>
                                    </>
                                }
                        </SoftBox>
                    </SoftBox>
                </Card>
            </Grid>
        </Grid>
        </>     
    )
}

export default WpWriteAndTemporary