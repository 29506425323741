import { customAxios } from "../../axios/CustomAxios";
import {myIpAddress} from "../../config";

/**
 * 업무 메모 저장
 * @param body : workMemo
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const userWorkMemoSave =(body)=>{
    return customAxios.post(myIpAddress+'/user/workMemo/save',body);
}
export default userWorkMemoSave;