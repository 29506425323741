import { Icon, IconButton } from "@mui/material";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import AddCircleIcon from '@mui/icons-material/AddCircle';


function TableColumns(props) {
    const { light } = colors;
    const { size, fontWeightBold } = typography;
    const { borderWidth, borderColor } = borders;

    return props.column.map(({ name, align, width, color, icon, min_width }, key) => {
        let pl;
        let pr;
        if (key === 0) { pl = 3; pr = 3; }
        else if (key === props.column.length - 1) { pl = 3; pr = 3; }
        else { pl = 1; pr = 1; }

        if (name === "추가") {
            return (
                <SoftBox
                    key={name} component="th" width={width ? width : "auto"}
                    textAlign="center" alignItems="center"
                    opacity={0.7} borderBottom={`${borderWidth[1]} solid ${light.main}`}
                >
                    <IconButton aria-label="add" color="dark" >
                        <AddCircleIcon onClick={props.add} />
                    </IconButton>
                </SoftBox>
            );
        } else if (name === ""){
            return (
                <SoftBox
                    key={name} component="th"
                    width={width ? width : "auto"}
                    opacity={0.7} borderBottom={`${borderWidth[1]} solid ${light.main}`}
                    p={0}
                    >
                    {name}
                </SoftBox>
            )
        } else {
            return (
                <SoftBox
                    key={name} component="th" width={width ? width : "auto"} pt={1} pb={1.25} pl={align === "left" ? pl : 3} pr={align === "right" ? pr : 3}
                    textAlign="center" fontSize={size.lg} fontWeight={fontWeightBold} color="secondary" alignItems="center"
                    opacity={0.7} borderBottom={`${borderWidth[1]} solid ${light.main}`}
                    style={{ minWidth: min_width ? min_width : null }}
                >
                    <SoftBox display="flex" alignItems="center" justifyContent="center">
                        <SoftBox
                            bgColor={color}
                            width="1.5rem"
                            height="1.5rem"
                            borderRadius="sm"
                            color="white"
                            fontSize={size.md}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            shadow="md"
                            mr={1}
                            variant="gradient"

                        >
                            <Icon>{icon}</Icon>
                        </SoftBox>
                        <SoftTypography
                            variant="h6"
                            textTransform="capitalize"
                            fontWeight="medium"
                            color="text"
                            sx={{ display: "inline-block", width: "max-content", }}
                        >
                            {name.toUpperCase()}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            );
        }
    });
}

export default TableColumns;