import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 복지포인트 메인 페이지 금액 통계
 * @param {*} body 
 * @returns 
 */

const wpPointList =(body)=>{
    return customAxios.post(myIpAddress+'/wp/pointList', body ); 
}
export default wpPointList;
