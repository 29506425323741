import {atomFamily} from "recoil";

/**
 * 셀렉트 박스 체크 값
 * @type {(param: SerializableParam) => RecoilState<{value: string, keyName: *}>}
 */
export const selectValueAtomFamily = atomFamily({
    key: "selectValueAtomFamily",
    default: (key) => (
        {
            value : "",
            keyName : key
        }
    )
})