import colorConfig from "../../../../../colorConfig";

/**
 * 휴가 바 차트 설정값
 * @param labels
 * @param datasets
 * @returns {{monthData: {datasets: [{borderSkipped: boolean, tension: number, backgroundColor: string, borderRadius: number, data, borderWidth: number, maxBarThickness: number, label},{borderSkipped: boolean, tension: number, backgroundColor: string, borderRadius: number, data: [number,number,number,number,number,number,number,number,number,number,number,number], borderWidth: number, maxBarThickness: number, label: string},{borderSkipped: boolean, tension: number, backgroundColor: string, borderRadius: number, data: [number,number,number,number,number,number,number,number,number,number,number,number], borderWidth: number, maxBarThickness: number, label: string},{borderSkipped: boolean, tension: number, backgroundColor: string, borderRadius: number, data: [number,number,number,number,number,number,number,number,number,number,number,number], borderWidth: number, maxBarThickness: number, label: string}], labels}, options: {plugins: {legend: {display: boolean}}, responsive: boolean, scales: {x: {ticks: {display: boolean}, grid: {drawBorder: boolean, drawOnChartArea: boolean, display: boolean, drawTicks: boolean}}, y: {ticks: {padding: number, suggestedMin: number, color: string, suggestedMax: number, beginAtZero: boolean, font: {size: number, style: string, lineHeight: number, family: string}}, grid: {drawBorder: boolean, drawOnChartArea: boolean, display: boolean, drawTicks: boolean}}}, interaction: {mode: string, intersect: boolean}, maintainAspectRatio: boolean}}}
 */
function configs(labels, datasets) {
  const {vacationType} = colorConfig;
  return {
    monthData: {
      labels,
      datasets: [
        {
          label: datasets.label,
          tension: 0.4,
          borderWidth: 0,
          borderRadius: 4,
          borderSkipped: false,
          backgroundColor: vacationType.VT000,
          data: datasets.data,
          maxBarThickness: 4,
        },
        {
          label: datasets.label2,
          tension: 0.4,
          borderWidth: 0,
          borderRadius: 4,
          borderSkipped: false,
          backgroundColor: vacationType.VT001,
          data: datasets.data2,
          maxBarThickness: 4,
        },
        {
          label: datasets.label3,
          tension: 0.4,
          borderWidth: 0,
          borderRadius: 4,
          borderSkipped: false,
          backgroundColor: vacationType.VT002,
          data: datasets.data3,
          maxBarThickness: 4,
        },
        {
          label: datasets.label4,
          tension: 0.4,
          borderWidth: 0,
          borderRadius: 4,
          borderSkipped: false,
          backgroundColor: vacationType.VT003,
          data: datasets.data4,
          maxBarThickness: 4,
        },
      ],
    },

    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            suggestedMin: 0,
            suggestedMax: 500,
            beginAtZero: true,
            padding: 15,
            font: {
              size: 14,
              family: "Roboto",
              style: "normal",
              lineHeight: 2,
            },
            color: "#fff",
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  };
}

export default configs;
