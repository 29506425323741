import SoftBox from "../../../../components/SoftBox";
import Grid from "@mui/material/Grid";
import {Badge, Card, useMediaQuery} from "@mui/material";
import allDoc from "../../../../assets/images/docStatus/allDoc.png";
import person from "../../../../assets/images/docStatus/person.png";
import docApprovalFinish from "../../../../assets/images/docStatus/docApprovalFinish.png";
import docUnderApproval from "../../../../assets/images/docStatus/docUnderApproval.png";
import docReject from "../../../../assets/images/docStatus/docReject.png";
import DirectionAnimation from "../../components/DirectionAnimation";
import colors from "../../../../assets/theme/base/colors";
import SoftTypography from "../../../../components/SoftTypography";
import Tooltip from "@mui/material/Tooltip";
import {useEffect, useState} from "react";
import {QueryClient, useQueries} from "react-query";
import {isRecoilValue, useRecoilValue} from "recoil";
import {loginUser} from "../../../../atom/member/loginUser";
import myDocCount from "../../../../api/docs/myDocCount";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import ReferenceDocs from "../ReferenceDocs";
import checkDocCount from "../../../../api/docs/checkDocCount";

/**
 * 내 문서함 갯수 세기 뷰
 * @returns {JSX.Element}
 * @constructor
 */
function DocsCountView() {

    const navigate = useNavigate();

    // 컬러값
    const {success, error, warning, primary, badgeColors} = colors;

    // 화면 사이즈 : 모바일 화면일때
    const isXsScreen = useMediaQuery('(max-width:800px)');

    // react query
    const queryClient = new QueryClient();

    // login 정보
    const recoilState = isRecoilValue(loginUser);
    const user = useRecoilValue(loginUser);

    // recoil 정보가 가져와 지면 refetch
    useEffect(() => {
        if (recoilState) {
            // Only execute the query if myState is not null
            queryClient.refetchQueries('myDocCount');
        }
    }, [recoilState]);

    const [noApprovalCount, setNoApprovalCount] = useState(0); // 무결재
    const [approvalCount, setApprovalCount] = useState(0); // 상신문서
    const [underApprovalCount, setUnderApprovalCount] = useState(0); // 결재진행중인문서
    const [completeApprovalCount, setCompleteApprovalCount] = useState(0); // 결재완료 문서
    const [rejectCount, setRejectCount] = useState(0); // 반려문서
    const [nonCheckRejectCount, setNonCheckRejectCount] = useState(0); // 반려 미확인 문서
    const [storeDocCount, setStoreDocCount] = useState(0); // 임시보관문서
    const [cancelCount, setCancelCount] = useState(0); // 상신취소 문서
    const [receiveCount, setReceiveCount] = useState(0); // 결재 수신 문서
    const [nonCheckReceiveCount, setNonCheckReceiveCount] = useState(0); // 미결재 문서
    const [refCount, setRefCount] = useState(0); // 참조 수신 문서
    const [nonCheckRefCount, setNonCheckRefCount] = useState(0); // 참조 미확인 문서


    // react-query
    const queries = useQueries([
            // queries[0] : 문서 카운트
            {
                queryKey: ["myDocCount", user?.id],
                queryFn: () => myDocCount(user?.id),
                options: {
                    staleTime: 120000,
                    refetchOnWindowFocus: false,
                    refetchOnMount: false,
                },
                onSuccess: (data) => {
                    const docCount = data.data;
                    setNoApprovalCount(UndefinedClassifier(docCount?.AP000));
                    setApprovalCount(UndefinedClassifier(docCount?.AP001));
                    setUnderApprovalCount(UndefinedClassifier(docCount?.AP002));
                    setCompleteApprovalCount(UndefinedClassifier(docCount?.AP003));
                    setRejectCount(UndefinedClassifier(docCount?.AP004));
                    setNonCheckRejectCount(UndefinedClassifier(docCount?.rejectNonCheckCount));
                    setStoreDocCount(UndefinedClassifier(docCount?.AP005));
                    setCancelCount(UndefinedClassifier(docCount?.AP006));
                    setReceiveCount(UndefinedClassifier(docCount?.approval_count));
                    setRefCount(UndefinedClassifier(docCount?.ref_count));
                    setNonCheckRefCount(UndefinedClassifier(docCount?.refNonCheckCount));
                }
            },
            {
            queryKey: ["checkDocCount", {
                mem_no : user?.id
            } ],
            queryFn: () => checkDocCount({
                mem_no : user?.id
            }),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            },
            onSuccess: (data) => {
                const countList = data.data;
                const count = countList[0].checkCount;
                setNonCheckReceiveCount(UndefinedClassifier(count));
            }
        }
        ]
    );

    /**
     * 들어온 변수가 undifined면 0 리턴 아니면 값 리턴하는 함수
     * @param keyValue - docCount
     * @returns {number|*}
     * @constructor
     */
    function UndefinedClassifier(keyValue) {
        if (keyValue !== undefined) {
            return keyValue;
        } else {
            return 0;
        }
    }

    function goSent() {
        navigate('/docs/sent', {state: 'APT000'});
    }

    function goReceive() {
        navigate('/docs/receive', {state: 'APT000'});
    }

    function goStore() {
        navigate('/docs/store', {state: 'APT000'});
    }
    function goReference() {
        navigate('/docs/reference', {state: 'APT000'});
    }

    return (
        <Card sx={{width: "100%"}}>
            <Grid container my={3} display="flex" justifyContent="space-between" alignItems="center">
                {isXsScreen ? (
                    // 모바일 환경
                    <>
                        <Grid item xs={4} md={4} onClick={goSent} sx={{
                            borderRadius: "10px",
                            "&:hover": {
                                bgcolor: badgeColors.info.background,
                                cursor: "pointer",
                            },
                        }}
                        >
                            <Grid item xs={12} display="flex" justifyContent="center"
                                  alignItems="flex-start">
                                <SoftTypography variant="caption" fontWeight="bold">
                                    {noApprovalCount + approvalCount + underApprovalCount + completeApprovalCount + rejectCount + nonCheckRejectCount + cancelCount + storeDocCount}개
                                </SoftTypography>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center"
                                  alignItems="center">
                                <img src={allDoc} style={{width: `${isXsScreen ? '35%' : '60%'}`}}/>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center"
                                  alignItems="center">
                                <SoftTypography variant="button" fontWeight="bold">
                                    전체상신문서
                                </SoftTypography>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} md={4} onClick={goSent} sx={{
                            borderRadius: "10px",
                            "&:hover": {
                                bgcolor: badgeColors.info.background,
                                cursor: "pointer",
                            },
                        }}
                        >
                            <Grid item xs={12} display="flex" justifyContent="center"
                                  alignItems="flex-start">
                                <SoftTypography variant="caption" fontWeight="bold">
                                    {noApprovalCount + approvalCount + underApprovalCount + completeApprovalCount + rejectCount + nonCheckRejectCount + cancelCount + storeDocCount}개
                                </SoftTypography>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center"
                                  alignItems="center">
                                <img src={person} style={{width: `${isXsScreen ? '35%' : '60%'}`}}/>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center"
                                  alignItems="center">
                                <SoftTypography variant="button" fontWeight="bold">
                                    결재완료문서
                                </SoftTypography>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} md={4} borderRadius="10px" onClick={goReceive}
                              sx={{
                                  borderRadius: "10px",
                                  "&:hover": {
                                      bgcolor: badgeColors.info.background,
                                      cursor: "pointer",
                                  },
                              }}>
                            <Grid item xs={12} display="flex" justifyContent="center"
                                  alignItems="flex-start">
                                <SoftTypography variant="caption" fontWeight="bold">
                                    {refCount + receiveCount + nonCheckReceiveCount}개
                                </SoftTypography>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center"
                                  alignItems="center">
                                <img src={docApprovalFinish} style={{width: `${isXsScreen ? '35%' : '60%'}`}}/>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center"
                                  alignItems="center">
                                <SoftTypography variant="button" fontWeight="bold">
                                    전체수신문서
                                </SoftTypography>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} md={6} pt={1}>
                            <Grid pb={1} item xs={12} md={12} lg={12} display="flex" justifyContent="center"
                                  alignItems="center">
                                <Grid item xs={6} md={6} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <Badge color="error" badgeContent={approvalCount}>
                                        <SoftBox m={-0.3}>
                                            <Grid item xs={12} p={1}
                                                  borderRadius="0.5rem" border="1px solid black" onClick={goSent}
                                                  sx={{
                                                      borderRadius: "0.5rem",
                                                      "&:hover": {
                                                          bgcolor: badgeColors.info.background,
                                                          cursor: "pointer",
                                                      },
                                                  }}>
                                                <SoftTypography variant="h6" fontWeight="bold">
                                                    상신문서
                                                </SoftTypography>
                                            </Grid>
                                        </SoftBox>
                                    </Badge>
                                </Grid>
                                <Grid item xs={6} md={6} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <Badge color="error" badgeContent={underApprovalCount}>
                                        <SoftBox m={-0.3}>
                                            <Grid item xs={12} p={1}
                                                  borderRadius="0.5rem" border="1px solid black" onClick={goSent}
                                                  sx={{
                                                      borderRadius: "0.5rem",
                                                      "&:hover": {
                                                          bgcolor: badgeColors.info.background,
                                                          cursor: "pointer",
                                                      },
                                                  }}>
                                                <SoftTypography variant="h6" fontWeight="bold">
                                                    결재진행
                                                </SoftTypography>
                                            </Grid>
                                        </SoftBox>
                                    </Badge>
                                </Grid>
                            </Grid>
                            <Grid pt={1} item xs={12} md={12} lg={12} display="flex" justifyContent="center"
                                  alignItems="center">
                                <Grid item xs={4} md={4} lg={4} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <Badge color="error" badgeContent={storeDocCount}>
                                        <SoftBox m={-0.3}>
                                            <Grid item xs={12} p={1}
                                                  borderRadius="0.5rem" border="1px solid black" onClick={goStore}
                                                  sx={{
                                                      borderRadius: "0.5rem",
                                                      "&:hover": {
                                                          bgcolor: badgeColors.info.background,
                                                          cursor: "pointer",
                                                      },
                                                  }}>
                                                <SoftTypography variant="h6" fontWeight="bold">
                                                    보관문서
                                                </SoftTypography>
                                            </Grid>
                                        </SoftBox>
                                    </Badge>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} md={6} pt={1}>
                            <Grid pb={1} item xs={12} md={12} lg={12} display="flex" justifyContent="center"
                                  alignItems="center">
                                <Grid item xs={6} md={6} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <Badge color="error" badgeContent={nonCheckReceiveCount}>
                                        <SoftBox m={-0.3}>
                                            <Grid item xs={12} p={1}
                                                  borderRadius="0.5rem" border="1px solid black" onClick={goReceive}
                                                  sx={{
                                                      borderRadius: "0.5rem",
                                                      "&:hover": {
                                                          bgcolor: badgeColors.info.background,
                                                          cursor: "pointer",
                                                      },
                                                  }}>
                                                <SoftTypography variant="h6" fontWeight="bold">
                                                    결재수신
                                                </SoftTypography>
                                            </Grid>
                                        </SoftBox>
                                    </Badge>
                                </Grid>
                                <Grid item xs={6} md={6} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <Badge color="error" badgeContent={nonCheckRefCount}>
                                        <SoftBox m={-0.3}>
                                            <Grid item xs={12} p={1}
                                                  borderRadius="0.5rem" border="1px solid black" onClick={goReference}
                                                  sx={{
                                                      borderRadius: "0.5rem",
                                                      "&:hover": {
                                                          bgcolor: badgeColors.info.background,
                                                          cursor: "pointer",
                                                      },
                                                  }}>
                                                <SoftTypography variant="h6" fontWeight="bold">
                                                    참조문서
                                                </SoftTypography>
                                            </Grid>
                                        </SoftBox>
                                    </Badge>
                                </Grid>
                            </Grid>
                            <Grid pt={1} item xs={12} md={12} lg={12} display="flex" justifyContent="center"
                                  alignItems="center">
                                <Grid item xs={4} md={4} lg={4} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <Badge color="error" badgeContent={nonCheckRejectCount}>
                                        <SoftBox m={-0.3}>
                                            <Grid item xs={12} p={1}
                                                  borderRadius="0.5rem" border="1px solid black" onClick={goSent}
                                                  sx={{
                                                      borderRadius: "0.5rem",
                                                      "&:hover": {
                                                          bgcolor: badgeColors.info.background,
                                                          cursor: "pointer",
                                                      },
                                                  }}>
                                                <SoftTypography variant="h6" fontWeight="bold">
                                                    반려문서
                                                </SoftTypography>
                                            </Grid>
                                        </SoftBox>
                                    </Badge>
                                </Grid>
                            </Grid>
                        </Grid>

                    </>
                ) : (
                    // pc 환경
                    <>
                        <Tooltip
                            title={`${noApprovalCount + approvalCount + underApprovalCount + completeApprovalCount + rejectCount + nonCheckRejectCount + cancelCount + storeDocCount}개`}
                            placement="top">
                            <Grid item xs={1.5} md={1.5} lg={1.5} onClick={goSent} sx={{
                                borderRadius: "10px",
                                "&:hover": {
                                    bgcolor: badgeColors.info.background,
                                    cursor: "pointer",
                                },
                            }}
                            >
                                <Grid item xs={12} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <img src={allDoc} style={{width: `${isXsScreen ? '20%' : '60%'}`}}/>
                                </Grid>
                                <Grid item xs={12} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <SoftTypography variant="h5" fontWeight="bold">
                                        전체상신문서
                                    </SoftTypography>
                                </Grid>
                            </Grid>
                        </Tooltip>
                        <Grid item xs={3.7} md={3.7} lg={3.7}>
                            <Grid pb={1} item xs={12} md={12} lg={12} display="flex" justifyContent="center"
                                  alignItems="center">
                                <Grid item xs={2} md={2} lg={2} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <DirectionAnimation color={warning.focus}/>
                                </Grid>
                                <Grid item xs={3} md={3} lg={3} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <Badge color="error" badgeContent={approvalCount}>
                                        <SoftBox m={-0.3}>
                                            <Grid item xs={12} p={1}
                                                  borderRadius="0.5rem" border="1px solid black" onClick={goSent}
                                                  sx={{
                                                      borderRadius: "0.5rem",
                                                      "&:hover": {
                                                          bgcolor: badgeColors.info.background,
                                                          cursor: "pointer",
                                                      },
                                                  }}>
                                                <SoftTypography variant="h5" fontWeight="bold">
                                                    상신문서
                                                </SoftTypography>
                                            </Grid>
                                        </SoftBox>
                                    </Badge>
                                </Grid>
                                <Grid item xs={2} md={2} lg={2} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <DirectionAnimation color={warning.focus}/>
                                </Grid>
                                <Grid item xs={3} md={3} lg={3} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <Badge color="error" badgeContent={underApprovalCount}>
                                        <SoftBox m={-0.3}>
                                            <Grid item xs={12} p={1}
                                                  borderRadius="0.5rem" border="1px solid black" onClick={goSent}
                                                  sx={{
                                                      borderRadius: "0.5rem",
                                                      "&:hover": {
                                                          bgcolor: badgeColors.info.background,
                                                          cursor: "pointer",
                                                      },
                                                  }}>
                                                <SoftTypography variant="h5" fontWeight="bold">
                                                    결재진행
                                                </SoftTypography>
                                            </Grid>
                                        </SoftBox>
                                    </Badge>
                                </Grid>
                                <Grid item xs={2} md={2} lg={2} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <DirectionAnimation color={warning.focus}/>
                                </Grid>
                            </Grid>
                            <Grid pt={1} item xs={12} md={12} lg={12} display="flex" justifyContent="center"
                                  alignItems="center">
                                <Grid item xs={4} md={4} lg={4} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <DirectionAnimation color={success.focus} reverse={true}/>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <Badge color="error" badgeContent={storeDocCount}>
                                        <SoftBox m={-0.3}>
                                            <Grid item xs={12} p={1}
                                                  borderRadius="0.5rem" border="1px solid black" onClick={goStore}
                                                  sx={{
                                                      borderRadius: "0.5rem",
                                                      "&:hover": {
                                                          bgcolor: badgeColors.info.background,
                                                          cursor: "pointer",
                                                      },
                                                  }}>
                                                <SoftTypography variant="h5" fontWeight="bold">
                                                    보관문서
                                                </SoftTypography>
                                            </Grid>
                                        </SoftBox>
                                    </Badge>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <DirectionAnimation color={success.focus} reverse={true}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Tooltip title={`${completeApprovalCount}개`} placement="top">
                            <Grid item xs={1.5} md={1.5} lg={1.5} borderRadius="10px" onClick={goSent} sx={{
                                borderRadius: "10px",
                                "&:hover": {
                                    bgcolor: badgeColors.info.background,
                                    cursor: "pointer",
                                },
                            }}>
                                <Grid item xs={12} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <img src={person}
                                         style={{width: `${isXsScreen ? '20%' : '60%'}`}}/>
                                </Grid>
                                <Grid item xs={12} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <SoftTypography variant="h5" fontWeight="bold">
                                        결재완료문서
                                    </SoftTypography>
                                </Grid>
                            </Grid>
                        </Tooltip>
                        <Grid item xs={3.7} md={3.7} lg={3.7}>
                            <Grid pb={1} item xs={12} md={12} lg={12} display="flex" justifyContent="center"
                                  alignItems="center">
                                <Grid item xs={2} md={2} lg={2} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <DirectionAnimation color={warning.focus} reverse={true}/>
                                </Grid>
                                <Grid item xs={3} md={3} lg={3} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <Badge color="error" badgeContent={nonCheckReceiveCount}>
                                        <SoftBox m={-0.3}>
                                            <Grid item xs={12} p={1}
                                                  borderRadius="0.5rem" border="1px solid black" onClick={goReceive}
                                                  sx={{
                                                      borderRadius: "0.5rem",
                                                      "&:hover": {
                                                          bgcolor: badgeColors.info.background,
                                                          cursor: "pointer",
                                                      },
                                                  }}>
                                                <SoftTypography variant="h5" fontWeight="bold">
                                                    결재수신
                                                </SoftTypography>
                                            </Grid>
                                        </SoftBox>
                                    </Badge>
                                </Grid>
                                <Grid item xs={2} md={2} lg={2} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <DirectionAnimation color={warning.focus} reverse={true}/>
                                </Grid>
                                <Grid item xs={3} md={3} lg={3} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <Badge color="error" badgeContent={nonCheckRefCount}>
                                        <SoftBox m={-0.3}>
                                            <Grid item xs={12} p={1}
                                                  borderRadius="0.5rem" border="1px solid black" onClick={goReference}
                                                  sx={{
                                                      borderRadius: "0.5rem",
                                                      "&:hover": {
                                                          bgcolor: badgeColors.info.background,
                                                          cursor: "pointer",
                                                      },
                                                  }}>
                                                <SoftTypography variant="h5" fontWeight="bold">
                                                    참조문서
                                                </SoftTypography>
                                            </Grid>
                                        </SoftBox>
                                    </Badge>
                                </Grid>
                                <Grid item xs={2} md={2} lg={2} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <DirectionAnimation color={warning.focus} reverse={true}/>
                                </Grid>
                            </Grid>
                            <Grid pt={1} item xs={12} md={12} lg={12} display="flex" justifyContent="center"
                                  alignItems="center">
                                <Grid item xs={4} md={4} lg={4} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <DirectionAnimation color={error.focus}/>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <Badge color="error" badgeContent={nonCheckRejectCount}>
                                        <SoftBox m={-0.3}>
                                            <Grid item xs={12} p={1}
                                                  borderRadius="0.5rem" border="1px solid black" onClick={goSent}
                                                  sx={{
                                                      borderRadius: "0.5rem",
                                                      "&:hover": {
                                                          bgcolor: badgeColors.info.background,
                                                          cursor: "pointer",
                                                      },
                                                  }}>
                                                <SoftTypography variant="h5" fontWeight="bold">
                                                    반려문서
                                                </SoftTypography>
                                            </Grid>
                                        </SoftBox>
                                    </Badge>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <DirectionAnimation color={error.focus}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Tooltip title={`${refCount + receiveCount + nonCheckReceiveCount}개`} placement="top">
                            <Grid item xs={12} md={1.5} lg={1.5} borderRadius="10px" onClick={goReceive} sx={{
                                borderRadius: "10px",
                                "&:hover": {
                                    bgcolor: badgeColors.info.background,
                                    cursor: "pointer",
                                },
                            }}>
                                <Grid item xs={12} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <img src={docApprovalFinish}
                                         style={{width: `${isXsScreen ? '20%' : '60%'}`}}/>
                                </Grid>
                                <Grid item xs={12} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <SoftTypography variant="h5" fontWeight="bold">
                                        전체수신문서
                                    </SoftTypography>
                                </Grid>
                            </Grid>
                        </Tooltip>
                    </>
                )}
            </Grid>
        </Card>
    );
}


export default DocsCountView;