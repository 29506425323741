import {atomFamily} from "recoil";

/**
 * 결재자 상태
 * @type {(param: SerializableParam) => RecoilState<{keyName: *, status: boolean}>}
 */
export const nameCompleteInputStatusAtomFamily = atomFamily({
    key: "nameCompleteInputStatusAtomFamily",
    default: (key) => ({
        status:true,
        keyName: key
    }),
})