import VacationTable from "../../../../../../examples/Tables/VacationTable";
import data from "./data";
import SoftBox from "../../../../../../components/SoftBox";
import Pagination from "@mui/material/Pagination";
import {useRecoilState, useRecoilValue} from "recoil";
import {vacationSentState} from "../../../../../../atom/approval/sent/vacationSentState";
import {toMap} from '../../../../../../utils/ToMap';
import {sentDocTotalCount} from "../../../../../../atom/approval/sent/sentDocTotalCount";

/**
 * 휴가 상신 문서들
 * @returns {JSX.Element}
 * @constructor
 */
function VacationSentDocs () {
    const {columns, rows} = data();

    const [sentState, setSentState ] = useRecoilState(vacationSentState);

    const totalCount = useRecoilValue(sentDocTotalCount);
    const itemsPerPage = sentState.docsNum;
    const totalPages =  Math.ceil(totalCount / itemsPerPage);

    // page 변경
    function handlePageChange(event, value) {
        const thisSentMap = toMap({...sentState});
        thisSentMap.set("nowPage",value);
        setSentState(Object.fromEntries(thisSentMap));
    }

    return (
        <>
            <VacationTable columns={columns} rows={rows}/>
            <SoftBox display='flex' justifyContent='center'>
                <Pagination
                    count={totalPages}
                    page={sentState.nowPage}
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton
                />
            </SoftBox>
        </>
    );
}

export default VacationSentDocs;