import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftProgress from "components/SoftProgress";
import {QueryClient, useQueries} from "react-query";
import React, {useEffect, useState} from "react";
import allCommonCodeMap from "../../../../api/commonCode/allCommonCodeMap";
import allUserVacationInfo from "../../../../api/vacation/allUserVacationInfo";
import SoftTypography from "../../../../components/SoftTypography";
import CommonCodeInfoReturn from "../../../../components/commonCode/CommonCodeInfoReturn";
import Loading from "../../../../components/loading/Loading";
import vacationYearMonthData from "../../../../api/vacation/vacationYearMonthData";
import {isRecoilValue, useRecoilState, useRecoilValue} from "recoil";
import {
    allUserVacationMonthAtom,
    alluserVacationMonthAtom,
    customMonthSelector
} from "../../../../atom/vacation/alluserVacationMonthAtom";
import {loginUser} from "../../../../atom/member/loginUser";
import Tooltip from "@mui/material/Tooltip";
import SoftBadge from "../../../../components/SoftBadge";
import Icon from "@mui/material/Icon";
import SoftButton from "../../../../components/SoftButton";
import {useNavigate} from "react-router-dom";

/**
 * 전체 유저 해당월 테이블
 *
 * @returns {{columns: [{name: string, align: string},{name: string, align: string},{name: string, align: string}], rows: *[]}}
 */

/**
 * 전체 유저 해당월 테이블
 * @returns {{memberList: [], memberIndexList: Map<any, any>, columns: [{name: string, width: string, align: string, value: string},{name: string, width: string, align: string, value: string},{name: string, width: string, align: string, value: string},{name: string, width: string, align: string, value: string},{name: string, width: string, align: string, value: string},null,null], rows: *[], memberIndexBool: Map<any, any>}}
 * @constructor
 */
export default function AllUserVacationMonthData() {

    // react query
    const queryClient = new QueryClient();
    const navigate = useNavigate();

    // 처음 로드된 여부
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [firstLoad, setFirstLoad] = useState(false);

    // 테이블 데이터 정보
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const tableDataList = [];

    // 공용코드 맵
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [commonCodeMap, setCommonCodeMap] = useState(new Map());

    // 월별 조회 정보
    const [vacationMonthRecoil, setVacationMonthRecoil] = useRecoilState(allUserVacationMonthAtom);
    const recoilState = isRecoilValue(allUserVacationMonthAtom);
    const customMonthValue = useRecoilValue(customMonthSelector);

    const yearList = () => {
        const thisYear = Number(vacationMonthRecoil?.year);
        const yearSetList = [thisYear];
        for (let i = 1; i < 6; i++) {
            yearSetList.push(thisYear + i);
            yearSetList.push(thisYear - i);
        }
        yearSetList.sort((a, b) => a - b);
        return (yearSetList);
    }

    useEffect(() => {
        if (recoilState) {
            queryClient.refetchQueries('vacationYearMonthData');
        }
    }, [recoilState]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const queries = useQueries([
        // queries[0] : 휴가 정보
        {
            queryKey: ["vacationYearMonthData", {
                now_page: vacationMonthRecoil?.nowPage,
                docs_num: vacationMonthRecoil?.docsNum,
                ascending: vacationMonthRecoil?.ascending,
                year: String(vacationMonthRecoil?.year),
                month: customMonthValue,
                approvedList: vacationMonthRecoil?.approvedList,
            }],
            queryFn: () => vacationYearMonthData({
                now_page: vacationMonthRecoil?.nowPage,
                docs_num: vacationMonthRecoil?.docsNum,
                ascending: vacationMonthRecoil?.ascending,
                year: String(vacationMonthRecoil?.year),
                month: customMonthValue,
                approvedList: vacationMonthRecoil?.approvedList,
            }),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            },
            onSuccess: (result) => {
                // 휴가 정보
                const userVacationList = result.data;
                if(userVacationList.length === 0){
                    setVacationMonthRecoil((prevValue) => ({
                        ...prevValue,
                        totalCount:0
                    }));
                } else {
                    setVacationMonthRecoil((prevValue) => ({
                        ...prevValue,
                        totalCount:userVacationList[0].total_count
                    }));
                }
            },
        },
        {
            queryKey: ["allCommonCodeMap"],
            queryFn: () => allCommonCodeMap(),
            onSuccess: (result) => {
                setCommonCodeMap(result.data);
            }
        }
    ]);

    if (queries[0].isSuccess && queries[1].isSuccess) {
        // 휴가 정보
        const userVacationList = queries[0].data.data;
        for (let i = 0; i < userVacationList.length; i++) {
            const approverList = [];
            for (let j = 0; j < userVacationList[i].approverList.length; j++) {
                const tinyInfoSetList = [];
                const approverRank = commonCodeMap[userVacationList[i].approverList[j]["approver_rank"]]?.info; // 결재자 랭크
                const approverName = userVacationList[i].approverList[j]["approver_name"]; // 결재자 이름
                const approverLevel = userVacationList[i].approverList[j]["approver_level"];
                const approverImg = userVacationList[i].approverList[j]["approver_img"]; // 결재자 프로필사진
                // 저장용 리스트에 저장
                tinyInfoSetList.push(approverImg);
                tinyInfoSetList.push("[" + (approverLevel + 1) + "번 결재자] " + approverName + " " + approverRank);

                // 저장용 리스트에 저장
                approverList.push(tinyInfoSetList);
            }

            const saveMap = {
                doc_no: userVacationList[i].vac_no,
                mem_no: userVacationList[i].mem_no,
                mem_name: userVacationList[i].mem_name,
                mem_rank: userVacationList[i].vac_rank,
                mem_team: userVacationList[i].vac_team,
                img: userVacationList[i].imageUrl,
                approved: userVacationList[i].vac_approved,
                category: userVacationList[i].vac_category,
                start: userVacationList[i].vac_start,
                end: userVacationList[i].vac_end,
                days: userVacationList[i].vac_days,
                approverList: approverList
            }
            // 전체 리스트에 저장
            tableDataList.push(saveMap);
        }
    }

    /**
     * 네임 카드
     * @param name:String-이름
     * @param rank:String-랭크공용코드
     * @param team:String-팀공용코드
     * @param img:String-구글이미지
     * @returns {JSX.Element}
     */
    const nameCard = (name, rank, team, img) => {
        return (
            <SoftBox display="flex">
                <SoftBox>
                    <SoftAvatar
                        src={img}
                        alt="profile-image"
                        variant="rounded"
                        size="md"
                        shadow="sm"
                    />
                </SoftBox>
                <SoftBox ml={1}>
                    <SoftBox mt={0.5} lineHeight={1}>
                        <SoftTypography variant="h6" fontWeight="bold">
                            {name}
                        </SoftTypography>
                        <SoftTypography variant="button" color="text" fontWeight="medium">
                            {commonCodeMap[team]?.info} / {commonCodeMap[rank]?.info}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            </SoftBox>
        );
    }

    /**
     * row에 데이터 렌더링
     * @returns {*[]}
     */
    const tableDataRender = () => {
        const dataArr = [];
        for (let i = 0; i < tableDataList.length; i++) {
            const approvedInfo = commonCodeMap[tableDataList[i]?.approved]?.info
            dataArr.push(
                {
                    docNo: tableDataList[i]?.doc_no,
                    memNo: tableDataList[i]?.mem_no,
                    memInfo: nameCard(tableDataList[i]?.mem_name, tableDataList[i]?.mem_rank, tableDataList[i]?.mem_team, tableDataList[i]?.img),
                    vacationDate: (
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                            <SoftBadge
                                variant="contained"
                                badgeContent={tableDataList[i]?.start}
                                color="secondary" size="md" container
                                sx={{display: "inline-block", width: "max-content"}}
                            />
                            <SoftTypography variant="button" fontWeight="bold">
                                &nbsp;-&nbsp;
                            </SoftTypography>
                            <SoftBadge
                                variant="contained"
                                badgeContent={tableDataList[i]?.end}
                                color="secondary" size="md" container
                                sx={{display: "inline-block", width: "max-content"}}
                            />
                        </SoftBox>
                    ),
                    days: (
                        <>
                            <SoftBadge
                                variant="contained"
                                badgeContent={commonCodeMap[tableDataList[i]?.category]?.info}
                                color="secondary" size="md" container
                                sx={{display: "inline-block", width: "max-content"}}
                            />
                            <SoftTypography variant="button" fontWeight="bold">
                                &nbsp;{tableDataList[i]?.days} 일
                            </SoftTypography>
                        </>
                    ),
                    approver: (
                        <SoftBox display="flex" py={1}>
                            {avatars(
                                tableDataList[i]?.approverList
                            )
                            }
                        </SoftBox>
                    ),
                    approved: (
                        <SoftBadge
                            variant="contained"
                            badgeContent={approvedInfo}
                            color={approvedInfo === "결재완료" ? "success" : approvedInfo === "반려" ? "error" : "info"}
                            size="lm" container
                            sx={{display: "inline-block", width: "max-content"}}
                        />
                    ),
                    pdf: (
                        <SoftButton variant="gradient" color="error" onClick={() =>
                            navigate("/vacation/pdf", {state: parseInt(tableDataList[i]?.doc_no)})
                        }>
                            PDF미리보기
                        </SoftButton>
                    )

                }
            );
        }
        return dataArr;
    };

    /**
     * 이름과 이미지로 호버시 팀,이름,직책 나옴
     * @param members
     * @returns {*}
     */
    const avatars = (members) =>
        members.map(([image, name], index) => (
            <Tooltip key={name + index} title={name} placeholder="bottom">
                <SoftAvatar
                    src={image}
                    alt="name"
                    size="xs"
                    sx={{
                        border: ({borders: {borderWidth}, palette: {white}}) =>
                            `${borderWidth[2]} solid ${white.main}`,
                        cursor: "pointer",
                        position: "relative",

                        "&:not(:first-of-type)": {
                            ml: -1.25,
                        },

                        "&:hover, &:focus": {
                            zIndex: "10",
                        },
                    }}
                />
            </Tooltip>
        ));

    return {
        columns: [
            {name: "docNo", align: "center", value: "문서번호", width: "10%"},
            {name: "memInfo", align: "center", value: "사원정보", width: "20%"},
            {name: "vacationDate", align: "center", value: "휴가일정", width: "25%"},
            {name: "days", align: "center", value: "휴가정보", width: "20%"},
            {name: "approver", align: "center", value: "결재자", width: "10%"},
            {name: "approved", align: "center", value: "결재상태", width: "15%"},
            // {name: "pdf", align: "center", value: "PDF", width: "10%"},
        ],
        rows: tableDataRender(),
        monthList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        yearList: yearList()
    };
}
