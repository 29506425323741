import {atomFamily} from "recoil";

/**
 * 라디오 체크박스 값
 * @type {(param: SerializableParam) => RecoilState<{value: string, keyName: *}>}
 */
export const radioValueAtomFamily = atomFamily({
    key: "radioValueAtomFamily",
    default: (key) => (
        {
            value : "",
            keyName : key
        }
    )
})