import * as React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import imageCompression from "browser-image-compression";
import { HourglassDisabled } from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'
import Swal from 'sweetalert2';






export default function UploadButtons({ setFile, theme , value , setValue, docType}) {
	const [ uploadfilename, setUploadFilename] = useState("");
  
	const handleFileSelect = async (event) => {
		const selectedFile = event.target.files[0];

		if (selectedFile) { // 파일이 선택된 경우에만 처리
			if(event.target.files[0].type == "application/pdf") { //pdf 파일일 떄
				// 파일 이름 전달
				setValue(selectedFile.name);
				// 압축된 이미지 파일을 상위 컴포넌트로 전달
				setFile({
					file: selectedFile,
					name: selectedFile.name
				  });
			} else { //이미지 파일일 때
				const options = {
					maxSizeMB: 0.2, // 1 : 최대 용량 (1MB 이하) / 200KB 이하로 설정해 둠
					maxWidthOrHeight: 1200, // 최대 폭 또는 높이 (1200px 이하)
					useWebWorker: true, // 웹 워커 사용
				};
		  
				try {
					const compressedFile = await imageCompression(selectedFile, options); // 이미지 압축
		
					const reader = new FileReader();
					reader.readAsDataURL(compressedFile);
	
					console.log(compressedFile);
		
					// 파일 이름 전달
					setValue(compressedFile.name);
					// 압축된 이미지 파일을 상위 컴포넌트로 전달
					setFile({
						file: compressedFile,
						name: compressedFile.name
					  });
		
					//setWpFilename(compressedFile); 
		
				} catch (error) {
					console.log(error);
				}
			}
		}
	};
	
	const handleUploadCancel = () =>{
		try {
			if(value) {
				setValue('')
				setFile(null)
			} else {
				
			}	
		} catch(e) {
			Swal.fire({
                title: '사진 삭제 중 오류가 발생 했습니다.',
                icon: 'error',
                backdrop: false
            })
		}
	}

	return (
	  <Stack direction="row" alignItems="center" spacing={2} justifyContent="center">
		{theme === "button" ? (
		  <Button variant="contained" component="label" >
			Upload
			<input
			  hidden
			  accept="image/*,.pdf"
			  multiple
			  type="file"
			  onChange={handleFileSelect}
			/>
			<div className="Filename"
				font-size="small"
				width="max-content"
				>
				{value}
			</div>
		  </Button>
		) : (
			<>
				<IconButton color="primary" aria-label="upload picture" component="label">
					<input
						hidden
						accept="image/*,.pdf"
						type="file"
						onChange={handleFileSelect}
					/>
					{	!value &&
						<PhotoCamera /> }
					<div className="Filename"
						font-size="small"
						width="max-content"
						>
						{value}
					</div>
				</IconButton>
				{
					value &&
					<div style={{margin:0}}> 
						<button 
							style={{background:"#fff", color:"red", border: "1px solid red", cursor: "pointer"}}
							onClick={handleUploadCancel}
						>
							X
						</button>
					</div>
				}
			</>
				// <Tooltip title="준비중">
				// 	<IconButton color="primary" aria-label="upload picture" component="label">
				// 	{/*<input*/}
				// 	{/*  hidden*/}
				// 	{/*  accept="image/*,.pdf"*/}
				// 	{/*  type="file"*/}
				// 	{/*  // onChange={handleFileSelect}*/}
				// 	{/*/>*/}
				// 	{/*<PhotoCamera />*/}
				// 		<HourglassDisabled/>
				// 	<div className="Filename"
				// 		font-size="small"
				// 		width="max-content"
				// 		>
				// 		{value}
				// 	</div>
				// 	</IconButton>
				// </Tooltip>
		)}
	  </Stack>
	);
}