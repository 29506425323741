import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Icon } from "@mui/material";
import GetNowHolyDay from "api/date/GetNowHolyDay";
import { SelectReservationAtom } from "atom/workPlace/ReservationAtom";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { add, differenceInCalendarWeeks, format, getDay, getYear, startOfDay } from "date-fns";
import { ko } from "date-fns/esm/locale";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { isMobile } from "react-device-detect";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { StringToDate } from "utils/DateUtils";
import { getSelectDateMyReservation, updateReservation } from '../../../api/workPlace/WorkPlace';
import '../workPlace.css';
import MyWorkPlace from './MyWorkPlace';
import SeatCount from "./SeatCount";
import SelectBoxWorkPlace from "./SelectBoxWorkPlace";




const WorkPlaceUpdateDialog = (props) => {

    const { 
        open,
        setOpen
    } = props;
    
    // 예약 Id
    const [reservationId, setReservationId] = useState(0);
    // 근무지
    const [selectedPlace, setSelectedPlace] = useState('');
    // 수정 날짜 변경 
    const [isChangeDate , setIsChangeDate] = useState(false);
    // 예약이 있는지 없는지 
    const [hasReservation, setHasReservation ] = useState(true);

    // 수정 버튼 클릭 했을 때의 날짜로 가져온 예약 데이터
    const selectReservation = useRecoilValue(SelectReservationAtom);

    useEffect(() =>{
        updateDateSet(selectReservation);
    }, [selectReservation])


    // datePicker 관련 state
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [filteredHolyDayList, setFilteredHolyDayList] = useState([]) // 공휴일 제외


    // 선택한 날짜 예약 데이터 가져옴
    const {isLoading, isError, data} = useQuery(['getMyReservation',selectedDate], () =>
        getSelectDateMyReservation(selectedDate),
        {
            enabled: isChangeDate,    // 날짜 변경 했을 때만 불러오게
            refetchOnWindowFocus:false,
            onSuccess: (result) => {
                if(Object.entries(result).length !== 0 && isChangeDate){
                    updateDateSet(result);
                    setHasReservation(true)
                } else {
                    setNoReservationState();
                    setHasReservation(false)
                }
            }
    });


    const updateDateSet = (reservationData) => {
        const date = reservationData?.reservationDate;
        const place = reservationData?.region?.regId;
        const resId = reservationData?.reservationId;

        if (date && place && resId) {
            setSelectedDate(StringToDate(date))
            setSelectedPlace(String(place))
            setReservationId(resId)
            setHasReservation(true)
        }
    };

    const setNoReservationState = () => {
        setSelectedPlace('')
    }



    
    const toDayYear = {
        toDayYear: getYear(new Date()).toString()
        , nextYear: getYear(add(new Date(), {years: 1})).toString()
    };
    const {data:holyDay} = useQuery(["GetNowHolyDay", toDayYear] , ()=> GetNowHolyDay(toDayYear), {
        onSuccess: (result) => {
            try {
                const nowYearHolyDayList = result.data.response.body.items.item;
                const filterHolyList = [];
                for (let i = 0; i < nowYearHolyDayList.length; i++) {
                    // 제헌절이 리스트에 있으나 공휴일이 아니고 N으로 표시되어있어서 Y 인것만
                    if (nowYearHolyDayList[i].isHoliday === "Y") {
                        const map = new Map;
                        map.set("name", nowYearHolyDayList[i].dateName);
                        map.set("date", nowYearHolyDayList[i].locdate);
                        filterHolyList.push(map);
                    }
                }
                const saveList = [...filteredHolyDayList];
                setFilteredHolyDayList(saveList.concat(filterHolyList));
            } catch (e) {
                console.log("now holy day list load error - " + e);
            }
        },
    });

    const isHolyday = (date) => {
        const now = new Date();
        // date 요일
        const day = getDay(date);
        let checkFilterDate;
        for (let i = 0; i < filteredHolyDayList.length; i++) {
            // 공휴일의 날짜 가져와서 date형식으로 만들기
            const fullDate = String(filteredHolyDayList[i].get('date'));
            let year = fullDate.substring(0, 4);
            let month = fullDate.substring(4, 6) - 1;
            let day = fullDate.substring(6, 8);
            let targetDate = new Date(year, month, day);
            // date와 공휴일이 년월일이 같으면 해당하는 date값을 set
            if (date.getFullYear() === targetDate.getFullYear()
                && date.getMonth() === targetDate.getMonth()
                && date.getDate() === targetDate.getDate()
            ) {
                checkFilterDate = targetDate;
            }
        }
        // 오늘 날 이전은 선택 X
        const today = startOfDay(new Date()); 
        if (date < today) {
            checkFilterDate = date;
        }
        
        const weekDifference = differenceInCalendarWeeks(date, now, { weekStartsOn: 1 });

        return day !== 0 && day !== 6 && checkFilterDate === undefined && (weekDifference === 0 || weekDifference === 1);

    };


    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const reservationUpdate = useMutation(updateReservation,{
        onSuccess: (data) =>{
            // if(!data.success){
                // let message = "다음과 같은 날짜에 예약이 실패했습니다."
                // const resultMap = new Map(Object.entries(data.results))
                // const keys = Array.from(resultMap.keys()).sort()
                // for (const key of keys){
                //     const result = resultMap.get(key)
                //     message = message + "\n" +
                //         key + " : "+resultMessageToKoMap[result]
                // }
                // alert(message)
            // }else{
            //     queryClient.invalidateQueries('reservationInfo')
            // }
            if(isMobile){
                navigate('/workPlace')
            }else{
                setOpen(false)
                queryClient.invalidateQueries('reservationInfo')
            }
        },
        onError: (e) =>{
            console.log(e)
            alert(e.response.data.replaceAll(/.*: /g,""))
        }
    })

    const updateWorkPlace = () => {
        const res = {
            reservationId: reservationId,
            selectDate: selectedDate,
            regionId: selectedPlace
        }
        reservationUpdate.mutate(res)
    }

    const handleClose = () => {
        setIsChangeDate(false)
        setHasReservation(false)
        updateDateSet(selectReservation)    // 메인페이지에서 선택한 날짜로 다시 불러오기 위해서 

        if(isMobile){
            navigate("/workPlace")
        }else{
            setOpen(false)
        }
    }

    const refreshData = () => {
        queryClient.invalidateQueries('getSeatCountByReservationDate');
    }
    




    return (
        <>
        <Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
            <DialogTitle>
                <Grid container justifyContent={"space-between"} alignItems="center">
                    <SoftTypography fontWeight="bold" variant="h4">근무지 수정</SoftTypography>
                    <SoftButton variant="text" color="info" iconOnly onClick={refreshData}>
                        <Icon>refresh</Icon>
                    </SoftButton>
                </Grid>
            </DialogTitle>
            <DialogContent className="workPlace-dialog">
                <DialogContentText>
                    <Grid container>
                        {/* 좌석 현황 */}
                        <Grid container className="workPlace-grid">
                            <SeatCount selectedDate={selectedDate} selectedPlace={selectedPlace} />
                        </Grid>

                        <Grid container className="workPlace-grid">
                            <Grid item xs={12} className="workPlace-content">
                                <MyWorkPlace 
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: '#fff !important' ,
                                            cursor: 'unset !important'
                                        },
                                    }}
                                    color={selectedPlace}
                                    image={selectedPlace}
                                    title={["예약 내역", ]}
                                    date={hasReservation ? format(selectedDate, 'yyyy-MM-dd EEEE', { locale: ko })
                                            : "등록된 예약이 없습니다."}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className="workPlace-grid">
                            <Grid item xs={12} className="workPlace-content">
                                <SelectBoxWorkPlace selectedPlace={selectedPlace} setSelectedPlace={setSelectedPlace}/> 
                            </Grid>
                        </Grid>
                        <Grid container className="workPlace-grid">
                            <Grid item xs={12} className="workPlace-content">
                                <SoftTypography fontWeight="bold" variant="h4" sx={{pb:'0.5rem'}}>기간</SoftTypography>
                                <ReactDatePicker
                                    // customInput={<ExampleCustomInput />}
                                    locale={ko}    // 언어설정 
                                    inline  // 달력 노출
                                    dateFormat="yyyy-MM-dd"    // 날짜 형식 설정
                                    // selectsRange={true} // 레인지로 선택
                                    // showYearDropdown // 연도 선택
                                    // showMonthDropdown // 달 선택
                                    selected={selectedDate && selectedDate}
                                    filterDate={isHolyday} // 필터되는 날짜
                                    onChange={(date) => {
                                        setSelectedDate(date)
                                        setIsChangeDate(true)
                                    }} 
                                    className={"workPlace datepicker"}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContentText>
                <DialogActions>
                    <SoftButton onClick={updateWorkPlace} color="success" disabled={!hasReservation}>수정</SoftButton>
                    <SoftButton onClick={handleClose} color="light">취소</SoftButton>
                </DialogActions>
            </DialogContent>
        </Dialog>
        </>
    )
}

export default WorkPlaceUpdateDialog