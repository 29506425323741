import { Chip, Grid } from '@mui/material'
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import React, { useState } from 'react'
import CommonCodeInfoReturn from 'components/commonCode/CommonCodeInfoReturn'
import SoftAvatar from 'components/SoftAvatar'
import { customAxios } from '../../../axios/CustomAxios'
import { myIpAddress } from '../../../config'
// props.item
const ReferrerList = (props) => {

  const [ref_url, setRef_url] = useState("")
  const [ref_name, setRef_name] = useState("")
  const [ref_rank, setRef_rank] = useState("")
  const [ref_team, setRef_team] = useState("")
  customAxios.post(myIpAddress + `/cost/referrerInfo`, props.item.ref_mem_no)
    .then((response) => {
      setRef_url(response.data.imageUrl)
      setRef_name(response.data.memName)
      setRef_rank(response.data.memRank)
      setRef_team(response.data.memTeam)
    });
  return (
    <Grid item xs={12} lg={props.length % 2 === '0' ? 12 : 6}>
      <SoftBox pt={5} mt={-6} display="flex" justifyContent="space-between" alignItems="center"
        flexWrap="wrap">
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1} display="flex" >
              <SoftAvatar
                src={ref_url}
                alt="profile-image"
                variant="rounded"
                size="md"
                shadow="sm"
              />
              <SoftBox ml={2}>
                <SoftTypography variant="h6" fontWeight="medium">
                  {ref_name} <Chip label={props.idx + 1 + "번 참조자"} color="info" variant="outlined" size="small" />
                </SoftTypography>
                <SoftTypography variant="button" color="text" fontWeight="medium" sx={{ width: "max-content" }} whiteSpace="nowrap">
                  <CommonCodeInfoReturn init={ref_team} />/
                </SoftTypography>
                <SoftTypography variant="button" color="text" fontWeight="medium" sx={{ width: "max-content" }} whiteSpace="nowrap" >
                  <CommonCodeInfoReturn init={ref_rank} />
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Grid>
  )
}
export default ReferrerList