import Swal from 'sweetalert2';
import { customAxios } from '../../axios/CustomAxios';
import { myIpAddress } from "../../config";

const CostSaveInsert = (costList) => {

    return customAxios.post(myIpAddress + '/cost/save', costList, {
        headers: { 'Content-Type': 'multipart/form-data' },
    })
        .then((response) => {
            Swal.fire({
                icon: 'success',
                title: '입력 완료!',
                backdrop: false,
            })
        });
}

export default CostSaveInsert;