import {atom} from "recoil";

/**
 * 복지포인트 작성하기 - 클릭 한 해당 row 의 값 
 * @type {RecoilState<number>}
 */

export const wpThisRowNumAtom = atom({
    key: "wpThisRowNumAtom"
    , default: 0
})
