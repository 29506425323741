import Grid from "@mui/material/Grid";
import SoftTypography from "../../../../../components/SoftTypography";
import {Autocomplete, Card, TextField} from "@mui/material";
import AllUserVacationInfoTable from "./component/AllUserVacationInfoTable";
import AllUserVacationInfoData from "../../data/AllUserVacationInfoData";
import {useState} from "react";

/**
 * 전체 유저 휴가 정보 보기
 * @returns {JSX.Element}
 * @constructor
 */
const AllUserVacationInfo=()=>{
    const {columns, rows, memberList, memberIndexBool, memberIndexList} = AllUserVacationInfoData();

    const options = memberList;

    const [checkIndex, setCheckIndex] = useState();

    const [status, setStatus] = useState(true);

    const handleInputChange = (event, newInputValue)=>{
        if (newInputValue !== '' || newInputValue !== null) {
            // 입력한 값과 옵션값이 맞는지 체크
            if (memberIndexBool.get(newInputValue)) {
                setCheckIndex(memberIndexList.get(newInputValue));
                setStatus(false);
            } else {
                setStatus(true);
                setCheckIndex();
            }
        }
    };

    return(
        <Card>
            <Grid container p={1} display="flex" justifyContent="space-around">
                <Grid item xs={6} pt={2}>
                    <SoftTypography variant="h5" fontWeight="bold">
                        전체 사원 휴가 정보 조회
                    </SoftTypography>
                </Grid>
                <Grid item xs={6} pt={2}>
                    <Autocomplete
                        renderInput={(params) => (
                            <
                                TextField
                                {...params}
                                label={status ? '멤버를 선택해주세요.' : ''}
                                InputProps={{
                                    ...params.InputProps,
                                }}
                                InputLabelProps={{
                                    style: {
                                        top: '-12px', // 플레이스홀더를 위로 이동시킵니다.
                                    }
                                }}
                            />
                        )}
                        options={options}
                        onInputChange={handleInputChange}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <AllUserVacationInfoTable columns={columns} rows={rows} checkIndex={checkIndex}/>
            </Grid>
        </Card>
    );
}

export default AllUserVacationInfo;