import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 복지포인트 결재 반려 
 * @param {*} body 
 * @returns 
 */

const wpApprovalReject =(body)=>{
    return customAxios.post(myIpAddress+'/wp/approvalReject', body ); 
}
export default wpApprovalReject;
