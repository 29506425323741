import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import allCommonCodeList from '../../../api/commonCode/allCommonCodeList';
import { useQuery } from 'react-query';

const ClassificationList = (props) => {
    const [classification, setClassification] = React.useState(props.temp);
    const [classificationList, setClassificationList] = useState([]);

    const handleChange = (event) => {
        setClassification(event.target.value);
        props.setClassification(event.target.value)
    };

    const { data, isSuccess, isFetching, isLoading, isError } = useQuery('allCommonCodeList', allCommonCodeList, {
        // 데이터 불러오지(fetch) 않는 시간 지정 20분
        staleTime: 120000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        select: (data) => {
            return data.data
        }
    })
    const classificationArr = [...classificationList];
    // 2023-06-02 결재수단 및 교통비(기타비용)-용도 selectbox 수정
    useEffect(() => {
        if (isSuccess) {
            if(props.category === "PAY_METHOD"){
                const PAY_METHOD = [
                    { 'classification' : "PM001" , 'info' : "개인카드"}
                    ,{ 'classification' : "PM002", 'info': "법인카드"}
                    ,{ 'classification' : "PM000", 'info': "현금"}
                ]
                setClassificationList(PAY_METHOD)
            } else if (props.category ==="COST_TRFFIC_OTHER"){
                const COST_TRFFIC_OTHER = [
                    { 'classification' : "CTO002" , 'info' : "교통비"}
                    ,{ 'classification' : "CTO000" , 'info' : "주차비"}
                    ,{ 'classification' : "CTO001" , 'info' : "유류비"}
                    ,{ 'classification' : "CTO003" , 'info' : "접대비"}
                ]
                setClassificationList(COST_TRFFIC_OTHER)
            } else {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].cc_category == props.category) {
                        //classification에 용도구분 공통코드, info에 코드 설명
                        let classi = { 'classification': data[i].cc_name, 'info': data[i].cc_info }
                        classificationArr.push(classi);
                        setClassificationList(classificationArr)
                    }
                }
            }
        }
    }, [isSuccess])

    const [open, setOpen] = React.useState(false);

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleClose = (event) => {
        if (event.target.value) {
            setClassification(event.target.value);
        }
        setOpen(false);
    };

    return (
        <FormControl sx={{ width: '100%' }}>
            {/* <InputLabel htmlFor="demo-simple-select" sx={{ mt: -1 }}>
                복리후생
            </InputLabel> */}
            <Select
                open={open}
                onClose={handleClose}
                onOpen={handleToggle}
                onClick={handleToggle}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                displayEmpty
                value={classification}
                label="classification"
                onChange={handleChange}
                sx={{ cursor: "pointer" }}
            >
                <MenuItem value="">
                    -선택-
                </MenuItem>
                {classificationList.map((classification, idx) => (
                    <MenuItem key={idx} value={classification.classification}>{classification.info}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default ClassificationList