import axios from 'axios';
import {ACCESS_TOKEN, REFRESH_TOKEN} from 'config';
import {myIpAddress} from 'config';
import Swal from "sweetalert2";
import DeleteCookie from "../api/auth/deleteCookie";


export const customAxios = axios.create({
    baseURL: `${myIpAddress}`,
    withCredentials: true, // 쿠키 전송을 허용하는 옵션 설정
});

// customAxios.interceptors.request.use(
//     function (config) {
//         const tokenData = JSON.parse(localStorage.getItem(ACCESS_TOKEN));
//         const refreshTokenData = JSON.parse(localStorage.getItem(REFRESH_TOKEN));
//         if (tokenData !== null && refreshTokenData !== null) {
//             const tokenSaveTime = new Date(tokenData.createdAt);
//             const refreshTokenSaveTime = new Date(refreshTokenData.createdAt);
//             const nowTime = new Date();
//             const oneHour = 60 * 60 * 1000;
//             const oneDay = 24 * 60 * 60 * 1000;
//             const oneWeek = 7 * 24 * 60 * 60 * 1000;
//             let tokenDateBool = nowTime.getTime() - tokenSaveTime.getTime() > oneDay;
//             let refreshTokenDateBool = nowTime.getTime() - refreshTokenSaveTime.getTime() > oneWeek;
//             if (tokenDateBool && refreshTokenDateBool) {
//                 localStorage.removeItem(ACCESS_TOKEN);
//                 localStorage.removeItem(REFRESH_TOKEN);
//                 delete config.headers["Authorization"];
//                 delete config.headers["Refresh-Token"];
//                 return config;
//             } else {
//                 if (!tokenData) {
//                     delete config.headers["Authorization"];
//                     config.headers["Refresh-Token"] = "Bearer " + refreshTokenData.token;
//                     return config;
//                 }
//                 config.headers["Authorization"] = "Bearer " + tokenData.token;
//                 config.headers["Refresh-Token"] = "Bearer " + refreshTokenData.token;
//                 return config;
//             }
//         } else {
//             delete config.headers["Authorization"];
//             delete config.headers["Refresh-Token"];
//             return config;
//         }
//     }
// )

customAxios.interceptors.response.use(
    function (response) {
        // 응답 처리 로직 작성
        return response;
    },
    function (error) {
        if (error.response.status === 401) {
            window.location.href = "/errorPage/401"
        }
        if (error.response.status === 403) {
            window.location.href = "/errorPage/403"
        }
        return Promise.reject(error);
    }
);







