import { Grid, Table, TableCell, TableHead } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import styled from "styled-components";





const CustomeTableRowCell = styled(TableCell)(({width, align}) => ({
    padding : 0,
    width : width || "auto",
    textAlign : align,
    color: "inherit",
    borderBottom: "none",
    // p={isMobile? 0.5 : 1}
}));


const CustomeTableHeadCell = styled(TableCell)(({width, align}) => ({
    padding : 0,
    // width : width || "auto",
    textAlign : align,
    color: "inherit",
    width : width || "auto",
    borderBottom: "none",
}));


 /**
  * 공용 코드 목록
  * @param columns
  * @param rows
  * @returns {JSX.Element}
  * @constructor
  */
 function CommonCodeTable({ columns, rows, deleteCode }) {

    const { light } = colors;
    const { size, fontWeightBold } = typography;
    const { borderWidth } = borders;

    const renderColumns = columns.map(({ name, align, width, value }, key) => {
        


        let pl;
        let pr;

        if (key === 0) {
            pl = 1;
            pr = 1;
        } else if (key === columns.length - 1) {
            pl = 2;
            pr = 2;
        } else {
            pl = 1;
            pr = 1;
        }

        return (
            <CustomeTableHeadCell key={name} width={width} align={align}>
                <SoftBox py={1} pr={pr} pl={pl}>
                    <SoftTypography variant="button" fontSize={size.xs} fontWeight={fontWeightBold}>
                        {value}
                    </SoftTypography>
                </SoftBox>
            </CustomeTableHeadCell>
        );
    });





    /**
     * 
     */
    const renderRows = Array.from(rows).map(([key, rowValue]) => {
        
        const tableRow = columns.map(({ name, align, value, width }, colKey) => {

            let pl;
            let pr;
            
            if (colKey === 0) {
                pl = 1;
                pr = 1;
            } else if (colKey === columns.length - 1) {
                pl = 2;
                pr = 2;
            } else {
                pl = 1;
                pr = 1;
            }
            
            const ccNo = "ccNo_"+rowValue["cc_no"]+name;

            let template;
            

            if (Array.isArray(rowValue)) {
                template = (
                    <CustomeTableRowCell key={ccNo} align={align}>
                        <SoftBox pr={pr} pl={pl}>
                            <SoftTypography variant="button" fontWeight="medium">
                                {rowValue[name]}
                            </SoftTypography>
                        </SoftBox>
                    </CustomeTableRowCell> 
                );
            } else if (name === 'update') {
                template = (
                    <CustomeTableRowCell key={ccNo} align={align}>
                        <SoftBox pr={pr} pl={pl}>
                            <SoftButton variant="outlined" fontWeight="medium" color="dark" sx={{p:'5px 15px',position:"inherit"}}>
                                {value}
                            </SoftButton>
                        </SoftBox>
                    </CustomeTableRowCell>
                );
            } else if (name === 'delete') {
                template = (
                    <CustomeTableRowCell key={ccNo} align={align}>
                        <SoftBox pr={pr} pl={pl}>
                            <SoftButton variant="outlined" fontWeight="medium" color="dark" sx={{p:'5px 15px',position:"inherit"}}
                                        onClick={()=>deleteCode(rowValue["cc_no"], rowValue["cc_info"])}
                            >
                                {value}
                            </SoftButton>
                        </SoftBox>
                    </CustomeTableRowCell>
                );
            } else {
                template = (
                    <CustomeTableRowCell key={ccNo} align={align}>
                        <SoftBox pr={pr} pl={pl}>
                            <SoftTypography variant="button" fontWeight="medium">
                                {rowValue[name]}
                            </SoftTypography>
                        </SoftBox>
                    </CustomeTableRowCell>
                );
            }

            return template;
        });

        return <TableRow
                    py={1}
                    sx={{ 
                        backgroundColor: key % 2 === 0 ? "#f5f5f5" : "inherit", 
                        "&:hover": { backgroundColor: "#DBE2EF" ,},
                        
                    }}
                >
                    {tableRow}
                </TableRow>;
    });

    return (
        <Grid  sx={{ width: '100%', overflow: 'hidden' }} >
            <TableContainer sx={{maxHeight : "calc(100vh - 320px)"}}>
                <Table>
                    <TableHead sx={{top:0, position:"sticky",display:"table-row-group", zIndex: 0}}>
                        <TableRow
                            sx={{ 
                                backgroundColor: "#fff", 
                            }}
                        >
                            {renderColumns}
                        </TableRow>
                    </TableHead> 
                    <TableBody>
                        {
                            rows.size > 0 ? 
                                renderRows 
                                : 
                                    <SoftTypography
                                        variant="button"
                                        fontWeight="regular"
                                        color="dark"
                                        sx={{ display: "inline-block", width: "max-content" }}
                                    >
                                        "등록 된 공용코드가 없습니다."
                                    </SoftTypography>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
 }
 
 // Setting default values for the props of Table
 CommonCodeTable.defaultProps = {
    columns: [],
    rows: [{}],
 };
 
 // Typechecking props for the Table
 CommonCodeTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    rows: PropTypes.arrayOf(PropTypes.object),
 };
 
 export default CommonCodeTable;
 