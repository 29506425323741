import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

// 공용코드 맵
const CostMainLists =(memno,size)=>{
    return customAxios.get(myIpAddress+`/cost/list/${memno}`,{
        params: {
            size
        }
    });
}
export default CostMainLists;