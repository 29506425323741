import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 참조 문서 리스트
 * @returns {Promise<axios.AxiosResponse<any>>}
 * @constructor
 */
const refAllDocList =({
                        no,
                        nowPage,
                        docsNum
                    })=>{
    return customAxios.post(myIpAddress+'/ref/refAllDocList',
        {
            ref_mem_no: no
            , now_page: nowPage
            , docs_num: docsNum
        }
    );
}
export default refAllDocList;