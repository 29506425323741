import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {approverMaxAtom} from "../../../../../atom/approval/approver_referrer/approverMaxAtom";
import {nameCompleteInputValueAtomFamily} from "../../../../../atom/approval/approver_referrer/nameCompleteInputValue";
import {
    nameCompleteInputStatusAtomFamily
} from "../../../../../atom/approval/approver_referrer/nameCompleteInputStatusAtomFamily";
import {approverInsertAtomFamily} from "../../../../../atom/approval/approver_referrer/approverInsertAtomFamily";
import cloneDeep from "lodash.clonedeep";
import {toMap} from "../../../../../utils/ToMap";
import Grid from "@mui/material/Grid";
import SoftButton from "../../../../../components/SoftButton";
import Icon from "@mui/material/Icon";
import Approver from "../Approver";
import Tooltip from "@mui/material/Tooltip";
import {approverCountAtomFamily} from "../../../../../atom/approval/approver_referrer/approverCountAtomFamily";
import {useQueries} from "react-query";
import allCommonCodeMap from "../../../../../api/commonCode/allCommonCodeMap";
import Swal from "sweetalert2";

/**
 * 결재자 카드
 * @param keyName : String (고유키값확인을위한값)
 * @param type : String (결재모듈타입)
 * @param gridXs : int (입력값: 0~12) (해상도:0px ~ 599px)
 * @param gridMd : int (입력값: 0~12) (해상도:960px ~ 1279px)
 * @param gridLg : int (입력값: 0~12) (해상도:1280px ~ )
 * @returns {JSX.Element}
 * @constructor
 */
function ApproverInsertCard({keyName, type, gridXs, gridMd, gridLg}) {

    // 최대 결재자 수
    const maxApprover = useRecoilValue(approverMaxAtom);

    // 결재자수 recoil
    const [approverNum, setApproverNum] = useRecoilState(approverCountAtomFamily(keyName));

    // 제일 마지막에 추가된 recoil 값 초기화
    const lastInputValueReset = useResetRecoilState(nameCompleteInputValueAtomFamily(keyName + "_" + approverNum.count));

    // 제일 마지막에 추가된 recoil 상태
    const lastInputStatus = useRecoilValue(nameCompleteInputStatusAtomFamily(keyName + "_" + approverNum.count));

    // approver set 값
    const [approverInfo, setApproverInfo] = useRecoilState(approverInsertAtomFamily(keyName));
    // react-query
    const queries = useQueries([
        // queries[0] : 공용코드 리스트
        {
            queryKey: ["allCommonCodeMap"],
            queryFn: () => allCommonCodeMap(),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            },
        }
    ]);

    // 결재라인 추가
    const approverAdd = () => {
        /**
         * 2023-05-23
         * type="wp" 일 경우 결재자 2명 이상 되지 않게
         */
        if(type === "wp") {
            if( approverNum.count > 0) {
                Swal.fire({
                    icon: 'error',
                    text: '결재자를 추가 할 수 없습니다.',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    backdrop: false,
                })
            } else {
                // 최대 결재자 수
                const count = approverNum.count + 1;
                if (count <= maxApprover && lastInputStatus !== true) {
                    setApproverNum({
                        count: count
                    });
                }
            }
        } else {
            // 최대 결재자 수
            const count = approverNum.count + 1;
            if (count <= maxApprover && lastInputStatus !== true) {
                setApproverNum({
                    count: count
                });
            }
        }
    }

    // 결재라인 삭제
    const approverRemove = () => {
        const count = approverNum.count - 1;
        if (approverNum.count > 0) {
            setApproverNum({
                count: count
            });
            // 제일 마지막 참조자 리코일 값 초기화
            if (lastInputStatus !== true) {
                lastInputValueReset();
                const approverList = cloneDeep(approverInfo).approverList;
                const insert = toMap({...approverInfo});
                approverList.pop() // 마지막 요소 제거
                insert.set("approverList", approverList);
                setApproverInfo(Object.fromEntries(insert)); // 저장
            }
        }
    }

    /**
     * 결재자 렌더링
     * @returns {[Approver]}
     */
    const approverRender = () => {
        const reder = [];
        for (let i = 0; i < approverNum?.count; i++) {
            if (cloneDeep(approverInfo)?.approverList !== null) {
                /** type="wp" 부분 삭제 */
                const approver = cloneDeep(approverInfo)?.approverList[i];
                if (approver?.approver_level === i && queries[0].isSuccess) {
                    const codeMap = queries[0].data.data;
                    const approverTeam = approver?.approver_team;
                    const approverRank = approver?.approver_rank;
                    const defaultValue = "[" + (codeMap[approverTeam]?.info) + "]"
                        + approver?.approver_name + " " + (codeMap[approverRank]?.info)
                    reder.push(
                        <Grid item xs={12} lg={6}>
                            <SoftBox mt={2}>
                                <SoftTypography variant="h5" gutterBottom fontWeight="bold">
                                    {i + 1}번 결재자
                                </SoftTypography>
                                <Approver key={i + 1} num={i + 1} keyName={keyName} defaultValue={defaultValue}/>
                            </SoftBox>
                        </Grid>
                    );
                } else {
                    reder.push(
                        <Grid item xs={12} lg={6}>
                            <SoftBox mt={2}>
                                <SoftTypography variant="h5" gutterBottom fontWeight="bold">
                                    {i + 1}번 결재자
                                </SoftTypography>
                                <Approver key={i + 1} num={i + 1} keyName={keyName}/>
                            </SoftBox>
                        </Grid>
                    );
                }
            } else {
                reder.push(
                    <Grid item xs={12} lg={6}>
                        <SoftBox mt={2}>
                            <SoftTypography variant="h5" gutterBottom fontWeight="bold">
                                {i + 1}번 결재자
                            </SoftTypography>
                            <Approver key={i + 1} num={i + 1} keyName={keyName}/>
                        </SoftBox>
                    </Grid>
                );
            }
        }
        return reder;
    }

    return (
        <>
            <Grid item xs={gridXs} md={gridMd} lg={gridLg}>
                <Card>
                    <Grid
                        container
                        spacing={0.5}
                        p={3}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item xs={3} md={3} lg={3}>
                            <SoftTypography variant="h5" gutterBottom fontWeight="bold">
                                결재자
                            </SoftTypography>
                        </Grid>
                        <Grid item xs={9} md={9} lg={9}>
                            <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                                {lastInputStatus ?
                                    <Tooltip title="이전 결재자가 정확히 선택되지 않으면 결재 추가되지 않습니다." placement="top">
                                        <SoftButton variant="gradient" color="warning" onClick={() => {
                                            approverAdd()
                                        }}>
                                            <Icon sx={{fontWeight: "bold"}}>person_add</Icon>
                                            &nbsp;결재추가
                                        </SoftButton>
                                    </Tooltip>
                                    : <>
                                        <SoftButton variant="gradient" color="success" onClick={() => {
                                            approverAdd()
                                        }}>
                                            <Icon sx={{fontWeight: "bold"}}>person_add</Icon>
                                            &nbsp;결재추가
                                        </SoftButton>
                                    </>
                                }
                                &nbsp;
                                <SoftButton variant="gradient" color="error" onClick={() => {
                                    approverRemove()
                                }}>
                                    <Icon sx={{fontWeight: "bold"}}>person_remove</Icon>
                                    &nbsp;결재제거
                                </SoftButton>
                            </SoftBox>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={12}
                                p={3} mt={-5}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                flexWrap="wrap"
                            >
                                {approverRender()}
                            </Grid>
                    </Grid>
                </Card>
            </Grid>
        </>
    );
}

export default ApproverInsertCard;
