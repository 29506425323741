import { Grid, Table as MuiTable, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import borders from 'assets/theme/base/borders';
import colors from "assets/theme/base/colors";
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import AmountFormatInput from 'components/textField/AmountFormatInput';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { CostBenefitDetail, CostEmployeeBenefitList, CostSave } from '../../../atom/cost/CostWriteDetail';
import '../../../styles/Cost/CostSt.css';
import EmployeeBenefitList from './EmployeeBenefitList';
import TableColumns from './TableColumns';




const EmployeeBenefit = () => {

    const { light } = colors;
    const { borderWidth } = borders;

    /** 복리후생비 row 값 (행 갯수)- */ 
    const [costWriteDetailRowNum, setCostWriteDetailRowNum] = useRecoilState(CostBenefitDetail);
    // 복리후생 상세내용 리스트
    const [costBenefitList, setCostBenefitList] = useRecoilState(CostEmployeeBenefitList);
    // 복리후생 상세내용 Map
    const [costBenefitMap, setCostBenefitMap] = useState(new Map);
    // 금액 합계
    // const totalAmount = useRecoilValue(TotalAmount);
    const [totalAmount, setTotalAmount] = useState(0);
    // 저장
    const costSave = useRecoilValue(CostSave);

    let [delay, setdelay] = useState(false);
    useEffect(() => {
        let timer = setTimeout(() => { setdelay(true) }, 50);
    })


    useEffect(() => {
        const newMap = new Map(costBenefitList.map(item => [item.employee_benefit_detail, { ...item }]));
        setCostBenefitMap(newMap);
        sumAmount()
    }, []); 


    // 저장 
    useEffect(()=>{
        if(costSave) {
            const updatedList = Array.from(costBenefitMap.values());
            setCostBenefitList(updatedList);
            // console.log("EmployeeBenefit costSave :: ", updatedList)  
        }
    },[costSave])

    
    const AddCostWriteDetailRow = () => {
        // 새로운 Map 생성하여 기존 costBenefitMap의 내용 복사
        const newMap = new Map(costBenefitMap);
        // map의 맨 마지막 key 값 이후에 생성되어야 해서 
        const maxKey = Math.max(...costBenefitMap.keys()) + 1;
        // console.log("가장 큰 키:", maxKey);
        // 새로운 항목 추가
        newMap.set(maxKey, {
            employee_benefit_detail: maxKey,
            other_date: "",
            other_category: "",
            other_contents: "",
            other_personnel: "",
            cost_filename: "",
            cost_file: "",
            other_amount: "",
            other_paytype: "",
            other_fueldo: ""
        });
        // 상태 업데이트
        setCostWriteDetailRowNum(newMap.size);
        setCostBenefitMap(newMap);
        // console.log("바꾼 맵 값 : ", newMap);
    }

    const removeDetailRow = (rowindex, key) => {
        // console.log("해당 레코드 인덱스 : ", rowindex, key);
        // const newRestailRow = new Map([...costBenefitMap].filter(([key, value]) => value.employee_benefit_detail !== rowindex));
        // setCostBenefitMap(newRestailRow)
        const newCostBenefitMap = new Map(costBenefitMap);
        newCostBenefitMap.forEach((value, key) => {
            if (value.employee_benefit_detail === rowindex) {
                newCostBenefitMap.delete(key);
            }
        });
        setCostBenefitMap(newCostBenefitMap);
        // sumAmount();
    }


    useEffect(()=> {
        sumAmount()
    },[costBenefitMap])

    const sumAmount = () => {
        let total = 0;
        costBenefitMap.forEach((value, key) => {
            const otherAmount = value.other_amount;
            if (otherAmount === "" || otherAmount === null) {
                total += 0;
            } else {
                total += parseInt(otherAmount);
            }
        });
        setTotalAmount(total)
    }

    const employeecolumns = [
        // { key: "grab", name: '', align: "center",  width: "30px" },
        { key: "cost_date", name: '지출일', align: "center", color: "warning", icon: "event_available", width: "140px" },
        { key: "cost_category", name: '용도', align: "center", color: "success", icon: "fact_check", width: "160px" },
        { key: "cost_content", name: '내용', align: "center", color: "info", icon: "notes", min_width: "200px" },
        { key: "cost_person", name: '인원', align: "center", color: "secondary", icon: "person", width: "130px", min_width: "130px" },
        { key: "cost_filename", name: '영수증', align: "center", color: "secondary", icon: "insert_photo", width: "130px", min_width: "130px" },
        { key: "cost_amount", name: '금액', align: "center", color: "error", icon: "paid", width: "180px", min_width: "180px" },
        { key: "cost_payway", name: '결제수단', align: "center", color: "primary", icon: "payment", width: "150px" },
        { key: "btn", name: "추가", align: "center", color: "dark", icon: "payment", width: "30px" }
    ]


    const renderColumns = <TableColumns column={employeecolumns} add={AddCostWriteDetailRow}/>


    //드래그를할 애를 잡았을때 인덱스값 저장용
    const [grab, setGrab] = useState(null)

    //드래그 할때
    const _onDragOver = e => {
        e.preventDefault();
    }

    //드래그 시작
    const _onDragStart = (e, item) => {
        const index = Array.from(costBenefitMap.values())
                        .findIndex(detail => detail.employee_benefit_detail === item.employee_benefit_detail);
        if (index !== -1) {
            setGrab(index);
        }
        // for (const [key, value] of costBenefitMap.entries()) {
        //     if (value === item) {
        //         setGrab(key)
        //     }
        // }
        // setGrab(Array.from(costBenefitMap.keys()).indexOf(item));
    }

    //드래그한거를 놨을때
    // 2023-06-08 드래그앤드롭 되는 위치 수정
    const _onDrop = (e, item) => {
        // let grabPosition = Number(grab);
        // let targetPosition = 0;
        // const list = [...Array.from(costBenefitMap.values())];
        // targetPosition = list.findIndex(detail => detail.employee_benefit_detail === item.employee_benefit_detail);
        // if(targetPosition !== -1) {
        //     let _list = [...Array.from(costBenefitMap.values())];
        //     if (grabPosition < targetPosition) {
        //         _list.splice(targetPosition + 1, 0, _list[grabPosition]);
        //         _list.splice(grabPosition, 1);
        //     } else {
        //         _list.splice(targetPosition, 0, _list[grabPosition]);
        //         _list.splice(grabPosition + 1, 1);
        //     }
        //     const newMap = new Map();
        //     _list.forEach((value, index) => {
        //         newMap.set(index, value);
        //     });
        //     setCostBenefitMap(newMap);
        // }
        e.preventDefault();
        const grabPosition = Number(grab);
        const list = Array.from(costBenefitMap.values());
        const targetPosition = list.findIndex(detail => detail.employee_benefit_detail === item.employee_benefit_detail);

        // -1 아닐 때만 Map 재배치
        if (targetPosition !== -1) {
            const newList = [...list];
            const movedItem = newList.splice(grabPosition, 1)[0];
            newList.splice(targetPosition, 0, movedItem);

            costBenefitMap.clear();
            newList.forEach((value, index) => {
                costBenefitMap.set(index, value);
            });
            setCostBenefitMap(new Map(costBenefitMap));
        }

    }

    const _onDragLeave = (e, item) => {
        if (grab === Array.from(costBenefitMap.keys()).indexOf(item)) {
            e.target.style.visibility = "hidden";
        }
    }




    return (
        <>
            <TableContainer>
                <MuiTable>
                    <SoftBox component="thead">
                        <TableRow>{renderColumns}</TableRow>
                    </SoftBox>
                    <TableBody>
                        {delay === true ?
                            Array.from(costBenefitMap.values()).map(item => (
                                <EmployeeBenefitList
                                    key={item.employee_benefit_detail}
                                    rowindex={item.employee_benefit_detail}
                                    removeDetailRow={removeDetailRow} 
                                    item={item}
                                    costBenefitMap={costBenefitMap}
                                    setCostBenefitMap={setCostBenefitMap}
                                    _onDragOver={_onDragOver}
                                    _onDragStart={_onDragStart}
                                    _onDrop={_onDrop}
                                    sumAmount={sumAmount}
                                />
                            )) : null
                        }
                        <TableRow key={"costBenefitAmount"} bgColor="#b2dfdb" >
                            <TableCell colSpan={9} key={"costBenefitAmount_cell"} style={{ borderTop: `${borderWidth[1]} solid ${light.main}` }}>
                                <Grid container justifyContent="flex-end" alignItems="center">
                                    <SoftBox pr={1}  >
                                        <SoftTypography
                                            variant="h4"
                                            color="white"
                                            fontWeight="bold"
                                            sx={{ display: "inline-block", width: "max-content", }}>
                                            합계
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox>
                                        <AmountFormatInput
                                            label="합계"
                                            theme="sum"
                                            size="Normal"
                                            value={totalAmount}
                                            unit="원"
                                        />
                                    </SoftBox>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        {/* <EmployeeBenefitTotalAmount sumAmount={sumAmount}/> */}
                    </TableBody>
                </MuiTable>
            </TableContainer>
        </>
    )
}

export default EmployeeBenefit