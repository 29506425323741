import { Chip, Grid } from '@mui/material'
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import React, { Fragment, useEffect } from 'react'
import CommonCodeInfoReturn from 'components/commonCode/CommonCodeInfoReturn'
import SoftAvatar from 'components/SoftAvatar'
import { FaBan, FaCheckSquare } from 'react-icons/fa';
import Tooltip from "@mui/material/Tooltip";
import { TimestampToStringDate } from 'utils/DateUtils'

// props.item
const ApprovalList = ({ item, length, idx }) => {

    const app_do = item.approver_order;
    const color = app_do === 2 ? "#04d104" : app_do === 3 ? "#d10404" : "inherit"
    let message = '';
    if (item.approver_order === 0 || item.approver_order === 1) {
        message = '결재 미완료';
    } else if (item.approver_order === 2) {
        message = `결재일 : ${TimestampToStringDate(item.approver_regdate)}`;
    } else if (item.approver_order === 3) {
        message = '반려사유 : ' + item.approval_cancel_reason;
        /// message = '반려사유 : ' + '글자수세기는 입력한 글자의 개수를 바로 확인하여 주는 도구입니다.\n 글자수나 단어수를 세는 것은 이력서나 자기소개서(자소서)를 작성할 때나 블로그 글을 작성할 때 매우 중요한 요소입니다.'
    }

    return (
        <Grid item xs={12} lg={length % 2 === '0' ? 12 : 6}>
            {/* <Tooltip title={message} placement="top"> */}
                <SoftBox pt={5} mt={-6} display="flex" justifyContent="space-between" alignItems="center"
                    flexWrap="wrap">
                    <Grid container spacing={3} alignItems="center">
                        <Grid item>
                            <SoftBox height="100%" mt={0.5} lineHeight={1} display="flex" >
                                <SoftAvatar
                                    src={item.approver_img}
                                    alt="profile-image"
                                    variant="rounded"
                                    size="md"
                                    shadow="sm"
                                />
                                <SoftBox ml={2}>
                                    <SoftTypography variant="h6" fontWeight="medium">
                                        <Chip label={idx + 1 + "차 결재자"} style={{ color, marginRight: 2 }} variant="outlined" size="normal" />
                                        {item.approver_order === 2 && <FaCheckSquare color={color} size={20} style={{ verticalAlign: "middle", marginLeft: 4 }} />}
                                        {item.approver_order === 3 && <FaBan color={color} size={20} style={{ verticalAlign: "middle", marginLeft: 4,marginTop:-3 }} />}
                                        {item.approver_name}
                                    </SoftTypography>
                                    <SoftTypography variant="button" color="text" fontWeight="medium" sx={{ width: "max-content" }} whiteSpace="nowrap">
                                        <CommonCodeInfoReturn init={item.approver_team} />/
                                    </SoftTypography>
                                    <SoftTypography variant="button" color="text" fontWeight="medium" sx={{ width: "max-content" }} whiteSpace="nowrap" >
                                        <CommonCodeInfoReturn init={item.approver_rank} />
                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>
                        </Grid>
                    </Grid>
                    <Grid item pt={2} sx={{ whiteSpace: "normal" }}>
                        <SoftTypography variant="h6" color="text" fontWeight="bold" sx={{ width: "max-content" }} whiteSpace="normal" >
                            {message}
                        {/* {message > window.innerWidth ? (
                            message.split("").map((char, index) => (
                                <Fragment key={index}>
                                    {char}
                                    {index !== 0 && (index + 1) % Math.floor(window.innerWidth / 10) === 0 && <br />}
                                </Fragment>
                            ))
                        ) : (
                            message
                        )} */}
                        </SoftTypography>
                    </Grid>
                </SoftBox>
            {/* </Tooltip> */}
        </Grid>
    )
}
export default ApprovalList