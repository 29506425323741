import {atomFamily} from "recoil";

/**
 * 휴가 기간 범위 및 총 합산일 아톰 패밀리
 * @type {(param: SerializableParam) => RecoilState<{start: string, keyName: *, end: string, day: number}>}
 */
export const selectDaysAtomFamily = atomFamily({
    key: "selectDaysAtomFamily",
    default: (key) => ({
        start:"",
        end:"",
        day:0,
        keyName: key
    }),
})