import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CalendarData from "./CalendarData";

/**
 * 휴가 캘린더
 * @returns {JSX.Element}
 */
function vacationCalendar(){
    return(
        <DashboardLayout>
            <DashboardNavbar/>
                <CalendarData height='75'/>
                  <Footer/>
        </DashboardLayout>
    )
}

export default vacationCalendar;