import AS000 from '../../../assets/images/AS000.png';
import AS001 from '../../../assets/images/AS001.png';
import AS002 from '../../../assets/images/AS002.png';
import AS003 from '../../../assets/images/AS003.png';
import {useEffect, useRef, useState} from "react";

import AssetInsertModal from "components/modal/AssetInsertModal";

import {useRecoilValue} from "recoil";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DefaultBlogCard from "examples/Cards/BlogCards/DefaultBlogCard";
import {Grid} from '@mui/material';
import newAsset from "assets/images/new-asset.jpg";
import peoples from "assets/images/peoples.png"
import CommonCodeInfoReturn from 'components/commonCode/CommonCodeInfoReturn';
import getCountByAssCate from '../../../api/asset/getCountByAssCate';
import {Link} from 'react-router-dom';
import listUpdate from 'atom/data/listUpdate';
import styled from 'styled-components';

const HoverableSoftBox = styled(SoftBox)`
  &:hover {
    cursor: pointer;
    // 원하는 스타일을 여기에 추가하세요
  }
`;


function AssetCountAndInsert() {

    const [cateCount, setCateCount] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const modalChange2 = useRef();
    const closeModal2 = () => {
        modalChange2.current.style = "display:none;";
    };

    const openModal2 = (e) => {
        modalChange2.current.style = "display:block;";
    };

    const isUpdate = useRecoilValue(listUpdate);

    useEffect(() => {
        getCountByAssCate()
            .then(response => {                
                setCateCount(response.data)
            })
    }, [isUpdate])


    return (
        <Grid container spacing={3}>
            {cateCount.map(cC => (
                <Grid item xs={12} sm={6} xl={2.4} textAlign="center">
                    <DefaultBlogCard
                        image={cC.ass_cate === "AS000" ? AS000 : cC.ass_cate === "AS001" ? AS001 : cC.ass_cate === "AS002" ? AS002 : AS003}
                        category={{color: "info", label: "house"}}
                        title={<CommonCodeInfoReturn init={cC.ass_cate}/>}
                        description={cC.cnt}
                        action=''
                    />
                </Grid>
            ))}

            <Grid item xs={12} sm={6} xl={2.4}>
                <Card sx={{height: "100%"}}>
                    <AssetInsertModal
                        isOpen={isOpen}
                        modalChange2={modalChange2}
                        closeModal2={closeModal2}
                        openModal2={openModal2}
                    />
                    <SoftBox position="relative" height="100%" p={2}>
                        <HoverableSoftBox
                            onClick={openModal2}
                            display="flex"
                            flexDirection="column"
                            height="50%"
                            py={2}
                            px={2}
                            borderRadius="lg"
                            sx={{
                                backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                                    `${linearGradient(
                                        rgba(gradients.dark.main, 0),
                                        rgba(gradients.dark.state, 0)
                                    )}, url(${newAsset})`,
                                backgroundSize: "cover",
                            }}
                        >
                            <SoftBox mb={3} pt={1}>
                                <SoftTypography variant="h3" color="white" fontWeight="bold">
                                    자산 등록
                                </SoftTypography>
                            </SoftBox>

                        </HoverableSoftBox>
                        <Link to="/assetManagement/member">
                            <SoftBox
                                display="flex"
                                flexDirection="column"
                                height="50%"
                                py={2}
                                px={2}
                                borderRadius="lg"
                                sx={{
                                    backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                                        `${linearGradient(
                                            rgba(gradients.dark.main, 0),
                                            rgba(gradients.dark.state, 0.2)
                                        )}, url(${peoples})`,
                                    backgroundSize: "cover",
                                }}
                            >
                                <SoftBox mb={3} pt={1}>
                                    <SoftTypography variant="h3" color="white" fontWeight="bold">
                                        물품관리대장
                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>
                        </Link>
                    </SoftBox>
                </Card>
            </Grid>
        </Grid>

    )
}

export default AssetCountAndInsert;