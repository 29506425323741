import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftProgress from "components/SoftProgress";
import {useQueries} from "react-query";
import {useState} from "react";
import allCommonCodeMap from "../../../../api/commonCode/allCommonCodeMap";
import allUserVacationInfo from "../../../../api/vacation/allUserVacationInfo";
import SoftTypography from "../../../../components/SoftTypography";
import CommonCodeInfoReturn from "../../../../components/commonCode/CommonCodeInfoReturn";
import Loading from "../../../../components/loading/Loading";

/**
 * 전체 유저 테이블 데이터
 * @returns {{columns: [{name: string, align: string},{name: string, align: string},{name: string, align: string}], rows: *[]}}
 */
export default function AllUserVacationInfoData() {


    // 전체 휴가 정보 리스트
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [vacationInfoList, setVacationInfoList] = useState([]);

    // 처음 로드된 여부
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [firstLoad, setFirstLoad] = useState(false);

    // 테이블 데이터 정보
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [tableDataList, setTableDataList] = useState([]);

    // 공용코드 맵
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [commonCodeMap, setCommonCodeMap] = useState(new Map());

    // 인풋 옵션 리스트
    const userArr = [];
    // 이름과 인덱스 매칭 맵
    const nameUserMap = new Map();
    // 이름과 true 여부 맵
    const inputMap = new Map();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const queries = useQueries([
        // queries[0] : 휴가 정보
        {
            queryKey: ["allUserVacationInfo"],
            queryFn: () => allUserVacationInfo(),
            onSuccess: (result) => {
                if (!firstLoad) {
                    // 휴가 정보
                    const userVacationList = result.data;

                    for (let i = 0; i < userVacationList.length; i++) {
                        const saveMap = {
                            mem_no: userVacationList[i].mem_no,
                            mem_name: userVacationList[i].mem_name,
                            mem_rank: userVacationList[i].vac_rank,
                            mem_team: userVacationList[i].vac_team,
                            img: userVacationList[i].imageUrl,
                            left_annual: userVacationList[i].left_annual,
                            total_annual: userVacationList[i].total_annual,
                            pull_annual: userVacationList[i].pull_annual,
                            sick_leave: userVacationList[i].sick_leave,
                            cc_leave: userVacationList[i].cc_leave,
                            etc_leave: userVacationList[i].etc_leave
                        }

                        // 전체 리스트에 저장
                        tableDataList.push(saveMap);
                    }
                    setFirstLoad(true);
                }
            },
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            },
        },
        {
            queryKey: ["allCommonCodeMap"],
            queryFn: () => allCommonCodeMap(),
            onSuccess: (result) => {
                setCommonCodeMap(result.data);
            }
        }
    ]);

    const nameCard = (name, rank, team, img) => {
        return (
            <SoftBox display="flex">
                <SoftBox>
                    <SoftAvatar
                        src={img}
                        alt="profile-image"
                        variant="rounded"
                        size="lg"
                        shadow="sm"
                    />
                </SoftBox>
                <SoftBox ml={1}>
                    <SoftBox mt={0.5} lineHeight={1}>
                        <SoftTypography variant="h6" fontWeight="bold">
                            {name}
                        </SoftTypography>
                        <SoftTypography variant="button" color="text" fontWeight="medium">
                            {commonCodeMap[team]?.info} / {commonCodeMap[rank]?.info}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            </SoftBox>
        );
    }

    /**
     * row에 데이터 렌더링
     * @returns {*[]}
     */
    const tableDataRender = () => {
        const dataArr = [];
        for (let i = 0; i < tableDataList.length; i++) {
            const useAnnual = tableDataList[i].total_annual - (tableDataList[i].left_annual - tableDataList[i].pull_annual)
            const totalAnnual = tableDataList[i].total_annual
            const optionVal = `[${commonCodeMap[tableDataList[i]?.mem_team]?.info}] ${tableDataList[i]?.mem_name} ${commonCodeMap[tableDataList[i]?.mem_rank]?.info}`
            userArr.push(optionVal);
            inputMap.set(optionVal, true);
            nameUserMap.set(optionVal, i);
            dataArr.push(
                {
                    memNo: tableDataList[i]?.mem_no,
                    memInfo: nameCard(tableDataList[i]?.mem_name, tableDataList[i]?.mem_rank, tableDataList[i]?.mem_team, tableDataList[i]?.img),
                    annual: (
                        <SoftBox>
                            <SoftTypography variant="h6" fontWeight="bold">
                                {useAnnual} 일 / {totalAnnual} 일
                            </SoftTypography>
                        </SoftBox>
                    ),
                    annualUsePercent: (
                        <SoftBox>
                            <SoftTypography variant="h6" fontWeight="bold">
                                {useAnnual / totalAnnual * 100 > 0 ? (useAnnual / totalAnnual * 100).toFixed(1) : 0} %
                            </SoftTypography>
                        </SoftBox>
                    ),
                    sick_leave: (
                        <SoftBox>
                            <SoftTypography variant="h6" fontWeight="bold">
                                {tableDataList[i].sick_leave} 일
                            </SoftTypography>
                        </SoftBox>
                    ),
                    cc_leave: (
                        <SoftBox>
                            <SoftTypography variant="h6" fontWeight="bold">
                                {tableDataList[i].cc_leave} 일
                            </SoftTypography>
                        </SoftBox>
                    ),
                    etc_leave: (
                        <SoftBox>
                            <SoftTypography variant="h6" fontWeight="bold">
                                {tableDataList[i].etc_leave} 일
                            </SoftTypography>
                        </SoftBox>
                    ),
                    pull_annual: (
                        <SoftBox>
                            <SoftTypography variant="h6" fontWeight="bold">
                                {tableDataList[i].pull_annual} 일
                            </SoftTypography>
                        </SoftBox>
                    )
                }
            );
        }
        return dataArr;
    };

    // /**
    //  * 유저 리스트
    //  * @returns {*[]}
    //  */
    // const UserList = () => {
    //     for (let i = 0; i < tableDataList.length; i++) {
    //         userArr.push(
    //             {
    //                 index: i,
    //                 memNo: tableDataList[i]?.mem_no,
    //                 memInfo: `[${commonCodeMap[tableDataList[i]?.mem_team]?.info}] ${commonCodeMap[tableDataList[i]?.mem_name]?.info} ${commonCodeMap[tableDataList[i]?.mem_rank]?.info}`
    //             }
    //         );
    //     }
    //     return userArr;
    // };

    return {
        columns: [
            {name: "memInfo", align: "left", value: "이름", width: "25%"},
            {name: "annual", align: "center", value: "사용연차 / 전체연차", width: "12.5%"},
            {name: "annualUsePercent", align: "center", value: "연차 소진율", width: "12.5%"},
            {name: "sick_leave", align: "center", value: "병가", width: "12.5%"},
            {name: "cc_leave", align: "center", value: "경조휴가", width: "12.5%"},
            {name: "etc_leave", align: "center", value: "기타휴가", width: "12.5%"},
            {name: "pull_annual", align: "center", value: "당겨쓴휴가", width: "12.5%"},
        ],
        rows: tableDataRender(),
        memberList : userArr,
        memberIndexBool : inputMap,
        memberIndexList : nameUserMap
    };
}
