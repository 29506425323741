import allCommonCodeMap from 'api/commonCode/allCommonCodeMap';
import CostMainLists from '../../../../../../api/cost/CostMainLists';
import { loginUser } from 'atom/member/loginUser';
import CostDetailTable from 'examples/Tables/CostTable/CostDetailTable'
// import CostReceptionTable from 'examples/Tables/CostTable/costReceptionTable';
import CostReceptionTable from '../../../../../../examples/Tables/CostTable/CostReceptionTable'
import VacationTable from 'examples/Tables/VacationTable'
import React, { useState } from 'react'
import { QueryClient, useQuery } from 'react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Pagination, Tooltip } from '@mui/material';
import SoftAvatar from 'components/SoftAvatar';
import SoftProgress from 'components/SoftProgress';
import SoftBox from 'components/SoftBox';
import noApprove from "../../../../../../assets/images/-_image.png";
import check from "../../../../../../assets/images/check.png";
import CostSentList from 'api/cost/CostSentList';
import { FaBan } from 'react-icons/fa';
import SoftTypography from 'components/SoftTypography';
import { CostSentPageNumber } from 'atom/cost/CostWriteDetail';
import NameCard from "../../../../../../components/nameCard/NameCard";
import SoftBadge from "../../../../../../components/SoftBadge";



const CostSentDocs = () => {
    const user = useRecoilValue(loginUser);
    const columns = [
        { key: "cost_no", name: "cost_no", value:"번호", align: "center" , width : "7%"},
        { key: "memberInfo", name: "memberInfo", value:"작성자", align: "center"  , width : "20%"},
        { key: "category_info", name: "category_info", value:"비용처리분류", align: "center" , width : "20%" },
        { key: "regDate", name: "regDate", value:"작성일", align: "center" , width : "15%" },
        { key: "approver", name: "approver", align: "center" , value : "결재자" , width : "10%"},
        { key: "approved", name: "approved", align: "center" , value : "결재상태",  width: "10%"},
        { key: "approvalProgress", name: "approvalProgress", align: "center" , value : "결재완료율" , width : "10%"},
    ]
    const rows = []

    const queryClient = new QueryClient();
    // const [page, setPage] = useState(1);
    const [page, setPage] = useRecoilState(CostSentPageNumber);
    const [size, setSize] = useState(10);

    //비용처리 문서 내역 가져오기
    const queries = useQuery(['CostSentList', user.id, page, size], () => CostSentList(user.id, page, size));

    const allCommonCodesQuery = useQuery(
        ["allCommonCodeMap"],
        () => allCommonCodeMap(),
        {
            staleTime: 120000,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            initialData: [],
        }
    );

    // page 변경
    function handlePageChange(event, value) {
        setPage(value)

    }

    let quotient = 1;
    if (queries.isSuccess && allCommonCodesQuery.isSuccess) {
        // 휴가 정보
        const costList = queries.data.data;
        // 공용코드 리스트
        const codeMap = allCommonCodesQuery.data.data; console.log(costList[0])
        quotient = Math.ceil(costList[0]?.cost_doc_count / size)
        let progress;
        for (let i = 0; i < costList.length; i++) {
            let cancelBool = false;
            let cancelReason = '';
            const costRowNo = costList[i]?.cost_no;
            const costCategory = codeMap[costList[i]?.category_info]?.info;
            const regDate = costList[i]?.dateformat;
            const approverDataList = costList[i]?.approvalList
            const approval_approvers = costList[i]?.approval_approvers;
            const approval_do = costList[i]?.approval_do;
            const approved = costList[i]?.cost_approved;

            progress = Math.round((approval_do / approval_approvers) * 100);

            const approvalList = [];
            if (approved === "AP000") {
                const tinyInfoSetList = [];
                // 저장용 리스트에 저장
                tinyInfoSetList.push(check);
                tinyInfoSetList.push("무결재 문서(결재자 없음)");

                // 저장용 리스트에 저장
                approvalList.push(tinyInfoSetList);
                progress = (1 / 1) * 100;
                // 상신취소
            } else if (approved === "AP006") {
                const tinyInfoSetList = [];
                // 저장용 리스트에 저장
                tinyInfoSetList.push(noApprove);
                tinyInfoSetList.push("상신취소 문서(결재자 없음)");

                // 저장용 리스트에 저장
                approvalList.push(tinyInfoSetList);
                progress = (0 / 2) * 100;
            } else if (approved === "AP004") {
                for (let j = 0; j < approverDataList.length; j++) {
                    const tinyInfoSetList = [];
                    const approverRank = codeMap[approverDataList[j].approver_rank]?.info;
                    const approverName = approverDataList[j].approver_name;
                    const approverLevel = approverDataList[j].approver_level;
                    const approverImg = approverDataList[j].approver_img;
                    tinyInfoSetList.push(approverImg);
                    tinyInfoSetList.push("[" + (approverLevel + 1) + "번 결재자] " + approverName + " " + approverRank);
                    approvalList.push(tinyInfoSetList);
                }
                cancelBool = true;
                cancelReason = truncateString(`사유: ${costList[i]["approval_cancel_reason"]}`,10);
            } else {
                for (let j = 0; j < approverDataList.length; j++) {
                    const tinyInfoSetList = [];
                    const approverRank = codeMap[approverDataList[j].approver_rank]?.info;
                    const approverName = approverDataList[j].approver_name;
                    const approverLevel = approverDataList[j].approver_level;
                    const approverImg = approverDataList[j].approver_img;
                    tinyInfoSetList.push(approverImg);
                    tinyInfoSetList.push("[" + (approverLevel + 1) + "번 결재자] " + approverName + " " + approverRank);
                    approvalList.push(tinyInfoSetList);
                }
            }

            const costRows = {
                cost_no: costRowNo,
                category_info: costCategory,
                regDate: regDate,
                approvedInfo:codeMap[approved]?.info,
                memberInfo: (NameCard(costList[i]?.mem_name, codeMap[costList[i]?.rank_info]?.info, codeMap[costList[i]?.team_info]?.info, costList[i]?.imageUrl)),
                approver: approvalList,
                cancelBool:cancelBool,
                cancelReason:cancelReason,
                approvalProgress: progress,
                approve_status: approved
            }
            rows.push(costRows)
        }
    }

    /**
     * 문자열과 절삭문자열수를 받아 ... 으로 생략해주는 함수
     * @param str:String-문자열
     * @param maxLength:Number-최대절삭문자열 수
     * @returns {*|string}
     */
    function truncateString(str, maxLength) {
        if (str.length > maxLength) {
            return str.slice(0, maxLength) + '...';
        } else {
            return str;
        }
    }

    const avatars = (members) =>
        members.map(([image, name], index) => (
            <Tooltip key={name + index} title={name} placeholder="bottom">
                <SoftAvatar
                    src={image}
                    alt="name"
                    size="xs"
                    sx={{
                        border: ({ borders: { borderWidth }, palette: { white } }) =>
                            `${borderWidth[2]} solid ${white.main}`,
                        cursor: "pointer",
                        position: "relative",

                        "&:not(:first-of-type)": {
                            ml: -1.25,
                        },

                        "&:hover, &:focus": {
                            zIndex: "10",
                        },
                    }}
                />
            </Tooltip>
        ));
    /**
     * row에 데이터 렌더링
     * @returns {*[]}
     */
    const tabelDataRender = () => {
        const dataArr = [];
        for (let i = 0; i < rows.length; i++) {
            dataArr.push(
                {
                    cost_no: rows[i].cost_no,
                    category_info: (
                        <>
                            <SoftBadge
                                variant="contained"
                                badgeContent={rows[i].category_info}
                                color="secondary" size="md" container
                                sx={{display: "inline-block", width: "max-content"}}
                            />
                        </>
                    ),
                    regDate: rows[i].regDate,
                    memberInfo: rows[i].memberInfo,
                    approver: (
                        <SoftBox display="flex" py={1}>
                            {avatars(
                                rows[i].approver
                            )
                            }
                        </SoftBox>
                    ),
                    approved: (
                        <SoftBadge
                            variant="contained"
                            badgeContent={rows[i]?.approvedInfo}
                            color={
                                rows[i]?.approvedInfo === "결재완료"
                                    ? "success" : rows[i]?.approvedInfo === "반려"
                                        ? "error" : rows[i]?.approvedInfo === "상신취소"
                                            ? "warning" : "info"}
                            size="lm" container
                            sx={{display: "inline-block", width: "max-content"}}
                        />
                    ),
                    approvalProgress: (
                        <SoftBox width="8rem" textAlign="left">
                            {rows[i]?.cancelBool ?
                                <>
                                    <SoftBox display="flex" justifyContent="flex-start" alignItems="center">
                                        <SoftTypography variant="h6" fontWeight="medium">
                                            <FaBan color="#d10404" size={18} style={{ verticalAlign: "middle", marginTop: -3 }} />
                                            결재 반려
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftTypography variant="button" fontWeight="medium">
                                        {rows[i]?.cancelReason}
                                    </SoftTypography>
                                </>
                                :
                                <SoftProgress value={rows[i]["approvalProgress"]} color="info" variant="gradient"
                                              label={true}/>
                            }
                        </SoftBox>
                    ),
                }
            );
        }
        return dataArr;
    };

    return (
        <>
            <CostReceptionTable columns={columns} rows={tabelDataRender()} />
            <SoftBox display='flex' justifyContent='center'>
                <Pagination
                    count={quotient}
                    page={page}
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton
                />
            </SoftBox>
        </>
    )
}

export default CostSentDocs