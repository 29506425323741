/**
 * yyyy-MM-dd 문자열로 받아서 요일로 리턴
 * @param input : String
 * @returns {string}
 */
export function GetDayOfWeek(input){

    const week = ['일', '월', '화', '수', '목', '금', '토'];

    const dayOfWeek = week[new Date(input).getDay()];

    return dayOfWeek;

}