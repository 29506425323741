import {atomFamily} from "recoil";

/**
 * 결재자 수 카운트
 * @type {RecoilState<number>}
 */
export const approverCountAtomFamily = atomFamily({
    key: "approverCountAtomFamily",
    default: (key) => ({
        count:0,
        keyName: key
    }),
})