import { customAxios } from '../../axios/CustomAxios'
import { format, formatISO } from 'date-fns'


export const getReservationInfo = async (selectDate) => {
    const {data} = await customAxios.post('/reservation/select/date',{
        selectDate: selectDate
    })
    return data
}

export const getAllRegionList = async (req) => {
    const { data } = await customAxios.get('/reservation/region')
    return data;
}

export const insertReservation = async (req) => {
    const sendReq = {
        ...req,
        startDate:format(req.startDate,'yyyy-MM-dd')
    }
    const {data} = await customAxios.put("/reservation/insert",sendReq,{
        headers:{
            'Content-Type':'application/json'
        }
    })
    return data;
}

export const updateReservation = async (req) => {
    const sendReq = {
        ...req,
        selectDate:format(req.selectDate,'yyyy-MM-dd')
    }
    const {data} = await customAxios.post("/reservation/update",sendReq,{
        headers:{
            'Content-Type':'application/json'
        }
    })
    return data;
}


export const getSelectDateMyReservation = async (selectDate) => {
    const sendReq = {
        selectDate:format(selectDate, 'yyyy-MM-dd')
    }
    const {data} = await customAxios.post('/reservation/select/my',sendReq,{
        headers:{
            'Content-Type':'application/json'
        }
    })
    return data;
}

export const deleteReservation = async (req) => {
    const {data} = await customAxios.delete('/reservation/delete', {
        data: req
    });
    return data;
}


export const getAllMemberReservation = async (req) => {
    const sendReq = {
        selectDate:format(req, 'yyyy-MM-dd')
    }
    const {data} = await customAxios.post('/reservation/select/allSeat', sendReq , {
        headers:{
            'Content-Type':'application/json'
        }
    })
    return data;
}


export const getSeatCountByReservationDate = async (req) => {
    const sendReq = {
        ...req,
        startDate:format(req.startDate, 'yyyy-MM-dd'),
        endDate:format(req.endDate, 'yyyy-MM-dd')
    }
    const {data} = await customAxios.post('/reservation/select/seatCount', sendReq)
    return data
}