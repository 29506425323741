import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 2023-06-02 비용처리 임시보관함 쿼리
 * @param {*} memno 
 * @param {*} page 
 * @param {*} size 
 * @returns 
 */
const CostStoreLists =(memno,page, size)=>{
    return customAxios.get(myIpAddress+`/cost/storeList/${memno}`,{
        params: {
            page,
            size
        }
    });
}
export default CostStoreLists;
