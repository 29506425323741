import {atom} from "recoil";

/**
 * 휴가 정보 입력용 atom
 * @type {RecoilState<{}>}
 */
export const vacationInsertAtom = atom({
    key: 'vacationInsertAtom',
    default:{
        auth_no:""
        , mem_no:0
        , vac_team:""
        , vac_tel:""
        , vac_rank:""
        , vac_start:""
        , vac_end:""
        , vac_category:""
        , vac_days:0
        , vac_reason:""
        , vac_approved:""
        , doc_type:"APT000"
        , vac_overuse:0
        , approverList:null
        , referrerList:null
    }
});