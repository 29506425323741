import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 버그리스트
 * @param page : int-현재 페이지
 * @param size : int-페이지당게시물수
 * @returns {Promise<axios.AxiosResponse<any>>}
 * @constructor
 */
const getBugList =({page, size})=>{
    return customAxios.post(myIpAddress+'/bug/getBugs', {
        page: page,
        size: size
    } );
}
export default getBugList;