import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { useEffect } from "react";
import { useState } from "react";

// api
// atom
import { wpTemporaryDocListAtom } from "../../atom/wp/wpTemporaryDocListAtom";
import { wpTemporalListRowAtom } from "../../atom/wp/wpTemporalListRowAtom";
import { wpRowCountAtom } from "../../atom/wp/wpRowCountAtom";
import { wpThisRowNumAtom } from "../../atom/wp/wpThisRowNumAtom";
import { wpSumPointAtom } from "../../atom/wp/wpSumPointAtom";
// util
// component
import CostWriterInput from "layouts/costInsert/components/CostWriterInput";
import WpEditTable from "./components/WpEditTable";
import WpInsertInfo from "layouts/wpInsert/components/WpInsertInfo";

// @mui material components
import Grid from '@mui/material/Grid';
import Card from "@mui/material/Card";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import Footer from "examples/Footer";
import ApproverInsertCard from "../../layouts/vacationInsert/components/ApproverReferrer/ApproverInsertCard";
import { Icon } from "@mui/material";
import { approverInsertAtomFamily } from "atom/approval/approver_referrer/approverInsertAtomFamily";
import { approverCountAtomFamily } from "atom/approval/approver_referrer/approverCountAtomFamily";



const WPEditPage = () => {
    const navigate = useNavigate();
	const location = useLocation();

	/** WPEditTable 컴포넌트 에서 리코일 리셋 전 data set 하는 이슈 로 인해 props 로 의존성 추가해줌...  */
	const [isResetting, setIsResetting] = useState(false);
	/** 결재자 리코일 key 값 */
	const wpNo = location.state;
	//console.log("번호      " , wpNo); 
	const approverKey = "approverEditKey_" + parseInt(wpNo);

	/** 리코일 리셋 */
	const tempListReset = useResetRecoilState(wpTemporaryDocListAtom); 
	const tempListRowReset = useResetRecoilState(wpTemporalListRowAtom); 
	const tempRowCountReset = useResetRecoilState(wpRowCountAtom);
	const tempThisRowNumReset = useResetRecoilState(wpThisRowNumAtom); 
	const totalpointReset = useResetRecoilState(wpSumPointAtom);
	const approverListReset = useResetRecoilState(approverInsertAtomFamily(approverKey)); // 결재자 리스트
    const approverCountReset = useResetRecoilState(approverCountAtomFamily(approverKey)); // 결재자 수

	// 리셋 실행
	useEffect(()=>{
		setIsResetting(false);
		tempListReset();
		tempListRowReset();
		tempRowCountReset();
		tempThisRowNumReset();
		totalpointReset();
		approverListReset();
		approverCountReset();
		setIsResetting(true);
//		console.log(" 실행 ");
	},[])

	return (
		<>
		<DashboardLayout>
            <DashboardNavbar/> 
			<Card
				sx={{
				backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
				boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
				position: "relative",
				mt: 2,
				mx: 0,
				mb : 4,
				py: 2,
				px: 2,
				}}
				>
				<Grid container spacing={3} alignItems="center" justifyContent="space-between">
					<Grid item>
						<SoftBox height="100%" mt={0.5} lineHeight={1}>
							<SoftTypography variant="h5" fontWeight="bold">
								복지포인트 수정
							</SoftTypography>
						</SoftBox>
					</Grid>
					<Grid item>
						<Grid container style={{ display: "flex", gap: "5px",flexWrap: "nowrap" }}>          
							<SoftButton variant="gradient" color="info" onClick={()=> navigate('/welfarePoint')} mr={2}>
								<Icon sx={{fontWeight: "bold"}}>home</Icon>
                                    &nbsp;메인
							</SoftButton>
							<SoftButton variant="gradient" color="dark" onClick={()=> navigate('/welfarePoint/tempList')} >
								<Icon sx={{fontWeight: "bold"}}>format_list_bulleted</Icon>
									&nbsp;임시 저장 목록
							</SoftButton>
						</Grid>
					</Grid>
				</Grid>
			</Card>
			<SoftBox mb={2}>
				<Card>
					<WpInsertInfo />
				</Card>
			</SoftBox>

			<SoftBox mt={2} mb={2}>
                <ApproverInsertCard keyName={approverKey} gridXs={12} gridMd={12} gridLg={12} type="wp" ></ApproverInsertCard>
            </SoftBox>

			<SoftBox mb={2}>
				<Card>
					<CostWriterInput
						writer="writer" />
        		</Card>
			</SoftBox>

			<WpEditTable isResetting={isResetting} keyName={approverKey} />
			<Footer/>

		</DashboardLayout>
		</>
	);
}

export default WPEditPage
