import VacationTable from "../../../../../../examples/Tables/VacationTable";
import data from "./data";
import SoftBox from "../../../../../../components/SoftBox";
import Pagination from "@mui/material/Pagination";
import {useRecoilState, useRecoilValue} from "recoil";
import {toMap} from '../../../../../../utils/ToMap';
import {receiveDocTotalCount} from "../../../../../../atom/approval/receive/receiveDocTotalCount";
import {vacationReceiveState} from "../../../../../../atom/approval/receive/vacationReceiveState";

/**
 * 휴가 수신함 문서
 * @returns {JSX.Element}
 * @constructor
 */
function VacationReceiveDocs () {
    const {columns, rows} = data();

    const [receiveState, setReceiveState ] = useRecoilState(vacationReceiveState);

    const totalCount = useRecoilValue(receiveDocTotalCount);
    const itemsPerPage = receiveState.docsNum;
    const totalPages =  Math.ceil(totalCount / itemsPerPage);

    // page 변경
    function handlePageChange(event, value) {
        const thisSentMap = toMap({...receiveState});
        thisSentMap.set("nowPage",value);
        setReceiveState(Object.fromEntries(thisSentMap));
    }

    return (
        <>
            <VacationTable columns={columns} rows={rows}/>
            <SoftBox display='flex' justifyContent='center'>
                <Pagination
                    count={totalPages}
                    page={receiveState.nowPage}
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton
                />
            </SoftBox>
        </>
    );
}

export default VacationReceiveDocs;