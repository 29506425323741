import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 복지포인트 상신 취소 
 * @param {*} body 
 * @returns 
 */

const wpApprovalCancel =(body)=>{
    return customAxios.post(myIpAddress+'/wp/approvalCancel', body ); 
}
export default wpApprovalCancel;
