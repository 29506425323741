import allCommonCodeMap from 'api/commonCode/allCommonCodeMap';
import { loginUser } from 'atom/member/loginUser';
import CostReceptionTable from '../../../../../../examples/Tables/CostTable/CostReceptionTable'
import React, { useState } from 'react'
import { QueryClient, useQuery } from 'react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Pagination, Tooltip } from '@mui/material';
import SoftAvatar from 'components/SoftAvatar';
import SoftProgress from 'components/SoftProgress';
import SoftBox from 'components/SoftBox';
import CostReceiveList from '../../../../../../api/cost/CostReceiveList';
import SoftTypography from 'components/SoftTypography';
import { FaBan } from 'react-icons/fa';
import { CostReceivePageNumber } from 'atom/cost/CostWriteDetail';


const CostReceiveDocs = () => {
    const user = useRecoilValue(loginUser);
    // 2023-06-02 칼럼 name 변경
    const columns = [
        { key: "cost_no", name: "번호", align: "center" , width : "10%"},
        { key: "category_info", name: "문서정보", align: "left" , width : "35%"},
        { key: "mem_name", name: "작성자", align: "left" , width : "23%"},
        { key: "approvalList", name: "결재자", align: "left" , width : "12%"},
        { key: "progress", name: "결재상태", align: "center", width : "20%"},
    ]
  
    const rows = []
    let count = 1;
    const queryClient = new QueryClient();
    // const [page, setPage] = useState(1);
    const [page, setPage] = useRecoilState(CostReceivePageNumber);
    const [size, setSize] = useState(5);

    //비용처리 문서 내역 가져오기
    const queries = useQuery(['CostReceiveList', user?.id, page, size], () => CostReceiveList(user?.id, page, size));
    const allCommonCodesQuery = useQuery(
        ["allCommonCodeMap"],
        () => allCommonCodeMap(),
        {
            staleTime: 120000,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            initialData: [],
        }
    );

    // page 변경
    function handlePageChange(event, value) {
        setPage(value)

    }

    let quotient=1;
    if (queries.isSuccess && allCommonCodesQuery.isSuccess) {
        // 비용처리 정보
        const costList = queries.data.data;
        // 공용코드 리스트
        const codeMap = allCommonCodesQuery.data.data;
        quotient = Math.ceil(costList[0]?.cost_doc_count / size)
        let progress;
        for (let i = 0; i < costList.length; i++) {
            const costRowNo = costList[i]?.cost_no;
            const costCategory = "[" + costList[i]?.category_info + "] " + costList[i]?.dateformat;
            const costWriter = "[" + costList[i]?.team_info + "] " + costList[i]?.mem_name + " " + costList[i]?.rank_info;
            const approverDataList = costList[i]?.approvalList
            const approverSituation = costList[i]?.approved_info;
            const approval_approvers = costList[i]?.approval_approvers;
            const approval_do = costList[i]?.approval_do;
            const approved = costList[i]?.cost_approved;
            progress = Math.round((approval_do / approval_approvers) * 100);
            const approvalList = [];
            for (let j = 0; j < approverDataList.length; j++) {
                const tinyInfoSetList = [];
                const approverRank = codeMap[approverDataList[j].approver_rank]?.info;
                const approverName = approverDataList[j].approver_name;
                const approverLevel = approverDataList[j].approver_level;
                const approverImg = approverDataList[j].approver_img;
                tinyInfoSetList.push(approverImg);
                tinyInfoSetList.push("[" + (approverLevel + 1) + "번 결재자] " + approverName + " " + approverRank);
                approvalList.push(tinyInfoSetList);
            }

            const costRows = {
                cost_no: costRowNo,
                category_info: costCategory,
                mem_name: costWriter,
                approvalList: approvalList,
                progress: progress,
                approve_status: approved
            }
            rows.push(costRows)
        }
    }


    const avatars = (members) =>
        members.map(([image, name], index) => (
            <Tooltip key={name + index} title={name} placeholder="bottom">
                <SoftAvatar
                    src={image}
                    alt="name"
                    size="xs"
                    sx={{
                        border: ({ borders: { borderWidth }, palette: { white } }) =>
                            `${borderWidth[2]} solid ${white.main}`,
                        cursor: "pointer",
                        position: "relative",

                        "&:not(:first-of-type)": {
                            ml: -1.25,
                        },

                        "&:hover, &:focus": {
                            zIndex: "10",
                        },
                    }}
                />
            </Tooltip>
        ));

    /**
     * row에 데이터 렌더링
     * @returns {*[]}
     */
    const tabelDataRender = () => {
        const dataArr = [];
        for (let i = 0; i < rows.length; i++) {
            dataArr.push(
                {
                    번호: rows[i].cost_no,
                    문서정보: rows[i].category_info,
                    작성자: rows[i].mem_name,
                    결재자: (
                        <SoftBox display="flex" py={1}>
                            {avatars(
                                rows[i].approvalList
                            )
                            }
                        </SoftBox>
                    ),
                    결재상태: rows[i].approve_status === 'AP004' ? (
                        <SoftBox width="8rem" textAlign="left">
                            <SoftTypography variant="h6" fontWeight="medium">
                                <FaBan color="#d10404" size={20} style={{ verticalAlign: "middle", marginLeft: 4, marginTop: -3 }} />
                                결재 반려
                            </SoftTypography>
                        </SoftBox>
                    ) : (
                        <SoftBox width="8rem" textAlign="left">
                            <SoftProgress value={rows[i].progress} color="info" variant="gradient" label={true} />
                        </SoftBox>
                    ),
                }
            );
        }
        return dataArr;
    };
    return (
        <>
            <CostReceptionTable columns={columns} rows={tabelDataRender()} />
            {/* <Pagination
                currentPage={page}
                pageSize={size}
                onPageChange={handlePageChange}
                onSizeChange={handleSizeChange}
                total={9}
            /> */}
            <SoftBox display='flex' justifyContent='center'>
                <Pagination
                    count={quotient}
                    page={page}
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton
                />
            </SoftBox>
        </>
    )
}

export default CostReceiveDocs