import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 복지포인트 작성한 목록
 * @param {*} body 
 * @returns 
 */

const wpList =(body)=>{
    return customAxios.post(myIpAddress+'/wp/wpList', body); 
}
export default wpList;
