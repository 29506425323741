import {useNavigate, useParams} from "react-router-dom";
import {styled} from "@mui/material/styles";
import SoftBox from "../../../components/SoftBox";
import {CircularProgress} from "@mui/material";
import SoftTypography from "../../../components/SoftTypography";
import Swal from "sweetalert2";
import {ACCESS_TOKEN} from "../../../config";
import {useQuery} from "react-query";
import DeleteCookie from "../../../api/auth/deleteCookie";
import {useState} from "react";

const CenteredSoftBox = styled(SoftBox)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

/**
 * error code에 따라 error 핸들
 * @constructor
 */
const ErrorPage = () => {
    const {errorType} = useParams();
    const navigate = useNavigate();
    const [deleteFlag, setDeleteFlag] = useState(false);
    const {data: deleteCookie} = useQuery("deleteCookie", async () => await DeleteCookie(), {
        enabled : deleteFlag,
        onSuccess: (data) => {
            setDeleteFlag(false);
            navigate('/', {state:1});

        },
        onError: (err)=>{
            Swal.fire({
                title: '로그아웃이 정상적으로 진행되지 않았습니다. 잠시후 다시 시도해주세요.',
                icon: 'error',
                backdrop: false

            })
        }
    })
    if(errorType === "401") {
        Swal.fire({
            title: "로그인 정보가 없습니다.",
            text: "클릭시 로그인 페이지로 이동합니다.",
            icon: "error",
            backdrop: true,
        }).then(() => {
            setDeleteFlag(true);
            navigate('/', {state:1});

        })
    } else if (errorType === "403") {
        Swal.fire({
            title: "가입승인 대기중 입니다.",
            text: "관리자에게 문의해주세요.",
            icon: "error",
            backdrop: true,
        }).then(() => {
            setDeleteFlag(true);
            navigate('/', {state:1});

        });
    } else {
        navigate('/', {state:1});

    }

    return (
        <CenteredSoftBox>
            <CircularProgress/>
            <SoftTypography variant="h4" fontWeight="medium">
                Loading.....
            </SoftTypography>
        </CenteredSoftBox>
    );

}

export default ErrorPage;