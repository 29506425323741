import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

// 공용코드 맵
const CostSentList =(memno, page, size)=>{
    return customAxios.get(myIpAddress+`/cost/sentList/${memno}`,{
        params: {
            page,
            size
        }
    });
}
export default CostSentList;