import {Doughnut} from "react-chartjs-2";
import SoftBox from "../../../../components/SoftBox";
import {Chart, registerables} from "chart.js";
import userAnnualInfo from "../../../../api/vacation/userAnnualInfo";
import {useEffect} from "react";
import {QueryClient, useQuery} from "react-query";
import {isRecoilValue, useRecoilValue} from "recoil";
import {loginUser} from "../../../../atom/member/loginUser";
import colorConfig from "../../../../colorConfig";

/**
 *  휴가 사용 일수 정보 파이 차트
 * @returns {JSX.Element}
 * @constructor
 */
function VacationPieChart() {
    Chart.register(...registerables);

    // react query
    const queryClient = new QueryClient();

    const {vacationType} = colorConfig;

    // login 정보
    const recoilState = isRecoilValue(loginUser);
    const user = useRecoilValue(loginUser);
    let annualInfo;

    let leftAnnualDay;
    let pullAnnualDay;
    let sickAnnualDay;
    let ccAnnualDay;
    let etcAnnualDay;
    let totalAnnualDay;

    // recoil 정보가 가져와 지면 refetch
    useEffect(() => {
        if (recoilState) {
            // Only execute the query if myState is not null
            queryClient.refetchQueries('userAnnualInfo');
        }
    }, [recoilState]);

    // user 휴가 일수 정보 - 파라미터 유저 번호로 변경
    const {
        data,
        isSuccess,
    } = useQuery(['userAnnualInfo', user?.id], () => userAnnualInfo(parseInt(user?.id)), {
        // 데이터 불러오지(fetch) 않는 시간 지정 20분
        staleTime: 120000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        select: (data) => {
            annualInfo = data.data
            return annualInfo
        }
    })

    if (isSuccess) {
        if (annualInfo.total_annual != null) {
            totalAnnualDay = annualInfo.total_annual;
            leftAnnualDay = annualInfo.left_annual;
            pullAnnualDay = annualInfo.pull_annual;
            sickAnnualDay = annualInfo.sick_leave;
            ccAnnualDay = annualInfo.cc_leave;
            etcAnnualDay = annualInfo.etc_leave;
        }
    }

    const dataSet = {
        labels: ["연차", "병가", "경조사휴가", "기타휴가", "당겨쓴 휴가"],
        datasets: [
            {
                label:"사용일수",
                data: [totalAnnualDay-leftAnnualDay, sickAnnualDay, ccAnnualDay, etcAnnualDay, pullAnnualDay],
                backgroundColor: [vacationType.VT000, vacationType.VT001, vacationType.VT002, vacationType.VT003, vacationType.PULL],
                hoverOffset: 5,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
    }

    const textCenter = {
        id: 'text',
        beforeDatasetsDraw(chart, args, options) {
            const {ctx} = chart;
            ctx.save();
            ctx.font = 'bold 30px';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = 'black';
            ctx.fillText("휴가사용정보", chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y);
        }

    }

    return (
        <>
            <SoftBox>
                <Doughnut
                    data={dataSet}
                    options={options}
                    plugins={[textCenter]}
                />
            </SoftBox>
        </>
    );
}

export default VacationPieChart;
