import {  Grid, Icon, } from "@mui/material";
import wpAdminBasicSetting from "../../../../api/wp/wpAdminBasicSetting";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useMutation, useQuery } from "react-query";

// Soft UI Dashboard React base styles
import borders from "assets/theme/base/borders";
import AmountFormatInput from "../../../../components/textField/AmountFormatInput";
import { useEffect, useState } from "react";
import wpAdminBasicPointUpdate from "../../../../api/wp/wpAdminBasicPointUpdate";
import Swal from "sweetalert2";


const WpBasicSetting = () => {  

    const { borderWidth, borderColor } = borders;
    const [ modify, setModify] = useState({});

    const [ memYearPoint, setMemYearPoint ] = useState(0);
    const [ memMonthPoint, setMemMonthPoint ] = useState(0);
    const [ lederYearPoint, setLederYearPoint ] = useState(0);
    const [ lederMonthPoint, setLederMonthPoint ] = useState(0);

    const [ basicPoint , setBasicPoint ] = useState({});


    const columns = [
        { key: "memYearPoint" , name: "사원 1년 복지포인트"},
        { key: "memMonthPoint" , name: "사원 한 달 복지포인트" },
        { key: "lederYearPoint" , name: "팀장 이상 1년 복지포인트"}, 
        { key: "lederMonthPoint" , name: "팀장 이상 한 달 복지포인트"},
     ]

    const { mutate:basicPointUpdate } = useMutation(wpAdminBasicPointUpdate, {
        onSuccess: ()=>{
            Swal.fire({
				title: '복지포인트 금액 변경 완료 되었습니다.',
				icon: 'success',
				showCancelButton: true,
				confirmButtonColor: '#3085d6', 
				cancelButtonColor: '#d33',
				backdrop: false
			}).then((result) => {
					if (result.isConfirmed) {
						refetch();
					}
				}
			)
            columns.forEach(({ key }) => {
                setModify((prev) => ({
                    ...prev,
                    [key]: false,
                }));
            });
		},
		onError: () => {
			Swal.fire({
				title: '업데이트 중 에러가 발생했습니다. 잠시후 다시 실행해주세요',
				icon: 'error',
                backdrop: false,
			})
		}
    });

    const { data , refetch } = useQuery(['wpAdminBasicSetting'] , () => wpAdminBasicSetting() , {
        select : (data) => {
			return data.data;
		}
    })

    
    useEffect(()=>{
        let basic = {}
        if (data){
            data.map((list)=>{
                if(list.wp_rank === "member") {
                    setMemMonthPoint(list.month_point)
                    setMemYearPoint(list.year_point)
                    basic["memMonthPoint"] = list.month_point;
                    basic["memYearPoint"] = list.year_point;
                } else{
                    setLederMonthPoint(list.month_point)
                    setLederYearPoint(list.year_point)
                    basic["lederMonthPoint"] = list.month_point;
                    basic["lederYearPoint"] = list.year_point;
                }
            })
            setBasicPoint(basic)
        }
    }, [data])

    // const handleModifyClick = (modifyKey) => () => {
    //     if(modifyKey === "all") {
    //         columns.forEach(({ key }) => {
    //             console.log(key)
    //             setModify((prev) => ({
    //                 ...prev,
    //                 [key]: !prev[modifyKey], 
    //             }));
    //         })
    //     } else{
    //         console.log(modify ,modifyKey)
    //         setModify((prev) => ({
    //             ...prev,
    //             [modifyKey]: !prev[modifyKey], 
    //         }));
    //         console.log(modify)
    //     }
    //     console.log(modify)
    // };

    const handleModifyClick = (modifyKey) => {
        return () => {
            if (modifyKey === "all") {
                columns.forEach(({ key }) => {
                    setModify((prev) => ({
                        ...prev,
                        [key]: !prev[modifyKey],
                    }));
                });
            } else {
                setModify((prev) => ({
                    ...prev,
                    [modifyKey]: !prev[modifyKey],
                }));
            }
        };
    };
      
      
    const handleInsertClick = () =>  {
        const body = {
            mem_year_point : parseInt(memYearPoint)
            , mem_month_point : parseInt(memMonthPoint)
            , leader_year_point : parseInt(lederYearPoint)
            , leader_month_point : parseInt(lederMonthPoint)
        }
        //console.log(body)
        basicPointUpdate(body);
    };
 
    const handleCancelClick = (modifyKey) => {
        return () => {
            if (modifyKey === "memMonthPoint") {
                setMemMonthPoint(basicPoint[modifyKey]);
            } else if (modifyKey === "memYearPoint") {
                setMemYearPoint(basicPoint[modifyKey]);
            } else if (modifyKey === "lederMonthPoint") {
                setLederMonthPoint(basicPoint[modifyKey]);
            } else if (modifyKey === "lederYearPoint") {
                setLederYearPoint(basicPoint[modifyKey]);
            } 
            setModify((prev) => ({
                ...prev,
                [modifyKey]: !prev[modifyKey],
            }));
        };
    };



    function rows (key) {
        if(key === "memYearPoint" ) {
            return (
                <AmountFormatInput
                    theme={modify[key] === undefined || modify[key] === false ? "sum" : null } 
                    unit="원"
                    value={memYearPoint}
                    setValue={setMemYearPoint}
                />
            )
        }else if(key === "memMonthPoint"){
            return (
                <AmountFormatInput
                    theme={modify[key] === undefined || modify[key] === false ? "sum" : null} 
                    unit="원"
                    value={memMonthPoint}
                    setValue={setMemMonthPoint}
                />
            )
        }else if(key === "lederYearPoint"){
            return (
                <AmountFormatInput
                    theme={modify[key] === undefined || modify[key] === false ? "sum" : null} 
                    unit="원"
                    value={lederYearPoint}
                    setValue={setLederYearPoint}
                />
            )
        }else if(key === "lederMonthPoint"){
            return (
                <AmountFormatInput
                    theme={modify[key] === undefined || modify[key] === false ? "sum" : null} 
                    unit="원"
                    value={lederMonthPoint}
                    setValue={setLederMonthPoint}
                    />
            )
        }
    }


    const renderColumns = columns.map(({ name, value, key}, index) => {
        let pl;
        let pr;
        if (index === 0) {pl = 3;pr = 3;
        } else if (index === columns.length - 1) {pl = 3;pr = 3;
        } else {pl = 1;pr = 1;
        }

        const isModify = modify[key];
            return ( 
                data && data.length > 0 &&
                <Grid item xs={12} sm={6} xl={3}>
                    <SoftBox
                        border={`${borderWidth[1]} solid ${borderColor}`}
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        flexDirection="column"
                        p={2}
                        key={key}
                        sx={{
                            width: '100%'
                        }}
                        >   
                        <SoftTypography variant="caption" fontWeight="bold" pb={1}>
                            {name}
                        </SoftTypography>
                
                        {rows(key)}
                
                        <SoftBox
                            p={1} 
                            display="flex"
                            >
                            {   isModify === undefined || isModify == false?
                                <>
                                    <SoftTypography 
                                        variant="button" fontWeight="medium" alignItems="center" display="flex"
                                        onClick={handleModifyClick(key)}>
                                        <Icon sx={{ cursor: "pointer" }} fontSize="small"  >
                                            edit
                                        </Icon>
                                        수정
                                    </SoftTypography>    
                                </>
                                : 
                                <>
                                    <SoftTypography 
                                        pr={2}
                                        variant="button" fontWeight="medium" alignItems="center" display="flex"
                                        onClick={handleInsertClick}>
                                        <Icon sx={{ cursor: "pointer" }} fontSize="small"  >
                                        save
                                        </Icon>
                                        저장
                                    </SoftTypography>  
                                    <SoftTypography 
                                        variant="button" fontWeight="medium" alignItems="center" display="flex"
                                        onClick={handleCancelClick(key)}>
                                        <Icon sx={{ cursor: "pointer" }} fontSize="small"  >
                                        cancel
                                        </Icon>
                                        취소
                                    </SoftTypography>  
                                </>
                            }
                        </SoftBox>
                    </SoftBox>
                </Grid>
            )
    });


    return (
        <> 
            <Grid>
                <SoftBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                    <SoftTypography variant="h6" fontWeight="bold" >
                    복지포인트 지급액 
                    </SoftTypography>
                    {/* {   
                        Object.keys(modify).length > 4 ?
                        <SoftButton color="info" variant="outlined" size="small" onClick={handleInsertClick}>
                            전체 저장 
                        </SoftButton>
                        :
                        <SoftButton color="info" variant="outlined" size="small" onClick={handleModifyClick("all")} >
                            전체 수정
                        </SoftButton>
                    } */}
                </SoftBox> 
                <SoftBox p={1} pb={2}>
                    <Grid container spacing={1} >
                        {renderColumns}
                    </Grid>
                </SoftBox>
            </Grid> 
        </>
    );
    
}

export default WpBasicSetting