import React ,  { forwardRef, useCallback, useEffect, useMemo, useState }from "react";
import { useRecoilState } from "recoil";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import DatePicker from 'react-datepicker';
import { ko  } from 'date-fns/esm/locale'; //한국어 설정

// atom
import { wpSumPointAtom } from "../../../atom/wp/wpSumPointAtom";
import { wpThisRowNumAtom } from "../../../atom/wp/wpThisRowNumAtom";
import { wpRowCountAtom } from "../../../atom/wp/wpRowCountAtom";
import { wpDetailRowAtom } from "../../../atom/wp/wpDetailRowAtom";
// util
import AmountFormatInput from '../../../components/textField/AmountFormatInput';
import { DateFormat } from "utils/DateFormat.js";

// wp components
import CommonCodeSelectBox from '../../../components/inputBox/CommonCodeSelectBox'

// @mui material components
import { IconButton,  Table as MuiTable, TableBody, TableCell } from "@mui/material";
import { TableRow } from "@mui/material";
import RemoveIcon from '@mui/icons-material/RemoveCircle';
// Soft UI Dashboard React examples
import SoftBox from "components/SoftBox/index.js";
import SoftInput from "components/SoftInput/index.js";
import { DateBeforeAndAfterTenthDay } from "../../../utils/DateBeforeAndAfterTenthDay";
import UploadButtons from "components/button/UploadButtons";


/** DatePicker 커스텀 - 버튼 모양 */
const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref} >
        {value}
    </button>
));


const WpInsertTableRow = (props) => {
	const { rowindex , isResetting } = props;

	/** 오늘 날짜 */
	const currentDate = new Date();
	//const testDate = new Date(2023,0,1);
	/** datePicker 날짜 범위 */
	const datePickerRange = DateBeforeAndAfterTenthDay(currentDate);
	/** 사용일 시작일 */
	const minDate = datePickerRange.minDate;
	/** 사용일 끝일 */
	const maxDate = datePickerRange.maxDate;
	//console.log(moment(dateRange.minDate).format('YYYY-MM-DD') ," ::::::::::: ", moment(dateRange.maxDate).format('YYYY-MM-DD'));

	/** datepicker default 날짜 -> minDate */
	const [startDate, setStartDate] = useState(minDate);


	/** 복지포인트 row 갯수*/
	const [ WriteDetailRowNum , setWriteDetailRowNum] = useRecoilState(wpRowCountAtom);
	/** 복지포인트 상세내역 - row 리스트 */
	const [ detailListRow , setDetailListRow] = useRecoilState(wpDetailRowAtom);
	/** 삭제버튼 클릭한 해당 row의 key 값 */
	const [ WriteThisRowNum , setWriteThisRowNum ]  = useRecoilState(wpThisRowNumAtom);
	/** 사용한 금액 합계 리코일 - row 삭제 시 실행 되게 하기 위해서 */
	const [ totalpoint, setTotalpoint ] = useRecoilState(wpSumPointAtom);


	/** 작성 내용 state  */
	const [ usedate , setUsedate ] = useState(startDate);
	const [ category , setCategory] = useState("");  
	const [ content, setContent ] = useState("");
	const [ filename, setFilename ] = useState("");
	const [ wpFile, setWpFile ] = useState(null);
	const [ usedpoint, setUsedpoint ] = useState(null);
	const [ paytype, setPaytype ] = useState("");
	
	/** state 값에 업데이트 있을 시 detailListRow 에 값 업데이트 */
	const updateDetailListRow = useCallback(() => {
		const array = detailListRow.map(list => {
			if (list.wp_rowIndex === rowindex) {
				return {
					...list,
					wp_rowIndex : rowindex,
					wp_usedate: DateFormat(usedate) ,
					wp_category: category,
					wp_content: content,
					wp_filename: filename,
                    wp_file: wpFile,
					wp_usedpoint: usedpoint,
					wp_paytype: paytype
				};
			} else {
				return list;
			}
		});
		setDetailListRow(array);

		const totalusedpoint = array.reduce((acc, cur) => acc + parseInt(cur.wp_usedpoint || 0), 0);
		setTotalpoint(totalusedpoint)
	}, [isResetting, usedate, category, content, filename, usedpoint, paytype , WriteThisRowNum]);

	useEffect(()=>{
		updateDetailListRow()
	},[updateDetailListRow])

	/** 복지포인트 상세 내역 row 삭제 */
	const RemovewpWriteDetailRow = (rowindex) => {
		const row = [...detailListRow];
		if(WriteDetailRowNum > 0 && row.length > 1){
			setWriteThisRowNum(rowindex)
			//console.log("삭제 : " + rowindex);
		} else {
			Swal.fire({
				icon: 'error',
				title: '에러',
				text: '삭제에 실패했습니다.',    
				backdrop : false,            
			}); 
		} 
	}
	
    return (
		<>	
			<TableRow key={rowindex} sx={{ width : "100%" ,}}>
				<TableCell
					sx={{ textAlign :"center", paddingX : "0px" , paddingLeft : "5px" , lineHeight : 0 , paddingY :0.5}}
					>
					<DatePicker
						key={rowindex+"usedate"}
						selected={usedate}
						locale={ko}
						onChange={(date) => {
							//setStartDate(date);
							setUsedate(date);
						}}
						// minDate={minDate}
  						// maxDate={maxDate}
						customInput={<ExampleCustomInput/>}
						dateFormat="yyyy-MM-dd"
						value = {setUsedate}
					/>
				</TableCell>
				<TableCell 
					sx={{ textAlign :"center", paddingX : "0px", lineHeight : 0 , paddingY :0.5}}
					>
						<CommonCodeSelectBox
							key={rowindex+"category"}
							label="사용처"
							category="WELFARE_CATEGORY"
							selectedValue={category}
							value={setCategory}
							textAlign="center"
							>
						</CommonCodeSelectBox>
				</TableCell>
				<TableCell sx={{ paddingX : "0px" , lineHeight : 0 , paddingY :0.5 }}>
					<SoftInput
						sx={{  minWidth : 200 , textAlign :"center", paddingX : "0px" , paddingLeft : "10px" }}
						key={rowindex+"content"} 
						variant="button" fontWeight="regular"
						onChange={(e) => {
							setContent(e.target.value)
						}}
						value={content}
						>
					</SoftInput>
				</TableCell>
				{/* 영수증 파일 첨부 부분 */}
				<TableCell sx={{ paddingX : "0px" }}>
					<UploadButtons 
						key={rowindex+"filename"} 
						theme = "icon"
						value = {filename}
						setValue = {setFilename}
						setFile = {setWpFile}
						docType = "wp"
					/> 	
				</TableCell>
				<TableCell sx={{ paddingX : "0px" , lineHeight : 0 , paddingY :0.5}} >
					<SoftBox
						sx={{
							"@media screen and (max-width: 1300px)": {
								width: "200px",
							},
							"@media screen and (max-width: 1000px)": {
								width: "130px",
							},
							paddingLeft : "7px"
						}}
						>
						<AmountFormatInput
							key={rowindex+"usedpoint"}
							unit = "원"
							value={usedpoint}
							setValue={setUsedpoint}
						/>
					</SoftBox>
				</TableCell>
				<TableCell sx={{ paddingX : "0px" , textAlign :"center", lineHeight : 0 , paddingY :0.5}} >
					<CommonCodeSelectBox
						key={rowindex+"paytype"} 
						category="PAY_METHOD"
						selectedValue={paytype}
						value={setPaytype}
					>
					</CommonCodeSelectBox>
				</TableCell>
				<TableCell sx={{ paddingX : "0px" , lineHeight : 0 , paddingY :0.5}} >
					<SoftBox
						key={rowindex+"delete"} 
						sx={{ padding:"0px" , textAlign :"-webkit-center",}}
						>
						<IconButton 
							aria-label="delete" 
							key = {rowindex}
							onClick={() => {
								RemovewpWriteDetailRow(rowindex);
							}}
							>
							<RemoveIcon />
						</IconButton>
					</SoftBox>
				</TableCell>
			</TableRow>
		</>
    );
}

    // Setting default values for the props of Table
    WpInsertTableRow.defaultProps = {
    columns: [],
    detailListRow: [{}],
    };

    // Typechecking props for the Table
    WpInsertTableRow.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    detailListRow: PropTypes.arrayOf(PropTypes.object),
    };

export default WpInsertTableRow
