import React ,  { useEffect, useState }from "react";
import { useNavigate } from "react-router-dom";
import { useResetRecoilState } from "recoil";

// atom
import { wpThisRowNumAtom } from "../../atom/wp/wpThisRowNumAtom";
import { wpRowCountAtom } from "../../atom/wp/wpRowCountAtom";
import { wpSumPointAtom } from "../../atom/wp/wpSumPointAtom";
import { wpDetailRowAtom } from "../../atom/wp/wpDetailRowAtom";
// components
import ApproverInsertCard from "../../layouts/vacationInsert/components/ApproverReferrer/ApproverInsertCard";
import WpInsertInfo from "./components/WpInsertInfo";
import WpInsertTable from "./components/WpInsertTable";
import CostWriterInput from "../../layouts/costInsert/components/CostWriterInput";

// @mui material components
import Grid from '@mui/material/Grid';
import Card from "@mui/material/Card";
import 'react-datepicker/dist/react-datepicker.css';
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import '../wpInsert/components/index.css'
import { Icon } from "@mui/material";
import { approverInsertAtomFamily } from "atom/approval/approver_referrer/approverInsertAtomFamily";
import { approverCountAtomFamily } from "atom/approval/approver_referrer/approverCountAtomFamily";
						

   
const WPInsertPage = () => {
	const navigate = useNavigate();
    /** WPWriteTable 컴포넌트 에서 리코일 리셋 전 data set 하는 이슈 로 인해 props 로 의존성 추가해주기위해...  */
    const [isResetting, setIsResetting] = useState(false);

    /** 결재자 키 값*/
    const approverKey = "approverKey_wp"

   	/** 리코일 리셋 */
	const insertListRowReset = useResetRecoilState(wpDetailRowAtom); // 상세 내역 row
	const insertRowCountReset = useResetRecoilState(wpRowCountAtom); // row 수
	const insertThisRowNumReset = useResetRecoilState(wpThisRowNumAtom);  // 클릭 한 해당 row
	const totalpointReset = useResetRecoilState(wpSumPointAtom); // 합계 금액 
    const approverListReset = useResetRecoilState(approverInsertAtomFamily(approverKey)); // 결재자 리스트
    const approverCountReset = useResetRecoilState(approverCountAtomFamily(approverKey)); // 결재자 수

	// 리셋 실행
	useEffect(()=>{
		insertListRowReset();
		insertRowCountReset();
		insertThisRowNumReset();
		totalpointReset();
        approverListReset();
        approverCountReset();
        setIsResetting(true);
        //console.log(" 실행 ")
	},[])

	return (
		<>
		<DashboardLayout>
            <DashboardNavbar/>
			<Card
                sx={{
                backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
                boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                position: "relative",
                mt: 2,
                mx: 0,
                mb : 4,
                py: 2,
                px: 2,
                }}
                >
                <Grid container spacing={3} alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <SoftBox height="100%" mt={0.5} lineHeight={1}>
                            <SoftTypography variant="h5" fontWeight="bold" >
                                복지포인트 작성
                            </SoftTypography>
                        </SoftBox>
                    </Grid>
                    <Grid item>
                        <Grid container style={{ display: "flex", gap: "5px",flexWrap: "nowrap" }}>                                
                            <SoftButton  variant="gradient" color="info" onClick={()=> navigate('/welfarePoint')} >
                                <Icon sx={{fontWeight: "bold"}}>home</Icon>
                                    &nbsp;메인
                            </SoftButton>
                            <SoftButton variant="gradient" color="dark" onClick={() => navigate('/welfarePoint/list')} >
                                <Icon sx={{fontWeight: "bold"}}>format_list_bulleted</Icon>
                                    &nbsp;전체 목록
                            </SoftButton>
                        </Grid>
                    </Grid>
                </Grid> 
            </Card> 
			<Card>
				<WpInsertInfo/> 
			</Card>

            <SoftBox mt={2} mb={2}>
                <ApproverInsertCard keyName={approverKey} gridXs={12} gridMd={12} gridLg={12} type="wp" ></ApproverInsertCard>
            </SoftBox>

			<SoftBox mt={2} mb={2}>
				<Card> 
					<CostWriterInput
						writer="writer" />
				</Card>
			</SoftBox>

            <SoftBox>
		        <WpInsertTable isResetting={isResetting} keyName={approverKey}></WpInsertTable>
            </SoftBox>

            <Footer/>

		</DashboardLayout>
		</>
	);
	

}

export default WPInsertPage
