import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import SoftBox from "../../components/SoftBox";
import Grid from "@mui/material/Grid";
import DocsCountView from "./components/DocsCountView";

/**
 * 내 문서함
 * @returns {JSX.Element}
 * @constructor
 */
function Docs() {
    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <SoftBox my={2}>
                <Grid container spacing={1}>
                    <DocsCountView/>
                </Grid>
            </SoftBox>
            <Footer/>
        </DashboardLayout>
    );
}


export default Docs;