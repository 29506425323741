import SoftBox from "../../../components/SoftBox";
import Grid from "@mui/material/Grid";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import {Tab, Tabs} from "@mui/material";

import {useState} from "react";
import AllUserVacationInfo from "./component/AllUserVacationInfo";
import AllUserMonthVacationInfo from "./component/AllUserMonthVacationInfo";
const AdminVacation = () => {

    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <SoftBox pt={1} pb={3}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Tabs value={tabValue} onChange={handleChange} centered sx={{bgcolor: '#ccd6e6'}}>
                            <Tab
                                label="월별 휴가 정보 조회"
                            />
                            <Tab
                                label="전체 사원 휴가 정보 조회"
                            />
                        </Tabs>
                    </Grid>
                    <Grid item xs={12}>
                        {tabValue === 0 ? <AllUserMonthVacationInfo/> : <AllUserVacationInfo/>}
                    </Grid>
                </Grid>
            </SoftBox>
            <Footer/>
        </DashboardLayout>
    )
}

export default AdminVacation;