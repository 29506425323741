import {QueryClient, useQuery} from "react-query";
import useVacationMonthTotal from "../../../api/vacation/useVacationMonthTotal";
import {isRecoilValue, useRecoilValue} from "recoil";
import {loginUser} from "../../../atom/member/loginUser";
import {useEffect} from "react";

/**
 * 월별 휴가 정보 json 데이터 리턴
 * @returns {{chartData: {chart: {datasets: {label4: string, data: number[], data4: number[], data3: number[], data2: number[], label: string, label2: string, label3: string}, labels: string[]}}}}
 * @constructor
 */
function VacationBarChartData() {
    // react query
    const queryClient = new QueryClient();

    // login 정보
    const recoilState = isRecoilValue(loginUser);
    const user = useRecoilValue(loginUser);

    // recoil 정보가 가져와 지면 refetch
    useEffect(() => {
        if (recoilState) {
            // Only execute the query if myState is not null
            queryClient.refetchQueries('useVacationMonthTotal');
        }
    }, [recoilState]);

    // 유저 휴가 쿼리 저장용 변수
    let daysInfo;

    // 휴가 종류 별 사용일 기본 세팅
    let annual = [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0]
    let sick = [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0]
    let cc = [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0]
    let etc = [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0]

    // 유저 휴가 정보 가져오기
    const {
        data,
        isSuccess,
        // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useQuery(['useVacationMonthTotal', user?.id], () => useVacationMonthTotal(parseInt(user?.id)), {
        // 데이터 불러오지(fetch) 않는 시간 지정 20분
        staleTime: 120000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        select: (data) => {
            daysInfo = data.data
            return daysInfo
        }
    })

    // 성공시 휴가값 저장
    if (isSuccess) {
        // 연차
        if(daysInfo.VT000.length > 0){
            const list = daysInfo.VT000;
            annual = monthDataSplice(list, annual);
        }
        // 병가
        if(daysInfo.VT001.length > 0){
            const list = daysInfo.VT001;
            sick = monthDataSplice(list, sick);
        }
        // 경조사
        if(daysInfo.VT002.length > 0){
            const list = daysInfo.VT002;
            cc = monthDataSplice(list, cc);
        }
        // 기타
        if(daysInfo.VT003.length > 0){
            const list = daysInfo.VT003;
            etc = monthDataSplice(list, etc);
        }

    }

    /**
     * 휴가타입 리스트와 월휴가 디폴트 리스트를 넣어 해당하는 달의 값 변경한 리스트 리턴
     * @param Array : 휴가타입List
     * @param monthList : 월휴가값List
     * @returns changeList : 값이변경된월휴가List
     */
    function monthDataSplice(Array, monthList){
        let month;
        let days;
        let changeList = monthList;
        for(let i=0; i<Array.length; i++){
            month = parseInt(Array[i].month);
            days = parseFloat(Array[i].days);
            changeList.splice(month-1,1,days);
        }
        return changeList;
    }

    const chartData = {
        chart: {
            labels: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
            datasets: {
                label: "연차", data: annual
                , label2: "병가", data2: sick
                , label3: "경조사", data3: cc
                , label4: "기타", data4: etc
            }
        },
    };

    return (
        {chartData}
    )
}

export default VacationBarChartData;
