import './AssetList.css';
import AS000 from '../../../assets/images/AS000.png';
import AS001 from '../../../assets/images/AS001.png';
import AS002 from '../../../assets/images/AS002.png';
import AS003 from '../../../assets/images/AS003.png';
import { useEffect, useMemo, useState } from 'react';
import AssetInfoModal from '../../../components/modal/AssetInfoModal';
import styled, {css} from 'styled-components';
import userAll from '../../../api/user/userAll';
import CommonCodeInfoReturn from '../../../components/commonCode/CommonCodeInfoReturn';
import Pagination from '../components/Pagination';
import listUpdate from '../../../atom/data/listUpdate';
import { useRecoilState } from 'recoil';
import { DebounceInput } from 'react-debounce-input';
import { Card, Grid, Typography } from '@mui/material';
import { customAxios } from '../../../axios/CustomAxios';
import { myIpAddress } from 'config';
import { useQuery } from 'react-query';
import { debounce } from 'lodash';


const categories = [
  {
    name: 'all',
    text: '전체보기'
  },
  {
    name: 'AS000',
    text: '노트북'
    },
    {
      name: 'AS001',
      text: '데스크탑'
    },
    {
      name: 'AS002',
      text: '모니터'
    },
    {
      name: 'AS003',
      text: '기타'
    }
  ];
  
  
  
  const CategoriesBlock = styled.div`
  display: flex;
  flex-wrap : wrap;
  padding: 1rem;
  width:100%;           
}
`;


const Category = styled.div`
font-size: 1.5rem;
font-weight : bolder;
cursor: pointer;
white-space: pre;
text-decoration: none;
    color: inherit;
    padding-bottom: 0.25rem;    
    &:hover {
      color: #495057;  
    }
    
    ${props =>
      props.active && css`
      font-weight: 600;
      border-bottom: 2px solid #22b8cf;
      color: #22b8cf;
      &:hover {
        color: #3bc9db;
      }
      `}
      
      & + & {
        margin-left: 1rem;
      }
      `;
      
      
      function AssetList({ category, modalChange, closeModal, openModal, onSelect }) {       
        const [ass_no, setAss_no] = useState('');
        const [keyword, setKeyword] = useState('');
        const [assetList, setAssetList] = useState([]);
        const [nameList, setNameList] = useState([]);
        const [mem_no, setMem_no] = useState(null);
        const [isUpdate, setIsUpdate] = useRecoilState(listUpdate);   
        
        
        const { data: items, isLoading, refetch } = useQuery('assets', async () => {
          const response = await customAxios.get(myIpAddress + '/asset');
          setAssetList(response.data)
          return response.data;
        });
        
        const filteredItems = useMemo(() => {
          if (!assetList) return [];    
          let results = assetList;
          
          if (category !== 'all') {
            results = results.filter((item) => item.ass_cate === category);
          } 
          if (mem_no !== null) {
            results = results.filter((item) => item.mem_no === mem_no);
          }
          if (keyword !== '') {
            results = results.filter((item) =>
            item.ass_sn.toLowerCase().includes(keyword.toLowerCase()) ||
            item.ass_name.toLowerCase().includes(keyword.toLowerCase())
            );
          }
          
          return results;
    }, [category, assetList, keyword, mem_no ]);    
 
    
    useEffect(() => {
      refetch().then(res=>{
        setAssetList(res.data)
      })
      setIsUpdate(false);
      
    }, [isUpdate, refetch]);
    
    useEffect(() => {
      setPage(1);      
      userAll().then((response) => {
        setNameList(response.data);
      });
    }, []);
    
    const handleAsset = (e) => {
      setAss_no(e);
      openModal();
    };
    
    const handleDebouncedInputChange = debounce((inputValue) => {
    }, 300); // 디바운스 간격
  
    const handleChange = (event) => {
      const inputValue = event.target.value;
      setKeyword(inputValue);
      handleDebouncedInputChange(inputValue);
    };

    // const onChange = (e) => {    
    //   console.log("onChange  , : " , e.target.value)
    //   setKeyword(e.target.value);
    // };           
    
    //페이징
      const [page, setPage] = useState(1);
      const [limit, setLimit] = useState(30);      
      const offset = (page - 1) * limit;
     
    return(
         <>     
       
       <AssetInfoModal
        modalChange={modalChange}
        closeModal={closeModal}
        ass_no={ass_no}       
        />
      
       <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          mt: 0,
          mx: -2,
          py: 1,
          px: 2,
        }}
      > 
        <Grid container spacing={1} textAlign="center" mb={2} ml={2}>
        <CategoriesBlock>
          {categories.map(c => (
            <Grid item lg={1.1} md={4.5} xs={3} mr={3}>
            <Category
              key={c.name}
              active={category === c.name}
              onClick={() => onSelect(c.name)}
            >
          {c.text}
        </Category>
        </Grid>
      ))}
        <Grid item lg={2} mr={2}>
         <input className="search" type="text"
                debounceTimeout={300}
                minLength={2}
                value={keyword} placeholder="&nbsp;모델명 혹은 시리얼 넘버를 입력하세요"
                onChange={handleChange} />
        </Grid>
        <Grid item lg={2} xs={1.5}>
        <select onChange={(e) => setMem_no(e.target.value === 'none' ? null : parseInt(e.target.value))} className="select-member">
        <option value="none"> - 사용자 -</option>    
        {nameList.map(nl => (        
        <option value={nl.memNo}>{nl.memName}&nbsp;<CommonCodeInfoReturn init={nl.memRank}/></option>
        ))}
        </select>        
        </Grid>        
        </CategoriesBlock>
        </Grid>
          <Grid container textAlign="center" spacing={1}>         
           {filteredItems && filteredItems.slice(offset, offset + limit).map((item, idx) => (
            <Grid item xs={3} sm={2} xl={1.2}>
            <div className="item" key={idx} onClick={() => handleAsset(item.ass_no)}
            >
            {item.ass_cate === "AS002" ? <img src={AS002} alt={"img" + item.ass_no} /> : 
            item.ass_cate === "AS000" ? <img src={AS000} alt={"img" + item.ass_no} /> :
            item.ass_cate === "AS001" ? <img src={AS001} alt={"img" + item.ass_no} /> : 
            <img src={AS003} alt={"img" + item.ass_no} /> }
            <Typography variant='h5'> {item.ass_name} </Typography>           
            </div>
            </Grid>
             ))}
            
             </Grid>
             </Card>
             <Pagination
        total={filteredItems.length}
        limit={limit}
        page={page}
        setPage={setPage}
        />     
        </>
       
    )

}

export default AssetList;