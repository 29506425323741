import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 복지포인트 기본 설정 업데이트
 * @param {*} body 
 * @returns 
 */

const wpAdminBasicPointUpdate =(body)=>{
    return customAxios.post(myIpAddress+'/wp/adminPointUpdate', body ); 
}
export default wpAdminBasicPointUpdate;
