/**
 * icon type별 구글 폰트
 * @type {{vacation: string[], datasets: [{color: string, data: number[], label: string},{color: string, data: number[], label: string}]}}
 */
const iconConfig = {
    approvalType:{
        APT000:"deck"
        , APT001:"credit_card"
        , APT002:"inventory_2"
        , APT003:"redeem"
    },
    vacationTypeIcon: {
        VT000: "luggage"
        , VT001: "vaccines"
        , VT002: "theater_comedy"
        , VT003: "more_horiz"
        , VT004: "wb_twilight"
        , VT005: "light_mode"
    },
    teamIcon : {
        T000:"computer",
        T001:"business_center",
        T002:"manage_accounts",
    }
}

export default iconConfig;
