import { Grid } from '@mui/material'
import SoftBadge from 'components/SoftBadge'
import { isEqual } from 'date-fns'
import { useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import { getSeatCountByReservationDate } from '../../../api/workPlace/WorkPlace'
import SoftTypography from '../../../components/SoftTypography'



const SeatCount = (props) =>{

    const {
        startDate,
        endDate,
        selectedDate,
        selectedPlace
    } = props;


    const searchData = useMemo(() => {
        if (selectedDate) {
            return {
                range: false,
                startDate: selectedDate,
                endDate: selectedDate,
                regionId: selectedPlace
            };
        } else {
            if(endDate){
                return {
                    range: !isEqual(startDate,endDate),
                    startDate: startDate ? startDate : new Date(),
                    endDate: endDate ? endDate : startDate,
                    regionId: selectedPlace
                };
            }
        }
    }, [selectedDate, startDate, endDate, selectedPlace]);


    // const {isLoading, isError, data} = useQuery(['getAllRegionInfo'], () => getAllRegionList(),{
    //     refetchOnWindowFocus:false
    // });

    
    const {isLoading, isError, data} = useQuery(['getSeatCountByReservationDate', searchData], () => getSeatCountByReservationDate(searchData),{
        refetchOnWindowFocus:false,
        refetchOnMount: false,
    });


    if(isError){
        return (
            <></>
        )
    }

    const getColor = (value) => {
        switch (value) {
            case 1:
                return "success";
            case 2:
                return "warning";
            default:
                return "dark";
        }
    }

    const getSeatCount = (data) => {
        // console.log(data.regionListByDate)
        return (
            data.regionListByDate.map((region ,index) =>(
                <>
                    <Grid container sx={{mb: index === 0 ? 1 : 0}} alignItems="center">
                        <SoftBadge container badgeContent={region.regName} size="lg" variant="contained"  
                                    color={getColor(region.regId)} />
                        <SoftTypography variant="h5" fontWeight="medium" sx={{ pl: 1 }}>{region.regMaxSeats}</SoftTypography>
                        <SoftTypography variant="h5" fontWeight="medium" sx={{ pl: 1 }}>좌석</SoftTypography>
                    </Grid>
                </>
            ))
        )
    }

    return(
        <>
            <Grid item xs={12} sx={{padding: "1rem 1rem 0rem 1rem"}}>
                <SoftTypography fontWeight="bold" variant="h4">좌석 현황</SoftTypography>
            </Grid>
            <Grid item xs={12} className="workPlace-content" alignItems="center">
                {
                    data  ? 
                        getSeatCount(data)
                        :
                    <>
                        <Grid container alignItems="center">
                            <SoftTypography variant="h5" fontWeight="medium">기간을 선택해 주세요</SoftTypography>
                        </Grid>
                    </>
                }
            </Grid>
        </>
    )
}
export default SeatCount;