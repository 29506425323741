import {Card, Grid, Typography} from "@mui/material";
import axios from "axios";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import {useEffect, useState} from "react";
import weatherDescKo from "./WeatherDescKo";

const API_KEY = '4bdfeecf6ee0232ed8499db812b213b8'; // OpenWeatherMap API Key

function Weather() {


    const [weather, setWeather] = useState(null);
    const [lat, setLat] = useState('');
    const [lon, setLon] = useState('');
    const [city, setCity] = useState('');

    const getCurrentLocation = () => {
        // 현재 위치 가져오기
        navigator.geolocation.getCurrentPosition((position) => {
            setLat(position.coords.latitude)
            setLon(position.coords.longitude)
        });
    };
    useEffect(() => {
        getCurrentLocation();
    }, []);


    useEffect(() => {
        const fetchWeatherByLocation = async () => {
            try {
                const cityName = await axios.get(
                    `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lon}&localityLanguage=ko`
                );
                setCity(cityName.data.locality);
                const response = await axios.get(
                    `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${API_KEY}&units=metric`
                );
                setWeather(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        if (lat && lon) {
            fetchWeatherByLocation();
        }
    }, [lat, lon]);

    if (!weather) return <SoftBox>
        <SoftBox mt={0.5} display="flex">
        <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
                     <span style={{color: "#91a1effa"}}>날씨 정보 불러오는중 ..</span>
            </SoftTypography>
        </SoftBox>
            </SoftBox>;

    const {main, weather: weatherData,} = weather;

    let iconurl = "http://openweathermap.org/img/w/" + weatherData[0].icon + ".png";

    return (
            <SoftBox>
                {/*<Card sx={{padding:3}}>*/}
                <SoftBox mt={0.5} display="flex">
                    <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        <span style={{color: "#91a1effa"}}>{city}</span>
                    </SoftTypography>
                    <img src={iconurl} style={{width: "25px"}}/>
                    <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        {main.temp.toFixed(1)}°C / {main.humidity} %
                    </SoftTypography>
                </SoftBox>
                {/*<SoftBox>*/}
                {/*    <Grid container alignItems="center" justifyContent="center">*/}
                {/*        <Grid mr={1}>*/}
                {/*            <img src={iconurl} style={{width: "80px"}}/>*/}
                {/*        </Grid>*/}
                {/*        <Grid mr={3}>*/}
                {/*            <SoftTypography>*/}
                {/*                {weatherDescKo[weatherData[0].id]}*/}
                {/*            </SoftTypography>*/}
                {/*        </Grid>*/}
                {/*        <Grid mr={3}>*/}
                {/*            <SoftTypography>*/}
                {/*                기온 : {main.temp.toFixed(1)}°C*/}
                {/*            </SoftTypography>*/}
                {/*        </Grid>*/}
                {/*        <Grid mr={3}>*/}
                {/*            <SoftTypography>*/}
                {/*                습도: {main.humidity}*/}
                {/*            </SoftTypography>*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*</SoftBox>*/}
                {/*</Card>*/}
            </SoftBox>
    );
}

export default Weather;



