import { Card, CircularProgress, Grid } from '@mui/material'
import SoftTypography from '../../../components/SoftTypography'
import { useQuery } from 'react-query'
import { getAllRegionList, getReservationInfo } from '../../../api/workPlace/WorkPlace'
import { useEffect, useState } from 'react'
import DateSelectItem from './DateSelectItem'
import { isMobile } from 'react-device-detect'


const PlaceItemComponent = (props) => {
    const {name, content} = props;
    
    return (
        <div className={`${content ? 'seat used' : 'seat'} ${isMobile ? 'mobile' : ''}`}>
            <p className={isMobile ? "userName-mobile" : "userName"}>
                {content}
            </p>
        </div>
    )
}
const WorkPlaceContent = ({reservationInfo,selectDate, setSelectDate}) =>{
    const {isLoading, isError, data} = useQuery(['getAllRegionInfo'], () => getAllRegionList(),{
        refetchOnWindowFocus:false
    });

    if(isError){
        return (
            <></>
        )
    }

    const getLoadReservationItem = (region) => {
        const reservationInfoMap = new Map(Object.entries(reservationInfo.reservationsListsByRegion))
        const regionReservations = reservationInfoMap.get(''+region.regId);
        const emptyPlaceCount = region.regMaxSeats - (regionReservations?.length?regionReservations?.length:0)
        const emptyPlace = Array.from({ length: emptyPlaceCount }, (_, index) => index + 1).map(number => (
            <PlaceItemComponent key={`empty_${number}`}/>
        ));
        return(
            <>
                {
                    regionReservations?.map(reservation => (
                        <PlaceItemComponent key={reservation.reservationId} content={reservation.user.name}/>
                    ))

                }
                {emptyPlace}
            </>
        )
    }

    return(
        <>
            <Card sx={{ mt: 2, py: 2, px: 2}}>
                <Grid container flexWrap="nowrap" justifyContent="center">
                    <Grid item >
                        <DateSelectItem selectDate={selectDate} setSelectDate={setSelectDate}/>
                    </Grid>
                </Grid>
                <Grid container flexWrap="nowrap">
                    {
                        isLoading?<></>:data.map(region => (
                            <Grid item xs={12} md={12} lg={12} key={region.regId}>
                                <SoftTypography fontWeight="bold" textAlign="center" pb={1}>{region.regName}</SoftTypography>
                                <Grid container justifyContent="center">
                                    <Grid item xs={8} md={4}>
                                        <div style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            justifyContent: 'space-between',
                                        }}>
                                            {
                                                getLoadReservationItem(region)
                                            }
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
            </Card>
        </>
    )
}
export default WorkPlaceContent;