// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import Card from "@mui/material/Card";
import ApproverReferrer from "layouts/vacationInsert/components/ApproverReferrer";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import SoftButton from "../../components/SoftButton";
import "../../styles/Cost/CostSt.css";
import CostDetailContent from "./components/CostDetailContent";

const CostInsertHeader = () => {

    // navigate
    const navigate = useNavigate();
    let currentDate = new Date();
    let month = currentDate.getMonth()+1;
    let day = currentDate.getDate();
    let displayMonth;

    if (day < 11){
        if (month == 1) {
            displayMonth = 12;
        } else {
            displayMonth = month-1;
        }
    } else {
        displayMonth = month;
    }

     //목록버튼
     const mainbutton = () => {
        Swal.fire({
            title: '작성하던 내용은 전부 삭제됩니다',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '뒤로가기',
            cancelButtonText: '취소',
            backdrop: false
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/cost')
            }
        })
    }

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
                <Card sx={{ height: "100%", marginBottom: "1rem" }}>
                    <SoftBox mt={1} mb={1} p={1} display="flex" justifyContent="space-between" alignItems="center">
                        <SoftTypography variant="h4" fontWeight="medium" sx={{ fontWeight: 'bold' }}>
                            {displayMonth}월 비용처리 결재 문서
                        </SoftTypography>
                        <SoftButton variant="gradient" color="dark" onClick={mainbutton}>
                            <Icon sx={{ fontWeight: "bold" }}>arrow_back</Icon>
                            &nbsp;뒤로가기
                        </SoftButton>
                    </SoftBox>
                </Card>
            </Grid>
        </Grid>
    )
}

function CostInsert() {

    const { state } = useLocation();

    // 하위 컴포넌트에서 아톰패밀리 접근 키값
    let approverKey = "approver_vac";
    let referrerKey = "referrer_vac";
    
    if (state.state === undefined) {
        approverKey = "approver_vac";
        referrerKey = "referrer_vac";
    } else {
        approverKey = "approverEditKey_" + state?.state
        referrerKey = "referrerEditKey_" + state?.state
    }

    //모달
    const [showModal, setShowModal] = useState(false)
    const [modalMent, setModalMent] = useState("")

    
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {showModal && (
                        <Card sx={{ height: "100%" }}>
                            <Grid container p={2}>
                                <SoftTypography variant="h6" textTransform="capitalize" pl={2} dangerouslySetInnerHTML={{ __html: modalMent }}>
                                </SoftTypography>
                            </Grid>
                        </Card>
                    )}
                </Grid>
            </Grid>
            <SoftBox py={3}>
                {/* 비용처리 제목 및 뒤로가기 버튼 */}
                <CostInsertHeader />

                <SoftBox mb={2}>
                    <ApproverReferrer approverKey={approverKey} referrerKey={referrerKey} />
                </SoftBox>
                
                {/* 비용처리 내용 */}
                <CostDetailContent setShowModal={setShowModal} setModalMent={setModalMent}/>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

export default CostInsert;
