import RemoveIcon from '@mui/icons-material/RemoveCircle'
import { IconButton, TableCell, TableRow } from '@mui/material'
import SoftTypography from 'components/SoftTypography'
import UploadButtons from 'components/button/UploadButtons'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import NumberInput from '../../../components/inputBox/NumberInput'
import Month from '../../../components/month/Month'
import AmountFormatInput from '../../../components/textField/AmountFormatInput'
import ClassificationList from './ClassificationList'
import InputType from './InputType'

const FuelCostList = (props) => {

    const {
        rowindex, 
        removeDetailRow, 
        item, 
        _onDragOver, 
        _onDragStart, 
        _onDragEnd, 
        _onDrop, 
        _onDragLeave,
        fuelCostListMap,
        setFuelCostListMap,
        sumAmount    
    } = props;


    const removeRow = () => {
        Swal.fire({
            title: '삭제 하시겠습니까?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '삭제',
            cancelButtonText: '취소',
            backdrop: false
        }).then((result) => {
            if (result.isConfirmed) {
                removeDetailRow(rowindex)
                Swal.fire({
                    title: '삭제 성공',
                    icon: 'success',
                    backdrop: false
                })
            }
        })
    }


    
    //날짜 받아오기
    const [date, setDate] = useState(item.fuel_date);
    //용도구분
    const [fuelCategory, setFuelCategory] = useState(item.fuel_category)
    //차량번호
    const [fuelCarnum, setFuelCarnum] = useState(item.fuel_carnum)
    //출발지
    const [fuelStart, setFuelStart] = useState(item.fuel_start)
    //도착지
    const [fuelEnd, setFuelEnd] = useState(item.fuel_end)
    //운행거리
    const [fuelDistance, setFuelDistance] = useState(item.fuel_distance)
    //영수증 이미지
    const costFileNo = item.cost_fno ? item.cost_fno : 0;
    const [costFilename, setCostFilename] = useState(item.cost_filename)
    const [costFile, setCostFile] = useState(null)
    const [costFilenameReal, setCostFilenameReal] = useState(item.cost_filename_real)
    //금액
    const [fuelAmount, setFuelAmount] = useState(item.fuel_amount)
    //결제수단
    const [fuelPaytype, setFuelPaytype] = useState(item.fuel_paytype)
    // 총 금액
    // const setSumAmount = useSetRecoilState(TotalAmount);



    // const amountSum = () => {
    //     let total = 0;
    //     fuelCostListMap.forEach((value, key) => {
    //         const fuelAmount = value.fuel_amount;
    //         if (fuelAmount === "" || fuelAmount === null) {
    //             total += 0;
    //         } else {
    //             total += parseInt(fuelAmount);
    //         }
    //     });
    //     setSumAmount(total)
    // }


    useEffect(() => {
        fuelCostListMap.set(rowindex, {
            fuel_cost_detail: rowindex,
            fuel_date: date,
            fuel_category: fuelCategory,
            fuel_carnum: fuelCarnum,
            fuel_start: fuelStart,
            fuel_end: fuelEnd,
            fuel_distance: fuelDistance,
            cost_fno: costFileNo,
            cost_filename: costFilename,
            cost_file: costFile,
            cost_filename_real: costFilenameReal,
            fuel_amount: fuelAmount,
            fuel_paytype: fuelPaytype
        })
    }, [date, fuelCategory, fuelCarnum, fuelStart, fuelEnd, fuelDistance, costFilename, fuelPaytype])



    useEffect(() => {
        fuelCostListMap.set(rowindex, {
            fuel_cost_detail: rowindex,
            fuel_date: date,
            fuel_category: fuelCategory,
            fuel_carnum: fuelCarnum,
            fuel_start: fuelStart,
            fuel_end: fuelEnd,
            fuel_distance: fuelDistance,
            cost_fno: costFileNo,
            cost_filename: costFilename,
            cost_file: costFile,
            cost_filename_real: costFilenameReal,
            fuel_amount: fuelAmount,
            fuel_paytype: fuelPaytype
        })
        sumAmount();
    }, [fuelAmount])

    return (
        <>
            {/* 2023-06-08  드래그앤드롭버튼 추가 및 TableCell css 수정 */}
            {/* 2024-04-24  쿼리로 date별로 정렬 되기도하고 오류나서 주석 처리 */}
            <TableRow key={rowindex} sx={{ width: "100%" }} 
                // onDragOver={_onDragOver} 
                // onDragStart={(e) => { _onDragStart(e, item) }} 
                // onDrop={(e) => { _onDrop(e, item) }}
                >
                {/* <TableCell sx={{ textAlign: "right",  cursor: "pointer" , lineHeight : 0 , paddingY :0.5}}>
                    <SoftTypography draggable>::</SoftTypography>
                </TableCell> */}
                <TableCell sx={{ width: "max-content", textAlign: "-webkit-right", paddingLeft: "5px", paddingRight: "5px" , lineHeight : 0 , paddingY :0.5}}>
                    {/* 2023-06-02  costType="cost" 추가 */}
                    <Month type='date' num='10' name='일자' setDate={setDate} temp={date} costType="cost"/>
                </TableCell>
                <TableCell sx={{ textAlign: "-webkit-right", paddingLeft: "5px", paddingRight: "5px" , lineHeight : 0 , paddingY :0.5}}>
                    <InputType type='text' value={setFuelCategory} temp={fuelCategory} />
                </TableCell>
                <TableCell sx={{ textAlign: "-webkit-right", paddingLeft: "5px", paddingRight: "5px" , lineHeight : 0 , paddingY :0.5}}>
                    <InputType type='text' value={setFuelCarnum} temp={fuelCarnum} />
                </TableCell>
                <TableCell sx={{ paddingLeft: "5px", paddingRight: "5px" , lineHeight : 0 , paddingY :0.5}}>
                    <InputType type='text' value={setFuelStart} temp={fuelStart} />
                </TableCell>
                <TableCell sx={{ paddingLeft: "5px", paddingRight: "5px" , lineHeight : 0 , paddingY :0.5}}>
                    <InputType type='text' value={setFuelEnd} temp={fuelEnd} />
                </TableCell>
                <TableCell sx={{ paddingLeft: "5px", paddingRight: "5px" , lineHeight : 0 , paddingY :0.5}}>
                    {/* <NumberInput endMent='km' value={setFuelDistance} temp={fuelDistance} end='end'/> */}
                    <AmountFormatInput
                        label="거리"
                        size="Normal"
                        value={fuelDistance}
                        setValue={setFuelDistance}
                        unit="km"
                        width="110px"
                    />
                </TableCell>
                <TableCell sx={{ paddingX : "0px" }}>
					<UploadButtons 
						key={rowindex+"filename"} 
						theme = "icon"
						value = {costFilename}
						setValue = {setCostFilename}
						setFile = {setCostFile}
					/> 	
				</TableCell>
                <TableCell sx={{ paddingLeft: "5px", paddingRight: "5px" , lineHeight : 0 , paddingY :0.5}}>
                    <NumberInput endMent='attach_money' value={setFuelAmount} temp={fuelAmount} />
                </TableCell>
                <TableCell sx={{ paddingLeft: "5px", paddingRight: "5px" , lineHeight : 0 , paddingY :0.5}}>
                    <ClassificationList setClassification={setFuelPaytype} temp={fuelPaytype} category="PAY_METHOD" />
                </TableCell>
                <TableCell sx={{ paddingLeft: "5px", paddingRight: "5px" , lineHeight : 0 , paddingY :0.5}}>
                    <IconButton aria-label="delete" onClick={removeRow}>
                        <RemoveIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        </>
    )
}

export default FuelCostList