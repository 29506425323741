import {atom} from "recoil";

/**
 * 복지포인트 리스트 페이지 body 
 * @type { RecoilState<{key : value}> }
 */


export const wpListSearch = atom({
    key: "wpListSearch",
    default: {
        mem_no : 0
        , page : 1
        , pageSize : 10
	}
});
