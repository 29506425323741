import { Grid, Table as MuiTable, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import borders from 'assets/theme/base/borders';
import colors from "assets/theme/base/colors";
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import AmountFormatInput from 'components/textField/AmountFormatInput';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { v4 as uuidv4 } from "uuid";
import { CostSave, TotalAmount, TransportationCostDetail, TransportationCostDetailList } from '../../../atom/cost/CostWriteDetail';
import TableColumns from './TableColumns';
import TransportationCostList from './TransportationCostList';

const TransportationCost = (props) => {

    /** 교통비 row 값 (행 갯수)- */
    const [costWriteDetailRowNum, setCostWriteDetailRowNum] = useRecoilState(TransportationCostDetail);
    // 교통비 상세내용 리스트
    const [transportationCostList, setTransportationCostList] = useRecoilState(TransportationCostDetailList);
    // 교통비 상세내용 Map
    const [transportationCostMap, setTransportationCostMap] = useState(new Map);
    // 금액 합계
    // const sumAmount = useRecoilValue(TotalAmount);
    const [totalAmount, setTotalAmount] = useState(0);
    // 저장
    const costSave = useRecoilValue(CostSave);


    let [delay, setdelay] = useState(false);
    useEffect(() => {
        let timer = setTimeout(() => { setdelay(true) }, 50);
    })


    useEffect(() => {
        const newMap = new Map(transportationCostList.map(item => [item.employee_benefit_detail, { ...item }]));
        setTransportationCostMap(newMap);
    }, []); 


    // 저장 
    useEffect(()=>{
        if(costSave) {
            const updatedList = Array.from(transportationCostMap.values());
            setTransportationCostList(updatedList);
            // console.log("EmployeeBenefit costSave :: ", updatedList)  
        }
    },[costSave])

    

    const AddCostWriteDetailRow = () => {
        const newMap = new Map(transportationCostMap);
        // map의 맨 마지막 key 값 이후에 생성되어야 해서 
        const maxKey = Math.max(...transportationCostMap.keys()) + 1;
        // 새로운 항목 추가
        newMap.set(maxKey, {
            employee_benefit_detail: maxKey,
            other_date: "",
            other_category: "",
            other_contents: "",
            other_personnel: "",
            cost_filename: "",
            cost_file: "",
            other_amount: "",
            other_paytype: "",
            other_fueldo: ""
        });
        setCostWriteDetailRowNum(newMap.size)
        setTransportationCostMap(newMap)
        // console.log("바꾼 맵 값 : ", newMap);
    }


    // row 삭제 
    const removeDetailRow = (rowindex) => {
        // const newRestailRow = new Map([...transportationCostMap].filter(([key, value]) => value.employee_benefit_detail !== rowindex));
        // setTransportationCostMap(newRestailRow)
        const newTransportationCostMap = new Map(transportationCostMap);
        newTransportationCostMap.forEach((value, key) => {
            if (value.employee_benefit_detail === rowindex) {
                newTransportationCostMap.delete(key);
            }
        });
        setTransportationCostMap(newTransportationCostMap);
    }


    useEffect(()=> {
        sumAmount()
    },[transportationCostMap])

    const sumAmount = () => {
        let total = 0;
        transportationCostMap.forEach((value, key) => {
            const otherAmount = value.other_amount;
            if (otherAmount === "" || otherAmount === null) {
                total += 0;
            } else {
                total += parseInt(otherAmount);
            }
        });
        setTotalAmount(total)
    }



    const transcolumns = [
        // { key: "grab", name: '', align: "center",  width: "30px" },
        { key: "trans_date", name: '지출일', align: "center", color: "warning", icon: "event_available", width: "150px" },
        { key: "trans_category", name: '용도', align: "center", color: "success", icon: "fact_check", width: "160px" },
        { key: "trans_content", name: '내용', align: "center", color: "info", icon: "notes", min_width: "200px" },
        { key: "trans_person", name: '인원', align: "center", color: "secondary", icon: "person", width: "130px", min_width: "130px" },
        { key: "cost_filename", name: '영수증', align: "center", color: "secondary", icon: "insert_photo", width: "130px", min_width: "130px" },
        { key: "trans_amount", name: '금액', align: "center", color: "error", icon: "paid", width: "180px", min_width: "180px" },
        { key: "trans_payway", name: '결제수단', align: "center", color: "primary", icon: "payment", width: "150px" },
        { key: "btn", name: "추가", align: "center", color: "dark", icon: "payment", width: "30px" }
    ]

    const { light } = colors;
    const { borderWidth } = borders;


    const renderColumns = <TableColumns column={transcolumns} add={AddCostWriteDetailRow} />


    //드래그를할 애를 잡았을때 인덱스값 저장용
    const [grab, setGrab] = useState(null)

    //드래그 할때
    const _onDragOver = e => {
        e.preventDefault();
    }

    //드래그 시작
    const _onDragStart = (e, item) => {
        const index = Array.from(transportationCostMap.values())
                        .findIndex(detail => detail.employee_benefit_detail === item.employee_benefit_detail);
        if (index !== -1) {
            setGrab(index);
        }
    }

    //드래그한거를 놨을때
    // 2023-06-08 드래그앤드롭 되는 위치 수정
    const _onDrop = (e, item) => {
        // let targetPosition = Number(transportationCostList.indexOf(item));
        // const grabPosition = Number(grab);
        // let targetPosition = 0;
        // const list = [...Array.from(transportationCostMap.values())];
        // targetPosition = list.findIndex(detail => detail.employee_benefit_detail === item.employee_benefit_detail);
        
        // // -1 아닐 때만 Map 재배치
        // if(targetPosition !== -1){
        //     let _list = [...Array.from(transportationCostMap.values())];
        //     // 드롭 대상 위치가 더 뒤에 있는 경우에만 요소 위치를 변경
        //     if (grabPosition < targetPosition) {
        //         _list.splice(targetPosition + 1, 0, _list[grabPosition]);
        //         _list.splice(grabPosition, 1);
        //     } else {
        //         _list.splice(targetPosition, 0, _list[grabPosition]);
        //         _list.splice(grabPosition + 1, 1);
        //     }
        //     const newMap = new Map();
        //     _list.forEach((value, index) => {
        //         newMap.set(index, value);
        //     });
        //     setTransportationCostMap(newMap);
        // }
        e.preventDefault();
        const grabPosition = Number(grab);
        const list = Array.from(transportationCostMap.values());
        const targetPosition = list.findIndex(detail => detail.employee_benefit_detail === item.employee_benefit_detail);

        // -1 아닐 때만 Map 재배치
        if (targetPosition !== -1) {
            const newList = [...list];
            const movedItem = newList.splice(grabPosition, 1)[0];
            newList.splice(targetPosition, 0, movedItem);

            transportationCostMap.clear();
            newList.forEach((value, index) => {
                transportationCostMap.set(index, value);
            });
            setTransportationCostMap(new Map(transportationCostMap));
        }
    }



    return (
        <>
            <TableContainer>
                <MuiTable>
                    <SoftBox component="thead">
                        <TableRow>{renderColumns}</TableRow>
                    </SoftBox>
                    <TableBody>
                        {delay === true ?
                            Array.from(transportationCostMap.values()).map((item) => (
                                <TransportationCostList
                                    key={item.employee_benefit_detail}
                                    rowindex={item.employee_benefit_detail}
                                    removeDetailRow={removeDetailRow}
                                    item={item} 
                                    transportationCostMap={transportationCostMap}
                                    setTransportationCostMap={setTransportationCostMap}
                                    _onDragOver={_onDragOver}
                                    _onDragStart={_onDragStart} 
                                    _onDrop={_onDrop} 
                                    sumAmount={sumAmount}
                                />
                            )) : null
                        }
                        <TableRow key={uuidv4()} bgColor="#b2dfdb" >
                            <TableCell colSpan={9} key={uuidv4()} style={{ borderTop: `${borderWidth[1]} solid ${light.main}` }}>
                                <Grid container justifyContent="flex-end" alignItems="center">
                                    <SoftBox pr={1}  >
                                        <SoftTypography
                                            variant="h4"
                                            color="white"
                                            fontWeight="bold"
                                            sx={{ display: "inline-block", width: "max-content", }}>
                                            합계
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox>
                                        <AmountFormatInput
                                            label="합계"
                                            theme="sum"
                                            size="Normal"
                                            value={totalAmount}
                                            unit="원"
                                        />
                                    </SoftBox>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </MuiTable>
            </TableContainer>
        </>
    )
}

export default TransportationCost