import React, { useEffect, useState } from 'react'
import {PDFViewer, Document, Page, Text, View, StyleSheet, Image} from '@react-pdf/renderer';
import { useQueries } from 'react-query';
import Swal from 'sweetalert2';
import allCommonCodeMap from 'api/commonCode/allCommonCodeMap';
import userWorkInfo from 'api/user/userWorkInfo';
import { useNavigate } from 'react-router-dom';
import monthlyVacationApprovalDocList from 'api/vacation/monthlyVacationApprovalDocList';
import { DiffTwoDate, TimestampToYMD } from 'utils/DateUtils';

const AllVacationPDFViewer = ({year, month}) => {

    const navigate = useNavigate();

    const styles = StyleSheet.create({
        page: {
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
        },
        section: {
            border: '1.5pt solid black',
            padding: 20,
            margin: 10,
            flexGrow: 1,
        },
        table: {
            display: "table",
            width: "auto",
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row",
        },
        tableCellHeader: {
            marginTop: 5,
            marginBottom: 5,
            fontSize: 15,
            textAlign: "center",
            fontFamily: "godic"
        },
        tableCellContent: {
            marginTop: 5,
            marginBottom: 5,
            fontSize: 12,
            textAlign: "center",
            fontFamily: "godic"
        },
    });

    const queries = useQueries([
        // queries[0] : 특정 달의 결재된 휴가 리스트
        {
            queryKey: ["monthlyVacationApprovalDocList"],
            queryFn: () => monthlyVacationApprovalDocList(year, month),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            },
            onError: (data) => {
                Swal.fire({
                    title: '잘못된 접근입니다.',
                    icon: 'warning',
                    backdrop: true
                })
                    .then(() => {
                        navigate("/")
                    });
            }
        },
        // queries[1] : 공용코드 리스트
        {
            queryKey: ["allCommonCodeMap"],
            queryFn: () => allCommonCodeMap(),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            }
        },
        // queries[2] : 유저 리스트
        {
            queryKey: ["userWorkInfo"],
            queryFn: () => userWorkInfo(),
            options: {
                staleTime: 120000,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            }
        }
    ])

    // 휴가 정보
    let startDate; // 휴가 시작일
    let endDate; // 휴가 종료일
    let tel; // 연락처
    let days; // 총 휴가 일 수
    let type; // 휴가 타입
    let approvalStatusCode; // 결재상태 코드
    let name; // 이름
    let team; // 소속부서
    let rank; // 직급
    let signature; // 결재요청자 서명 이미지
    let reason; // 휴가 사유
    let regDateYMD; // 문서 상신일 연월일
    let approverList; // 결재자 리스트
    let codeMap; // 공용코드 리스트
    let viewList; // 문서 정보
    let fileName; // 파일이름
    let memNo; // 멤버번호

    let title = `휴가신청서[${month}월]`;

    const pdfPages = [];

    if (queries[0].isSuccess && queries[1].isSuccess && queries[2].isSuccess) {
        // 휴가 리스트 : map 수정
        viewList = queries[0].data.data;
        // 공용코드 리스트
        codeMap = queries[1].data.data;
        // 유저 리스트
        const userInfo = queries[2].data.data;

        viewList.map((view) => {
            memNo = view["mem_no"];
            type = codeMap[view["vac_category"]]["info"];
            startDate = view["vac_start"];
            endDate = view["vac_end"];
            days = view["vac_days"];
            tel = view["vac_tel"];
            approvalStatusCode = view["vac_approved"];
            reason = view["vac_reason"];
            regDateYMD = TimestampToYMD(view["vac_regdate"]);
            for (let i = 0; i < userInfo.length; i++) {
                if (memNo === parseInt(userInfo[i]["memNo"])) {
                    name = userInfo[i]["memName"];
                    team = userInfo[i]["memTeam"];
                    rank = userInfo[i]["memRank"];
                    signature = window.atob(userInfo[i]["signature"]);
                    break;
                }
            }
            approverList = view["approverList"];
            // fileName = "[휴가]" + startDate + "~" + endDate + "_" + name + ".pdf";

            pdfPages.push({
                memNo, type, startDate, endDate, days, tel, approvalStatusCode, reason, regDateYMD,
                name, team, rank, signature, approverList
            })
        })
    }

    function PDFDocView() {
        return pdfPages.map((PDFPage, index) => (
            <Page key={index} size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text style={{
                        paddingTop: 30,
                        paddingBottom: 50,
                        fontFamily: 'godic',
                        fontSize: "20px",
                        textAlign: "center"
                    }}>
                        휴 가 신 청 서
                    </Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={{width: "100%", flexDirection: "row"}}>
                                <View style={{
                                    width: "30%",
                                }}>
                                </View>
                                <View style={{
                                    width: "40%",
                                }}>
                                </View>
                                {/* 결재 */}
                                {/* 무결재 일때*/}
                                {PDFPage.approvalStatusCode === "AP000" ? (
                                    <></>
                                ) : (
                                    <>
                                        <View style={{
                                            width: "20%",
                                            borderStyle: "solid",
                                            borderColor: "#000",
                                            borderWidth: 1,
                                            borderRightWidth: 1,
                                            borderLeftWidth: 1,
                                            borderBottomWidth: 0,
                                        }}>
                                            <Text style={styles.tableCellContent}>결 재</Text>
                                        </View>
                                        <View style={{
                                            width: "70%",
                                            borderStyle: "solid",
                                            borderColor: "#000",
                                            borderWidth: 1,
                                            borderLeftWidth: 0,
                                            borderBottomWidth: 0,
                                        }}>
                                            <View style={styles.tableRow}>
                                                {PDFPage.approverList?.map((item, index) => (
                                                    index === PDFPage.approverList.length - 1 ?
                                                        <View style={{
                                                            width: `${100 / PDFPage.approverList.length}%`,
                                                            borderStyle: "solid",
                                                            borderColor: "#000",
                                                            borderWidth: 0,
                                                            borderBottomWidth: 1,
                                                        }}>
                                                            <Text
                                                                style={styles.tableCellContent}>{codeMap[PDFPage.approverList[index]["approver_rank"]].info}</Text>
                                                        </View>
                                                        :
                                                        <View style={{
                                                            width: `${100 / PDFPage.approverList.length}%`,
                                                            borderStyle: "solid",
                                                            borderColor: "#000",
                                                            borderRightWidth: 1,
                                                            borderBottomWidth: 1,
                                                        }}>
                                                            <Text style={styles.tableCellContent}>
                                                                {codeMap[PDFPage.approverList[index]["approver_rank"]].info}
                                                            </Text>
                                                        </View>
                                                ))}
                                            </View>
                                            <View style={styles.tableRow}>
                                                {PDFPage.approverList?.map((item, index) => (
                                                    index === PDFPage.approverList.length - 1 ?
                                                        <View style={{
                                                            width: `${100 / PDFPage.approverList.length}%`,
                                                            margin: "auto",
                                                            height: "7vh",
                                                            borderStyle: "solid",
                                                            borderColor: "#000",
                                                            borderWidth: 0

                                                        }}>
                                                            <Image src={PDFPage.approverList[index]?.approver_signature}
                                                                    style={{
                                                                        margin: "auto",
                                                                        width: "40%",
                                                                        height: "90%"
                                                                    }}/>
                                                        </View>
                                                        : <View style={{
                                                            width: `${100 / PDFPage.approverList.length}%`,
                                                            margin: "auto",
                                                            height: "7vh",
                                                            borderStyle: "solid",
                                                            borderColor: "#000",
                                                            borderRightWidth: 1
                                                        }}>
                                                            <Image src={PDFPage.approverList[index]?.approver_signature}
                                                                    style={{
                                                                        margin: "auto",
                                                                        width: "90%",
                                                                        height: "90%"
                                                                    }}/>
                                                        </View>
                                                ))}
                                            </View>
                                        </View>
                                    </>
                                )}
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={{width: "100%", margin: "auto", flexDirection: "row"}}>
                                <View style={{
                                    width: "100%",
                                    borderStyle: "solid",
                                    borderColor: "#000",
                                    borderWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 0,
                                }}>
                                    <Text style={styles.tableCellHeader}>신 청 내 역</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={{width: "100%", margin: "auto", flexDirection: "row"}}>
                                <View style={{
                                    width: "25%",
                                    borderStyle: "solid",
                                    borderColor: "#000",
                                    borderWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 0,
                                }}>
                                    <Text style={styles.tableCellContent}>소속</Text>
                                </View>
                                <View style={{
                                    width: "50%",
                                    borderStyle: "solid",
                                    borderColor: "#000",
                                    borderWidth: 1,
                                    borderRightWidth: 1,
                                    borderLeftWidth: 0,
                                    borderBottomWidth: 0,
                                }}>
                                    <Text style={styles.tableCellContent}>{codeMap[PDFPage.team]?.info}</Text>
                                </View>
                                <View style={{
                                    width: "25%",
                                    borderStyle: "solid",
                                    borderColor: "#000",
                                    borderWidth: 1,
                                    borderRightWidth: 1,
                                    borderLeftWidth: 0,
                                    borderBottomWidth: 0,
                                }}>
                                    <Text style={styles.tableCellContent}>직급</Text>
                                </View>
                                <View style={{
                                    width: "50%",
                                    borderStyle: "solid",
                                    borderColor: "#000",
                                    borderWidth: 1,
                                    borderLeftWidth: 0,
                                    borderBottomWidth: 0,
                                }}>
                                    <Text style={styles.tableCellContent}>{codeMap[PDFPage.rank]?.info}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={{width: "100%", margin: "auto", flexDirection: "row"}}>
                                <View style={{
                                    width: "25%",
                                    borderStyle: "solid",
                                    borderColor: "#000",
                                    borderWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 0,
                                }}>
                                    <Text style={styles.tableCellContent}>연락처</Text>
                                </View>
                                <View style={{
                                    width: "50%",
                                    borderStyle: "solid",
                                    borderColor: "#000",
                                    borderWidth: 1,
                                    borderRightWidth: 1,
                                    borderLeftWidth: 0,
                                    borderBottomWidth: 0,
                                }}>
                                    <Text style={styles.tableCellContent}>{PDFPage.tel}</Text>
                                </View>
                                <View style={{
                                    width: "25%",
                                    borderStyle: "solid",
                                    borderColor: "#000",
                                    borderWidth: 1,
                                    borderRightWidth: 1,
                                    borderLeftWidth: 0,
                                    borderBottomWidth: 0,
                                }}>
                                    <Text style={styles.tableCellContent}>성명</Text>
                                </View>
                                <View style={{
                                    width: "50%",
                                    borderStyle: "solid",
                                    borderColor: "#000",
                                    borderWidth: 1,
                                    borderLeftWidth: 0,
                                    borderBottomWidth: 0,
                                }}>
                                    <Text style={styles.tableCellContent}>{PDFPage.name}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={{width: "100%", margin: "auto", flexDirection: "row"}}>
                                <View style={{
                                    width: "20%",
                                    borderStyle: "solid",
                                    borderColor: "#000",
                                    borderWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 0,
                                }}>
                                    <Text style={styles.tableCellContent}>휴가구분</Text>
                                </View>
                                <View style={{
                                    width: "100%",
                                    borderStyle: "solid",
                                    borderColor: "#000",
                                    borderWidth: 1,
                                    borderRightWidth: 1,
                                    borderLeftWidth: 0,
                                    borderBottomWidth: 0,
                                }}>
                                    <Text style={styles.tableCellContent}>{PDFPage.type}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={{width: "100%", margin: "auto", flexDirection: "row"}}>
                                <View style={{
                                    width: "20%",
                                    borderStyle: "solid",
                                    borderColor: "#000",
                                    borderWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 0,
                                }}>
                                    <Text style={styles.tableCellContent}>휴가기간</Text>
                                </View>
                                <View style={{
                                    width: "100%",
                                    borderStyle: "solid",
                                    borderColor: "#000",
                                    borderWidth: 1,
                                    borderRightWidth: 1,
                                    borderLeftWidth: 0,
                                    borderBottomWidth: 0,
                                }}>
                                    <Text
                                        style={styles.tableCellContent}>{PDFPage.startDate} ~ {PDFPage.endDate} ({DiffTwoDate(PDFPage.startDate, PDFPage.endDate)}일)</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={{width: "100%", margin: "auto", flexDirection: "row"}}>
                                <View style={{
                                    width: "20%",
                                    borderStyle: "solid",
                                    borderColor: "#000",
                                    borderWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 0,
                                }}>
                                    <Text style={styles.tableCellContent}>사용 휴가일</Text>
                                </View>
                                <View style={{
                                    width: "100%",
                                    borderStyle: "solid",
                                    borderColor: "#000",
                                    borderWidth: 1,
                                    borderRightWidth: 1,
                                    borderLeftWidth: 0,
                                    borderBottomWidth: 0,
                                }}>
                                    <Text style={styles.tableCellContent}>{PDFPage.days}일</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={{width: "100%", margin: "auto", flexDirection: "row"}}>
                                <View style={{
                                    width: "20%",
                                    borderStyle: "solid",
                                    borderColor: "#000",
                                    borderWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 0,
                                }}>
                                    <Text style={styles.tableCellContent}>휴가사유</Text>
                                </View>
                                <View style={{
                                    width: "100%",
                                    borderStyle: "solid",
                                    borderColor: "#000",
                                    borderWidth: 1,
                                    borderRightWidth: 1,
                                    borderLeftWidth: 0,
                                    borderBottomWidth: 0,
                                }}>
                                    <Text style={styles.tableCellContent}>{PDFPage.reason}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={{width: "100%", margin: "auto", flexDirection: "row"}}>
                                <View style={{
                                    width: "100%",
                                    borderStyle: "solid",
                                    borderColor: "#000",
                                    borderWidth: 1,
                                    borderBottomWidth: 1,
                                }}>
                                    <Text style={{
                                        marginTop: 50,
                                        marginBottom: 5,
                                        fontSize: 12,
                                        textAlign: "center",
                                        fontFamily: "godic"
                                    }}>위와 같이 휴가를 신청하오니 허가하여 주시기 바랍니다.</Text>
                                    <Text style={{
                                        marginTop: 40,
                                        marginBottom: 35,
                                        fontSize: 12,
                                        textAlign: "center",
                                        fontFamily: "godic"
                                    }}>
                                        {PDFPage.regDateYMD["year"]}년 {PDFPage.regDateYMD["month"]}월 {PDFPage.regDateYMD["day"]}일
                                        </Text>
                                    <View style={styles.tableRow}>
                                        <Text style={{
                                            marginTop: 10,
                                            marginBottom: 10,
                                            fontSize: 12,
                                            textAlign: "center",
                                            fontFamily: "godic"
                                        }}>신청자 성명 : {PDFPage.name}  </Text>
                                        <Image src={PDFPage.signature} style={{margin: "auto", width: 30, height: 30}}/>
                                    </View>
                                    <Text style={{
                                        marginTop: 40,
                                        marginBottom: 35,
                                        fontSize: 12,
                                        textAlign: "center",
                                        fontFamily: "godic"
                                    }}>나인원소프트주식회사</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        ));
    }

  return (
    <PDFViewer style={{fontSize: "20px", width: "70%", height: "90%"}}>
        <Document title={title}>
            <PDFDocView />
        </Document>
    </PDFViewer>
  )
}

export default AllVacationPDFViewer

