import RemoveIcon from '@mui/icons-material/RemoveCircle';
import { IconButton, TableCell, TableRow } from '@mui/material';
import NumberInput from 'components/inputBox/NumberInput';
import SoftTypography from 'components/SoftTypography';
import UploadButtons from 'components/button/UploadButtons';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Month from '../../../components/month/Month';
import "../../../styles/Cost/CostSt.css";
import ClassificationList from './ClassificationList';
import InputType from './InputType';


const EmployeeBenefitList = (props) => {

    const {
        rowindex, 
        removeDetailRow, 
        item,
        _onDragOver, 
        _onDragStart, 
        _onDragEnd, 
        _onDrop, 
        _onDragLeave,
        costBenefitMap,
        setCostBenefitMap,
        sumAmount
    } = props;

    
    const removeRow = () => {
        Swal.fire({
            title: '삭제 하시겠습니까?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '삭제',
            cancelButtonText: '취소',
            backdrop: false
        }).then((result) => {
            if (result.isConfirmed) {
                removeDetailRow(rowindex)
                Swal.fire({
                    title: '삭제 성공',
                    icon: 'success',
                    backdrop: false
                })
            }
        })
    }

    //날짜 받아오기
    const [date, setDate] = useState(item.other_date);
    //용도구분
    const [classification, setClassification] = useState(item.other_category)
    //내용
    const [otherDetail, setOtherDetail] = useState(item.other_contents)
    //인원수 체크
    const [otherPersonnel, setOtherPersonnel] = useState(item.other_personnel)
    //영수증 이미지
    const costFileNo = item.cost_fno ? item.cost_fno : 0;
    const [costFilename, setCostFilename] = useState(item.cost_filename)
    const [costFile, setCostFile] = useState(null)
    const [costFilenameReal, setCostFilenameReal] = useState(item.cost_filename_real)
    //금액
    const [otherAmount, setOtherAmount] = useState(item.other_amount)
    //결제수단
    const [otherPaytype, setOtherPaytype] = useState(item.other_paytype)
    // 총 금액
    // const setSumAmount = useSetRecoilState(TotalAmount);


    // const amountSum = () => {
    //     let total = 0;
    //     costBenefitMap.forEach((value, key) => {
    //         const otherAmount = value.other_amount;
    //         if (otherAmount === "" || otherAmount === null) {
    //             total += 0;
    //         } else {
    //             total += parseInt(otherAmount);
    //         }
    //     });
    //     setSumAmount(total)
    // }


    useEffect(() => {
        costBenefitMap.set(rowindex, {
            employee_benefit_detail: rowindex,
            other_date: date,
            other_category: classification,
            other_contents: otherDetail,
            other_personnel: otherPersonnel,
            cost_fno: costFileNo,
            cost_filename: costFilename,
            cost_file: costFile,
            cost_filename_real: costFilenameReal,
            other_amount: otherAmount,
            other_paytype: otherPaytype,
            other_fueldo: ""
        })
    }, [date, classification, otherDetail, otherPersonnel, costFilename, otherPaytype])


    useEffect(()=>{
        costBenefitMap.set(rowindex, {
            employee_benefit_detail: rowindex,
            other_date: date,
            other_category: classification,
            other_contents: otherDetail,
            other_personnel: otherPersonnel,
            cost_fno: costFileNo,
            cost_filename: costFilename,
            cost_file: costFile,
            cost_filename_real: costFilenameReal,
            other_amount: otherAmount,
            other_paytype: otherPaytype,
            other_fueldo: ""
        })
        sumAmount()
    },[otherAmount])

    return (
        <>
            {/* 2023-06-08  드래그앤드롭버튼 추가 및 TableCell css 수정 */}
            {/* 2024-04-24  쿼리로 date별로 정렬 되기도하고 오류나서 주석 처리 */}
            <TableRow key={rowindex} sx={{ width: "100%" }} 
                // onDragOver={_onDragOver} 
                // onDragStart={(e) => { _onDragStart(e, item) }} 
                // onDrop={(e) => { _onDrop(e, item) }}
                >
                {/* <TableCell sx={{ textAlign: "right",  cursor: "pointer" , lineHeight : 0 , paddingY :0.5}}>
                    <SoftTypography draggable>::</SoftTypography>
                </TableCell> */}
                <TableCell sx={{ width: "max-content", textAlign: "-webkit-right", paddingLeft: "5px", paddingRight: "5px" , lineHeight : 0 , paddingY :0.5}}>
                    {/* 2023-06-02 costType="cost" 추가 */}
                    <Month type='date' num='10' name='일자' setDate={setDate} temp={date} costType="cost" />
                </TableCell>
                <TableCell sx={{ textAlign: "-webkit-right", paddingLeft: "5px", paddingRight: "5px" , lineHeight : 0 , paddingY :0.5}}>
                    <ClassificationList setClassification={setClassification} temp={classification} category="COST_EMPLOYEE_BENEFITS" />
                </TableCell>
                <TableCell sx={{ textAlign: "-webkit-right", paddingLeft: "5px", paddingRight: "5px" , lineHeight : 0 , paddingY :0.5}}>
                    <InputType type='text' value={setOtherDetail} temp={otherDetail} />
                </TableCell >
                <TableCell sx={{ paddingLeft: "5px", paddingRight: "5px" , lineHeight : 0 , paddingY :0.5}} >
                    <NumberInput endMent='person' value={setOtherPersonnel} temp={otherPersonnel} />
                </TableCell >
                {/* 영수증 파일 첨부 부분 */}
                <TableCell sx={{ paddingX : "0px" }}>
					<UploadButtons 
						key={rowindex+"filename"} 
						theme = "icon"
						value = {costFilename}
						setValue = {setCostFilename}
						setFile = {setCostFile}
					/> 	
				</TableCell>
                <TableCell sx={{ paddingLeft: "5px", paddingRight: "5px" , lineHeight : 0 , paddingY :0.5}}>
                    <NumberInput endMent='attach_money' value={setOtherAmount} temp={otherAmount} />
                </TableCell >
                <TableCell sx={{ paddingLeft: "5px", paddingRight: "5px" , lineHeight : 0 , paddingY :0.5}}>
                    <ClassificationList setClassification={setOtherPaytype} temp={otherPaytype} category="PAY_METHOD" />
                </TableCell>
                <TableCell sx={{ paddingLeft: "5px", paddingRight: "5px" , lineHeight : 0 , paddingY :0.5}} >
                    <IconButton aria-label="delete" onClick={removeRow}>
                        <RemoveIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        </>
    )
}

export default EmployeeBenefitList