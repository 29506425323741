import {useState, useEffect} from "react";
import {Routes, Route, useNavigate, useLocation} from "react-router-dom";
import SignIn from "layouts/authentication/sign-in"
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import routes from "routes";
import {useSoftUIController, setOpenConfigurator} from "context";
import brand from "assets/images/logo-nasdoc.png";
import {useRecoilState} from "recoil";
import {loginUser} from "atom/member/loginUser";
import Swal from "sweetalert2";
import {useQuery} from "react-query";
import getCookie from "./api/auth/getCookie";
import DeleteCookie from "./api/auth/deleteCookie";

function App() {

    //리코일에 로그인정보 저장.
    const [user, setUser] = useRecoilState(loginUser);
    const [isAdmin, setIsAdmin] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    const [isGuest, setIsGuest] = useState(false);
    const [haveInfo, setHaveInfo] = useState();


    const {data: cookie, isLoading} = useQuery("getCookie", async () => await getCookie(), {
        enabled: location.pathname !== '/' && location.pathname !== '/errorPage/401' && location.pathname !== '/errorPage/403',
        onSuccess: (data) => {
            const userInfo = data.data;
            if (userInfo !== null && userInfo !== "") {
                try {
                    setUser(userInfo);
                    if (userInfo.role === "GUEST") {
                        setIsGuest(true);
                    }
                    if (userInfo.rank && userInfo.team) {
                        setHaveInfo(true);
                    } else {
                        setHaveInfo(false);
                    }
                    (userInfo.role === "ADMIN" || userInfo.role === "MASTER") ? setIsAdmin(true) : setIsAdmin(false)
                } catch (e) {
                    console.log(e)
                    navigate('/', {state: 1});
                }
            }
        },
        onError: (err) => {
            console.log(err);
            navigate('/', {state: 1});
        }
    })

    const [controller, dispatch] = useSoftUIController();
    const {miniSidenav, direction, layout, openConfigurator, sidenavColor} = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const [rtlCache, setRtlCache] = useState(null);


    // Change the openConfigurator state
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                if (route.showOnlyForAdmin && !isAdmin) {
                    return null;
                }
                return getRoutes(route.collapse);
            }

            if (route.route) {
                if (route.showOnlyForAdmin && !isAdmin) {
                    return null;
                }
                return (
                    <Route
                        exact
                        path={route.route}
                        key={route.key}
                        user={user}
                        element={route.component}
                    />
                );
            }
            return null;
        });

    function scrollToTop() {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    const configsButton = (
        <>
            <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="3.5rem"
                height="3.5rem"
                bgColor="white"
                shadow="sm"
                borderRadius="50%"
                position="fixed"
                right="2rem"
                bottom="6rem"
                zIndex={99}
                color="dark"
                sx={{cursor: "pointer"}}
                onClick={scrollToTop}
            >
                <Icon fontSize="default" color="inherit">
                    arrow_upward
                </Icon>
            </SoftBox>
            <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="3.5rem"
                height="3.5rem"
                bgColor="white"
                shadow="sm"
                borderRadius="50%"
                position="fixed"
                right="2rem"
                bottom="2rem"
                zIndex={99}
                color="dark"
                sx={{cursor: "pointer"}}
                onClick={handleConfiguratorOpen}
            >
                <Icon fontSize="default" color="inherit">
                    settings
                </Icon>
            </SoftBox>
        </>
    );

    useEffect(() => {
        if (isGuest) {
            navigate("/errorPage/403");
        }
    }, [isGuest])

    useEffect(() => {
        if (haveInfo === false) {
            navigate("/errorPage/403");
        }
    }, [haveInfo])


    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            {layout === "dashboard" && (

                <>
                    {/* {!isGuest && haveInfo ? */}
                        <>
                            <Sidenav
                                color={sidenavColor}
                                brand={brand}
                                brandName="NasDoc"
                                routes={isAdmin ? routes : routes.filter((route) => !route.showOnlyForAdmin)}
                                // onMouseEnter={handleOnMouseEnter}
                                // onMouseLeave={handleOnMouseLeave}
                            />
                            {/* 사이드바 설정 탭 */}
                            <Configurator/>
                            {configsButton}
                        </>
                    {/* } */}
                </>
            )}

            <Routes>
                {getRoutes(routes)}
                <Route path="/" element={<SignIn/>}/>
            </Routes>
        </ThemeProvider>
    );
}

export default App;
