import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 복지포인트-관리자 페이지 문서 목록 
 * @param {*} body 
 * @returns 
 */

const wpAdminAllDocList =(body)=>{
    return customAxios.post(myIpAddress+'/wp/wpAdminAllDocList', body ); 
}
export default wpAdminAllDocList;
