import {atom} from "recoil";

/**
 * 복지포인트 임시 저장 페이지 row 
 * @type {  RecoilState< [{ key : value }] >  }
 */


export const wpTemporalListRowAtom = atom({
    key: "wpTemporalListRowAtom",
    default: [{
        wp_rowIndex : 0
        , wp_no : 0
        , wp_dno : 0
        , wp_fno : 0
        , wp_detail_date : ""
        , wp_detail_update : ""
        , wp_category : ""
        , wp_content : ""
        , wp_filename : ""
        , wp_file : ""
        , wp_filename_real : ""
        , wp_file_date : ""
        , wp_usedpoint : 0
        , wp_paytype : ""
        , wp_usedate : ""
    }]
});