import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 휴가 해당 달의 데이터
 * @param now_page : int-현재 페이지
 * @param docs_num : int-페이지당 문서 갯수
 * @param ascending : String-정렬 순서
 * @param year : String-년도
 * @param month : String-월
 * @param approvedList : List<String>-결재코드리스트
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const vacationYearMonthData =({
                                  now_page,
                                  docs_num,
                                  ascending,
                                  year,
                                  month,
                                  approvedList
                              })=>{
    return customAxios.post(myIpAddress+'/vacation/vacationFindByYearMonth',
        {
            now_page:now_page,
            docs_num:docs_num,
            ascending:ascending,
            year:year,
            month:month,
            approvedList:approvedList
        });
}
export default vacationYearMonthData;
