import {useRecoilState, useRecoilValue} from "recoil";
import {CostMaintitle, CostSentPageNumber} from "../../../../../../atom/cost/CostWriteDetail";
import {useQuery} from "react-query";
import CostTable from "../../../../../../examples/Tables/CostTable";
import {loginUser} from "../../../../../../atom/member/loginUser";
import {useEffect, useState} from "react";
import SoftBox from "components/SoftBox";
import { Pagination, Tooltip } from "@mui/material";
import CostStoreLists from "../../../../../../api/cost/CostStoreLists";
import allCommonCodeMap from "api/commonCode/allCommonCodeMap";
import noApprove from "../../../../../../assets/images/-_image.png";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";
import SoftProgress from "components/SoftProgress";
import CostReceptionTable from "../../../../../../examples/Tables/CostTable/CostReceptionTable";
import { FaBan } from "react-icons/fa";
import { getFormattedDateTitle } from "layouts/wp/components/getFormattedDateTitle";



/**
 * 비용처리 컴포넌트
 * @returns {JSX.Element}
 * @constructor
 */
const CostStoreDocs = () => {
    // 유저
    const user = useRecoilValue(loginUser);

    // 선택 사이즈
    const [page, setPage] = useRecoilState(CostSentPageNumber);
    const [size, setSize] = useState(10);
    //
    const [documentStateSort, setDocumentStateSort] = useState("");


    // 컬럼
    const columns = [
        { key: "cost_no", name: "번호", align: "center" , width: "10%"},
        { key: "category_info", name: "문서정보", align: "left" , width: "35%"},
        { key: "mem_name", name: "작성자", align: "left" , width: "23%"},
        { key: "approvalList", name: "결재자", align: "left" , width: "12%"},
        { key: "dateformat", name: "작성일", align: "center", width: "20%"},
    ];

    // 문서정보를 가져올 배열
    const rows = [];
    let reExamination = false;

    const { data, isSuccess } = useQuery(['CostStoreLists', user.id, page, size], () => CostStoreLists(user.id, page, size), {
        select: (data) => {
            console.log(data.data)
            return data.data;
        }
    });


    const allCommonCodesQuery = useQuery(
        ["allCommonCodeMap"],
        () => allCommonCodeMap(),
        {
            staleTime: 120000,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            initialData: [],
        }
    );

    // page 변경
    function handlePageChange(event, value) {
        setPage(value)
    }

    let quotient = 1;
    
    if (isSuccess && allCommonCodesQuery.isSuccess) {
        const costList = data;
        quotient = Math.ceil(costList[0]?.cost_doc_count / size);
        const codeMap = allCommonCodesQuery.data.data; console.log(costList[0])

        const approvalList = [];
        let progress;
        
        for (let i = 0; i < costList.length ; i ++) {
            const costRowNo = costList[i]?.cost_no;
            //const costCategory = "[" + costList[i]?.category_info + "] " + costList[i]?.dateformat;
            const costCategory = "[" + costList[i]?.category_info + "] " + costList[i]?.dateformat;
            const dateformat = costList[i]?.dateformat;
            const costWriter = "[" + costList[i]?.team_info + "] " + costList[i]?.mem_name + " " + costList[i]?.rank_info;
            const approverDataList = costList[i]?.approvalList
            const approverSituation = costList[i]?.approved_info;
            const approval_approvers = costList[i]?.approval_approvers;
            const approval_do = costList[i]?.approval_do;
            const approved = costList[i]?.cost_approved;

            progress = (approval_do / approval_approvers) * 100;

            const approvalList = [];
            
            if(approverDataList.length > 0 && (approverDataList[0].approver_name === null || approverDataList[0].approver_name === "")) {
                const tinyInfoSetList = [];
                // 저장용 리스트에 저장
                tinyInfoSetList.push(noApprove);
                tinyInfoSetList.push("무결재 문서(결재자 없음)");

                // 저장용 리스트에 저장
                approvalList.push(tinyInfoSetList);
            } else if (approverDataList.length === 0 ) {
                const tinyInfoSetList = [];
                // 저장용 리스트에 저장
                tinyInfoSetList.push(noApprove);
                tinyInfoSetList.push("무결재 문서(결재자 없음)");

                // 저장용 리스트에 저장
                approvalList.push(tinyInfoSetList);
            } else {
                for (let j = 0; j < approverDataList.length; j++) {
                    const tinyInfoSetList = [];
                    const approverRank = codeMap[approverDataList[j].approver_rank]?.info;
                    const approverName = approverDataList[j].approver_name;
                    const approverLevel = approverDataList[j].approver_level;
                    const approverImg = approverDataList[j].approver_img;
                    tinyInfoSetList.push(approverImg);
                    tinyInfoSetList.push("[" + (approverLevel + 1) + "번 결재자] " + approverName + " " + approverRank);
                    approvalList.push(tinyInfoSetList);
                }
            }

            const costRows = {
                cost_no: costRowNo,
                category_info: costCategory,
                mem_name: costWriter,
                approvalList: approvalList,
                dateformat: dateformat,
            }
            rows.push(costRows)

        }
        // data.map((list) => {
        //     let row = {};
        //     if (documentStateSort === "임시보관" && list.cost_approved === 'AP005') {
        //         columns.map((col) => {
        //             row[col.name] = list[col.key] + (list[col.key2] ? " " + list[col.key2] : "")
        //         })
        //         rows.push(row)
        //     } else if (documentStateSort === "") {
        //         columns.map((col) => {
        //             row[col.name] = list[col.key] + (list[col.key2] ? " " + list[col.key2] : "")
        //         })
        //         rows.push(row)
        //     }
        // })
        // if(data[0].count>0){
        //     reExamination = true;
        // }
    }


    const avatars = (members) =>
        members.map(([image, name], index) => (
            <Tooltip key={name + index} title={name} placeholder="bottom">
                <SoftAvatar
                    src={image}
                    alt="name"
                    size="xs"
                    sx={{
                        border: ({ borders: { borderWidth }, palette: { white } }) =>
                            `${borderWidth[2]} solid ${white.main}`,
                        cursor: "pointer",
                        position: "relative",

                        "&:not(:first-of-type)": {
                            ml: -1.25,
                        },

                        "&:hover, &:focus": {
                            zIndex: "10",
                        },
                    }}
                />
            </Tooltip>
    ));



    /**
     * row에 데이터 렌더링
     * @returns {*[]}
     */
    const tabelDataRender = () => {
        const dataArr = []; console.log(rows)
        for (let i = 0; i < rows.length; i++) {
            dataArr.push(
                {
                    번호: rows[i].cost_no,
                    문서정보: rows[i].category_info,
                    작성자: rows[i].mem_name,
                    결재자: (
                        <SoftBox display="flex" py={1}>
                            {avatars(
                                rows[i].approvalList
                            )
                            }
                        </SoftBox>
                    ),
                    작성일: rows[i].dateformat,
                }
            );
        }
        return dataArr;
    };



    return(
        <>
            <CostReceptionTable columns={columns} rows={tabelDataRender()} />
            <SoftBox display='flex' justifyContent='center'>
                <Pagination
                    count={quotient}
                    page={page}
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton
                />
            </SoftBox>
        </>
    );

}

export default CostStoreDocs;