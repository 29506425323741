import { useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SoftInput from 'components/SoftInput';
import styled from 'styled-components';

export default function NumberInput(props) {
  const [value, setValue] = useState(props.temp);

  const handleChange = (event) => {
    const inputValue = event.target.value;
    // 입력된 값에서 숫자가 아닌 문자를 모두 제거하여 숫자값만 추출
    const numericValue = inputValue.replace(/[^0-9]/g, '');
    // 숫자값을 천의 자리마다 콤마로 구분하여 포맷팅
    const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    let newValue = formattedValue;
    if (numericValue.length > 1 && numericValue[0] === '0') {
      // 첫번째 글자가 0이고, 길이가 2 이상인 경우, 맨 앞의 0 제거
      newValue = numericValue.slice(1).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    setValue(newValue);
    props.value(numericValue);
  };

  
  const icon = {
    component: props.endMent, // 오른쪽 끝에 배치할 아이콘 지정
    direction: props.end ? props.end : "right", // 아이콘을 오른쪽에 배치할 것이므로 "right"로 설정
  };


  return (
    <SoftInput
      variant="outlined"
      size="normal"
      label="숫자 입력"
      error={props.error}
      success={props.success}
      disabled={props.disabled}
      icon={icon} 
      value={value}
      onChange={handleChange}
    />
  );
}