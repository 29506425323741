import {atom, selector} from "recoil";

/**
 * 모든 유저
 * @type {RecoilState<number>}
 */
export const allUserVacationMonthAtom = atom({
    key: "allUserVacationMonthAtom"
    , default: {
        totalCount: 0,
        nowPage: 1,
        docsNum: 10,
        ascending: 'ASC',
        year: new Date().getFullYear(),
        month: new Date().getMonth()+1,
        approvedList: ['AP001', 'AP002', 'AP003', 'AP004'],
    }
})

/**
 * month 0앞에 붙여서 문자열로 리턴
 * @type {RecoilValueReadOnly<unknown>}
 */
export const customMonthSelector = selector({
    key: "customMonthSelector",
    get: ({ get }) => {
        const allUserVacationMonth = get(allUserVacationMonthAtom);
        const { month } = allUserVacationMonth;

        // 조건에 따라 customYear 값을 반환
        let customMonth;
        if (month.toString().length === 1) {
            customMonth = "0"+String(month);
        } else {
            customMonth = String(month);
        }

        return customMonth;
    },
});