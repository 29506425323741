import {atom, selector} from "recoil";

/**
 * pdf 여는 값
 * @type {RecoilState<number>}
 */
export const vacationAdminPdfAtom = atom({
    key: "vacationAdminPdfAtom"
    , default: {
        docNo: 7489,
        flag: true,
    }
})