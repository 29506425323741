import {useMemo} from "react";
import PropTypes from "prop-types";
import {Bar} from "react-chartjs-2";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import BarReportsChartItem from "examples/Charts/BarCharts/ReportsBarChart/ReportsBarChartItem";
import configs from "layouts/vacation/components/VacationBarChart/configs";
import {Chart, registerables} from "chart.js";
import Loading from "../../../../components/loading/Loading";

function VacationBarChart({color, title, description, chart, items, chartOn}) {
    Chart.register(...registerables);

    const {monthData, options} = configs(chart.labels || [], chart.datasets || {});

    if (items[0].load !== true) {
        return (
            <Loading/>
        )
    }

    const renderItems = items.map(({icon, label, progress}) => (
            <BarReportsChartItem
                color={color}
                icon={{color: icon.color, component: icon.component}}
                label={label}
                progress={{content: progress.content, percentage: progress.percentage}}
            />
    ));

    return (
        <Card sx={{width: "100%", height: "100%"}}>
            <Grid container m={2}>
                {/*<Grid item xs={12} pt={2.5} pl={2.5}display="flex" justifyContent="space-between" alignItems="center">*/}
                    {/* 휴가 일수 정보 */}
                <Grid item container spacing={1} xs={12}  display="flex" justifyContent="space-between" alignItems="flex-start">
                    {renderItems}
                </Grid>
                {/*</Grid>*/}
                {/* 당해년도 사용 휴가 정보 */}
                {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
                {useMemo(
                    () => (
                        chartOn && (
                            <Grid item xs={12} pl={-1} pr={3}>
                                <SoftBox
                                    variant="gradient"
                                    bgColor={color}
                                    borderRadius="lg"
                                    py={2}
                                    pr={2}
                                    mt={2}
                                    ml={-1}
                                    height="12.5rem"
                                >
                                    <Bar data={monthData} options={options}/>
                                </SoftBox>
                            </Grid>
                        )
                    ),
                    [chart, color, chartOn]
                )}
            </Grid>
        </Card>
    );
}

// Setting default values for the props of ReportsBarChart
VacationBarChart.defaultProps = {
    color: "dark",
    description: "",
    items: [],
};

// Typechecking props for the ReportsBarChart
VacationBarChart.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    title: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
    items: PropTypes.arrayOf(PropTypes.object),
};

export default VacationBarChart;
