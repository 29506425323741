/**
 * date 데이터를 받아 10일 기준으로 사용일 datepicker 시작일과 끝일 리턴 
 * @param date 
 * @returns { minDate: minDate, maxDate:maxDate}
 */
 export function DateBeforeAndAfterTenthDay(date){

    const year = date.getFullYear();
	const month = date.getMonth();
	const day = date.getDate();

	/** 사용일 선택 범위 시작 */
	let minDate;
	/** 사용일 선택 범위 끝 */
	let maxDate;

	// 1월일 경우
	if (month === 0) {
		// 1월 이면서 10일 이내 
		if (day <= 10) {
			const lastYear = year - 1;
			minDate = new Date(lastYear, 11, 1);	// lastYear-12-01 
			maxDate = new Date(lastYear, 12, 0);	// lastYear-12-31
		} else {
			minDate = new Date(year, month, 1);
			maxDate = new Date(year, month + 1, 0);
		}
	} else {
		if (day <= 10) {
			minDate = new Date(year, month - 1, 1);
			maxDate = new Date(year, month, 0);
		} else {
			minDate = new Date(year, month, 1);
			maxDate = new Date(year, month + 1, 0);
		}
	}

    const dateRange = { minDate: minDate, maxDate:maxDate}

    return dateRange;
}