import styled, {keyframes} from 'styled-components';
import SoftBox from "../../../../components/SoftBox";
import Icon from "@mui/material/Icon";
import SoftTypography from "../../../../components/SoftTypography";
import Grid from "@mui/material/Grid";
import colors from "../../../../assets/theme/base/colors";

const fadeInOut = keyframes`
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const DirectionVertical = styled.div`
`;

const Direction = styled.div`
  opacity: 0;
  position: relative;
  color: ${props => props.color};
  margin: ${props => `${props.m}px` || "0px"};

  &:nth-of-type(1) {
    animation: ${fadeInOut} 2s infinite;
  }

  &:nth-of-type(2) {
    animation: ${fadeInOut} 2s infinite;
    animation-delay: 0.5s;
  }

  &:nth-of-type(3) {
    animation: ${fadeInOut} 2s infinite;
    animation-delay: 1.0s;
  }
  
  &:nth-of-type(4) {
    animation: ${fadeInOut} 2s infinite;
    animation-delay: 1.5s;
  }
`;

const ReverseDirection = styled.div`
  opacity: 0;F
  position: relative;
  color: ${props => props.color};
  margin: ${props => `${props.m}px` || "0px"};

  &:nth-of-type(1) {
    animation: ${fadeInOut} 2s infinite;
    animation-delay: 1.5s;
  }

  &:nth-of-type(2) {
    animation: ${fadeInOut} 2s infinite;
    animation-delay: 1.0s;
  }

  &:nth-of-type(3) {
    animation: ${fadeInOut} 2s infinite;
    animation-delay: 0.5s;
  }

  &:nth-of-type(4) {
    animation: ${fadeInOut} 2s infinite;
  }

`;

/**
 * 방향을 표시하는 css 애니메이션이 적용된 꺽쇄
 * @param color : String-colorCode
 * @param vertical : boolean-수직여부
 * @param reverse : boolean-역방향여부
 */
function DirectionAnimation({color, vertical, reverse}) {

    const {badgeColors} = colors;

    /**
     * 수평 방향
     * @type {unknown[]}
     */
    const directionHorizon = Array.from({length: 3}).map((_, index) => (
        <Direction key={"horizon" + index} color={color} m={-4} >
            <Icon fontSize="medium" sx={{fontWeight: "bold"}}>chevron_right</Icon>
        </Direction>
    ));

    /**
     * 역수평 방향
     * @type {unknown[]}
     */
    const directionReverseHorizon = Array.from({length: 3}).map((_, index) => (
        <ReverseDirection key={"horizon" + index} color={color} m={-4}>
            <Icon fontSize="medium" sx={{fontWeight: "bold"}}>chevron_left</Icon>
        </ReverseDirection>
    ));

    /**
     * 수직 방향
     * @type {unknown[]}
     */
    const directionVertical = Array.from({length: 2}).map((_, index) => (
        <Direction m={-8} key={"vertical" + index} color={color}>
            <Icon fontSize="large" sx={{fontWeight: "bold"}}>expand_more</Icon>
        </Direction>
    ));

    /**
     * 역수직 방향
     * @type {unknown[]}
     */
    const directionReverseVertical = Array.from({length: 2}).map((_, index) => (
        <ReverseDirection m={-8} key={"vertical" + index} color={color}>
            <Icon fontSize="large" sx={{fontWeight: "bold"}}>expand_less</Icon>
        </ReverseDirection>
    ));

    return (
        <>
            {vertical ?
                reverse ?
                    // 역수직일때
                    <DirectionVertical>
                        {directionReverseVertical}
                    </DirectionVertical>
                    :
                    // 수직일때
                    <DirectionVertical>
                        {directionVertical}
                    </DirectionVertical>
                :
                reverse ?
                    // 역수평일때
                    <Grid container display="flex" justifyContent="space-between" alignItems="center">
                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="flex-end"
                              style={{overflow: 'hidden'}}>
                            {directionReverseHorizon}
                        </Grid>
                    </Grid>
                    :
                    // 수평일때
                    <Grid container display="flex" justifyContent="space-between" alignItems="center">
                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="flex-end"
                              style={{overflow: 'hidden'}}>
                            {directionHorizon}
                        </Grid>
                    </Grid>
            }
        </>
    );
};

export default DirectionAnimation;