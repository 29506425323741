/**
 * timestamp를 받아서 yyyy-MM-dd hh:mm 포맷으로 변경해주는 유틸
 * @param timestamp : timestamp
 * @returns {string}
 */
export function TimestampToStringDate(timestamp){

    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hour}:${minute}`;
    return formattedDate;
}

/**
 * String "yyyy-MM-dd"를 받아서 date 형태로 변환
 * @param stringDate : String
 * @returns Date
 */
export function StringToDate(stringDate){
    return new Date(stringDate);
}

/**
 * String "yyyy-MM-dd"의 시작일, 종료일 받아서 두 날짜 차이 리턴
 * @param startDate : String
 * @param endDate : String
 * @returns int
 */
export function DiffTwoDate(startDate, endDate) {

    // 날짜 문자열을 Date 객체로 변환
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);

    // Epoch 시간 계산
    const epochTime1 = date1.getTime();
    const epochTime2 = date2.getTime();

    // 두 날짜 간의 차이 계산 (밀리초 단위)
    const diffInMs = epochTime2 - epochTime1;

    // 일 단위로 변환 및 리턴
    const diffDay = diffInMs / (1000 * 60 * 60 * 24);
    if(diffDay === 0){
        return 1;
    } else {
        return diffDay;
    }
}

/**
 * timestamp 형식데이터를 년월일로 리턴
 * @param timestamp : timestamp
 * @returns Map{String:Obj}
 */
export function TimestampToYMD(timestamp){

    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return {"year": year, "month": month, "day": day};
}
