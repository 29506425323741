// eslint-disable-next-line import/extensions,import/no-unresolved
import { customAxios } from "../../axios/CustomAxios";
// eslint-disable-next-line import/extensions
import { myIpAddress } from "../../config";

// 전체 자산 리스트
const getCountByAssCate =()=>{
    return customAxios.get(myIpAddress+'/asset/count/all');
}
 
export default getCountByAssCate;