import { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './printCss.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const WpBills = ({printRef, imgList}) => {

    const [numPages, setNumPages] = useState(0);

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    return (
        <div ref={printRef}>
            {imgList.map((img, index) => {
                return (
                    <div key={index} style={{ width: '210mm', height: '297mm' }}>
                        {/* 파일이 pdf일 때 / img일 때 구분 */}
                        {img.endsWith('.pdf') ? (
                            <Document file={img} onLoadSuccess={onDocumentLoadSuccess}>
                                {Array.from({ length: numPages }, (_, pageIndex) => (
                                    <Page key={`page_${pageIndex + 1}`} pageNumber={pageIndex + 1} /> 
                                ))}
                            </Document>
                        ) : (
                            <img
                                key={index}
                                src={img}
                                style={{  objectFit: 'contain' }}
                                // width={"100%"}
                                // height={"100%"}
                                >
                            </img>
                        )}
                    </div>
                )
            })}
        </div>
    )
};

export default WpBills;
