import { customAxios } from '../../axios/CustomAxios';
import {myIpAddress} from "../../config";

/**
 * 전체 휴가 리스트
 * @returns {Promise<axios.AxiosResponse<any>>}
 * @constructor
 */
const refViewCheckUpdate =(body)=>{
    return customAxios.post(myIpAddress+'/ref/referrerViewCheck',body);
}
export default refViewCheckUpdate;