import {atomFamily } from "recoil";

/**
 * 결재자 no
 * @type {(param: SerializableParam) => RecoilState<{NO: string, keyName: *, category: string}>}
 */
export const nameCompleteInputValueAtomFamily = atomFamily({
    key: "nameCompleteInputValueAtomFamily",
    default: (key) => ({
        category: "NO",
        NO:"",
        keyName: key
    }),
})

