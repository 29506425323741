import refViewCheckUpdate from "../api/refer/refViewCheckUpdate";
import { useState } from "react";
import { useMutation } from "react-query";
import Swal from "sweetalert2";


export function ReferrerCheck({ referrerList, userNo, approvalNo }) {
    // 참조자 문서열람 여부 업데이트
    const refViewCheckBody = useMutation(refViewCheckUpdate, {
        onSuccess: (data) => {
            Swal.fire({
                title: '참조 확인 되었습니다.',
                icon: 'success',
                showCancelButton: true,
                backdrop: false
            })
        },
        onError: () => {
            console.log('참조자 열람 업데이트 중 에러')
        }
    });

    for (let i = 0; i < referrerList.length; i++) {
        if (referrerList[i]['ref_mem_no'] === userNo && referrerList[i]['ref_regdate'] === null) {
            const refBody = {
                ref_mem_no: userNo,
                approval_no: approvalNo
            }
            refViewCheckBody.mutate(refBody);
            break;
        }
    }

}
export function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}
